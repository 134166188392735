import React from 'react';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Checkbox,
  CheckboxRow,
  DatePicker,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Link,
  RadioButtonGroup,
  RadioButtonRow,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  ImageBackground,
  Modal,
  ScrollView,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ConfettiCannon from '../custom-files/ConfettiCannon';
import * as Track from '../custom-files/Track';
import * as convertTime from '../custom-files/convertTime';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import * as validatePayFreezeStatus from '../custom-files/validatePayFreezeStatus';
import Haptic from '../global-functions/Haptic';
import addLessonToCalendar from '../global-functions/addLessonToCalendar';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import convertTimeFormatString from '../global-functions/convertTimeFormatString';
import convertTimeFromLocalMachine from '../global-functions/convertTimeFromLocalMachine';
import convertTimeStringToISO from '../global-functions/convertTimeStringToISO';
import getLongLessonName from '../global-functions/getLongLessonName';
import getNextLessonID from '../global-functions/getNextLessonID';
import knackFilterParamsAllLessonsInPackage from '../global-functions/knackFilterParamsAllLessonsInPackage';
import triggerCannon from '../global-functions/triggerCannon';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  action: '',
  lessonKnackId: '630478a95d099c0021dc79b6',
  lessonPackageKnackId: '630478a09b4c150023c6d944',
};

const LessonDetailsAndManagementScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [addressObject, setAddressObject] = React.useState({});
  const [allLessonsInPackage, setAllLessonsInPackage] = React.useState([]);
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [clientName, setClientName] = React.useState('');
  const [completionDate, setCompletionDate] = React.useState(0);
  const [completionDateBeforeNow, setCompletionDateBeforeNow] =
    React.useState(true);
  const [confirmLessonSchedule, setConfirmLessonSchedule] =
    React.useState(false);
  const [confirmedCompletionDate, setConfirmedCompletionDate] = React.useState(
    Constants['empty']
  );
  const [currentLessonIndex, setCurrentLessonIndex] = React.useState(0);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [dateTimeScreenOpened, setDateTimeScreenOpened] = React.useState(
    new Date()
  );
  const [enableAddToCalendar, setEnableAddToCalendar] = React.useState(true);
  const [enableForfeitButton, setEnableForfeitButton] = React.useState(false);
  const [forfeitAgreementCount, setForfeitAgreementCount] = React.useState(0);
  const [forfeitAgreementPayHalf, setForfeitAgreementPayHalf] =
    React.useState(false);
  const [forfeitAgreementPayQuarter, setForfeitAgreementPayQuarter] =
    React.useState(false);
  const [forfeitAgreementRelinquish, setForfeitAgreementRelinquish] =
    React.useState(false);
  const [forfeitBeforeOrAfterSiTravel, setForfeitBeforeOrAfterSiTravel] =
    React.useState('');
  const [forfeitContactEmail, setForfeitContactEmail] = React.useState(false);
  const [forfeitContactNone, setForfeitContactNone] = React.useState(false);
  const [forfeitContactPhone, setForfeitContactPhone] = React.useState(false);
  const [forfeitContactText, setForfeitContactText] = React.useState(false);
  const [forfeitDate, setForfeitDate] = React.useState(Constants['empty']);
  const [forfeitDateBeforeNow, setForfeitDateBeforeNow] = React.useState(true);
  const [forfeitReasonImproperNotice, setForfeitReasonImproperNotice] =
    React.useState(false);
  const [forfeitReasonMax, setForfeitReasonMax] = React.useState(false);
  const [initialDatePickerValue, setInitialDatePickerValue] = React.useState(
    new Date()
  );
  const [isBonusPayLesson, setIsBonusPayLesson] = React.useState(false);
  const [lessonCalendarID, setLessonCalendarID] = React.useState('');
  const [lessonDuration, setLessonDuration] = React.useState(0);
  const [lessonId, setLessonId] = React.useState('');
  const [lessonKnackId, setLessonKnackId] = React.useState('');
  const [lessonName, setLessonName] = React.useState('');
  const [lessonNumber, setLessonNumber] = React.useState(0);
  const [lessonPackageKnackId, setLessonPackageKnackId] = React.useState('');
  const [lessonRating, setLessonRating] = React.useState(0);
  const [lessonStatus, setLessonStatus] = React.useState('');
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [matchedDate, setMatchedDate] = React.useState(0);
  const [modalScheduleVisible, setModalScheduleVisible] = React.useState(true);
  const [nextLessonKnackId, setNextLessonKnackId] = React.useState('');
  const [packageId, setPackageId] = React.useState(0);
  const [packageStatus, setPackageStatus] = React.useState('');
  const [paidDate, setPaidDate] = React.useState(0);
  const [paidStatus, setPaidStatus] = React.useState('');
  const [payBase, setPayBase] = React.useState(0);
  const [payBaseApproved, setPayBaseApproved] = React.useState(0);
  const [payNotesByAdmin, setPayNotesByAdmin] = React.useState('');
  const [payRate, setPayRate] = React.useState(0);
  const [previousLessonCompletionDate, setPreviousLessonCompletionDate] =
    React.useState(new Date());
  const [previousLessonType, setPreviousLessonType] = React.useState('');
  const [saveButtonEnabled, setSaveButtonEnabled] = React.useState(false);
  const [scheduleLessonErrMsg, setScheduleLessonErrMsg] = React.useState('');
  const [scheduleLessonSuccessMsg, setScheduleLessonSuccessMsg] =
    React.useState('');
  const [scheduledDate, setScheduledDate] = React.useState(0);
  const [showCompleteDoubleConfirmation, setShowCompleteDoubleConfirmation] =
    React.useState(false);
  const [showForfeitAgreements, setShowForfeitAgreements] =
    React.useState(false);
  const [
    showForfeitContactRequiredNotice,
    setShowForfeitContactRequiredNotice,
  ] = React.useState(false);
  const [showForfeitContactRequirements, setShowForfeitContactRequirements] =
    React.useState(false);
  const [showForfeitDateField, setShowForfeitDateField] = React.useState(false);
  const [showForfeitFormFinish, setShowForfeitFormFinish] =
    React.useState(false);
  const [showForfeitRelinquishCheck, setShowForfeitRelinquishCheck] =
    React.useState(true);
  const [showLessonEvaluation, setShowLessonEvaluation] = React.useState(false);
  const [showModalCompleteLesson, setShowModalCompleteLesson] =
    React.useState(false);
  const [showModalCompleteLessonSuccess, setShowModalCompleteLessonSuccess] =
    React.useState(false);
  const [showModalContactSuccess, setShowModalContactSuccess] =
    React.useState(false);
  const [showModalContactSunsational, setShowModalContactSunsational] =
    React.useState(false);
  const [showModalForfeit, setShowModalForfeit] = React.useState(false);
  const [showModalForfeitLessonSuccess, setShowModalForfeitLessonSuccess] =
    React.useState(false);
  const [showModalManageLesson, setShowModalManageLesson] =
    React.useState(false);
  const [showModalScheduleLesson, setShowModalScheduleLesson] =
    React.useState(false);
  const [showModalScheduleLessonSuccess, setShowModalScheduleLessonSuccess] =
    React.useState(false);
  const [showSSSContactOptions, setShowSSSContactOptions] =
    React.useState(false);
  const [showSchedLsnScsStep1, setShowSchedLsnScsStep1] = React.useState(false);
  const [showSchedLsnScsStep2, setShowSchedLsnScsStep2] = React.useState(false);
  const [showSendMsgFailed, setShowSendMsgFailed] = React.useState(false);
  const [siContactMessage, setSiContactMessage] = React.useState('');
  const [siLessonComment, setSiLessonComment] = React.useState('');
  const [siLessonStatus, setSiLessonStatus] = React.useState('');
  const [skipConfirmCompletionDate, setSkipConfirmCompletionDate] =
    React.useState(false);
  const [submittedDate, setSubmittedDate] = React.useState('');
  const dateIsBeforeNow = date => {
    const now = new Date();
    return date < now;
  };

  const getPayRateToSubmit = () => {
    let payRate =
      forfeitBeforeOrAfterSiTravel === 'forfeitAfterSiTraveled' ? 0.5 : 0.25;

    return payRate;
  };

  const toPercentage = num => {
    return (num * 100).toFixed() + '%';
  };

  const processSendMsgResponse = (setGlobalVariableValue, response) => {
    if (!response?.success) {
      setShowSendMsgFailed(true);
      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
      return;
    }

    setShowModalContactSunsational(false);
    setShowModalContactSuccess(true);

    setGlobalVariableValue({
      key: 'is_loading',
      value: false,
    });
  };

  const shouldShowPayNoteSpacer = () => {
    return payNotesByAdmin && siLessonStatus === 'Forfeited';
  };

  const prepareLessonRating = () => {
    return lessonRating > 0 ? 'Satisfactory' : 'Unsatisfactory';
  };

  const isLessonStateCompleted = (lessonStatus, paidStatus) => {
    return lessonStatus === 'Completed' && paidStatus !== 'Paid';
  };

  const getCompletionDate = () => {
    console.log('confirmedCompletionDate === ', confirmedCompletionDate);
    return confirmedCompletionDate > new Date('1969-01-01T00:00:00.000Z')
      ? new Date(confirmedCompletionDate.toString()).toLocaleDateString()
      : new Date(scheduledDate.toString()).toLocaleDateString();
  };

  const sortLessons = lessonsArray => {
    return lessonsArray.sort((a, b) => a.field_320_raw - b.field_320_raw);
  };

  const showAlertActionDisabled = disabledAction => {
    let msg;

    if (!disabledAction) {
      msg = 'This action is currently unaivalable.';
    }

    if (disabledAction === 'scheduleLesson') {
      msg =
        'This lesson schedule cannot be modified. This lesson has been marked complete.';
    }

    if (disabledAction === 'completeLesson') {
      msg =
        'This lesson has already been marked complete. To modify this lesson, contact Sunsational.';
    }

    alert(msg);
  };

  const shouldShowForfeitTransitSection = () => {
    return forfeitReasonImproperNotice;
  };

  const clearForfeitContactChecks = noContact => {
    if (noContact) {
      setForfeitContactPhone(false);
      setForfeitContactText(false);
      setForfeitContactEmail(false);
    }
  };

  const shouldShowForfeitContactRequirements = () => {
    return (
      shouldShowForfeitAgreements() &&
      (forfeitAgreementPayQuarter || forfeitAgreementPayHalf) &&
      forfeitAgreementRelinquish
    );
  };

  const shouldShowForfeitFormFinish = () => {
    return (
      shouldShowForfeitContactRequirements() &&
      (forfeitContactPhone || forfeitContactText || forfeitContactEmail) &&
      !forfeitContactNone
    );
  };

  const resetForfeitAgreementCount = () => {
    setForfeitAgreementCount(0);
  };

  // agree
  const updateForfeitAgreementCount = checked => {
    let count = forfeitAgreementCount;

    checked ? count++ : count--;

    setForfeitAgreementCount(count);
  };

  const resetForfeitAgreements = () => {
    setForfeitAgreementRelinquish(null);
    setForfeitAgreementPayQuarter(null);
    setForfeitAgreementPayHalf(null);
  };

  const resetForfeitContactFields = () => {
    setForfeitContactPhone(false);
    setForfeitContactEmail(false);
    setForfeitContactText(false);
  };

  // Check if the lesson's scheduled time is within 24 hours of the time the user began the completion sequence
  const shouldSkipConfirmCompletionDate = scheduledDate => {
    /* skip if swim instructor marks the lesson complete within 2 hours of the time the lesson is scheduled */
    /* this feature is currently suspended */
    /* we will require a lesson completion date for all SIs*/

    /* const now = new Date();
const timeDifference = Math.abs(now.getTime() - scheduledDate);
const skip = timeDifference <= 2 * 60 * 60 * 1000 && scheduledDate < now; 

setSkipConfirmCompletionDate(skip)*/

    setSkipConfirmCompletionDate(false);
  };

  const shouldEnableForfeitButton = () => {
    return (
      shouldShowForfeitFormFinish() &&
      forfeitDate &&
      forfeitDate.toString() !== dateTimeScreenOpened.toString() &&
      forfeitDateBeforeNow &&
      (forfeitContactPhone || forfeitContactEmail || forfeitContactText)
    );
  };

  const isLessonUnscheduled = (lessonStatus, scheduledDate) => {
    return lessonStatus === 'Matched' && !scheduledDate;
  };

  const shouldEnableSaveScheduleButton = (
    timeScreenAccessed,
    scheduleLessonInput,
    confirmedWithClient
  ) => {
    const dateTimeFilled = timeScreenAccessed != scheduleLessonInput;
    dateTimeFilled && confirmedWithClient
      ? setSaveButtonEnabled(true)
      : setSaveButtonEnabled(false);
  };

  const shouldShowLessonEvaluation = () => {
    let show = false;

    if (skipConfirmCompletionDate || confirmedCompletionDate) {
      show = true;
    }

    setShowLessonEvaluation(show);
  };

  const animateLoadingMessage = () => {
    let str = `Contacting client . . .`;
    setLoadingMessage(str);

    setTimeout(() => {
      str = `Client notified ✅`;
      setLoadingMessage(str);
    }, 350);

    setTimeout(() => {
      str = `Client notified ✅ 
Marking lesson as complete . . .`;
      setLoadingMessage(str);
    }, 750);

    setTimeout(() => {
      str = `Client notified ✅ 
Lesson Completed ✅'`;
      setLoadingMessage(str);
    }, 2000);
  };

  const isLessonStateScheduled = (lessonStatus, scheduledDate) => {
    return lessonStatus === 'Matched' && scheduledDate;
  };

  const shouldShowForfeitAgreements = () => {
    if (shouldShowForfeitTransitSection()) {
      return forfeitBeforeOrAfterSiTravel.length > 0;
    }

    return forfeitReasonImproperNotice || forfeitReasonMax;
  };

  const concatenatedForfeitComment = () => {
    let forfeitReason = '';
    let contactMethods = '';

    let siComment = siLessonComment.length > 1 ? siLessonComment : 'none';

    let suggestedPayRate =
      forfeitBeforeOrAfterSiTravel === 'forfeitAfterSiTraveled'
        ? '50%. Instructor states they already were on the way or arrived to the lesson before the client cancelled.'
        : '25%. Instructor states client cancelled before the instructor traveled to the lesson.';

    if (forfeitReasonImproperNotice) {
      forfeitReason += `Improper notice given. `;
    }

    if (forfeitReasonMax) {
      forfeitReason += `Max reschedules given.`;
    }

    if (forfeitContactEmail) {
      contactMethods += `Email `;
    }

    if (forfeitContactText) {
      contactMethods += `Text `;
    }

    if (forfeitContactPhone) {
      contactMethods += `Phone call `;
    }

    let fullForfeitComment = `Forfeit reason: ${forfeitReason} | Contacted client via: ${contactMethods} | Instructor note: ${siComment} | Suggested pay rate: ${suggestedPayRate}`;

    return fullForfeitComment;
  };

  const shouldEnableForfeitSequence = () => {
    if (!previousLessonCompletionDate && currentLessonIndex - 1 > 0) {
      alert(
        'Lessons must be marked complete or forfeit in sequential order. Please check the previous lesson is marked complete or forfeit.'
      );
      return;
    }

    setShowModalManageLesson(false);
    setShowModalForfeit(true);
  };

  const prepareForfeitDatesToSend = () => {
    let fillEmptyScheduledDate;

    setCompletionDate(forfeitDate.toISOString());

    if (scheduledDate === '') {
      fillEmptyScheduledDate = forfeitDate;
    } else {
      fillEmptyScheduledDate = convertTime.toISOformat(
        scheduledDate,
        'MM/DD/YYYY h:mma'
      );
    }

    return {
      scheduledDate: fillEmptyScheduledDate,
      forfeitDate: forfeitDate,
      dateSubmittedForPay: new Date().toISOString(),
    };
  };

  const showAlertPayFreeze = () => {
    const msg =
      'Your completed lessons are under review. This action is unavailable until the review has been completed. For more information, contact Sunsational Swim School.';

    alert(msg);
  };

  const handleScheduleLesssonResponse = response => {
    if (!response.record.id) {
      setScheduleLessonErrMsg(
        'Uh oh. There was an error saving your schedule. Please try again.'
      );
      return;
    }

    setShowModalScheduleLesson(false);
    setShowModalScheduleLessonSuccess(true);

    setTimeout(() => {
      setShowSchedLsnScsStep1(true);
    }, 350);

    setTimeout(() => {
      setShowSchedLsnScsStep2(true);
    }, 850);
  };

  // Extract keys from lesson object and assign them to variables
  const setScreenVariables = async lessonObj => {
    let count = 0;
    const {
      field_320_raw, // lessonNumber
      field_54_raw, // lessonStatus
      field_455_raw, // clientName
      field_58_raw, // packageIDs
      field_98_raw, // addressObject
      field_202, // scheduledDate
      field_534_raw, // paidStatus
      field_69, // payBaseApproved
      field_53_raw, // payBase
      field_231_raw, //lessonPackageStatus
      field_205, // completionDate
      field_312, // matchedDate
      field_313, // paidDate
      field_620, // submittedDate
      field_65_raw, // lessonDuration
      field_68_raw, // payRate
      field_51_raw, // lessonID (######-#)
      field_617_raw, // payNotesByAdmin
      field_201_raw, // siLessonStatus
    } = lessonObj;

    setLessonNumber(field_320_raw);
    setLessonStatus(field_54_raw);
    setClientName(field_455_raw);
    setLessonPackageKnackId(field_58_raw[0].id);
    setPackageId(field_58_raw[0].identifier);
    setAddressObject(field_98_raw);
    setPayBaseApproved(field_69);
    setPackageStatus(field_231_raw);
    setPaidStatus(field_534_raw);
    setLessonDuration(field_65_raw);
    setPayRate(field_68_raw);
    setPayBase(field_53_raw);
    setMatchedDate(convertTime.toLocalMachine(field_312));
    setScheduledDate(convertTime.toLocalMachine(field_202));
    setSubmittedDate(convertTime.toLocalMachine(field_620));
    setCompletionDate(convertTime.toLocalMachine(field_205));
    setPaidDate(convertTime.toLocalMachine(field_313));
    setLessonId(field_51_raw);
    setPayNotesByAdmin(field_617_raw);
    setSiLessonStatus(field_201_raw);
  };

  const prepareCompletionDatesToSend = async () => {
    let fillEmptyScheduledDate;

    setCompletionDate(confirmedCompletionDate.toString());

    if (scheduledDate === '') {
      fillEmptyScheduledDate = confirmedCompletionDate;
    } else {
      fillEmptyScheduledDate = convertTime.toISOformat(
        scheduledDate,
        'MM/DD/YYYY h:mma'
      );
    }

    const preparedDates = {
      scheduledDate: fillEmptyScheduledDate,
      completionDate: confirmedCompletionDate,
      dateSubmittedForPay: new Date().toISOString(),
    };

    return preparedDates;
  };

  const filterLessonsInPackage = () => {
    console.log('');
  };

  // Get the Friday date after the 72 hour hold on completed lessons request
  const getNextPayday = dateLessonSubmittedForPay => {
    let dateLessonSubmittedForPayISO = convertTime.toISOformat(
      dateLessonSubmittedForPay,
      'MM/DD/YYYY h:mma'
    );

    const dateAfter72HourHold = dateLessonSubmittedForPay
      ? new Date(dateLessonSubmittedForPayISO)
      : new Date();
    dateAfter72HourHold.setDate(dateAfter72HourHold.getDate() + 3);
    let dayOfWeek = dateAfter72HourHold.getDay();
    let daysToNextThursday = 4 - dayOfWeek;
    if (daysToNextThursday <= 0) {
      daysToNextThursday += 7;
    }
    const nextPayday = new Date(dateAfter72HourHold);
    nextPayday.setDate(nextPayday.getDate() + daysToNextThursday);
    return nextPayday.toLocaleDateString();
  };

  const handlePreviousAndNextLessonRules = async (
    allLessonsSorted,
    currentLessonKnackID
  ) => {
    const getCurrentLessonIndex = (lessons, currentLessonID) => {
      for (var i = 0; i < lessons.length; i++) {
        if (lessons[i].id === currentLessonID) {
          return i;
        }
      }
    };

    const totalLessons = allLessonsSorted.length;

    const currentLessonIndex = getCurrentLessonIndex(
      allLessonsSorted,
      currentLessonKnackID
    );

    const nextLessonID =
      currentLessonIndex + 1 < totalLessons
        ? allLessonsSorted[currentLessonIndex + 1].id
        : null;

    const previousLessonCompletionDate =
      currentLessonIndex > 0
        ? allLessonsSorted[currentLessonIndex - 1].field_205
        : null;

    const previousLessonType =
      currentLessonIndex > 0
        ? allLessonsSorted[currentLessonIndex - 1].field_168
        : '';

    setNextLessonKnackId(nextLessonID);
    setPreviousLessonCompletionDate(previousLessonCompletionDate);
    setCurrentLessonIndex(currentLessonIndex);
    setPreviousLessonType(previousLessonType);
  };

  const shouldEnableMarkCompleteSequence = () => {
    console.log('previousLessonType === ', previousLessonType);
    if (
      !previousLessonCompletionDate &&
      currentLessonIndex > 0 &&
      !previousLessonType.includes('Bonus')
    ) {
      console.log('conditions have been met');
      alert(
        'Lessons must be marked complete in sequential order. Please check the previous lesson is marked complete.'
      );
      return;
    }

    setShowModalManageLesson(false);
    setShowModalCompleteLesson(true);
  };

  const preloadUserAction = action => {
    if (!action) return;

    if (action === 'scheduleLesson') {
      setShowModalScheduleLesson(true);
      return;
    }

    if (action === 'completeLesson') {
      console.log('action ====== ', action);
      shouldEnableMarkCompleteSequence();
      // setShowModalCompleteLesson(true)
      // return;
    }
  };
  React.useEffect(() => {
    setDateTimeScreenOpened(datePickerValue);
    validatePayFreezeStatus.Validate(Variables, setGlobalVariableValue);
    Track.onEnterScreen(
      'LessonDetailsAndManagementScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);

  React.useEffect(() => {
    shouldEnableSaveScheduleButton(
      dateTimeScreenOpened,
      datePickerValue,
      confirmLessonSchedule
    );
    shouldShowLessonEvaluation();

    if (scheduledDate) {
      shouldSkipConfirmCompletionDate(scheduledDate);
    }
  });
  const knackAPIScheduleLessonPUT = KnackAPIApi.useScheduleLessonPUT();
  const knackAPICompleteLessonPUT = KnackAPIApi.useCompleteLessonPUT();
  const integromatSendEmailToMatching$BookingPOST =
    IntegromatApi.useSendEmailToMatching$BookingPOST();
  const knackAPIForfeitLessonPUT = KnackAPIApi.useForfeitLessonPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      preloadUserAction(props.route?.params?.action ?? defaultProps.action);

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color_7'],
            justifyContent: 'center',
            marginBottom: -15,
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes.App['Custom Color'],
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Lesson Detail'}
            </Text>
          </View>
          {/* Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            {/* Spacer */}
            <Link
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={''}
            />
          </View>
        </View>
      </View>
      {/* Curve Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingTop: 15,
          },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              minHeight: 600,
              paddingBottom: 100,
            },
            dimensions.width
          )}
        >
          {/* View Card Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderRadius: 0,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                height: '90%',
                minHeight: 400,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Fetch Lesson Details */}
            <KnackAPIApi.FetchGetLessonDetailsGET
              handlers={{
                onData: fetchLessonDetailsData => {
                  const handler = async () => {
                    try {
                      await setScreenVariables(fetchLessonDetailsData);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                },
              }}
              lessonKackId={
                props.route?.params?.lessonKnackId ?? defaultProps.lessonKnackId
              }
            >
              {({ loading, error, data, refetchGetLessonDetails }) => {
                const fetchLessonDetailsData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Header Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 24,
                          paddingLeft: '5.35%',
                          paddingRight: '5.35%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Card Title Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 24, width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* View Top Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Left Details */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginRight: 12 },
                                dimensions.width
                              )}
                            >
                              <Surface
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Sun Orange F4A100'],
                                    borderColor: palettes.App['Custom Color'],
                                    borderRadius: 50,
                                    borderWidth: 2,
                                    height: 40,
                                    justifyContent: 'center',
                                    width: 40,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Lesson Number */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['Custom Color'],
                                        fontFamily: 'Poppins_400Regular',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {lessonNumber}
                                  </Text>
                                </View>
                              </Surface>
                            </View>
                            {/* View Lesson Details */}
                            <View>
                              {/* Lesson Name Container */}
                              <View>
                                {/* Lesson Name Long */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {getLongLessonName(
                                    fetchLessonDetailsData?.field_320_raw
                                  )}
                                </Text>
                              </View>
                              {/* Client Name */}
                              <View>
                                {/* Client Name */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'with '}
                                  {fetchLessonDetailsData?.field_455_raw}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* View Status Details */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Unscheduled Status */}
                            <>
                              {!isLessonUnscheduled(
                                lessonStatus,
                                scheduledDate
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ],
                                      borderRadius: 8,
                                      flexDirection: 'row',
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Unscheduled */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Unscheduled'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* View Scheduled Status */}
                            <>
                              {!isLessonStateScheduled(
                                lessonStatus,
                                scheduledDate
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['App Orange #FDF9EC'],
                                      borderRadius: 8,
                                      flexDirection: 'row',
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Scheduled Status */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['App Gold FFA900'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Scheduled'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* View Completed Status */}
                            <>
                              {!isLessonStateCompleted(
                                lessonStatus,
                                paidStatus
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Blue/Low Emphasis'],
                                      borderRadius: 8,
                                      flexDirection: 'row',
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Completed */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App['App Blue#4 007fd4'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Pay Pending'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* View Paid Status */}
                            <>
                              {!(paidStatus === 'Paid') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['App Mint #F0F8F2'],
                                      borderRadius: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                      width: 60,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Paid */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color:
                                          palettes.App[
                                            'App Text Green #1DB058'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Paid'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* View Status Loading */}
                            <>
                              {!Constants['empty'] ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ],
                                      borderRadius: 8,
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    style={StyleSheet.applyWidth(
                                      { height: 36, width: 36 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      </View>
                      {/* View Row Pay and Lesson Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Surface Lesson Pay Details */}
                        <Surface
                          elevation={3}
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderRadius: 16,
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              marginBottom: -16,
                              minHeight: 40,
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                              zIndex: 2,
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Base Pay */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                width: '32%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label Base Pay */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  marginBottom: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Base Pay'}
                            </Text>
                            {/* Base Pay */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text Green #1DB058'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {'$'}
                              {payBase}
                            </Text>
                          </View>
                          {/* Amount Paid */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                borderLeftWidth: 1,
                                borderRightWidth: 1,
                                marginLeft: 1,
                                marginRight: 1,
                                width: '35%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Pending Pay.. */}
                            <>
                              {!(paidStatus !== 'Paid') ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'center',
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 14,
                                      marginBottom: 4,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Pending Pay..'}
                                </Text>
                              )}
                            </>
                            {/* Amount Paid */}
                            <>
                              {!(paidStatus === 'Paid') ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'center',
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 14,
                                      marginBottom: 4,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Amount Paid'}
                                </Text>
                              )}
                            </>
                            {/* Base Pay Approved */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Sun Orange F4A100'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {'$'}
                              {payBaseApproved}
                            </Text>
                          </View>
                          {/* View Lesson Duration */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                width: '32%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Duration */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  marginBottom: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Duration'}
                            </Text>
                            {/* Lesson Package */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {lessonDuration}
                              {' min'}
                            </Text>
                          </View>
                        </Surface>
                      </View>
                      {/* Notes Section */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Pay notes by admin */}
                        <>
                          {!payNotesByAdmin ? null : (
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'additional student details block 2'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    'additional student details block 2'
                                  ].style,
                                  {
                                    borderColor:
                                      palettes.App['App Grey Inputs E2E2E2'],
                                    borderRadius: 8,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 20,
                                    marginRight: 12,
                                    width: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App['App Black 191F32']}
                                  name={
                                    'MaterialCommunityIcons/note-text-outline'
                                  }
                                  size={18}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 6 },
                                    dimensions.width
                                  )}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '90%' },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Notice'}
                                </Text>
                                {/* Pay note */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App[
                                            'App Disabled Button Text 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {payNotesByAdmin}
                                </Text>
                              </View>
                            </View>
                          )}
                        </>
                        {/* View Pay Note Spacer */}
                        <>
                          {!shouldShowPayNoteSpacer() ? null : (
                            <View>
                              {/* Pay note spacer */}
                              <Spacer left={8} right={8} top={6} />
                            </View>
                          )}
                        </>
                        {/* Pay notes forfeit rate */}
                        <>
                          {!(
                            fetchLessonDetailsData?.field_201 === 'Forfeited'
                          ) ? null : (
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'additional student details block 2'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    'additional student details block 2'
                                  ].style,
                                  {
                                    borderColor:
                                      palettes.App['App Grey Inputs E2E2E2'],
                                    borderRadius: 8,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: 20,
                                    marginRight: 12,
                                    width: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App['App Black 191F32']}
                                  name={
                                    'MaterialCommunityIcons/note-text-outline'
                                  }
                                  size={18}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 6 },
                                    dimensions.width
                                  )}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '90%' },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Notice'}
                                </Text>
                                {/* Pay forfeit note */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App[
                                            'App Disabled Button Text 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Pay for this forfeited lesson is '}
                                  {toPercentage(payRate)}
                                  {' of the base pay.'}
                                </Text>
                              </View>
                            </View>
                          )}
                        </>
                      </View>
                    </View>
                    {/* Lesson History Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 24,
                          paddingLeft: '5.35%',
                          paddingRight: '5.35%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Lesson'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'History'}
                        </Text>
                      </View>
                      {/* View Row Event Paid */}
                      <>
                        {!(paidStatus === 'Paid') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column' },
                              dimensions.width
                            )}
                          >
                            {/* Details Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 16 },
                                  dimensions.width
                                )}
                              >
                                <Surface
                                  elevation={1}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App['App Sun Orange F4A100'],
                                      borderColor: palettes.App['Custom Color'],
                                      borderRadius: 50,
                                      borderWidth: 2,
                                      height: 16,
                                      justifyContent: 'center',
                                      width: 16,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Line title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Paid */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['primary14']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Paid'}
                                </Text>
                              </View>
                              {/* Variable */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].style,
                                      { fontFamily: 'Poppins_700Bold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {convertTimeFormatString(
                                    paidDate,
                                    'MM/DD/YYYY h:mma',
                                    'MM/DD/YYYY'
                                  )}
                                </Text>
                              </View>
                            </View>
                            {/* History Timeline */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor:
                                    palettes.App['App Sun Orange F4A100'],
                                  borderLeftWidth: 1,
                                  borderStyle: 'solid',
                                  height: 41,
                                  marginLeft: 8,
                                  marginTop: -4,
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                        )}
                      </>
                      {/* View Row Submitted for Pay */}
                      <>
                        {!submittedDate ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column' },
                              dimensions.width
                            )}
                          >
                            {/* Details Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 16 },
                                  dimensions.width
                                )}
                              >
                                <Surface
                                  elevation={1}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App['App Sun Orange F4A100'],
                                      borderColor: palettes.App['Custom Color'],
                                      borderRadius: 50,
                                      borderWidth: 2,
                                      height: 16,
                                      justifyContent: 'center',
                                      width: 16,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Line title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Submitted for pay */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['primary14']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Submitted for pay'}
                                </Text>
                              </View>
                              {/* Variable */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].style,
                                      { fontFamily: 'Poppins_700Bold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {convertTimeFormatString(
                                    submittedDate,
                                    'MM/DD/YYYY h:mma',
                                    'MM/DD/YYYY'
                                  )}
                                </Text>
                              </View>
                            </View>
                            {/* Subtitle */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 32, marginTop: 2 },
                                dimensions.width
                              )}
                            >
                              {/* Estimated payout date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular_Italic',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Estimated payout date '}
                                {getNextPayday(submittedDate)}
                              </Text>
                            </View>
                            {/* History Timeline */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor:
                                    palettes.App['App Sun Orange F4A100'],
                                  borderLeftWidth: 1,
                                  borderStyle: 'solid',
                                  height: 56,
                                  marginLeft: 8,
                                  marginTop: -24,
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                        )}
                      </>
                      {/* View Row Lesson Completed */}
                      <>
                        {!completionDate ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column' },
                              dimensions.width
                            )}
                          >
                            {/* Details Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 16 },
                                  dimensions.width
                                )}
                              >
                                <Surface
                                  elevation={1}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App['App Sun Orange F4A100'],
                                      borderColor: palettes.App['Custom Color'],
                                      borderRadius: 50,
                                      borderWidth: 2,
                                      height: 16,
                                      justifyContent: 'center',
                                      width: 16,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Line title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Completed */}
                                <>
                                  {!(
                                    fetchLessonDetailsData?.field_201 ===
                                    'Completed Successfully'
                                  ) ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'primary14'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Lesson Completed'}
                                    </Text>
                                  )}
                                </>
                                {/* Forfeited */}
                                <>
                                  {!(
                                    fetchLessonDetailsData?.field_201 ===
                                    'Forfeited'
                                  ) ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'primary14'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Lesson Forfeited'}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* Variable */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].style,
                                      { fontFamily: 'Poppins_700Bold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {completionDate}
                                </Text>
                              </View>
                            </View>
                            {/* History Timeline */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor:
                                    palettes.App['App Sun Orange F4A100'],
                                  borderLeftWidth: 1,
                                  borderStyle: 'solid',
                                  height: 41,
                                  marginLeft: 8,
                                  marginTop: -4,
                                },
                                dimensions.width
                              )}
                            >
                              <Spacer left={8} right={8} bottom={21} top={20} />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Row Event Schedule */}
                      <>
                        {!scheduledDate ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column' },
                              dimensions.width
                            )}
                          >
                            {/* Details Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* View Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 16 },
                                  dimensions.width
                                )}
                              >
                                <Surface
                                  elevation={1}
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App['App Sun Orange F4A100'],
                                      borderColor: palettes.App['Custom Color'],
                                      borderRadius: 50,
                                      borderWidth: 2,
                                      height: 16,
                                      justifyContent: 'center',
                                      width: 16,
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Line title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Schedule for */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['primary14']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Scheduled for'}
                                </Text>
                              </View>
                              {/* Variable */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'primary14'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'primary14'
                                      ].style,
                                      { fontFamily: 'Poppins_700Bold' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {scheduledDate}
                                </Text>
                              </View>
                              {/* View Edit Icon Holder */}
                              <>
                                {completionDate ? null : (
                                  <View>
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          setShowModalScheduleLesson(true);
                                          amplitudeEvents(
                                            Variables,
                                            'start schedule lesson',
                                            {
                                              step: '01',
                                              view: 'edit lesson',
                                              funnel: 'schedule lesson',
                                              origin: 'lesson details screen',
                                              category: 'lesson management',
                                            },
                                            lessonNumber
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* Edit Pencil */}
                                      <SVG
                                        source={Constants['uiIconPencilOrange']}
                                        style={StyleSheet.applyWidth(
                                          { height: 22, width: 22 },
                                          dimensions.width
                                        )}
                                      />
                                    </Touchable>
                                  </View>
                                )}
                              </>
                            </View>
                            {/* History Timeline */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor:
                                    palettes.App['App Sun Orange F4A100'],
                                  borderLeftWidth: 1,
                                  borderStyle: 'solid',
                                  height: 41,
                                  marginLeft: 8,
                                  marginTop: -4,
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                        )}
                      </>
                      {/* View Row Event Matched */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'column' },
                          dimensions.width
                        )}
                      >
                        {/* Details Row */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* View Icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginRight: 16 },
                              dimensions.width
                            )}
                          >
                            <Surface
                              elevation={1}
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Sun Orange F4A100'],
                                  borderColor: palettes.App['Custom Color'],
                                  borderRadius: 50,
                                  borderWidth: 2,
                                  height: 16,
                                  justifyContent: 'center',
                                  width: 16,
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                          {/* Line title */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginRight: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Matched with client on */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['primary14']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['primary14']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {'Matched with client on'}
                            </Text>
                          </View>
                          {/* Variable */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginRight: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Date */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['primary14']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['primary14']
                                    .style,
                                  { fontFamily: 'Poppins_700Bold' }
                                ),
                                dimensions.width
                              )}
                            >
                              {convertTimeFormatString(
                                matchedDate,
                                'MM/DD/YYYY h:mm a',
                                'MM/DD/YYYY'
                              )}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                );
              }}
            </KnackAPIApi.FetchGetLessonDetailsGET>
          </View>
          <Spacer left={8} right={8} bottom={24} top={24} />
        </ScrollView>
      </View>
      {/* View Bottom Anchor */}
      <>
        {!(lessonNumber > 0) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                bottom: 0,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Row Manage Lesson Button Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 0,
                  marginTop: 20,
                  paddingBottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  { borderRadius: 50, minHeight: 40, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Manage Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Manage Lesson */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowModalManageLesson(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Manage Lesson'}
                  />
                </View>
              </Surface>
            </View>
            {/* Manage Lesson */}
            <>
              {!showModalManageLesson ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Manage Lesson Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalManageLesson(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalManageLesson(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Modal Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 24 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'Manage Lesson'}
                            </Text>
                          </View>
                          {/* View Schedule Lesson Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Touchable - Open Add Schedule Enabled */}
                            <>
                              {completionDate ? null : (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      setShowModalScheduleLesson(true);
                                      setShowModalManageLesson(false);
                                      amplitudeEvents(
                                        Variables,
                                        'start schedule lesson',
                                        {
                                          step: '01',
                                          view: 'manage lesson sheet',
                                          funnel: 'schedule lesson',
                                          origin: 'lesson details screen',
                                          category: 'lesson management',
                                        },
                                        lessonNumber
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  activeOpacity={0.8}
                                  disabledOpacity={0.8}
                                  style={StyleSheet.applyWidth(
                                    { width: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Action Container */}
                                  <View>
                                    {/* Add Schedule */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Schedule Lesson'}
                                    </Text>
                                  </View>
                                </Touchable>
                              )}
                            </>
                            {/* Touchable - Open Add Schedule Disabled */}
                            <>
                              {!completionDate ? null : (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      showAlertActionDisabled('scheduleLesson');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  activeOpacity={0.8}
                                  disabledOpacity={0.8}
                                  style={StyleSheet.applyWidth(
                                    { width: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Action Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Add Schedule */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App[
                                              'App Disabled Button Text 7F868C'
                                            ],
                                          fontFamily: 'Poppins_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Schedule Lesson'}
                                    </Text>
                                    <Icon
                                      color={
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ]
                                      }
                                      name={'MaterialCommunityIcons/cancel'}
                                      size={16}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 8 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </View>
                          {/* View Mark Complete */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Pay Freeze False */}
                            <>
                              {Constants['payFreeze'] ? null : (
                                <View>
                                  {/* Touchable - Open Mark Complete */}
                                  <>
                                    {completionDate ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            shouldEnableMarkCompleteSequence();
                                            amplitudeEvents(
                                              Variables,
                                              'start complete lesson',
                                              {
                                                step: '01',
                                                view: 'manage lesson sheet',
                                                funnel: 'complete lesson',
                                                origin: 'lesson details screen',
                                                category: 'lesson management',
                                              },
                                              undefined
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                        style={StyleSheet.applyWidth(
                                          { width: '100%' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Action Container */}
                                        <View>
                                          {/* Mark As Complete */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Black 191F32'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Mark Lesson Complete'}
                                          </Text>
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                  {/* Touchable - Open Mark Complete Disabled */}
                                  <>
                                    {!completionDate ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            showAlertActionDisabled(
                                              'completeLesson'
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                        style={StyleSheet.applyWidth(
                                          { width: '100%' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Action Container */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Mark As Complete */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Disabled Button Text 7F868C'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Mark Lesson Complete'}
                                          </Text>
                                          <Icon
                                            color={
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ]
                                            }
                                            name={
                                              'MaterialCommunityIcons/cancel'
                                            }
                                            size={16}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 8 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                            {/* Pay Freeze True */}
                            <>
                              {!Constants['payFreeze'] ? null : (
                                <View>
                                  {/* Touchable - Open Mark Complete Disabled */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        showAlertPayFreeze();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    activeOpacity={0.8}
                                    disabledOpacity={0.8}
                                    style={StyleSheet.applyWidth(
                                      { width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Action Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Mark As Complete */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Mark Lesson Complete'}
                                      </Text>
                                      <Icon
                                        color={
                                          palettes.App[
                                            'App Disabled Button Text 7F868C'
                                          ]
                                        }
                                        name={'MaterialCommunityIcons/cancel'}
                                        size={16}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 8 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  </Touchable>
                                </View>
                              )}
                            </>
                          </View>
                          {/* View Forfeit Lesson Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Pay Freeze False */}
                            <>
                              {Constants['payFreeze'] ? null : (
                                <View>
                                  {/* Touchable - Open Forfeit Enabled */}
                                  <>
                                    {completionDate ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            shouldEnableForfeitSequence();
                                            amplitudeEvents(
                                              Variables,
                                              'start forfeit lesson',
                                              {
                                                step: '01',
                                                funnel: 'forfeit lesson',
                                                origin: 'lesson details screen',
                                                category: 'lesson management',
                                              },
                                              undefined
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                        style={StyleSheet.applyWidth(
                                          { width: '100%' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Action Container */}
                                        <View>
                                          {/* Forfeit Lesson */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Black 191F32'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Forfeit Lesson'}
                                          </Text>
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                  {/* Touchable - Open Forfeit Disabled */}
                                  <>
                                    {!completionDate ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            showAlertActionDisabled(
                                              'scheduleLesson'
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                        style={StyleSheet.applyWidth(
                                          { width: '100%' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Action Container */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Forfeit Lesson */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Disabled Button Text 7F868C'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Forfeit Lesson'}
                                          </Text>
                                          <Icon
                                            color={
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ]
                                            }
                                            name={
                                              'MaterialCommunityIcons/cancel'
                                            }
                                            size={16}
                                            style={StyleSheet.applyWidth(
                                              { marginLeft: 8 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                            {/* Pay Freeze True */}
                            <>
                              {!Constants['payFreeze'] ? null : (
                                <View>
                                  {/* Touchable - Open Forfeit Disabled */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        showAlertPayFreeze();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    activeOpacity={0.8}
                                    disabledOpacity={0.8}
                                    style={StyleSheet.applyWidth(
                                      { width: '100%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Action Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Forfeit Lesson */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Forfeit Lesson'}
                                      </Text>
                                      <Icon
                                        color={
                                          palettes.App[
                                            'App Disabled Button Text 7F868C'
                                          ]
                                        }
                                        name={'MaterialCommunityIcons/cancel'}
                                        size={16}
                                        style={StyleSheet.applyWidth(
                                          { marginLeft: 8 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  </Touchable>
                                </View>
                              )}
                            </>
                          </View>
                          {/* View Contact Sunsational */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Touchable - Contact Sunsational */}
                            <Touchable
                              onPress={() => {
                                try {
                                  setShowModalManageLesson(false);
                                  setShowSSSContactOptions(true);
                                  amplitudeEvents(
                                    Variables,
                                    'start contact sunsational',
                                    {
                                      step: '01',
                                      funnel: 'contact sunsational',
                                      origin: 'lesson details screen',
                                      category: 'support',
                                    },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              activeOpacity={0.8}
                              disabledOpacity={0.8}
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                            >
                              {/* Action Container */}
                              <View>
                                {/* Contact Sunsational */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Contact Sunsational'}
                                </Text>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View Close Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', paddingTop: 32, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Close Enabled */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowModalManageLesson(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Custom Color'],
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Sun Orange F4A100'],
                            borderLeftWidth: 1,
                            borderRadius: 50,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Close'}
                      />
                    </View>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            {/* Schedule Lesson */}
            <>
              {!showModalScheduleLesson ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalScheduleLesson(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalScheduleLesson(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Modal Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 24 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'Schedule Lesson '}
                              {lessonNumber}
                            </Text>
                          </View>
                          {/* View Schedule Lesson Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <DatePicker
                              autoDismissKeyboard={true}
                              disabled={false}
                              hideLabel={false}
                              inline={false}
                              label={'Date'}
                              leftIconMode={'inset'}
                              onDateChange={newDatePickerValue => {
                                try {
                                  if (newDatePickerValue) {
                                    setModalScheduleVisible(false);
                                  }
                                  if (newDatePickerValue) {
                                    const valuew7Pt9XOh = newDatePickerValue;
                                    setDatePickerValue(valuew7Pt9XOh);
                                    const datePickerValue = valuew7Pt9XOh;
                                  }
                                  amplitudeEvents(
                                    Variables,
                                    'set lesson scheduled date',
                                    {
                                      step: '02',
                                      funnel: 'schedule lesson',
                                      origin: 'lesson details screen',
                                      category: 'lesson management',
                                    },
                                    lessonNumber
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              type={'solid'}
                              format={'ddd, mmm dd @ h:MM TT'}
                              mode={'datetime'}
                              rightIconName={
                                'MaterialCommunityIcons/timeline-clock'
                              }
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: palettes.App['App Black 191F32'],
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                          {/* Agree to Client Schedule Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App['App Blue/Low Emphasis'],
                                borderRadius: 8,
                                flexDirection: 'row',
                                marginBottom: 30,
                                paddingBottom: '1.75%',
                                paddingLeft: '1.75%',
                                paddingRight: '1.75%',
                                paddingTop: '1.75%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Checkbox Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: 8,
                                  width: '8%',
                                },
                                dimensions.width
                              )}
                            >
                              <Checkbox
                                onCheck={() => {
                                  try {
                                    amplitudeEvents(
                                      Variables,
                                      'checkbox schedule ok with client',
                                      {
                                        step: '03',
                                        funnel: 'schedule lesson',
                                        origin: 'lesson details screen',
                                        category: 'lesson management',
                                      },
                                      lessonNumber
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                onPress={newCheckboxValue => {
                                  try {
                                    setCheckboxValue(newCheckboxValue);
                                    setConfirmLessonSchedule(
                                      !confirmLessonSchedule
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                checkedIcon={'FontAwesome/check-circle'}
                                color={palettes.App['App Blue#4 007fd4']}
                                status={checkboxValue}
                                style={StyleSheet.applyWidth(
                                  { height: 8, width: 8 },
                                  dimensions.width
                                )}
                                uncheckedColor={
                                  palettes.App['App Blue#4 007fd4']
                                }
                                uncheckedIcon={'Entypo/circle'}
                              />
                            </View>
                            {/* View Agreement Text Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '90%' },
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  try {
                                    setCheckboxValue(!checkboxValue);
                                    setConfirmLessonSchedule(
                                      !confirmLessonSchedule
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* View Preferred Tag */}
                                <>
                                  {!Constants['empty'] ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          alignSelf: 'flex-start',
                                          backgroundColor:
                                            palettes.App['App Blue#4 007fd4'],
                                          borderRadius: 40,
                                          marginBottom: 4,
                                          paddingBottom: 2,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                          paddingTop: 2,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: palettes.App['Custom Color'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 10,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'preferred'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Blue#4 007fd4'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'I have discussed this schedule with '}
                                  {clientName}
                                  {
                                    ' and they have agreed to this date and time.'
                                  }
                                </Text>
                              </Touchable>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View Row Button Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginBottom: 0,
                          marginTop: 20,
                          paddingBottom: 0,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Save Scheduled Button Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            marginBottom: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Save Schedule Disabled */}
                        <>
                          {saveButtonEnabled ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  convertTimeFromLocalMachine(datePickerValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['disabled']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ButtonStyles(theme)['disabled']
                                  .style,
                                dimensions.width
                              )}
                              title={'Save Schedule'}
                            />
                          )}
                        </>
                        {/* Save Schedule Enabled */}
                        <>
                          {!saveButtonEnabled ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'is_loading',
                                      value: true,
                                    });
                                    const scheduleLessonResponse = (
                                      await knackAPIScheduleLessonPUT.mutateAsync(
                                        {
                                          date: convertTimeFromLocalMachine(
                                            datePickerValue
                                          ),
                                          lessonKnackId:
                                            props.route?.params
                                              ?.lessonKnackId ??
                                            defaultProps.lessonKnackId,
                                        }
                                      )
                                    )?.json;
                                    setGlobalVariableValue({
                                      key: 'is_loading',
                                      value: false,
                                    });
                                    handleScheduleLesssonResponse(
                                      scheduleLessonResponse
                                    );
                                    amplitudeEvents(
                                      Variables,
                                      'schedule lesson submit',
                                      {
                                        step: '04',
                                        funnel: 'schedule lesson',
                                        origin: 'lesson details screen',
                                        category: 'lesson management',
                                      },
                                      lessonNumber
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 18,
                                  paddingBottom: 14,
                                  paddingTop: 14,
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Save Schedule'}
                            />
                          )}
                        </>
                        {/* View Activity Indicator */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  marginTop: -50,
                                  opacity: 0.8,
                                  paddingBottom: 7,
                                  paddingTop: 7,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                color={palettes.App['Custom Color']}
                                style={StyleSheet.applyWidth(
                                  { height: 36, width: 36 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Cancel Enabled */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setShowModalManageLesson(true);
                              setShowModalScheduleLesson(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['Custom Color'],
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Sun Orange F4A100'],
                              borderLeftWidth: 1,
                              borderRadius: 50,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Sun Orange F4A100'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Cancel'}
                        />
                      </View>
                    </View>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            {/* Schedule Lesson Success */}
            <>
              {!showModalScheduleLessonSuccess ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Manage Lesson Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        minHeight: 350,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalScheduleLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalScheduleLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Modal Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 24 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'Great! Your lesson schedule has been saved!'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                minHeight: 60,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Holder */}
                            <>
                              {!showSchedLsnScsStep1 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center', width: 48 },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={
                                      palettes.App['App Text Green #1DB058']
                                    }
                                    name={
                                      'MaterialCommunityIcons/timeline-clock'
                                    }
                                    size={48}
                                  />
                                </View>
                              )}
                            </>
                            {/* View Date */}
                            <>
                              {!showSchedLsnScsStep2 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-start', marginLeft: 8 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Date */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Disabled Button Text 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 28,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {scheduledDate}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View Add To Calendar */}
                    <>
                      {!enableAddToCalendar ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              marginBottom: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Add To My Calendar */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const enableAddToCal =
                                    await addLessonToCalendar(
                                      lessonNumber,
                                      clientName,
                                      scheduledDate,
                                      lessonDuration
                                    );
                                  if (!enableAddToCal) {
                                    return;
                                  }
                                  setEnableAddToCalendar(false);
                                  amplitudeEvents(
                                    Variables,
                                    'save lesson schedule to native calendar',
                                    {
                                      step: '05',
                                      funnel: 'schedule lesson',
                                      origin: 'lesson details screen',
                                      category: 'lesson management',
                                    },
                                    lessonNumber
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['Custom Color'],
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Sun Orange F4A100'],
                                borderLeftWidth: 1,
                                borderRadius: 50,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'Add to my calendar'}
                          />
                        </View>
                      )}
                    </>
                    {/* Confirm Lesson Added To Calendar */}
                    <>
                      {enableAddToCalendar ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'stretch',
                              flexDirection: 'column',
                              marginBottom: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Center */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                paddingBottom: 14,
                                paddingTop: 14,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'flex-end',
                                  alignItems: 'flex-end',
                                  marginRight: 8,
                                  width: 48,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['App Text Green #1DB058']}
                                name={'FontAwesome/check-circle'}
                                size={48}
                              />
                            </View>
                            {/* Confirmation Message */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-start', marginLeft: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Confirmation message */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 16,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Lesson has been added to your\ncalendar'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Schedule Next Lesson */}
                    <>
                      {!nextLessonKnackId ? null : (
                        <View>
                          {/* Schedule Next Lesson */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                setShowModalScheduleLessonSuccess(false);
                                navigation.navigate(
                                  'LessonDetailsAndManagementScreen',
                                  {
                                    action: 'scheduleLesson',
                                    lessonPackageKnackId: lessonPackageKnackId,
                                    lessonKnackId: nextLessonKnackId,
                                  }
                                );
                                preloadUserAction('scheduleLesson');
                                setEnableAddToCalendar(true);
                                setShowSchedLsnScsStep1(false);
                                setShowSchedLsnScsStep2(false);
                                amplitudeEvents(
                                  Variables,
                                  'start schedule lesson',
                                  {
                                    step: '01',
                                    view: 'schedule next lesson',
                                    funnel: 'schedule lesson',
                                    origin: 'lesson details screen',
                                    category: 'lesson management',
                                  },
                                  lessonNumber
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderRadius: 50,
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'Schedule Next Lesson'}
                          />
                        </View>
                      )}
                    </>
                    {/* Last lesson (no later lessons) */}
                    <>
                      {nextLessonKnackId ? null : (
                        <View>
                          {/* Back To Client Details */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                setShowModalScheduleLessonSuccess(false);
                                setEnableAddToCalendar(true);
                                setShowSchedLsnScsStep1(false);
                                setShowSchedLsnScsStep2(false);
                                navigation.goBack();
                                amplitudeEvents(
                                  Variables,
                                  'view client details',
                                  {
                                    origin: 'lesson details screen',
                                    category: 'client management',
                                  },
                                  undefined
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderRadius: 50,
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'Back to Client Details'}
                          />
                        </View>
                      )}
                    </>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            {/* Complete Lesson Double Confirmation */}
            <>
              {!showCompleteDoubleConfirmation ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Mint #F0F8F2'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ImageBackground
                      resizeMode={'cover'}
                      source={imageSource(Images['WhiteSun'])}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* View Modal Close Slide */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 6,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Spacer */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 40 },
                            dimensions.width
                          )}
                        />
                        {/* View Slide Close */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 40,
                              height: 3,
                              width: 40,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                setShowCompleteDoubleConfirmation(false);
                                setShowModalCompleteLesson(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              { height: '100%', width: '100%' },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* View Close Button */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 40,
                              paddingBottom: 4,
                              paddingLeft: 6,
                              paddingRight: 6,
                              paddingTop: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                setShowCompleteDoubleConfirmation(false);
                                setShowModalCompleteLesson(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* Close */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {'close x'}
                            </Text>
                          </Touchable>
                        </View>
                      </View>
                      {/* View Hide While Request is Uploading */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <View>
                            {/* View Confirm Lesson Completion Date */}
                            <View>
                              {/* View Modal Title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    paddingBottom: 12,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Modal Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Red EB3E26'],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'I, '}
                                  {Constants['userFullName']}
                                  {', confirm Lesson '}
                                  {lessonNumber}
                                  {' was completed on '}
                                  {getCompletionDate()}
                                  {' with '}
                                  {clientName}
                                  {'.'}
                                </Text>
                              </View>
                              {/* Bullet contact */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginBottom: 6,
                                    padding: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={palettes.App['App Black 191F32']}
                                  name={
                                    'MaterialCommunityIcons/cellphone-wireless'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 2, marginRight: 12 },
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {clientName}
                                  {
                                    ' will be notified you marked this lesson complete'
                                  }
                                </Text>
                              </View>
                              {/* Bullet what next */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginBottom: 12,
                                    padding: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={palettes.App['App Black 191F32']}
                                  name={
                                    'MaterialCommunityIcons/calendar-multiple-check'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 14 },
                                    dimensions.width
                                  )}
                                />
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Your lesson will be scheduled for payment'}
                                </Text>
                              </View>
                              {/* View Confirmation Message */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['Custom Color'],
                                    borderColor:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    borderRadius: 16,
                                    borderWidth: 1,
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    marginBottom: 6,
                                    padding: 16,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Confirmation message */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ],
                                      fontFamily: 'AbyssinicaSIL_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'By clicking Confirm Completed, '}
                                  {clientName}
                                  {
                                    ' will be notified I have marked this lesson completed. I understand that confirming job completion falsely is a crime. I grant Sunsational Swim School, LLC ("Sunsational") the right to investigate my claim that this lesson has been completed. During the investigation, Sunsational reserves the right to place my payments on hold. Payments may be permanently held or rescinded based on the investigation\'s outcome. I submit to the full penalties of false completion claims under the law.'
                                  }
                                </Text>
                              </View>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Loading Animation */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 8,
                                height: 200,
                                marginBottom: 16,
                                overflow: 'hidden',
                                width: '97%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Title */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 24 },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {loadingMessage}
                              </Text>
                            </View>

                            <ImageBackground
                              resizeMode={'cover'}
                              source={imageSource(
                                'https://thumbs.gfycat.com/WickedAdventurousFairyfly-size_restricted.gif'
                              )}
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: '100%',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Activity Holder */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    height: '100%',
                                    paddingTop: 50,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  color={palettes.App['Custom Color']}
                                  size={'large'}
                                  style={StyleSheet.applyWidth(
                                    { height: 36, width: 36 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </ImageBackground>
                          </View>
                        )}
                      </>
                      {/* View Row Button Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginBottom: 0,
                            marginTop: 20,
                            paddingBottom: 0,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Confirm Mark Completed */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              marginBottom: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {Constants['is_loading'] ? null : (
                              <Surface
                                elevation={0}
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    { borderRadius: 16, width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* Confirm Mark Completed */}
                                <Button
                                  iconPosition={'left'}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        setGlobalVariableValue({
                                          key: 'is_loading',
                                          value: true,
                                        });
                                        const preparedDates =
                                          await prepareCompletionDatesToSend();
                                        const lessonRating =
                                          prepareLessonRating();
                                        (
                                          await knackAPICompleteLessonPUT.mutateAsync(
                                            {
                                              completionDate:
                                                convertTimeFromLocalMachine(
                                                  preparedDates?.completionDate
                                                ),
                                              dateSubmittedForPay:
                                                convertTimeFromLocalMachine(
                                                  preparedDates?.dateSubmittedForPay
                                                ),
                                              lessonKnackId:
                                                props.route?.params
                                                  ?.lessonKnackId ??
                                                defaultProps.lessonKnackId,
                                              payRequestSource: 'app',
                                              payStatus:
                                                'Submitted - pending Approval',
                                              scheduledDate:
                                                convertTimeFromLocalMachine(
                                                  preparedDates?.scheduledDate
                                                ),
                                              siComment: siLessonComment,
                                              siLessonRating: lessonRating,
                                              siLessonStatus:
                                                'Completed Successfully',
                                            }
                                          )
                                        )?.json;
                                        triggerCannon(
                                          Variables,
                                          setGlobalVariableValue
                                        );
                                        Haptic('success');
                                        animateLoadingMessage();
                                        setGlobalVariableValue({
                                          key: 'is_loading',
                                          value: false,
                                        });
                                        setShowCompleteDoubleConfirmation(
                                          false
                                        );
                                        setShowModalCompleteLessonSuccess(true);
                                        amplitudeEvents(
                                          Variables,
                                          'complete lesson submit',
                                          {
                                            step: '04',
                                            funnel: 'complete lesson',
                                            origin: 'lesson details screen',
                                            category: 'lesson management',
                                          },
                                          lessonNumber
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['App Red EB3E26'],
                                      borderRadius: 16,
                                      fontFamily: 'BebasNeue_400Regular',
                                      fontSize: 18,
                                      paddingBottom: 34,
                                      paddingTop: 34,
                                      textAlign: 'center',
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                  title={'⚠️ Confirm Completed'}
                                />
                              </Surface>
                            )}
                          </>
                          <>
                            {!Constants['is_loading'] ? null : (
                              <Surface
                                elevation={0}
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      alignItems: 'center',
                                      backgroundColor:
                                        palettes.App['App Red EB3E26'],
                                      borderRadius: 16,
                                      justifyContent: 'center',
                                      paddingBottom: 34,
                                      paddingTop: 34,
                                      width: '100%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  color={palettes.App['Custom Color']}
                                  size={'large'}
                                  style={StyleSheet.applyWidth(
                                    { height: 36, width: 36 },
                                    dimensions.width
                                  )}
                                />
                              </Surface>
                            )}
                          </>
                        </View>
                        {/* View Close Button */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* Cancel Enabled */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                setShowCompleteDoubleConfirmation(false);
                                setShowModalCompleteLesson(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['Custom Color'],
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Sun Orange F4A100'],
                                borderLeftWidth: 1,
                                borderRadius: 50,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'Cancel'}
                          />
                        </View>
                      </View>
                      <Spacer bottom={34} left={0} right={0} top={0} />
                    </ImageBackground>
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            {/* Complete Lesson Success */}
            <>
              {!showModalCompleteLessonSuccess ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Manage Lesson Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        minHeight: 350,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalCompleteLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalCompleteLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', minHeight: 60 },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Holder */}
                            <View>
                              <Icon
                                color={palettes.App['App Text Green #1DB058']}
                                name={'FontAwesome/check-circle'}
                                size={48}
                              />
                            </View>
                            {/* View Title */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15, maxWidth: '75%' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Fantastic! Lesson marked complete.'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View What Happens Next */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start' },
                        dimensions.width
                      )}
                    >
                      {/* Congrats on teaching */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "Congrats on a great job teaching swim lessons! We're preparing this lesson for payment."
                        }
                      </Text>
                      {/* What happens next timelines */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            marginBottom: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Scheduled pay date */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={'MaterialCommunityIcons/calendar-star'}
                            />
                          </View>
                          {/* Scheduled Payday */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Scheduled Payday */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'Payment for this lesson is estimated to be issued on Thursday, '
                              }
                              {getNextPayday(undefined)}
                              {'.'}
                            </Text>
                          </View>
                        </View>
                        {/* Clearing process */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingTop: 14 },
                            dimensions.width
                          )}
                        >
                          {/* Clearing process */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Lesson processing time'}
                          </Text>
                        </View>
                        {/* Processing Payment */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={'MaterialCommunityIcons/clock-fast'}
                            />
                          </View>
                          {/* Reviewing request */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Reviewing request */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'It takes 72 hours to process your Completed Lesson request. Your request should clear our review process in 3 days.'
                              }
                            </Text>
                          </View>
                        </View>
                        {/* Following Thursday */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={
                                'MaterialCommunityIcons/calendar-month-outline'
                              }
                            />
                          </View>
                          {/* Following Thursday */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Following Friday */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                "Payments are sent every Thursday. We'll issue pay for this lesson on the Thursday following the date your Completed Request has cleared."
                              }
                            </Text>
                          </View>
                        </View>
                        {/* Disclaimer */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Disclaimer */}
                          <View
                            style={StyleSheet.applyWidth(
                              { borderRadius: 8, padding: 6, width: '100%' },
                              dimensions.width
                            )}
                          >
                            {/* Disclaimer */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                    marginRight: '10%',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                '† Please note pay amounts and dates are estimates. Adjustments and delays may be made due to customer disputes and other matters.'
                              }
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* Back to Client Package */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back to Client Package */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Custom Color'],
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Sun Orange F4A100'],
                            borderLeftWidth: 1,
                            borderRadius: 50,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Back to Client Package'}
                      />
                    </View>
                    {/* View More Jobs */}
                    <View>
                      {/* View More Jobs */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowModalCompleteLessonSuccess(false);
                            navigation.navigate('JobsScreen');
                            amplitudeEvents(
                              Variables,
                              'view open jobs list',
                              {
                                step: '01',
                                view: 'complete lesson success',
                                funnel: 'request job',
                                origin: 'lesson details screen',
                                category: 'opportunity management',
                              },
                              undefined
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'View More Jobs'}
                      />
                    </View>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            {/* Contact Sent Success */}
            <>
              {!showModalContactSuccess ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Manage Lesson Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalContactSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalContactSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Modal Header */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={palettes.App['App Text Green #1DB058']}
                              name={'MaterialCommunityIcons/email-check'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginRight: 8 },
                                dimensions.width
                              )}
                            />
                            {/* Modal Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'Message sent'}
                            </Text>
                            <Icon
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/sort-variant'}
                              size={20}
                              style={StyleSheet.applyWidth(
                                { marginLeft: 4, marginRight: 8 },
                                dimensions.width
                              )}
                            />
                          </View>
                          {/* Message body container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Reply fast */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color:
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ],
                                  fontFamily: 'Poppins_400Regular',
                                  paddingBottom: 6,
                                },
                                dimensions.width
                              )}
                            >
                              {
                                'Glad to hear from you! We work fast. Generally, you should receive a reply within the same business day. '
                              }
                            </Text>
                            {/* Call us */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color:
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ],
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {
                                'If you need a immediate assistance, give us a call M-F 8:30AM - 5PM PST at 1-888-788-2140.'
                              }
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View Close Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', paddingTop: 32, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Close Enabled */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowModalContactSuccess(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Custom Color'],
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Sun Orange F4A100'],
                            borderLeftWidth: 1,
                            borderRadius: 50,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Close'}
                      />
                    </View>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
            <Spacer bottom={34} left={0} right={0} top={0} />
            {/* Forfeit Lesson Success */}
            <>
              {!showModalForfeitLessonSuccess ? null : (
                <KeyboardAvoidingView
                  behavior={'padding'}
                  enabled={true}
                  keyboardVerticalOffset={0}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Transparent'],
                      bottom: 0,
                      paddingTop: '125%',
                      position: 'absolute',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Manage Lesson Card */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        bottom: 0,
                        minHeight: 350,
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 13,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Modal Close Slide */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 6,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Spacer */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                      {/* View Slide Close */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            height: 3,
                            width: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalForfeitLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Close Button */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 40,
                            paddingBottom: 4,
                            paddingLeft: 6,
                            paddingRight: 6,
                            paddingTop: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setShowModalForfeitLessonSuccess(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Close */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {'close x'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Hide While Request is Uploading */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <View>
                          {/* View Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                minHeight: 60,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Holder */}
                            <View>
                              <Icon
                                color={palettes.App['App Text Green #1DB058']}
                                name={'MaterialIcons/check-circle'}
                                size={48}
                              />
                            </View>
                            {/* View Title */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15, maxWidth: '75%' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Lesson marked forfeited.'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View What Happens Next */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start' },
                        dimensions.width
                      )}
                    >
                      {/* What happens next */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "Sorry this lesson didn't go as planned. We're preparing this lesson for payment."
                        }
                      </Text>
                      {/* What happens next timelines */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            marginBottom: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Processing Payment */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={'MaterialCommunityIcons/clock-fast'}
                            />
                          </View>
                          {/* Reviewing request */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Reviewing request */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'It takes 72 hours to process your Forfeit Lesson request. Your request should clear our review process in 3 days.'
                              }
                            </Text>
                          </View>
                        </View>
                        {/* Following Friday */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={
                                'MaterialCommunityIcons/calendar-month-outline'
                              }
                            />
                          </View>
                          {/* Following Friday */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Following Friday */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                "Payments are sent every Friday. We'll issue pay for this lesson on the Friday following the date your Completed Request has cleared."
                              }
                            </Text>
                          </View>
                        </View>
                        {/* Scheduled pay date */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icon */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '10%' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                palettes.App['App Disabled Button Text 7F868C']
                              }
                              name={'MaterialCommunityIcons/calendar-star'}
                            />
                          </View>
                          {/* Scheduled Payday */}
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '90%' },
                              dimensions.width
                            )}
                          >
                            {/* Scheduled Payday */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                'Payment for this lesson is scheduled to be issued on Friday, '
                              }
                              {getNextPayday(undefined)}
                              {'.'}
                            </Text>
                          </View>
                        </View>
                        {/* Disclaimer */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              paddingBottom: 14,
                              paddingTop: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Disclaimer */}
                          <View
                            style={StyleSheet.applyWidth(
                              { borderRadius: 8, padding: 6, width: '100%' },
                              dimensions.width
                            )}
                          >
                            {/* Disclaimer */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                    marginRight: '10%',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                '† Please note pay amounts and dates are estimates. Adjustments and delays may be made due to customer disputes and other matters.'
                              }
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* Back to Client Package */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back to Client Package */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            navigation.goBack();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Custom Color'],
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Sun Orange F4A100'],
                            borderLeftWidth: 1,
                            borderRadius: 50,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Back to Client Package'}
                      />
                    </View>
                    {/* View More Jobs */}
                    <View>
                      {/* View More Jobs */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowModalCompleteLessonSuccess(false);
                            navigation.navigate('JobsScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'View More Jobs'}
                      />
                    </View>
                    <Spacer bottom={34} left={0} right={0} top={0} />
                  </View>
                </KeyboardAvoidingView>
              )}
            </>
          </View>
        )}
      </>
      {/* Contact Sunsational Options */}
      <ActionSheet visible={showSSSContactOptions}>
        {/* Email Sunsational */}
        <ActionSheetItem
          color={theme.colors.text.strong}
          onPress={() => {
            try {
              setShowModalContactSunsational(true);
              setShowSSSContactOptions(false);
              amplitudeEvents(
                Variables,
                'start message sunsational support',
                {
                  step: '02',
                  view: 'contact options sheet',
                  funnel: 'contact sunsational',
                  origin: 'lesson details screen',
                  category: 'support',
                },
                undefined
              );
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
            .props}
          label={'Message'}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
              .style,
            dimensions.width
          )}
        />
        {/* Call Sunsational */}
        <ActionSheetItem
          color={theme.colors.text.strong}
          onPress={() => {
            try {
              Linking.openURL('tel:+18887882140');
              amplitudeEvents(
                Variables,
                'call sunsational support',
                {
                  step: '02',
                  view: 'contact options sheet',
                  funnel: 'contact sunsational',
                  origin: 'lesson details screen',
                  category: 'support',
                },
                undefined
              );
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
            .props}
          label={'Call'}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
              .style,
            dimensions.width
          )}
        />
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setShowSSSContactOptions(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Complete Lesson */}
      <>
        {!showModalCompleteLesson ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Transparent'],
                bottom: 0,
                paddingTop: '125%',
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalCompleteLesson(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalCompleteLesson(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* View Confirm Lesson Completion Date */}
                    <>
                      {skipConfirmCompletionDate ? null : (
                        <View>
                          {/* View Modal Questio */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 12 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Question */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'When was this lesson (Lesson '}
                              {lessonNumber}
                              {') completed?'}
                            </Text>
                          </View>
                          {/* View Confirm Lesson Completion Date */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Confirm Completion Date */}
                            <DatePicker
                              autoDismissKeyboard={true}
                              disabled={false}
                              hideLabel={false}
                              inline={false}
                              label={'Date'}
                              leftIconMode={'inset'}
                              onDateChange={newConfirmCompletionDateValue => {
                                try {
                                  if (newConfirmCompletionDateValue) {
                                    const valuee7cdWGfv =
                                      newConfirmCompletionDateValue;
                                    setConfirmedCompletionDate(valuee7cdWGfv);
                                    const newConfirmedCompletionDate =
                                      valuee7cdWGfv;
                                  }
                                  const result = dateIsBeforeNow(
                                    newConfirmCompletionDateValue
                                  );
                                  setCompletionDateBeforeNow(result);
                                  if (result) {
                                    return;
                                  }
                                  setConfirmedCompletionDate(
                                    Constants['empty']
                                  );
                                  amplitudeEvents(
                                    Variables,
                                    'set lesson completion date',
                                    {
                                      step: '02',
                                      funnel: 'complete lesson',
                                      origin: 'lesson details screen',
                                      category: 'lesson management',
                                    },
                                    lessonNumber
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              type={'solid'}
                              date={confirmedCompletionDate}
                              format={'ddd, mmm dd @ h:MM TT'}
                              mode={'datetime'}
                              rightIconName={
                                'MaterialCommunityIcons/timeline-clock'
                              }
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: palettes.App['App Black 191F32'],
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    {/* Completion Date Can't be in Future */}
                    <>
                      {completionDateBeforeNow ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 8,
                              paddingBottom: 5,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 5,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Completion date can't be in future */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['Custom Color'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'The completion date cannot be in the future. You cannot claim a lesson complete until after it has been completed.'
                            }
                          </Text>
                        </View>
                      )}
                    </>
                    {/* View Lesson Evaluation */}
                    <>
                      {!showLessonEvaluation ? null : (
                        <View>
                          {/* View Rating Block */}
                          <View>
                            {/* View Modal Question */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 12 },
                                dimensions.width
                              )}
                            >
                              {/* Modal Question */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'How did this lesson go?'}
                              </Text>
                            </View>
                            {/* View Rating Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderColor:
                                    palettes.App['App Grey Inputs E2E2E2'],
                                  flexDirection: 'row',
                                  flexWrap: 'nowrap',
                                  justifyContent: 'space-between',
                                  paddingBottom: 30,
                                  paddingTop: 4,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Positive Rating Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-end',
                                    paddingRight: 12,
                                    width: '50%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* View Thumbs Up Neutral */}
                                <>
                                  {!(lessonRating === 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 100,
                                          borderWidth: 1,
                                          height: 80,
                                          justifyContent: 'center',
                                          width: 80,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_8']}
                                        icon={'Feather/thumbs-up'}
                                        size={35}
                                      />
                                    </View>
                                  )}
                                </>
                                {/* View Thumbs Up Active */}
                                <>
                                  {!(lessonRating > 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            palettes.App['App Mint #F0F8F2'],
                                          borderColor:
                                            palettes.App[
                                              'App Text Green #1DB058'
                                            ],
                                          borderRadius: 100,
                                          borderWidth: 2,
                                          height: 80,
                                          justifyContent: 'center',
                                          width: 80,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_8']}
                                        icon={'MaterialIcons/thumb-up'}
                                        size={45}
                                      />
                                    </View>
                                  )}
                                </>
                                {/* View Thumbs Up Inactive */}
                                <>
                                  {!(lessonRating < 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 100,
                                          borderWidth: 1,
                                          height: 50,
                                          justifyContent: 'center',
                                          marginRight: 12,
                                          width: 50,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['Custom Color_8']}
                                        icon={'Feather/thumbs-up'}
                                        size={35}
                                      />
                                    </View>
                                  )}
                                </>
                              </View>
                              {/* View Negative Rating Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-start',
                                    paddingLeft: 12,
                                    width: '50%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* View Thumbs Down Neutral */}
                                <>
                                  {!(lessonRating === 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 100,
                                          borderWidth: 1,
                                          height: 80,
                                          justifyContent: 'center',
                                          width: 80,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(-1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['App Red EB3E26']}
                                        icon={
                                          'MaterialCommunityIcons/thumb-down-outline'
                                        }
                                        size={35}
                                      />
                                    </View>
                                  )}
                                </>
                                {/* View Thumbs Down Active */}
                                <>
                                  {!(lessonRating < 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            '"rgb(255, 238, 238)"',
                                          borderColor:
                                            palettes.App['App Red EB3E26'],
                                          borderRadius: 100,
                                          borderWidth: 2,
                                          height: 80,
                                          justifyContent: 'center',
                                          width: 80,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['App Red EB3E26']}
                                        icon={'MaterialIcons/thumb-down'}
                                        size={45}
                                      />
                                    </View>
                                  )}
                                </>
                                {/* View Thumbs Down Inactive */}
                                <>
                                  {!(lessonRating > 0) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 100,
                                          borderWidth: 1,
                                          height: 50,
                                          justifyContent: 'center',
                                          marginLeft: 12,
                                          width: 50,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            setLessonRating(-1);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={palettes.App['App Red EB3E26']}
                                        icon={
                                          'MaterialCommunityIcons/thumb-down-outline'
                                        }
                                        size={35}
                                      />
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          </View>
                          {/* View Comments Block */}
                          <>
                            {!(lessonRating !== 0) ? null : (
                              <View>
                                {/* View Comments Title */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { paddingBottom: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Any Comments? */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_500Medium',
                                        fontSize: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Any comments about this lesson?'}
                                  </Text>
                                </View>
                                {/* View Text Area Container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { height: 145 },
                                    dimensions.width
                                  )}
                                >
                                  {/* siLessonComment */}
                                  <TextInput
                                    autoCorrect={true}
                                    changeTextDelay={500}
                                    multiline={true}
                                    numberOfLines={4}
                                    onChangeText={newSiLessonCommentValue => {
                                      try {
                                        setSiLessonComment(
                                          newSiLessonCommentValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    textAlignVertical={'top'}
                                    webShowOutline={true}
                                    editable={true}
                                    placeholder={'Your comments'}
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1,
                                        borderColor:
                                          palettes.App[
                                            'App Grey Inputs E2E2E2'
                                          ],
                                        borderLeftWidth: 1,
                                        borderRadius: 8,
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        height: '100%',
                                        paddingBottom: 16,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 16,
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* View Row Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    marginTop: 20,
                    paddingBottom: 0,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Mark Complete Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 16, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Mark Complete Disabled */}
                  <>
                    {!(lessonRating === 0) ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Mark Complete'}
                      />
                    )}
                  </>
                  {/* Mark Complete Enabled */}
                  <>
                    {!(lessonRating !== 0) ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowModalCompleteLesson(false);
                            setShowCompleteDoubleConfirmation(true);
                            amplitudeEvents(
                              Variables,
                              'mark lesson complete',
                              {
                                step: '03',
                                funnel: 'complete lesson',
                                origin: 'lesson details screen',
                                category: 'lesson management',
                              },
                              lessonNumber
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Mark Complete'}
                      />
                    )}
                  </>
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Cancel Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowModalCompleteLesson(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                </View>
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Modal Contact Sunsational Support */}
      <>
        {!showModalContactSunsational ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  bottom: 0,
                  paddingTop: '125%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowModalContactSunsational(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowModalContactSunsational(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'How can we help you with '}
                          {clientName}
                          {"'s swim lesson?"}
                        </Text>
                      </View>
                      {/* View Text Area Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { height: 145, paddingBottom: 16 },
                          dimensions.width
                        )}
                      >
                        {/* Si Contact Message */}
                        <TextInput
                          autoCorrect={true}
                          changeTextDelay={500}
                          multiline={true}
                          numberOfLines={4}
                          onChangeText={newSiContactMessageValue => {
                            try {
                              setSiContactMessage(newSiContactMessageValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          textAlignVertical={'top'}
                          webShowOutline={true}
                          defaultValue={siContactMessage}
                          editable={true}
                          placeholder={'We generally respond sameday'}
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Grey Inputs E2E2E2'],
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                              height: '100%',
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View message failed/retry */}
                      <>
                        {!showSendMsgFailed ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', paddingBottom: 24 },
                              dimensions.width
                            )}
                          >
                            {/* Message failed to send */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Red EB3E26'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                },
                                dimensions.width
                              )}
                            >
                              {'Woops, something went wrong. Please try again.'}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
                {/* View Show While Request Uploading */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 8,
                          height: 200,
                          marginBottom: 16,
                          overflow: 'hidden',
                          width: '97%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Sending your request!'}
                        </Text>
                      </View>
                      <ImageBackground
                        resizeMode={'contain'}
                        source={imageSource(
                          `${Constants['uiAnimationChatBubble']}`
                        )}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                {/* View Request Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Send Message Disabled */}
                  <>
                    {siContactMessage ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Send Message'}
                      />
                    )}
                  </>
                  {/* Send Message Enabled */}
                  <>
                    {!siContactMessage ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              const response = (
                                await integromatSendEmailToMatching$BookingPOST.mutateAsync(
                                  {
                                    lessonID: lessonId,
                                    lessonKnackID: lessonKnackId,
                                    message: siContactMessage,
                                    packageID: packageId,
                                    packageKnackID:
                                      props.route?.params
                                        ?.lessonPackageKnackId ??
                                      defaultProps.lessonPackageKnackId,
                                    siEmailAddress:
                                      Constants['gInstructorEmailAddress'],
                                    siName: Constants['userFullName'],
                                  }
                                )
                              )?.json;
                              processSendMsgResponse(
                                setGlobalVariableValue,
                                response
                              );
                              amplitudeEvents(
                                Variables,
                                'submit support message',
                                {
                                  step: '03',
                                  view: 'message sunsational sheet',
                                  funnel: 'contact sunsational',
                                  origin: 'lesson details screen',
                                  category: 'support',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Send Message'}
                      />
                    )}
                  </>
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Forfeit Lesson */}
      <>
        {!showModalForfeit ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Transparent'],
                bottom: 0,
                height: '100%',
                paddingTop: '2%',
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  height: '100%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Spacer bottom={34} left={0} right={0} top={0} />
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalForfeit(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalForfeit(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>

              <ScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* Forfeit Reason */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 8 },
                          dimensions.width
                        )}
                      >
                        {/* Modal Question */}
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 12 },
                            dimensions.width
                          )}
                        >
                          {/* Modal Question */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'Why is this lesson forfeit? (check all that apply)'
                            }
                          </Text>
                        </View>
                        {/* Requirement Text */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 8 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'Clients are required to give a minimum 24-hour prior notice to reschedule a lesson OR 3-hour prior notice to reschedule for bad weather. Clients are allowed a maximum of 2 rescheduled lessons.'
                            }
                          </Text>
                        </View>
                        {/* Reasons checks */}
                        <View>
                          {/* Too many reschedules */}
                          <CheckboxRow
                            onPress={newTooManyReschedulesValue => {
                              try {
                                setForfeitReasonMax(newTooManyReschedulesValue);
                                const result = shouldShowForfeitAgreements();
                                if (result) {
                                  return;
                                }
                                resetForfeitAgreementCount();
                                resetForfeitAgreements();
                                resetForfeitContactFields();
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.CheckboxRowStyles(theme)[
                              'Checkbox Row'
                            ].props}
                            color={palettes.App['App Black 191F32']}
                            direction={'row'}
                            label={'Client has reached max. reschedules'}
                            size={22}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].style,
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            uncheckedColor={palettes.App['App Black 191F32']}
                          />
                          {/* Prior Notice */}
                          <CheckboxRow
                            onPress={newPriorNoticeValue => {
                              try {
                                setForfeitReasonImproperNotice(
                                  newPriorNoticeValue
                                );
                                const result = shouldShowForfeitAgreements();
                                if (result) {
                                  return;
                                }
                                resetForfeitAgreementCount();
                                resetForfeitAgreements();
                                resetForfeitContactFields();
                                setForfeitBeforeOrAfterSiTravel(
                                  Constants['empty']
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.CheckboxRowStyles(theme)[
                              'Checkbox Row'
                            ].props}
                            color={palettes.App['App Black 191F32']}
                            direction={'row'}
                            label={
                              'Client did not give proper rescheduling notice'
                            }
                            size={22}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].style,
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            uncheckedColor={palettes.App['App Black 191F32']}
                          />
                        </View>
                      </View>
                      {/* Transit Question */}
                      <>
                        {!shouldShowForfeitTransitSection() ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Question */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 12 },
                                dimensions.width
                              )}
                            >
                              {/* Modal Question */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  'Did the client cancel BEFORE or AFTER you were on your way to teach the lesson?'
                                }
                              </Text>
                            </View>
                            {/* Before or after */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Disabled Button EDEDEF'],
                                  borderRadius: 16,
                                  paddingBottom: 4,
                                  paddingTop: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Before or After Transit */}
                              <RadioButtonGroup
                                onValueChange={newBeforeOrAfterTransitValue => {
                                  try {
                                    setForfeitBeforeOrAfterSiTravel(
                                      newBeforeOrAfterTransitValue
                                    );
                                    resetForfeitAgreementCount();
                                    resetForfeitAgreements();
                                    setShowForfeitRelinquishCheck(
                                      !showForfeitRelinquishCheck
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                value={forfeitBeforeOrAfterSiTravel}
                              >
                                {/* Before */}
                                <RadioButtonRow
                                  color={palettes.App['App Black 191F32']}
                                  label={
                                    'Cancelled before I was on my way to teach the lesson'
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                  unselectedColor={
                                    palettes.App['App Black 191F32']
                                  }
                                  value={'forfeitBeforeSiTraveled'}
                                />
                                {/* After */}
                                <RadioButtonRow
                                  color={palettes.App['App Black 191F32']}
                                  label={
                                    'Cancelled after I was already on my way or arrived to teach the lesson'
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                  unselectedColor={
                                    palettes.App['App Black 191F32']
                                  }
                                  value={'forfeitAfterSiTraveled'}
                                />
                              </RadioButtonGroup>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Forfeit Agreements */}
                      <>
                        {!shouldShowForfeitAgreements(
                          showForfeitAgreements
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Question */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 12 },
                                dimensions.width
                              )}
                            >
                              {/* Modal Question */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Agreements (both required) '}
                                {forfeitAgreementCount}
                                {'/2'}
                              </Text>
                            </View>
                            {/* agreements form */}
                            <View>
                              {/* decline reschedule A */}
                              <>
                                {!showForfeitRelinquishCheck ? null : (
                                  <CheckboxRow
                                    onPress={newDeclineRescheduleAValue => {
                                      try {
                                        setForfeitAgreementRelinquish(
                                          newDeclineRescheduleAValue
                                        );
                                        updateForfeitAgreementCount(
                                          newDeclineRescheduleAValue
                                        );
                                        const result =
                                          shouldShowForfeitContactRequirements();
                                        if (result) {
                                          return;
                                        }
                                        resetForfeitContactFields();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={
                                      'I prefer not to complete this lesson for full pay'
                                    }
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* decline reschedule B */}
                              <>
                                {showForfeitRelinquishCheck ? null : (
                                  <CheckboxRow
                                    onPress={newDeclineRescheduleBValue => {
                                      try {
                                        setForfeitAgreementRelinquish(
                                          newDeclineRescheduleBValue
                                        );
                                        updateForfeitAgreementCount(
                                          newDeclineRescheduleBValue
                                        );
                                        const result =
                                          shouldShowForfeitContactRequirements();
                                        if (result) {
                                          return;
                                        }
                                        resetForfeitContactFields();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={
                                      'I prefer not to complete this lesson for full pay'
                                    }
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* accept quarter pay */}
                              <>
                                {!(
                                  forfeitBeforeOrAfterSiTravel !==
                                  'forfeitAfterSiTraveled'
                                ) ? null : (
                                  <CheckboxRow
                                    onPress={newAcceptQuarterPayValue => {
                                      try {
                                        setForfeitAgreementPayQuarter(
                                          newAcceptQuarterPayValue
                                        );
                                        updateForfeitAgreementCount(
                                          newAcceptQuarterPayValue
                                        );
                                        const result =
                                          shouldShowForfeitContactRequirements();
                                        if (result) {
                                          return;
                                        }
                                        resetForfeitContactFields();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={
                                      'I accept 25% pay rate for this forfeit lesson'
                                    }
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* accept half pay */}
                              <>
                                {!(
                                  forfeitBeforeOrAfterSiTravel ===
                                  'forfeitAfterSiTraveled'
                                ) ? null : (
                                  <CheckboxRow
                                    onPress={newAcceptHalfPayValue => {
                                      try {
                                        setForfeitAgreementPayHalf(
                                          newAcceptHalfPayValue
                                        );
                                        updateForfeitAgreementCount(
                                          newAcceptHalfPayValue
                                        );
                                        const result =
                                          shouldShowForfeitContactRequirements();
                                        if (result) {
                                          return;
                                        }
                                        resetForfeitContactFields();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={
                                      'I accept 50% pay rate for this forfeit lesson'
                                    }
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Forfeit Contact Requirements */}
                      <>
                        {!shouldShowForfeitContactRequirements(
                          showForfeitContactRequirements
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Modal Question */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 12 },
                                dimensions.width
                              )}
                            >
                              {/* Modal Question */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'I notified '}
                                {clientName}
                                {
                                  ' that this lesson is forfeited and will not be rescheduled via:'
                                }
                              </Text>
                            </View>
                            {/* Requirements form */}
                            <View>
                              {/* phone */}
                              <>
                                {forfeitContactNone ? null : (
                                  <CheckboxRow
                                    onPress={newPhoneValue => {
                                      try {
                                        setForfeitContactPhone(newPhoneValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={'Phone call'}
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* email */}
                              <>
                                {forfeitContactNone ? null : (
                                  <CheckboxRow
                                    onPress={newEmailValue => {
                                      try {
                                        setForfeitContactEmail(newEmailValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={'Email'}
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* text */}
                              <>
                                {forfeitContactNone ? null : (
                                  <CheckboxRow
                                    onPress={newTextValue => {
                                      try {
                                        setForfeitContactText(newTextValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].props}
                                    color={palettes.App['App Black 191F32']}
                                    direction={'row'}
                                    label={'Text message'}
                                    size={22}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.CheckboxRowStyles(theme)[
                                          'Checkbox Row'
                                        ].style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    uncheckedColor={
                                      palettes.App['App Black 191F32']
                                    }
                                  />
                                )}
                              </>
                              {/* haven't */}
                              <CheckboxRow
                                onCheck={() => {
                                  try {
                                    setForfeitContactPhone(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                onPress={newHavenTValue => {
                                  try {
                                    setForfeitContactNone(newHavenTValue);
                                    clearForfeitContactChecks(newHavenTValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.CheckboxRowStyles(theme)[
                                  'Checkbox Row'
                                ].props}
                                color={palettes.App['App Black 191F32']}
                                direction={'row'}
                                label={"I haven't notified the client"}
                                size={22}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.CheckboxRowStyles(theme)[
                                      'Checkbox Row'
                                    ].style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                uncheckedColor={
                                  palettes.App['App Black 191F32']
                                }
                              />
                            </View>
                            {/* View Contact Required Notice */}
                            <>
                              {!forfeitContactNone ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['App Red EB3E26'],
                                      borderRadius: 8,
                                      marginTop: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: palettes.App['Custom Color'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'You must notify the client you intend to mark the lesson forfeit.'
                                    }
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* View Lesson Forfeit Date */}
                      <>
                        {!shouldShowForfeitFormFinish(
                          showForfeitFormFinish
                        ) ? null : (
                          <View>
                            {/* View Modal Questio */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 12 },
                                dimensions.width
                              )}
                            >
                              {/* Modal Question */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'When was this lesson (Lesson '}
                                {lessonNumber}
                                {') supposed to take place?'}
                              </Text>
                            </View>
                            {/* View Confirm Lesson Completion Date */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  flexWrap: 'nowrap',
                                  paddingBottom: 16,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Confirm Forfeit Date */}
                              <DatePicker
                                autoDismissKeyboard={true}
                                disabled={false}
                                hideLabel={false}
                                inline={false}
                                label={'Date'}
                                leftIconMode={'inset'}
                                onDateChange={newConfirmForfeitDateValue => {
                                  try {
                                    setForfeitDate(newConfirmForfeitDateValue);
                                    const result = dateIsBeforeNow(
                                      newConfirmForfeitDateValue
                                    );
                                    setForfeitDateBeforeNow(result);
                                    if (result) {
                                      return;
                                    }
                                    setForfeitDate(Constants['empty']);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                type={'solid'}
                                date={forfeitDate}
                                format={'ddd, mmm dd @ h:MM TT'}
                                mode={'datetime'}
                                rightIconName={
                                  'MaterialCommunityIcons/timeline-clock'
                                }
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor:
                                      palettes.App['App Black 191F32'],
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_400Regular',
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Forfeit Date Can't be in Future */}
                            <>
                              {forfeitDateBeforeNow ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['App Red EB3E26'],
                                      borderRadius: 8,
                                      marginBottom: 8,
                                      paddingBottom: 5,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      paddingTop: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Forfeit date can't be in future */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color: palettes.App['Custom Color'],
                                          fontFamily: 'Poppins_400Regular',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'The forfeit date cannot be in the future.'
                                    }
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* View Lesson Evaluation */}
                      <>
                        {!shouldShowForfeitFormFinish() ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 20 },
                              dimensions.width
                            )}
                          >
                            {/* View Comments Block */}
                            <View>
                              {/* View Comments Title */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { paddingBottom: 12 },
                                  dimensions.width
                                )}
                              >
                                {/* Additional Comments? */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_500Medium',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Additional comments'}
                                </Text>
                              </View>
                              {/* View Text Area Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 145 },
                                  dimensions.width
                                )}
                              >
                                {/* additionalForfeitComments */}
                                <TextInput
                                  autoCorrect={true}
                                  changeTextDelay={500}
                                  multiline={true}
                                  numberOfLines={4}
                                  onChangeText={newAdditionalForfeitCommentsValue => {
                                    try {
                                      setSiLessonComment(
                                        newAdditionalForfeitCommentsValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  textAlignVertical={'top'}
                                  webShowOutline={true}
                                  editable={true}
                                  placeholder={'Your comments'}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderBottomWidth: 1,
                                      borderColor:
                                        palettes.App['App Grey Inputs E2E2E2'],
                                      borderLeftWidth: 1,
                                      borderRadius: 8,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                      height: '100%',
                                      paddingBottom: 16,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                      paddingTop: 16,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </ScrollView>
              {/* View Row Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    marginTop: 20,
                    paddingBottom: 0,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Mark Complete Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 16, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Mark Forfeit Disabled */}
                  <>
                    {shouldEnableForfeitButton() ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Mark Forfeit'}
                      />
                    )}
                  </>
                  {/* Mark Forfeit Enabled */}
                  <>
                    {!shouldEnableForfeitButton() ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              const preparedDates = prepareForfeitDatesToSend();
                              const comment = concatenatedForfeitComment();
                              const response = (
                                await knackAPIForfeitLessonPUT.mutateAsync({
                                  dateSubmittedForPay:
                                    convertTimeFromLocalMachine(
                                      preparedDates?.dateSubmittedForPay
                                    ),
                                  forfeitedDate: convertTimeFromLocalMachine(
                                    preparedDates?.forfeitedDate
                                  ),
                                  lessonKnackId:
                                    props.route?.params?.lessonKnackId ??
                                    defaultProps.lessonKnackId,
                                  lessonType: 'Forfeited',
                                  payRate: getPayRateToSubmit(),
                                  payRequestSource: 'app',
                                  payStatus: 'Submitted - pending Approval',
                                  scheduledDate: convertTimeFromLocalMachine(
                                    preparedDates?.scheduledDate
                                  ),
                                  siForfeitComment: comment,
                                  siLessonStatus: 'Forfeited',
                                })
                              )?.json;
                              console.log(response);
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                              setShowModalForfeit(false);
                              setShowModalForfeitLessonSuccess(true);
                              amplitudeEvents(
                                Variables,
                                'forfeit lesson submit',
                                {
                                  step: '02',
                                  funnel: 'forfeit lesson',
                                  origin: 'lesson details screen',
                                  category: 'lesson management',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Mark Forfeit'}
                      />
                    )}
                  </>
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Cancel Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowModalForfeit(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                </View>
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Confetti Cannon */}
      <View>
        <Utils.CustomCodeErrorBoundary>
          <ConfettiCannon.Confetti />
        </Utils.CustomCodeErrorBoundary>
      </View>
      {/* Fetch All Lessons In Package */}
      <KnackAPIApi.FetchGetLessonsListGET
        filters={JSON.stringify(
          knackFilterParamsAllLessonsInPackage(
            props.route?.params?.lessonPackageKnackId ??
              defaultProps.lessonPackageKnackId
          )
        )}
        handlers={{
          onData: fetchAllLessonsInPackageData => {
            const handler = async () => {
              try {
                const valueKYkiqhbL = sortLessons(
                  fetchAllLessonsInPackageData?.records
                );
                setAllLessonsInPackage(valueKYkiqhbL);
                const sortedLessons = valueKYkiqhbL;
                await handlePreviousAndNextLessonRules(
                  sortedLessons,
                  props.route?.params?.lessonKnackId ??
                    defaultProps.lessonKnackId
                );
                if (true) {
                  return;
                }
                const nextLessonKnackId = getNextLessonID(
                  sortedLessons,
                  props.route?.params?.lessonKnackId ??
                    defaultProps.lessonKnackId
                );
                setNextLessonKnackId(nextLessonKnackId);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          },
        }}
        rows_per_page={1000}
      >
        {({ loading, error, data, refetchGetLessonsList }) => {
          const fetchAllLessonsInPackageData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </KnackAPIApi.FetchGetLessonsListGET>
    </ScreenContainer>
  );
};

export default withTheme(LessonDetailsAndManagementScreen);
