import React from 'react';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Link,
  Pressable,
  SVG,
  ScreenContainer,
  SimpleStyleFlashList,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  StatusBar,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as MakeApi from '../apis/MakeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Track from '../custom-files/Track';
import * as nativeProgress from '../custom-files/nativeProgress';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import * as viewOpportunities from '../custom-files/viewOpportunities';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import concatAddress from '../global-functions/concatAddress';
import convertTimeToLocalMachine from '../global-functions/convertTimeToLocalMachine';
import getFirstNameLastInitial from '../global-functions/getFirstNameLastInitial';
import getUserInitials from '../global-functions/getUserInitials';
import knackFilterParamsAllLessonsInPackage from '../global-functions/knackFilterParamsAllLessonsInPackage';
import openMap from '../global-functions/openMap';
import splitStringOnComma from '../global-functions/splitStringOnComma';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  packageKnackId: '630478a09b4c150023c6d944',
  packageStatus: '',
};

const ClientDetailsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [allLessonRecords, setAllLessonRecords] = React.useState([]);
  const [clientAddressObj, setClientAddressObj] = React.useState({});
  const [clientDaysAvailable, setClientDaysAvailable] = React.useState('');
  const [clientEmail, setClientEmail] = React.useState('');
  const [clientKnackID, setClientKnackID] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [clientPhoneNumber, setClientPhoneNumber] = React.useState('');
  const [clientWeekDayTimesAvail, setClientWeekDayTimesAvail] =
    React.useState('');
  const [clientWeekEndTimesAvail, setClientWeekEndTimesAvail] =
    React.useState('');
  const [completionRate, setCompletionRate] = React.useState(0);
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [empty, setEmpty] = React.useState('');
  const [falseValue, setFalseValue] = React.useState(false);
  const [filters, setFilters] = React.useState({
    match: 'and',
    rules: [
      { field: 'field_58', value: '630478a09b4c150023c6d944', operator: 'is' },
    ],
  });
  const [firstLesson, setFirstLesson] = React.useState(0);
  const [is_loading, setIs_loading] = React.useState(false);
  const [is_loading_lessons_list, setIs_loading_lessons_list] =
    React.useState(true);
  const [is_loading_package_details, setIs_loading_package_details] =
    React.useState(true);
  const [lessonsCompleted, setLessonsCompleted] = React.useState(0);
  const [lessonsDuration, setLessonsDuration] = React.useState(0);
  const [lessonsListFilter, setLessonsListFilter] = React.useState('all');
  const [lessonsPerWeek, setLessonsPerWeek] = React.useState(0);
  const [lessonsQuantity, setLessonsQuantity] = React.useState(1);
  const [packageId, setPackageId] = React.useState(0);
  const [packageKnackId, setPackageKnackId] = React.useState('');
  const [payBase, setPayBase] = React.useState(0);
  const [payBonus, setPayBonus] = React.useState(0);
  const [payForLessonsCompleted, setPayForLessonsCompleted] = React.useState(0);
  const [payPerLesson, setPayPerLesson] = React.useState(0);
  const [payTotal, setPayTotal] = React.useState(0);
  const [poolType, setPoolType] = React.useState('');
  const [reviewBonusPayDate, setReviewBonusPayDate] = React.useState(
    new Date()
  );
  const [reviewBonusPayStatus, setReviewBonusPayStatus] = React.useState('');
  const [reviewBonusPayValue, setReviewBonusPayValue] = React.useState(0);
  const [reviewBonusStatus, setReviewBonusStatus] = React.useState('unclaimed');
  const [reviewBonusURL, setReviewBonusURL] = React.useState('');
  const [reviewRequestSent, setReviewRequestSent] = React.useState(false);
  const [reviewRequestSentDate, setReviewRequestSentDate] = React.useState(
    new Date()
  );
  const [reviewRequestTemplate, setReviewRequestTemplate] = React.useState('');
  const [sendReviewRequestFailed, setSendReviewRequestFailed] =
    React.useState(false);
  const [showComposeReviewRequest, setShowComposeReviewRequest] =
    React.useState(false);
  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const [showModalClientContact, setShowModalClientContact] =
    React.useState(false);
  const [showModalContactSuccess, setShowModalContactSuccess] =
    React.useState(false);
  const [showModalContactSunsational, setShowModalContactSunsational] =
    React.useState(false);
  const [showRequestReviewSuccess, setShowRequestReviewSuccess] =
    React.useState(false);
  const [showReviewBonusForm, setShowReviewBonusForm] = React.useState(false);
  const [showReviewBonusPromptFull, setShowReviewBonusPromptFull] =
    React.useState(false);
  const [showReviewBonusSubmittedSuccess, setShowReviewBonusSubmittedSuccess] =
    React.useState(false);
  const [showReviewRequestPreview, setShowReviewRequestPreview] =
    React.useState(false);
  const [showSSSContactOptions, setShowSSSContactOptions] =
    React.useState(false);
  const [showScheduleDetails, setShowScheduleDetails] = React.useState(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [showTemplateErrorMessage, setShowTemplateErrorMessage] =
    React.useState(false);
  const [siContactMessage, setSiContactMessage] = React.useState('');
  const [siRequestedReviewBonus, setSiRequestedReviewBonus] =
    React.useState(false);
  const [siReviewRequestBody, setSiReviewRequestBody] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [studentDetails, setStudentDetails] = React.useState('');
  const [studentDetailsAdditional, setStudentDetailsAdditional] =
    React.useState('');
  const [templateErrorMessage, setTemplateErrorMessage] = React.useState('');
  const [useReviewTemplate, setUseReviewTemplate] = React.useState(true);
  const formatPhoneNumber = (phoneNumber, type) => {
    if (!phoneNumber) {
      return 'Not available';
    }

    let cleaned = phoneNumber[0] === '+' ? phoneNumber.slice(1) : phoneNumber;

    const country = cleaned.slice(0, 1);
    const areaCode = phoneNumber.slice(2, 5);
    const prefix = phoneNumber.slice(5, 8);
    const suffix = phoneNumber.slice(8);

    let formattedNumber;

    if (type === 'frontend') {
      formattedNumber = `+1 (${areaCode}) ${prefix}-${suffix}`;
    }

    if (type === 'backend') {
      formattedNumber = `+1${areaCode}${prefix}${suffix}`;
    }

    return formattedNumber;
  };

  const getReviewPlatform = () => {
    if (reviewBonusURL.includes('google')) {
      return 'google';
    }

    if (reviewBonusURL.includes('yelp')) {
      return 'yelp';
    }

    if (reviewBonusURL.includes('customer')) {
      return 'customer lobby';
    }

    if (reviewBonusURL.includes('facebook')) {
      return 'facebook';
    }
  };

  const processRequestReviewResponse = response => {
    if (!response?.success) {
      setSendReviewRequestFailed(true);
      setIs_loading(false);
      return;
    }

    setShowReviewRequestPreview(false);
    setShowRequestReviewSuccess(true);
    setReviewRequestSent(true);

    setIs_loading(false);
  };

  const shouldShowReviewBonusForm = () => {
    if (completionRate >= 50) {
      setShowReviewBonusForm(true);
      return;
    }

    let title = "Water's A Little Too Shallow";
    let body =
      'You must complete at least 50% of the lesson package before requesting a review bonus.';
    let buttonText = 'GOT IT';

    showAlert(title, body, buttonText);
  };

  const getStudentFirstNames = students => {
    if (!students) {
      return;
    }

    let studentNamesStr = '';
    let studentsArr = students.split(',');

    studentsArr.forEach((student, i) => {
      let studentNames = student.split(' ');

      if (i < 1) {
        studentNamesStr += studentNames[0];
      }

      if (i > 0) {
        studentNamesStr += ` and ${studentNames[0]}`;
      }
    });

    return studentNamesStr;
  };

  const alertPoolType = () => {
    alert(`These lessons take place at the client's ${poolType}`);
  };

  const shouldShowReviewRequestPreview = isMessageComposed => {
    setShowReviewRequestPreview(isMessageComposed);
  };

  const processSendMsgResponse = (setGlobalVariableValue, response) => {
    if (!response?.success) {
      setShowSendMsgFailed(true);
      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
      return;
    }

    setShowModalContactSunsational(false);
    setShowModalContactSuccess(true);

    setGlobalVariableValue({
      key: 'is_loading',
      value: false,
    });
  };

  const isReviewRequestTemplateEdited = text => {
    if (!text) {
      return false;
    }

    if (
      text.includes('[student names/you]') ||
      text.includes('[him/her/them/you]')
    ) {
      return false;
    }

    return true;
  };

  const sortLessons = lessonsArray => {
    if (!lessonsArray) {
      return [];
    }

    return lessonsArray.sort((a, b) => a.field_320_raw - b.field_320_raw);
  };

  const identifyCompletionBonus = lessonNumber => {
    if (lessonNumber === 0) {
      return 'CB';
    }

    return lessonNumber;
  };

  const getLongLessonName = lessonNumber => {
    if (lessonNumber === 0) {
      return 'Completion Bonus';
    }
    if (lessonNumber < 10) {
      return 'Lesson 0' + lessonNumber;
    }

    return 'Lesson ' + lessonNumber;
  };

  const getLessonStatus = (lessonStatus, lessonScheduleDate) => {
    if (lessonScheduleDate) {
      return 'Scheduled';
    }

    if (lessonStatus === 'Matched') {
      return 'Unscheduled';
    }

    if (lessonStatus === 'Submitted - pending Approval') {
      return 'Marked Completed - Pay Pending';
    }

    return lessonStatus;
  };

  const getTotalBasePay = (basePay, lessonsCount) => {
    return basePay * lessonsCount;
  };

  const closeModalRequestSuccess = async (
    Variables,
    setGlobalVariableValue
  ) => {
    setGlobalVariableValue({
      key: 'sendRequestPackageResponse',
      value: '',
    });

    setShowModalRequestSuccess(false);
  };

  const mathRound = num => {
    return Math.round(num);
  };

  const getDate = string => {
    let date = string.replace('On a particular day', '');

    return date;
  };

  const createTemplateErrorMessage = reviewRequestText => {
    let errorMessage = 'Edit in first paragraph required:';
    let missingStudentNames = ` [students names/you]`;
    let missingStudentPronouns = ` [him/her/them/you]`;

    if (!reviewRequestText) {
      errorMessage += `${missingStudentNames}${missingStudentPronouns}`;

      if (!useReviewTemplate) {
        errorMessage = `Error: Empty message. Write a custom message or use a template.`;
      }
    }

    if (reviewRequestText.includes('[students names/you')) {
      errorMessage += missingStudentNames;
    }

    if (reviewRequestText.includes('[him/her/them/you]')) {
      errorMessage += missingStudentPronouns;
    }

    setTemplateErrorMessage(errorMessage);
  };

  const processSubmitReviewBonusResponse = response => {
    if (!response?.success) {
      setIs_loading(false);
      showAlert('Uh oh', 'We hit a ripple. Please try again.', 'OK');
      return;
    }

    setReviewBonusStatus('pending');
    setShowReviewBonusSubmittedSuccess(true);

    setIs_loading(false);
  };

  const showAlert = (title, message, buttonText) => {
    showAlertUtil({
      title: title ?? 'Paid',
      message: message ?? `Your review bonus was paid on ${reviewBonusPayDate}`,
      buttonText: buttonText ?? 'CLOSE',
    });
  };

  const scheduledTimeIsInFuture = inputDateTime => {
    const currentTime = new Date();
    const oneHour = 60 * 60 * 1000;

    console.log('currenTime === ', currentTime);
    console.log('inputDateTime === ', inputDateTime);
    return currentTime > inputDateTime;
  };

  const shouldShowComposeReviewRequest = reviewRequestSent => {
    let reviewRequestSentDate;

    if (!reviewRequestSent) {
      setShowComposeReviewRequest(true);
    } else {
      alert(
        `You requested a review from the client on ${reviewRequestSentDate}. Please follow up in person, over the phone or text message.`
      );
    }
  };

  const createFilterParams = packageKnackId => {
    function FilterLessonsInPackage(packageKnackId) {
      this.field = 'field_58';
      this.operator = 'is';
      this.value = packageKnackId;
    }

    function FilterLessonStatusNotUnbooked() {
      this.field = 'field_54';
      this.operator = 'is not';
      this.value = 'Unbooked';
    }

    let match = 'and';
    const rules = [];

    rules.push(new FilterLessonsInPackage(packageKnackId));
    rules.push(new FilterLessonStatusNotUnbooked());

    const filterRules = {
      match,
      rules,
    };

    return filterRules;
  };

  const fixReviewBonusPayValue = value => {
    console.log('field_694_raw === ', value);

    if (!value) {
      return 0;
    }
    if (value.length === 0) {
      return 0;
    }

    return value[0];
  };

  const generateReviewRequestDraft = (Variables, useTemplate) => {
    const standardEncoding = str => {
      return str.replace(/%0A/g, ' ');
    };

    const { userFirstName } = Variables;

    let name = userFirstName || 'Chris';

    let uriSafeTemplate = '';

    if (useTemplate) {
      uriSafeTemplate = `Hello ${clientName},%0A%0AI hope you're doing well! It's truly a pleasure working with [STUDENTS NAMES/YOU] during our swim sessions. I've genuinely enjoyed watching [HIM/HER/THEM/YOU] progress and become more confident in the water.%0A%0AIf you found our time together beneficial, I'd appreciate if you could take a few moments to leave a review about your experience with me at Sunsational Swim School. Your feedback not only helps me grow as an instructor but also assists other families in making informed decisions about their swim education. Sunsational also offers me a bonus for the positive reviews I receive.%0A%0AThank you for trusting me with your swim journey. I'm always here if you have any questions or need further guidance in the future.%0A%0AAll the best,%0A${name}%0ASunsational Swim Instructor`;
    }

    setReviewRequestTemplate(standardEncoding(uriSafeTemplate));
    return uriSafeTemplate;
  };

  const fixReviewBonusStatus = (reviewPayStatus, reviewBonusPayRequested) => {
    console.log('reviewBonusPayRequested === ', reviewBonusPayRequested);
    const isEmptyArray = value => {
      return Array.isArray(value) && value.length === 0;
    };

    let reviewPayStatusString = reviewPayStatus[0];

    let reviewStatus;

    if (
      isEmptyArray(reviewBonusPayRequested) ||
      !reviewBonusPayRequested ||
      isEmptyArray(reviewPayStatus) ||
      reviewPayStatusString === 'Approved - Pay Not Requested' ||
      reviewPayStatusString === 'Pending Approval - Pay Not Requested'
    ) {
      reviewStatus = 'unclaimed';
    }

    if (
      reviewPayStatusString === 'Pending approval' ||
      reviewPayStatusString === 'Pending Approval - Pay Requested'
    ) {
      reviewStatus = 'pending';
    }

    if (reviewPayStatusString === 'Approved - Pending Payment') {
      reviewStatus = 'approved';
    }

    if (reviewPayStatusString === 'Paid') {
      reviewStatus = 'paid';
    }

    if (reviewPayStatusString === 'Not eligible') {
      reviewStatus = 'not eligible';
    }

    console.log('reviewPayStatus === ', reviewPayStatus);
    console.log('reviewStatus === ', reviewStatus);

    return reviewStatus;
  };

  const setScreenVariables = (records, recordsType) => {
    if (recordsType === 'packageDetails') {
      const {
        field_117_raw, // clientPhoneNumberObj;
        field_119_raw, // clientKnackID
        field_615_raw, // clientEmail;
        field_455_raw, // clientName
        field_56_raw, // packageId
        id, // packageKnackId
        field_171_raw, // payPerLesson
        field_449_raw, // studentDetails
        field_98_raw, // clientAddressObj
        field_176_raw, //lessonsDuration
        field_314_raw, // clientAvailabilty
        field_460_raw, // startDate requested
        field_2394, // clientWeekDayTimesAvail
        field_2395, // clientWeekendTimesAvail
        field_253_raw, // lessonsPerWeek
        field_521_raw, // studentDetailsAdditional
        field_99_raw, // poolType,
        field_2517_raw, // siRequestedReviewBonus,
        field_694_raw, // reviewBonusPayValue
        field_695_raw, //reviewBonusPayStatus
        field_697_raw, //reviewBonusPayDate
      } = records;

      setClientPhoneNumber(field_117_raw.full);
      setClientKnackID(field_119_raw[0].id);
      setClientEmail(field_615_raw);
      setClientName(field_455_raw);
      setPackageId(field_56_raw);
      setPackageKnackId(id);
      setPayPerLesson(field_171_raw);
      setStudentDetails(field_449_raw);
      setClientAddressObj(field_98_raw);
      setLessonsDuration(field_176_raw);
      setStartDate(field_460_raw);
      setClientDaysAvailable(field_314_raw);
      setClientWeekDayTimesAvail(field_2394);
      setClientWeekEndTimesAvail(field_2395);
      setLessonsPerWeek(field_253_raw);
      setStudentDetailsAdditional(field_521_raw);
      setPoolType(field_99_raw);
      setSiRequestedReviewBonus(field_2517_raw);
      setReviewBonusPayValue(fixReviewBonusPayValue(field_694_raw));
      setReviewBonusStatus(fixReviewBonusStatus(field_695_raw, field_2517_raw));
      setReviewBonusPayDate(field_697_raw[0]?.date_formatted);
    }

    if (recordsType === 'lessons') {
      const getFirstLessonNumber = lessonRecords => {
        let firstLessonNumber;
        let firstLessonFilled = false;
        for (let i = 0; i < 2; i++) {
          let lessonNumber = lessonRecords[i]?.field_320_raw;
          if (lessonNumber > 0 && !firstLessonFilled) {
            firstLessonFilled = true;
            firstLessonNumber = lessonNumber;
          }
        }
        return firstLessonNumber;
      };

      let totalPay = 0;
      let totalBasePay = 0;
      let bonusPay = 0;
      let totalPayForLessonsCompleted = 0;
      let totalLessonsCount = 0;
      let totalLessonsCompleted = 0;

      for (const lesson of records) {
        let lessonType = lesson.field_168_raw;
        console.log('lessonType === ', lessonType);
        let lessonNumber = lesson.field_320_raw;
        let lessonStatus = lesson.field_54_raw;
        let pay = Number(lesson.field_53_raw);
        let payAmountApproved = lesson.field_69_raw;

        if (!lessonType.includes('Bonus') && lessonStatus !== 'Cancelled') {
          totalBasePay += pay;
          totalLessonsCount++;
        }
        if (lessonNumber < 1) {
          bonusPay += pay;
        }
        if (lessonStatus === 'Completed') {
          totalLessonsCompleted++;
        }
        if (lesson.field_534_raw === 'Paid') {
          totalPayForLessonsCompleted += payAmountApproved;
        }
      }
      totalPay = totalBasePay + bonusPay;

      let roundedCompletionRate = Math.round(
        (totalLessonsCompleted / totalLessonsCount) * 100
      );

      setPayBase(totalBasePay);
      setPayBonus(payBonus);
      setPayTotal(totalPay);
      setLessonsQuantity(totalLessonsCount);
      setLessonsCompleted(totalLessonsCompleted);
      setCompletionRate(roundedCompletionRate);
      setPayForLessonsCompleted(totalPayForLessonsCompleted);
      setFirstLesson(getFirstLessonNumber(records));
      setAllLessonRecords(records);
    }
  };

  const shouldEnableCompleteLessonNav = (currentLessonID, allLessonRecords) => {
    const currentLessonIndex = allLessonRecords.findIndex(
      record => record.id === currentLessonID
    );

    if (currentLessonIndex === -1) {
      console.error('Current lesson not found in the records.');
      return false;
    }

    const isPreviousLessonPresent = currentLessonIndex > 0;
    const previousLessonType = isPreviousLessonPresent
      ? allLessonRecords[currentLessonIndex - 1]?.field_168
      : '';
    const isPreviousLessonBonus = previousLessonType.includes('Bonus');
    const isPreviousLessonCompleted = isPreviousLessonPresent
      ? allLessonRecords[currentLessonIndex - 1]?.field_205
      : true;

    if (
      isPreviousLessonPresent &&
      !isPreviousLessonCompleted &&
      !isPreviousLessonBonus
    ) {
      alert(
        'Lessons must be marked complete in sequential order. Mark the previous lesson complete to continue.'
      );
      return false;
    }

    return true;
  };

  const checkReviewLinkIsValid = () => {
    if (reviewBonusURL.includes('https://')) {
      if (
        reviewBonusURL.includes('google') ||
        reviewBonusURL.includes('goo.gl') ||
        reviewBonusURL.includes('g.co') ||
        reviewBonusURL.includes('yelp') ||
        reviewBonusURL.includes('customer') ||
        reviewBonusURL.includes('facebook')
      ) {
        return true;
      }
    }

    let title = 'URL Out of Water';
    let body =
      'Please enter a valid link to a review on Google, Yelp, Customer Lobby or Facebook.';
    let buttonText = 'Woops';

    showAlert(title, body, buttonText);

    return false;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'ClientDetailsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const makeSubmitReviewBonusPOST = MakeApi.useSubmitReviewBonusPOST();
  const integromatSendEmailToMatching$BookingPOST =
    IntegromatApi.useSendEmailToMatching$BookingPOST();
  const makeSendClientAReviewRequestPOST =
    MakeApi.useSendClientAReviewRequestPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      const filters = knackFilterParamsAllLessonsInPackage(
        props.route?.params?.packageKnackId ?? defaultProps.packageKnackId
      );
      setFilters(filters);

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['Custom Color_7'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          { justifyContent: 'center' },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiIconArrowLeftWhite']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: palettes.App['Custom Color'],
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Client Details'}
            </Text>
          </View>
          {/* Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            {/* Spacer */}
            <Link
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={''}
            />
          </View>
        </View>
      </View>

      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors.background.brand,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            minHeight: 600,
            paddingBottom: 15,
            paddingTop: 15,
          },
          dimensions.width
        )}
      >
        {/* View Card Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['Custom Color'],
              borderRadius: 0,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              height: '90%',
              justifyContent: 'space-between',
              minHeight: 400,
              paddingBottom: 180,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Loading Package Details */}
          <>
            {!is_loading_package_details ? null : (
              <View>
                {/* Placeholder Primary Details */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: '5.35%', paddingRight: '5.35%' },
                    dimensions.width
                  )}
                >
                  {/* Placeholder Card Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 24,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Client Name Placeholder */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['App Blue eef6fa'],
                          borderRadius: 8,
                          height: 56,
                          width: '72%',
                        },
                        dimensions.width
                      )}
                    />
                    {/* Loading Status */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                          width: '24%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Status Loading */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 8,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  </View>
                  {/* Placeholder Payment */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Placeholder Surface */}
                    <Surface
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor:
                            palettes.App['App Disabled Button EDEDEF'],
                          borderRadius: 16,
                          flexDirection: 'row',
                          height: 96,
                          justifyContent: 'space-around',
                          marginBottom: -16,
                          paddingBottom: 16,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 16,
                          zIndex: 2,
                        },
                        dimensions.width
                      )}
                    />
                    {/* Placeholder Bottom */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Blue eef6fa'],
                          borderBottomLeftRadius: 16,
                          borderBottomRightRadius: 16,
                          flexDirection: 'column',
                          height: 132,
                          justifyContent: 'center',
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 32,
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            { height: 48, width: 48 }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                  {/* Placeholder Location Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Placeholder Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'baseline',
                          borderRadius: 8,
                          flexDirection: 'row',
                          height: 32,
                          marginBottom: 16,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 8,
                            height: 32,
                            marginRight: 8,
                            width: 56,
                          },
                          dimensions.width
                        )}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 8,
                            height: 32,
                            marginRight: 8,
                            width: 112,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Placeholder Address Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Blue eef6fa'],
                          borderRadius: 16,
                          flexDirection: 'row',
                          flexWrap: 'nowrap',
                          height: 148,
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* Placeholder Student Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Placeholder Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'baseline',
                          borderRadius: 8,
                          flexDirection: 'row',
                          height: 32,
                          marginBottom: 16,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 8,
                            height: 32,
                            marginRight: 8,
                            width: 56,
                          },
                          dimensions.width
                        )}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 8,
                            height: 32,
                            marginRight: 8,
                            width: 112,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Placeholder Student Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Blue eef6fa'],
                          borderRadius: 16,
                          flexDirection: 'row',
                          height: 72,
                          marginBottom: 6,
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </View>
            )}
          </>
          {/* Fetch Package Details */}
          <KnackAPIApi.FetchGetPackageDetailsGET
            handlers={{
              onData: fetchPackageDetailsData => {
                try {
                  setIs_loading_package_details(true);
                  setScreenVariables(fetchPackageDetailsData, 'packageDetails');
                  setIs_loading_package_details(false);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            knackId={
              props.route?.params?.packageKnackId ?? defaultProps.packageKnackId
            }
          >
            {({ loading, error, data, refetchGetPackageDetails }) => {
              const fetchPackageDetailsData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* View Primary Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: '5.35%', paddingRight: '5.35%' },
                      dimensions.width
                    )}
                  >
                    {/* View Card Title Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* View Top Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Left Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* View Client PP */}
                          <View
                            style={StyleSheet.applyWidth(
                              { height: 48, marginRight: 8, width: 48 },
                              dimensions.width
                            )}
                          >
                            <Surface
                              elevation={3}
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1.25,
                                  borderColor: palettes.App['Custom Color'],
                                  borderLeftWidth: 1.25,
                                  borderRadius: 100,
                                  borderRightWidth: 1.25,
                                  borderTopWidth: 1.25,
                                  height: '100%',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* SVG Avatar */}
                              <SVG
                                source={Constants['uiAvatar3']}
                                style={StyleSheet.applyWidth(
                                  { height: '100%', width: '100%' },
                                  dimensions.width
                                )}
                              />
                            </Surface>
                            {/* View Initials Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 48,
                                  justifyContent: 'center',
                                  marginTop: -48,
                                  width: 48,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Initials */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['Custom Color'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 24,
                                    textTransform: 'uppercase',
                                  },
                                  dimensions.width
                                )}
                              >
                                {getUserInitials(clientName)}
                              </Text>
                            </View>
                          </View>
                          {/* View Client Details */}
                          <View>
                            {/* View Client Name */}
                            <View>
                              {/* Client Name */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    textTransform: 'capitalize',
                                  },
                                  dimensions.width
                                )}
                              >
                                {clientName}
                              </Text>
                            </View>
                            {/* Package ID */}
                            <View>
                              {/* Package ID */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'ID '}
                                {packageId}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* View Status Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-end',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Matched Status */}
                          <View
                            style={StyleSheet.applyWidth(
                              { borderRadius: 8, flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* View Matched With SI */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Mint #F0F8F2'],
                                  borderRadius: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Matched Status */}
                              <>
                                {!(
                                  (props.route?.params?.packageStatus ??
                                    defaultProps.packageStatus) !== 'complete'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text Green #1DB058'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Matched'}
                                  </Text>
                                )}
                              </>
                              {/* Complete Status */}
                              <>
                                {!(
                                  (props.route?.params?.packageStatus ??
                                    defaultProps.packageStatus) === 'complete'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text Green #1DB058'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Complete'}
                                  </Text>
                                )}
                              </>
                            </View>
                          </View>
                          {/* View Status Loading */}
                          <>
                            {!is_loading ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  style={StyleSheet.applyWidth(
                                    { height: 36, width: 36 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                    {/* View Row Pay and Lesson Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 12 },
                        dimensions.width
                      )}
                    >
                      {/* Surface Lesson Pay Details */}
                      <Surface
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderRadius: 16,
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            marginBottom: -16,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 16,
                            zIndex: 2,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Base Pay */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '33%' },
                            dimensions.width
                          )}
                        >
                          {/* Label Base Pay */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: 'center',
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 14,
                                marginBottom: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Base Pay'}
                          </Text>
                          {/* Base Pay Loaded */}
                          <>
                            {!(payBase > 0) ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Text Green #1DB058'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {payBase}
                              </Text>
                            )}
                          </>
                          <>
                            {!(payBase < 1) ? null : (
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                        {/* View Bonus Pay */}
                        <>
                          {!(
                            fetchPackageDetailsData?.field_169_raw > 0
                          ) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderColor:
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ],
                                  borderLeftWidth: 1,
                                  borderRightWidth: 1,
                                  width: '33%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Label Bonus Pay */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 14,
                                    marginBottom: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Bonus'}
                              </Text>
                              {/* Bonus Pay Loaded */}
                              <>
                                {!(payBase > 0) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color:
                                          palettes.App['App Sun Orange F4A100'],
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'+ $'}
                                    {fetchPackageDetailsData?.field_169_raw}
                                  </Text>
                                )}
                              </>
                              <>
                                {!(payBase < 1) ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    {...GlobalStyles.ActivityIndicatorStyles(
                                      theme
                                    )['Activity Indicator'].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ActivityIndicatorStyles(
                                        theme
                                      )['Activity Indicator'].style,
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* View Lesson Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '33%' },
                            dimensions.width
                          )}
                        >
                          {/* Label Total Lessons */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: 'center',
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 14,
                                marginBottom: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Lesson Pack'}
                          </Text>
                          {/* Lesson Package Loaded */}
                          <>
                            {!(payBase > 0) ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {lessonsQuantity}
                                {'x'}
                                {lessonsDuration}
                                {'min'}
                              </Text>
                            )}
                          </>
                          <>
                            {!(payBase < 1) ? null : (
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      </Surface>
                      {/* View Paid Per Lesson Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['Custom Color_6'],
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                            flexDirection: 'column',
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 32,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Lesson and Pay Progress */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              paddingBottom: 16,
                              paddingTop: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Progress Bar Lessons */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: -6 },
                              dimensions.width
                            )}
                          >
                            <Utils.CustomCodeErrorBoundary>
                              <nativeProgress.Bar
                                lessonsCompleted={lessonsCompleted}
                                lessonsQuantity={lessonsQuantity}
                              ></nativeProgress.Bar>
                            </Utils.CustomCodeErrorBoundary>
                          </View>
                          {/* View Progress Bar Payment */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 4 },
                              dimensions.width
                            )}
                          >
                            <Utils.CustomCodeErrorBoundary>
                              <nativeProgress.Bar
                                lessonsCompleted={payForLessonsCompleted}
                                lessonsQuantity={payTotal}
                                fillColor={'#1DB058'}
                                unfilledColor={'#FF'}
                              ></nativeProgress.Bar>
                            </Utils.CustomCodeErrorBoundary>
                          </View>
                          {/* Progress Details */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Lessons complete */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Completed lessons */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Gold #FFC400'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {lessonsCompleted}{' '}
                              </Text>
                              {/* All lessons */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'of '}
                                {lessonsQuantity}
                                {' lessons complete ·'}
                              </Text>
                            </View>
                            {/* Pay */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row', marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Amount paid */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text Green #1DB058'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {payForLessonsCompleted}{' '}
                              </Text>
                              {/* Total amount */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'of $'}
                                {payTotal}
                                {' has been paid'}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* View Row Pay Per Lesson */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App['App Sun Orange F4A100']}
                            name={'Ionicons/information-circle-outline'}
                            size={18}
                          />
                          {/* Get paid x per lesson */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'Poppins_400Regular',
                                marginLeft: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Get paid $'}
                            {payPerLesson}
                            {' per lesson'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* Add Review Bonus Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* Review Bonus Unclaimed */}
                      <>
                        {!(reviewBonusStatus === 'unclaimed') ? null : (
                          <View>
                            {/* Add Review Button Container */}
                            <>
                              {showReviewBonusForm ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { paddingLeft: 16, paddingRight: 16 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Add Review Bonus */}
                                  <Link
                                    accessible={true}
                                    onPress={() => {
                                      try {
                                        shouldShowReviewBonusForm();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectable={false}
                                    {...GlobalStyles.LinkStyles(theme)['Link']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)['Link']
                                          .style,
                                        {
                                          color:
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'+ Add Social Media Review Bonus'}
                                  />
                                </View>
                              )}
                            </>
                            {/* Add Review Form Open */}
                            <>
                              {!showReviewBonusForm ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor:
                                        palettes.App['App Grey Inputs E2E2E2'],
                                      borderRadius: 8,
                                      borderWidth: 1,
                                      paddingBottom: 16,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                      paddingTop: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Close Add Review Bonus */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Close Icon */}
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setShowReviewBonusForm(false);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      icon={'MaterialCommunityIcons/close'}
                                      size={24}
                                    />
                                  </View>
                                  {/* Add Review Bonus Title */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'baseline',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* H1 */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'BebasNeue_400Regular',
                                          fontSize: 24,
                                          marginRight: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Add Review Bonus 💰'}
                                    </Text>
                                    {/* Amount */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App[
                                              'App Sun Orange F4A100'
                                            ],
                                          fontFamily: 'BebasNeue_400Regular',
                                          fontSize: 24,
                                          marginRight: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'+ $20'}
                                    </Text>
                                  </View>
                                  {/* Body */}
                                  <View>
                                    {/* Truncated About Text */}
                                    <>
                                      {showReviewBonusPromptFull ? null : (
                                        <View>
                                          {/* Text Tuncated */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'primary14'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'primary14'
                                                ].style,
                                                {
                                                  color:
                                                    palettes.App[
                                                      'App Text 2ndary 7F868C'
                                                    ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              "Earn up to an additional $20 bonus added to your package when your client posts a review about your lessons on Yelp, Google, Facebook or Customer Lobby. Submit the link to your client's review to receive the bonus pay."
                                            }
                                          </Text>
                                          {/* More info */}
                                          <Link
                                            accessible={true}
                                            onPress={() => {
                                              try {
                                                setShowReviewBonusPromptFull(
                                                  true
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            selectable={false}
                                            {...GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.LinkStyles(theme)[
                                                'Link'
                                              ].style,
                                              dimensions.width
                                            )}
                                            title={'more info'}
                                          />
                                        </View>
                                      )}
                                    </>
                                    {/* Full About Text */}
                                    <>
                                      {!showReviewBonusPromptFull ? null : (
                                        <View>
                                          {/* Full Text */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'primary14'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'primary14'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              "Earn up to an additional $20 bonus added to your package when your client posts a review about your lessons on Yelp, Google, Facebook or Customer Lobby. Submit the link to your client's review to receive the bonus pay."
                                            }
                                          </Text>
                                          {/* less info */}
                                          <Link
                                            accessible={true}
                                            onPress={() => {
                                              try {
                                                setShowReviewBonusPromptFull(
                                                  false
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            selectable={false}
                                            {...GlobalStyles.LinkStyles(theme)[
                                              'Link'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.LinkStyles(theme)[
                                                'Link'
                                              ].style,
                                              dimensions.width
                                            )}
                                            title={'less info'}
                                          />
                                        </View>
                                      )}
                                    </>
                                  </View>
                                  {/* Step 1 Ask Client */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 16 },
                                      dimensions.width
                                    )}
                                  ></View>
                                  {/* Review Input Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginTop: 24 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Link to client's review */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color:
                                              palettes.App['App Red EB3E26'],
                                            fontFamily: 'BebasNeue_400Regular',
                                            fontSize: 18,
                                            marginBottom: 4,
                                            marginLeft: 4,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {"Paste link to client's review"}
                                    </Text>
                                    {/* Link to Review Input */}
                                    <TextInput
                                      autoCapitalize={'none'}
                                      autoCorrect={true}
                                      changeTextDelay={500}
                                      onChangeText={newLinkToReviewInputValue => {
                                        try {
                                          setReviewBonusURL(
                                            newLinkToReviewInputValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      webShowOutline={true}
                                      clearTextOnFocus={false}
                                      editable={true}
                                      placeholder={'paste exact link'}
                                      placeholderTextColor={
                                        palettes.App.TextPlaceholder
                                      }
                                      secureTextEntry={false}
                                      spellcheck={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Custom Color'],
                                          borderBottomWidth: 1,
                                          borderColor:
                                            palettes.App['App Black 191F32'],
                                          borderLeftWidth: 1,
                                          borderRadius: 8,
                                          borderRightWidth: 1,
                                          borderTopWidth: 1,
                                          fontFamily: 'Poppins_400Regular',
                                          marginBottom: 16,
                                          paddingBottom: 16,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                          paddingTop: 16,
                                        },
                                        dimensions.width
                                      )}
                                      textContentType={'URL'}
                                    />
                                  </View>
                                  {/* Submit Review Container */}
                                  <View>
                                    {/* Submit Review */}
                                    <Button
                                      iconPosition={'left'}
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            const result =
                                              checkReviewLinkIsValid();
                                            if (!result) {
                                              return;
                                            }
                                            setIs_loading(true);
                                            const platform =
                                              getReviewPlatform();
                                            const response = (
                                              await makeSubmitReviewBonusPOST.mutateAsync(
                                                {
                                                  clientEmail: clientEmail,
                                                  clientKnackID: clientKnackID,
                                                  clientName: 'Dive Vader',
                                                  packageID: packageId,
                                                  packageKnackID:
                                                    packageKnackId,
                                                  platform: platform,
                                                  reviewLink: reviewBonusURL,
                                                  siEmail:
                                                    Constants[
                                                      'gInstructorEmailAddress'
                                                    ],
                                                  siKnackID:
                                                    Constants[
                                                      'AUTH_KNACK_USER_ID'
                                                    ],
                                                }
                                              )
                                            )?.json;
                                            processSubmitReviewBonusResponse(
                                              response
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      loading={is_loading}
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['App Gold #FFC400'],
                                          borderRadius: 8,
                                          fontFamily: 'BebasNeue_400Regular',
                                          fontSize: 18,
                                          paddingBottom: 14,
                                          paddingTop: 14,
                                          textAlign: 'center',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                      title={'Submit Review Bonus'}
                                    />
                                    {/* View Activity Indicator */}
                                    <>
                                      {!Constants['is_loading'] ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                palettes.App['App Red EB3E26'],
                                              borderRadius: 50,
                                              marginTop: -50,
                                              opacity: 0.8,
                                              paddingBottom: 7,
                                              paddingTop: 7,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            color={palettes.App['Custom Color']}
                                            style={StyleSheet.applyWidth(
                                              { height: 36, width: 36 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      )}
                                    </>
                                  </View>
                                  {/* Close Add Review Bonus */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center', marginTop: 16 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Close */}
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        try {
                                          setShowReviewBonusForm(false);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectable={false}
                                      {...GlobalStyles.LinkStyles(theme)['Link']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.LinkStyles(theme)['Link']
                                            .style,
                                          {
                                            color:
                                              palettes.App[
                                                'App Text 2ndary 7F868C'
                                              ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'close x'}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* Review Bonus Processing */}
                      <>
                        {!(reviewBonusStatus === 'pending') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  showAlertUtil({
                                    title: 'Processing',
                                    message:
                                      "We're processing your review bonus. Please allow 24 - 72 hours for your bonus to be added to your package.",
                                    buttonText: 'OK',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Review Bonus Processing */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Processing Icon */}
                                <Icon
                                  color={palettes.App['App Black 191F32']}
                                  name={'MaterialCommunityIcons/progress-clock'}
                                  size={16}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 4 },
                                    dimensions.width
                                  )}
                                />
                                {/* Ask Client for Review */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Review Bonus Processing'}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                        )}
                      </>
                      {/* Review Bonus Approved */}
                      <>
                        {!(reviewBonusStatus === 'approved') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  showAlertUtil({
                                    title: 'Scheduled for Pay',
                                    message:
                                      'Your bonus request was approved. Your bonus will be paid on the next pay date.',
                                    buttonText: 'HOORAY!',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Review Bonus Processing */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Mint #F0F8F2'],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    paddingBottom: 8,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Processing Icon */}
                                <Icon
                                  color={palettes.App['App Black 191F32']}
                                  name={'MaterialCommunityIcons/progress-clock'}
                                  size={16}
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 4 },
                                    dimensions.width
                                  )}
                                />
                                {/* Review Bonus Approved */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Review Bonus Approved - Scheduled for Pay'}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                        )}
                      </>
                      {/* Review Bonus Not Eligible */}
                      <>
                        {!(reviewBonusStatus === 'not eligible') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderRadius: 8,
                                flexDirection: 'row',
                                paddingBottom: 8,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Review Bonus Not Eligible */}
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  showAlertUtil({
                                    title: 'Not Eligible',
                                    message:
                                      'The social media review you submitted for bonus pay was not eligible. For more details, please contact Sunsational.',
                                    buttonText: 'OK',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectable={false}
                              {...GlobalStyles.LinkStyles(theme)['Link 2']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link 2']
                                    .style,
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Social Media Review Bonus Not Eligible'}
                            />
                          </View>
                        )}
                      </>
                      {/* Review Bonus Paid */}
                      <>
                        {!(reviewBonusStatus === 'paid') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <Pressable
                              onPress={() => {
                                try {
                                  showAlert(
                                    'Review Bonus Paid!',
                                    undefined,
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Review Bonus Paid */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Gold #FFC400'],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    marginRight: 4,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Ask Client for Review */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Review Bonus Added to Package & Paid!'}
                                </Text>
                              </View>
                            </Pressable>
                            {/* Bonus Amount */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Gold #FFC400'],
                                  borderRadius: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 4,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Review Bonus Pay */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['primary14']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['primary14']
                                      .style,
                                    { alignSelf: 'center' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'+$'}
                                {reviewBonusPayValue}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* View Location Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Client'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Location'}
                        </Text>
                      </View>
                      {/* View Address Block */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['Custom Color_6'],
                            borderRadius: 16,
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-start', height: 40, width: 52 },
                            dimensions.width
                          )}
                        >
                          <SVG
                            source={Constants['uiLocationIcon']}
                            style={StyleSheet.applyWidth(
                              { height: 40, width: 40 },
                              dimensions.width
                            )}
                          />
                        </View>

                        <Pressable
                          onPress={() => {
                            try {
                              openMap(
                                clientAddressObj?.street,
                                clientAddressObj?.city,
                                clientAddressObj?.zip
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* View Address and Icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                maxWidth: '100%',
                                paddingRight: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Address and Distance Conatiner */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexWrap: 'nowrap', width: '80%' },
                                dimensions.width
                              )}
                            >
                              {/* Address Container */}
                              <View>
                                {/* Address */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {concatAddress(clientAddressObj)}
                                </Text>
                              </View>
                              {/* View Distance Container */}
                              <>
                                {!empty ? null : (
                                  <View>
                                    {/* Distance */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ],
                                          fontFamily: 'Poppins_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {mathRound(null)}
                                      {' Miles Away'}
                                    </Text>
                                  </View>
                                )}
                              </>
                            </View>
                            {/* View Right Arrow Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 16,
                                  width: '20%',
                                },
                                dimensions.width
                              )}
                            >
                              <SVG
                                source={Constants['uiArrowRightBlack']}
                                style={StyleSheet.applyWidth(
                                  { height: 16, width: 16 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        </Pressable>
                      </View>
                      {/* Pool type */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-start',
                            borderColor: palettes.App['Custom Color_6'],
                            borderRadius: 16,
                            borderWidth: 1,
                            flexDirection: 'column',
                            paddingBottom: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Alert pool type */}
                        <Touchable
                          onPress={() => {
                            try {
                              alertPoolType();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '100%' },
                            dimensions.width
                          )}
                        >
                          {/* View Row */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Pool Icon */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginLeft: 20,
                                  marginRight: 12,
                                  width: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Home */}
                              <>
                                {!(poolType === 'Home Pool') ? null : (
                                  <Icon
                                    color={palettes.App['App Black 191F32']}
                                    name={'MaterialCommunityIcons/home'}
                                    size={18}
                                    style={StyleSheet.applyWidth(
                                      { marginRight: 6 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Pool Type */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '90%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Pool Type */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {poolType}
                              </Text>
                              {/* Info */}
                              <IconButton
                                color={
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ]
                                }
                                icon={
                                  'MaterialCommunityIcons/information-outline'
                                }
                                size={16}
                              />
                            </View>
                          </View>
                        </Touchable>
                      </View>
                    </View>
                    {/* View Student Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Student'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Details'}
                        </Text>
                      </View>
                      <FlashList
                        data={splitStringOnComma(studentDetails)}
                        estimatedItemSize={50}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(flashListData, index) =>
                          flashListData?.id ??
                          flashListData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(flashListData)
                        }
                        listKey={'s5DAjPDs'}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const flashListData = item;
                          return (
                            <>
                              {/* View Student Details Block */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['Custom Color_6'],
                                    borderRadius: 16,
                                    flexDirection: 'row',
                                    marginBottom: 6,
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* View Icon Container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      height: 40,
                                      justifyContent: 'center',
                                      marginRight: 12,
                                      width: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <SVG
                                    source={
                                      Constants['uiProfileOrangeBgCircle']
                                    }
                                    style={StyleSheet.applyWidth(
                                      { height: 40, width: 40 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* View Name and Age */}
                                <View>
                                  {/* Name */}
                                  <View>
                                    {/* Student name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          textTransform: 'capitalize',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {flashListData}
                                    </Text>
                                  </View>
                                  {/* Age */}
                                  <>
                                    {!falseValue ? null : (
                                      <View>
                                        {/* Age */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                palettes.App[
                                                  'App Text 2ndary 7F868C'
                                                ],
                                              fontFamily: 'Poppins_400Regular',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'30 - 40 Years Old'}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                </View>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      />
                      {/* View Additional Student Details */}
                      <>
                        {!studentDetailsAdditional ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderColor: palettes.App['Custom Color_6'],
                                borderRadius: 16,
                                borderWidth: 1,
                                flexDirection: 'row',
                                paddingBottom: 8,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginLeft: 20,
                                  marginRight: 12,
                                  width: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['App Black 191F32']}
                                name={'MaterialCommunityIcons/book-account'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 6 },
                                  dimensions.width
                                )}
                              />
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { width: '90%' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'More information about '}
                                {getStudentFirstNames(studentDetails)}
                              </Text>
                              {/* Additional student info */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {studentDetailsAdditional}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                  {/* View Divider */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Grey E5E5E5'],
                        height: 4,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  />
                  {/* View Scheduling Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        justifyContent: 'center',
                        paddingLeft: '5.35%',
                        paddingRight: '5.35%',
                        paddingTop: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View Schedule Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 24,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Client'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Availability'}
                        </Text>
                      </View>
                      {/* View Open/Close */}
                      <View>
                        {/* Icon Close */}
                        <>
                          {!showScheduleDetails ? null : (
                            <IconButton
                              onPress={() => {
                                try {
                                  setShowScheduleDetails(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              size={32}
                              color={palettes.App['App Grey Inputs E2E2E2']}
                              icon={'FontAwesome/angle-up'}
                            />
                          )}
                        </>
                        {/* Icon Open */}
                        <>
                          {showScheduleDetails ? null : (
                            <IconButton
                              onPress={() => {
                                try {
                                  setShowScheduleDetails(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              size={32}
                              color={palettes.App['App Grey Inputs E2E2E2']}
                              icon={'FontAwesome/angle-down'}
                            />
                          )}
                        </>
                      </View>
                    </View>
                    {/* Show Scheduling Details */}
                    <>
                      {!showScheduleDetails ? null : (
                        <View>
                          {/* Scheduling Start and Lessons Req */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                flexDirection: 'row',
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Start Date Block */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '50%' },
                                dimensions.width
                              )}
                            >
                              {/* Label */}
                              <View>
                                {/* Start */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Start Date'}
                                </Text>
                              </View>
                              {/* Date */}
                              <View>
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {getDate(startDate)}
                                </Text>
                              </View>
                            </View>
                            {/* Lessons Per Week Block */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '50%' },
                                dimensions.width
                              )}
                            >
                              {/* Label */}
                              <View>
                                {/* Lessons / Week */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Lessons / Week'}
                                </Text>
                              </View>
                              {/* Lessons */}
                              <View>
                                {/* No. of Lessons */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {lessonsPerWeek}
                                  {' Lessons'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* View Days Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Days Available */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Days available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.availableDays
                                  availableDaysClient={clientDaysAvailable}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                            {/* Legend */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Icon */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 6 },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App['App Brand Blue 2C9AD9']}
                                  name={'FontAwesome/circle'}
                                  size={8}
                                />
                              </View>
                              {/* Key */}
                              <View>
                                {/* Client is available */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Client is available'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* View Weekdays Times Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Weekdays Times */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Weekdays Times Available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.TimesAvailable
                                  availableTimesClient={clientWeekDayTimesAvail}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                          </View>
                          {/* View Weekends Times Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Weekends Times */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Weekends Times Available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.TimesAvailable
                                  availableTimesClient={clientWeekEndTimesAvail}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </>
              );
            }}
          </KnackAPIApi.FetchGetPackageDetailsGET>
          {/* View Divider */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Grey E5E5E5'],
                height: 4,
                width: '100%',
              },
              dimensions.width
            )}
          />
          {/* Placeholder Lessons List */}
          <>
            {!is_loading_lessons_list ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginTop: 24,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                  },
                  dimensions.width
                )}
              >
                {/* Placeholder Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'baseline',
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 32,
                      marginBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 8,
                        height: 32,
                        marginRight: 8,
                        width: 112,
                      },
                      dimensions.width
                    )}
                  />
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 8,
                        height: 32,
                        marginRight: 8,
                        width: 56,
                      },
                      dimensions.width
                    )}
                  />
                </View>
                {/* Placeholder Lesson */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['App Blue eef6fa'],
                      borderRadius: 16,
                      flexDirection: 'row',
                      height: 72,
                      marginBottom: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                />
                {/* Placeholder Lesson */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['App Blue eef6fa'],
                      borderRadius: 16,
                      flexDirection: 'row',
                      height: 72,
                      marginBottom: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                />
                {/* Placeholder Lesson */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['App Blue eef6fa'],
                      borderRadius: 16,
                      flexDirection: 'row',
                      height: 72,
                      marginBottom: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          {/* View Lessons List */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: '5.35%', paddingRight: '5.35%', paddingTop: 24 },
              dimensions.width
            )}
          >
            {/* View Lessons List Title */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'baseline',
                    flexDirection: 'row',
                    marginBottom: 16,
                  },
                  dimensions.width
                )}
              >
                {/* H1 */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 24,
                      marginRight: 4,
                    },
                    dimensions.width
                  )}
                >
                  {'Lessons'}
                </Text>
                {/* H1 Accent */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Red EB3E26'],
                      fontFamily: 'LeckerliOne_400Regular',
                      fontSize: 22,
                    },
                    dimensions.width
                  )}
                >
                  {'Schedule'}
                </Text>
              </View>
            </View>
            {/* View Legend Container */}
            <>
              {!Constants['empty'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Legend */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* View Icon */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center', marginRight: 4 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Text Green #1DB058']}
                        name={'FontAwesome/circle'}
                        size={14}
                        style={StyleSheet.applyWidth(
                          { backgroundColor: palettes.App['App Black 191F32'] },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* View Key */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Completed */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Completed'}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* View Lessons */}
            <View>
              {/* Previous instructor notice */}
              <>
                {!(firstLesson > 1) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Mint #F0F8F2'],
                        borderColor: palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 16,
                        borderWidth: 1,
                        marginBottom: 16,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {/* This client has prior lessons */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Prior lessons were taught by another Sunsational instructor. Your instruction for this client begins with Lesson '
                      }
                      {firstLesson}
                      {'.'}
                    </Text>
                  </View>
                )}
              </>
              {/* Fetch Lessons In Package */}
              <KnackAPIApi.FetchGetLessonsListGET
                filters={JSON.stringify(filters)}
                handlers={{
                  onData: fetchLessonsInPackageData => {
                    try {
                      setIs_loading_lessons_list(true);
                      setScreenVariables(
                        fetchLessonsInPackageData?.records,
                        'lessons'
                      );
                      setIs_loading_lessons_list(false);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
                rows_per_page={1000}
                sort_field={'field_320'}
                sort_order={'asc'}
              >
                {({ loading, error, data, refetchGetLessonsList }) => {
                  const fetchLessonsInPackageData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlashList
                      data={sortLessons(fetchLessonsInPackageData?.records)}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(flashListData, index) =>
                        flashListData?.field_320
                      }
                      listKey={'3RkRqNQQ'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const flashListData = item;
                        return (
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'LessonDetailsAndManagementScreen',
                                  {
                                    lessonPackageKnackId:
                                      props.route?.params?.packageKnackId ??
                                      defaultProps.packageKnackId,
                                    lessonKnackId: flashListData?.id,
                                  }
                                );
                                amplitudeEvents(
                                  Variables,
                                  'view lesson details',
                                  {
                                    origin: 'client details screen',
                                    category: 'lesson management',
                                  },
                                  undefined
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            activeOpacity={0.8}
                            disabledOpacity={0.8}
                          >
                            {/* View Lesson Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  borderColor:
                                    palettes.App['App Grey Inputs E2E2E2'],
                                  borderRadius: 16,
                                  borderWidth: 1,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginBottom: 16,
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Left Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* View Icon Container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginRight: 12 },
                                    dimensions.width
                                  )}
                                >
                                  <Surface
                                    elevation={3}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          palettes.App['App Sun Orange F4A100'],
                                        borderColor:
                                          palettes.App['Custom Color'],
                                        borderRadius: 50,
                                        borderWidth: 2,
                                        height: 40,
                                        justifyContent: 'center',
                                        width: 40,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Lesson Number */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: palettes.App['Custom Color'],
                                            fontFamily: 'Poppins_400Regular',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {identifyCompletionBonus(
                                          flashListData?.field_320_raw
                                        )}
                                      </Text>
                                    </View>
                                  </Surface>
                                </View>
                                {/* Lesson Details */}
                                <View>
                                  {/* View Lesson Name */}
                                  <View>
                                    {/* Lesson Name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_600SemiBold',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {getLongLessonName(
                                        flashListData?.field_320_raw
                                      )}
                                    </Text>
                                  </View>
                                  {/* Pay Status Blank */}
                                  <>
                                    {flashListData?.field_534_raw ? null : (
                                      <View>
                                        {/* View Lesson Status */}
                                        <View>
                                          {/* Lesson Status (not bonus) */}
                                          <>
                                            {!(
                                              flashListData?.field_320_raw > 0
                                            ) ? null : (
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Text 2ndary 7F868C'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {getLessonStatus(
                                                  flashListData?.field_54_raw,
                                                  flashListData?.field_202
                                                )}
                                              </Text>
                                            )}
                                          </>
                                          {/* Pending bonus */}
                                          <>
                                            {!(
                                              flashListData?.field_320_raw === 0
                                            ) ? null : (
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Text 2ndary 7F868C'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Pending'}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* Pay Status Not Blank */}
                                  <>
                                    {!flashListData?.field_534_raw ? null : (
                                      <View>
                                        {/* View Lesson Status */}
                                        <View>
                                          {/* Lesson Status */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Text 2ndary 7F868C'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {getLessonStatus(
                                              flashListData?.field_534_raw,
                                              undefined
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                </View>
                              </View>
                              {/* Right Container Action */}
                              <View>
                                {/* Pay Status Blank */}
                                <>
                                  {flashListData?.field_534_raw ? null : (
                                    <View>
                                      {/* Lesson Not Scheduled */}
                                      <>
                                        {flashListData?.field_202 ? null : (
                                          <View>
                                            {/* Not Completion Bonus */}
                                            <>
                                              {!(
                                                flashListData?.field_320_raw > 0
                                              ) ? null : (
                                                <View>
                                                  {/* Schedule Lesson */}
                                                  <>
                                                    {!(
                                                      flashListData?.field_54_raw ===
                                                      'Matched'
                                                    ) ? null : (
                                                      <Link
                                                        accessible={true}
                                                        onPress={() => {
                                                          try {
                                                            navigation.navigate(
                                                              'LessonDetailsAndManagementScreen',
                                                              {
                                                                action:
                                                                  'scheduleLesson',
                                                                lessonPackageKnackId:
                                                                  props.route
                                                                    ?.params
                                                                    ?.packageKnackId ??
                                                                  defaultProps.packageKnackId,
                                                                lessonKnackId:
                                                                  flashListData?.id,
                                                              }
                                                            );
                                                            amplitudeEvents(
                                                              Variables,
                                                              'start schedule lesson',
                                                              {
                                                                step: '01',
                                                                funnel:
                                                                  'schedule lesson',
                                                                origin:
                                                                  'client details screen',
                                                                category:
                                                                  'lesson management',
                                                              },
                                                              undefined
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Sun Orange F4A100'
                                                              ],
                                                            fontFamily:
                                                              'BebasNeue_400Regular',
                                                            fontSize: 18,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        title={
                                                          'Schedule Lesson'
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* Completion Bonus */}
                                            <>
                                              {!(
                                                flashListData?.field_320_raw ===
                                                0
                                              ) ? null : (
                                                <View>
                                                  {/* Schedule Lesson */}
                                                  <>
                                                    {fetchLessonsInPackageData?.field_320_raw >
                                                    0 ? null : (
                                                      <Link
                                                        accessible={true}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Sun Orange F4A100'
                                                              ],
                                                            fontFamily:
                                                              'BebasNeue_400Regular',
                                                            fontSize: 18,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        title={`$${flashListData?.field_69_raw}`}
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                      {/* Lesson Scheduled */}
                                      <>
                                        {!flashListData?.field_202 ? null : (
                                          <View>
                                            {/* Lesson is Scheduled In Future */}
                                            <>
                                              {scheduledTimeIsInFuture(
                                                flashListData?.field_202_raw
                                                  ?.unix_timestamp
                                              ) ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { alignItems: 'flex-end' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Lesson Schedule Date */}
                                                  <>
                                                    {!(
                                                      flashListData?.field_54_raw ===
                                                      'Matched'
                                                    ) ? null : (
                                                      <Link
                                                        accessible={true}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Sun Orange F4A100'
                                                              ],
                                                            fontFamily:
                                                              'BebasNeue_400Regular',
                                                            fontSize: 18,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        title={`${convertTimeToLocalMachine(
                                                          flashListData?.field_202,
                                                          'MM/DD/YYYY h:mma',
                                                          'ddd MMM DD, h:mm A'
                                                        )}`}
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* Lesson is Scheduled Date Past */}
                                            <>
                                              {!scheduledTimeIsInFuture(
                                                flashListData?.field_202_raw
                                                  ?.unix_timestamp
                                              ) ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { alignItems: 'flex-end' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Mark As Complete */}
                                                  <>
                                                    {!(
                                                      flashListData?.field_54_raw ===
                                                      'Matched'
                                                    ) ? null : (
                                                      <Link
                                                        accessible={true}
                                                        onPress={() => {
                                                          try {
                                                            /* hidden 'Run a Custom Function' action */
                                                            if (
                                                              !shouldEnableCompleteLessonNav(
                                                                flashListData?.id,
                                                                allLessonRecords
                                                              )
                                                            ) {
                                                              return;
                                                            }
                                                            navigation.navigate(
                                                              'LessonDetailsAndManagementScreen',
                                                              {
                                                                action:
                                                                  'completeLesson',
                                                                lessonPackageKnackId:
                                                                  props.route
                                                                    ?.params
                                                                    ?.packageKnackId ??
                                                                  defaultProps.packageKnackId,
                                                                lessonKnackId:
                                                                  flashListData?.id,
                                                              }
                                                            );
                                                            amplitudeEvents(
                                                              Variables,
                                                              'start complete lesson',
                                                              {
                                                                step: '01',
                                                                funnel:
                                                                  'complete lesson',
                                                                origin:
                                                                  'client details screen',
                                                                category:
                                                                  'lesson management',
                                                              },
                                                              undefined
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Sun Orange F4A100'
                                                              ],
                                                            fontFamily:
                                                              'BebasNeue_400Regular',
                                                            fontSize: 18,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        title={
                                                          'MARK AS COMPLETE'
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                      {/* Lesson Status Completed */}
                                      <>
                                        {!(
                                          flashListData?.field_54_raw ===
                                          'Completed'
                                        ) ? null : (
                                          <View>
                                            {/* Completion Date */}
                                            <Link
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    palettes.App[
                                                      'App Sun Orange F4A100'
                                                    ],
                                                  fontFamily:
                                                    'BebasNeue_400Regular',
                                                  fontSize: 18,
                                                },
                                                dimensions.width
                                              )}
                                              title={`${flashListData?.field_205_raw?.timestamp}`}
                                            />
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Pay Status Not Blank */}
                                <>
                                  {!flashListData?.field_313 ? null : (
                                    <View>
                                      {/* Paid date */}
                                      <>
                                        {!flashListData?.field_313 ? null : (
                                          <Link
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Sun Orange F4A100'
                                                  ],
                                                fontFamily:
                                                  'BebasNeue_400Regular',
                                                fontSize: 18,
                                              },
                                              dimensions.width
                                            )}
                                            title={`${flashListData?.field_313}`}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          </Touchable>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      estimatedItemSize={12}
                      initialNumToRender={12}
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    />
                  );
                }}
              </KnackAPIApi.FetchGetLessonsListGET>
            </View>
          </View>
          {/* View Contact Sunsational Container */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: '5.35%', paddingRight: '5.35%' },
              dimensions.width
            )}
          >
            {/* View Contact Sunsational */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color_6'],
                  borderRadius: 8,
                  paddingBottom: 20,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 20,
                },
                dimensions.width
              )}
            >
              {/* View Need Help */}
              <View>
                {/* Need help? */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Need help with this client?'}
                </Text>
              </View>
              {/* View Row Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    marginTop: 20,
                    paddingBottom: 0,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Contact Sunsational Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Contact Sunastional Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowSSSContactOptions(true);
                        amplitudeEvents(
                          Variables,
                          'start contact sunsational',
                          {
                            step: '01',
                            funnel: 'contact sunsational',
                            origin: 'client details screen',
                            category: 'support',
                          },
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderRadius: 50,
                        borderWidth: 2,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Contact Sunsational'}
                  />
                </View>
              </View>
            </View>
          </View>
          {/* Loading Lessons Details */}
          <View />
        </View>
      </SimpleStyleScrollView>
      {/* Contact Sunsational Options */}
      <ActionSheet visible={showSSSContactOptions}>
        {/* Email Sunsational */}
        <ActionSheetItem
          color={theme.colors.text.strong}
          onPress={() => {
            try {
              setShowModalContactSunsational(true);
              setShowSSSContactOptions(false);
              amplitudeEvents(
                Variables,
                'start message sunsational support',
                {
                  step: '02',
                  view: 'contact options sheet',
                  funnel: 'contact sunsational',
                  origin: 'client details screen',
                  category: 'support',
                },
                undefined
              );
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
            .props}
          label={'Message'}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
              .style,
            dimensions.width
          )}
        />
        {/* Call Sunsational */}
        <ActionSheetItem
          color={theme.colors.text.strong}
          onPress={() => {
            try {
              Linking.openURL('tel:+18887882140');
              amplitudeEvents(
                Variables,
                'call sunsational support',
                {
                  step: '02',
                  view: 'contact options sheet',
                  funnel: 'contact sunsational',
                  origin: 'client details screen',
                  category: 'support',
                },
                undefined
              );
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
            .props}
          label={'Call'}
          style={StyleSheet.applyWidth(
            GlobalStyles.ActionSheetItemStyles(theme)['Action Sheet Item']
              .style,
            dimensions.width
          )}
        />
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setShowSSSContactOptions(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
      {/* Modal Contact Sunsational Support */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={showModalContactSunsational}
      >
        <KeyboardAvoidingView
          behavior={'padding'}
          enabled={true}
          keyboardVerticalOffset={0}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              bottom: 0,
              paddingTop: '125%',
              position: 'absolute',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Card */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                bottom: 0,
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 13,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Modal Close Slide */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 6,
                },
                dimensions.width
              )}
            >
              {/* View Spacer */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              />
              {/* View Slide Close */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 40,
                    height: 3,
                    width: 40,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setShowModalContactSunsational(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
              {/* View Close Button */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 40,
                    paddingBottom: 4,
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 4,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setShowModalContactSunsational(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Close */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {'close x'}
                  </Text>
                </Touchable>
              </View>
            </View>
            {/* View Hide While Request is Uploading */}
            <>
              {Constants['is_loading'] ? null : (
                <View>
                  {/* View Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'How can we help you with '}
                      {clientName}
                      {"'s swim lessons?"}
                    </Text>
                  </View>
                  {/* View Text Area Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 145, paddingBottom: 16 },
                      dimensions.width
                    )}
                  >
                    {/* siContactMessage */}
                    <TextInput
                      autoCorrect={true}
                      changeTextDelay={500}
                      multiline={true}
                      numberOfLines={4}
                      onChangeText={newSiContactMessageValue => {
                        try {
                          setSiContactMessage(newSiContactMessageValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      textAlignVertical={'top'}
                      webShowOutline={true}
                      editable={true}
                      placeholder={'Enter your short message'}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          borderLeftWidth: 1,
                          borderRadius: 8,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                          height: '100%',
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              )}
            </>
            {/* View Show While Request Uploading */}
            <>
              {!Constants['is_loading'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      height: 200,
                      marginBottom: 16,
                      overflow: 'hidden',
                      width: '97%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'Sending your message!'}
                    </Text>
                  </View>
                  {/* Loading animation */}
                  <ImageBackground
                    resizeMode={'cover'}
                    source={imageSource(
                      `${Constants['uiAnimationChatBubble']}`
                    )}
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* View Request Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 32, width: '100%' },
                dimensions.width
              )}
            >
              {/* Send Message */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      amplitudeEvents(
                        Variables,
                        'submit support message',
                        {
                          step: '03',
                          view: 'message sunsational sheet',
                          funnel: 'contact sunsational',
                          origin: 'client details screen',
                          category: 'support',
                        },
                        undefined
                      );
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: true,
                      });
                      const response = (
                        await integromatSendEmailToMatching$BookingPOST.mutateAsync(
                          {
                            lessonID: '607562-1',
                            lessonKnackID: '630478a95d099c0021dc79b6',
                            message: siContactMessage,
                            packageID: null,
                            packageKnackID:
                              props.route?.params?.packageKnackId ??
                              defaultProps.packageKnackId,
                            siEmailAddress: Constants['gUserEmail'],
                            siName: Constants['userFullName'],
                          }
                        )
                      )?.json;
                      processSendMsgResponse(setGlobalVariableValue, response);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'Send Message'}
              />
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
            {/* Spacer Move Modal Up While Sending Request */}
            <>
              {!Constants['is_loading'] ? null : (
                <Spacer left={8} right={8} bottom={135} top={135} />
              )}
            </>
          </View>
        </KeyboardAvoidingView>
      </Modal>
      {/* Modal Compose Review Request to Client */}
      <>
        {!showComposeReviewRequest ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  bottom: 0,
                  paddingTop: '150%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowComposeReviewRequest(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowComposeReviewRequest(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 8 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Request a review from '}
                          {clientName}
                          {'.'}
                        </Text>
                      </View>
                      {/* View Text Area Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { height: 300, paddingBottom: 32 },
                          dimensions.width
                        )}
                      >
                        {/* Template options */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginBottom: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Use template selected */}
                          <>
                            {!useReviewTemplate ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 40,
                                    marginRight: 12,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable>
                                  {/* Use template */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'use template'}
                                  </Text>
                                </Touchable>
                              </View>
                            )}
                          </>
                          {/* Use template unselected */}
                          <>
                            {useReviewTemplate ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderRadius: 40,
                                    marginRight: 12,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      setUseReviewTemplate(true);
                                      generateReviewRequestDraft(
                                        Variables,
                                        !useReviewTemplate
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* Use template */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'use template'}
                                  </Text>
                                </Touchable>
                              </View>
                            )}
                          </>
                          {/* Compose custom selected */}
                          <>
                            {useReviewTemplate ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 40,
                                    marginRight: 12,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable>
                                  {/* Compose custom */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'compose custom'}
                                  </Text>
                                </Touchable>
                              </View>
                            )}
                          </>
                          {/* Compose custom unselected */}
                          <>
                            {!useReviewTemplate ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderRadius: 40,
                                    marginRight: 12,
                                    paddingBottom: 4,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    paddingTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      const valueEzEBlefD = false;
                                      setUseReviewTemplate(valueEzEBlefD);
                                      const useTemplate = valueEzEBlefD;
                                      generateReviewRequestDraft(
                                        Variables,
                                        useTemplate
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* compose custom */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'compose custom'}
                                  </Text>
                                </Touchable>
                              </View>
                            )}
                          </>
                        </View>
                        {/* Error message edit required */}
                        <>
                          {!showTemplateErrorMessage ? null : (
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'error message'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ViewStyles(theme)['error message']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {/* Error message */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['Custom Color'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {templateErrorMessage}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* siRequestToClient */}
                        <TextInput
                          autoCorrect={true}
                          changeTextDelay={500}
                          multiline={true}
                          numberOfLines={4}
                          onChangeText={newSiRequestToClientValue => {
                            try {
                              setReviewRequestTemplate(
                                newSiRequestToClientValue
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          textAlignVertical={'top'}
                          webShowOutline={true}
                          editable={true}
                          placeholder={'Ask for a review'}
                          placeholderTextColor={palettes.App.TextPlaceholder}
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Grey Inputs E2E2E2'],
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                              height: '100%',
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          value={reviewRequestTemplate}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Sub instructions */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      marginBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* This message will be emailed */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'This message will be emailed to your client from '}
                    {Constants['gUserEmail']}
                    {'.'}
                  </Text>
                </View>
                {/* View Show While Request Uploading */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 8,
                          height: 200,
                          marginBottom: 16,
                          overflow: 'hidden',
                          width: '97%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Sending your message!'}
                        </Text>
                      </View>
                      {/* Loading animation */}
                      <ImageBackground
                        resizeMode={'cover'}
                        source={imageSource(
                          `${Constants['uiAnimationChatBubble']}`
                        )}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                {/* View Request Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Request Review */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        const messageComposed = isReviewRequestTemplateEdited(
                          reviewRequestTemplate
                        );
                        setSiReviewRequestBody(reviewRequestTemplate);
                        shouldShowReviewRequestPreview(messageComposed);
                        createTemplateErrorMessage(reviewRequestTemplate);
                        setShowComposeReviewRequest(!messageComposed);
                        setShowTemplateErrorMessage(!messageComposed);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Request Review'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Spacer Move Modal Up While Sending Request */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <Spacer left={8} right={8} bottom={135} top={135} />
                  )}
                </>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Request Message Preview */}
      <>
        {!showReviewRequestPreview ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'pageSheet'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.Brand.Surface,
                  height: '100%',
                  paddingBottom: '5.35%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: '5.35%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowReviewRequestPreview(false);
                        setShowComposeReviewRequest(true);
                        setSendReviewRequestFailed(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowReviewRequestPreview(false);
                        setShowComposeReviewRequest(true);
                        setSendReviewRequestFailed(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* Show While Sending Message */}
              <>
                {!is_loading ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'auto',
                        height: '90%',
                        justifyContent: 'flex-start',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Sending Message */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18,
                            marginBottom: 14,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Sending Message'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          height: '75%',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Sending Message Gif */}
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          `${Constants['uiAnimationSendingEmail']}`
                        )}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                )}
              </>
              {/* Hide While Sending Message */}
              <>
                {is_loading ? null : (
                  <View>
                    {/* Hide When Review Message Failed */}
                    <>
                      {sendReviewRequestFailed ? null : (
                        <View>
                          {/* Title */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: theme.colors.text.light,
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 18,
                                  marginBottom: 14,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Message Preview'}
                          </Text>

                          <SimpleStyleScrollView
                            bounces={true}
                            horizontal={false}
                            keyboardShouldPersistTaps={'never'}
                            nestedScrollEnabled={false}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                borderTopWidth: 1,
                                paddingBottom: 150,
                                paddingTop: 14,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Si Message */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_400Regular',
                                    marginBottom: 12,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {siReviewRequestBody}
                            </Text>
                          </SimpleStyleScrollView>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Review Request Message Failed */}
              <>
                {!sendReviewRequestFailed ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'auto',
                        height: '90%',
                        justifyContent: 'flex-start',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Message Failed */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18,
                            marginBottom: 14,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Message Failed'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'center',
                          height: '75%',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Exception */}
                      <Icon
                        color={palettes.App['App Red EB3E26']}
                        name={'AntDesign/exception1'}
                        size={48}
                      />
                      {/* Error message */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Uh oh, we hit a ripple. Please try again.'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Button single bottom */}
              <View
                {...GlobalStyles.ViewStyles(theme)['Button single bottom 3']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['Button single bottom 3']
                      .style,
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      borderColor: palettes.App['App Disabled Button EDEDEF'],
                      borderTopWidth: 1,
                      flexDirection: 'column',
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View Row Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      marginBottom: 0,
                      marginTop: 20,
                      paddingBottom: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Send Request */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-start', width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Send Request */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setSendReviewRequestFailed(false);
                            setIs_loading(true);
                            const response = (
                              await makeSendClientAReviewRequestPOST.mutateAsync(
                                {
                                  clientEmail: clientEmail,
                                  messageBody: siReviewRequestBody,
                                  packageKnackID: packageKnackId,
                                  packageName: 999000,
                                  siEmail: Constants['gUserEmail'],
                                  siKnackID: Constants['AUTH_KNACK_USER_ID'],
                                }
                              )
                            )?.json;
                            processRequestReviewResponse(response);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      loading={is_loading}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          fontFamily: 'BebasNeue_400Regular',
                          fontSize: 18,
                          paddingBottom: 14,
                          paddingTop: 14,
                          textAlign: 'center',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                      title={'Send Request'}
                    />
                  </View>
                  {/* Edit/cancel */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 8 },
                      dimensions.width
                    )}
                  >
                    {/* Edit/cancel */}
                    <Link
                      accessible={true}
                      onPress={() => {
                        try {
                          setSendReviewRequestFailed(false);
                          setShowReviewRequestPreview(false);
                          setShowComposeReviewRequest(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectable={false}
                      {...GlobalStyles.LinkStyles(theme)['Link'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LinkStyles(theme)['Link'].style,
                        dimensions.width
                      )}
                      title={'edit / cancel'}
                    />
                  </View>
                </View>
                <Spacer left={8} right={8} bottom={34} />
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Client Contact Details */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={showModalClientContact}
      >
        <>
          {!showModalClientContact ? null : (
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  bottom: 0,
                  paddingTop: '125%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowModalClientContact(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowModalClientContact(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* View Modal Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Modal Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Contact '}
                          {getFirstNameLastInitial(clientName)}
                          {'.'}
                        </Text>
                      </View>
                      {/* View Email Row */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            paddingBottom: 16,
                            paddingTop: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              height: 40,
                              width: '14%',
                            },
                            dimensions.width
                          )}
                        >
                          <SVG
                            source={Constants['uiIconEmailOrangeRound']}
                            style={StyleSheet.applyWidth(
                              { height: 40, width: 40 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Touchable - Send Email */}
                        <Touchable
                          onPress={() => {
                            try {
                              amplitudeEvents(
                                Variables,
                                'email client via native email app',
                                {
                                  step: '02',
                                  view: 'contact client sheet',
                                  funnel: 'contact client',
                                  origin: 'client details screen',
                                  category: 'client management',
                                },
                                undefined
                              );
                              Linking.openURL(`mailto:${clientEmail}`);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          style={StyleSheet.applyWidth(
                            { width: '86%' },
                            dimensions.width
                          )}
                        >
                          {/* View Details and Right icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title and Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexWrap: 'nowrap' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <View>
                                {/* Email */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Send Email'}
                                </Text>
                              </View>
                              {/* Email */}
                              <View>
                                {/* Client email address */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {clientEmail}
                                </Text>
                              </View>
                            </View>
                            {/* View Right Arrow Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end', height: 16 },
                                dimensions.width
                              )}
                            >
                              <SVG
                                source={Constants['uiArrowRightBlack']}
                                style={StyleSheet.applyWidth(
                                  { height: 16, width: 16 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        </Touchable>
                      </View>
                      {/* View Send Text Row */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            paddingBottom: 16,
                            paddingTop: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              height: 40,
                              width: '14%',
                            },
                            dimensions.width
                          )}
                        >
                          <SVG
                            source={Constants['uiIconSmsOrangeRound']}
                            style={StyleSheet.applyWidth(
                              { height: 40, width: 40 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Touchable Text Client */}
                        <Touchable
                          onPress={() => {
                            try {
                              amplitudeEvents(
                                Variables,
                                'send sms to client',
                                {
                                  step: '02',
                                  view: 'contact client sheet',
                                  funnel: 'contact client',
                                  origin: 'client details screen',
                                  category: 'client management',
                                },
                                undefined
                              );
                              Linking.openURL(
                                `sms:${formatPhoneNumber(
                                  clientPhoneNumber,
                                  'backend'
                                )}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          style={StyleSheet.applyWidth(
                            { width: '86%' },
                            dimensions.width
                          )}
                        >
                          {/* View Details and Right icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title and Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexWrap: 'nowrap' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <View>
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Text'}
                                </Text>
                              </View>
                              {/* Phone number */}
                              <View>
                                {/* Client phone number */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {formatPhoneNumber(
                                    clientPhoneNumber,
                                    'frontend'
                                  )}
                                </Text>
                              </View>
                            </View>
                            {/* View Right Arrow Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end', height: 16 },
                                dimensions.width
                              )}
                            >
                              <SVG
                                source={Constants['uiArrowRightBlack']}
                                style={StyleSheet.applyWidth(
                                  { height: 16, width: 16 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        </Touchable>
                      </View>
                      {/* View Call Row */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            paddingBottom: 16,
                            paddingTop: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              height: 40,
                              width: '14%',
                            },
                            dimensions.width
                          )}
                        >
                          <SVG
                            source={Constants['uiIconPhoneOrangeRound']}
                            style={StyleSheet.applyWidth(
                              { height: 40, width: 40 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Touchable - Call Client */}
                        <Touchable
                          onPress={() => {
                            try {
                              amplitudeEvents(
                                Variables,
                                'call client',
                                {
                                  step: '02',
                                  view: 'contact client sheet',
                                  funnel: 'contact client',
                                  origin: 'client details screen',
                                  category: 'client management',
                                },
                                undefined
                              );
                              Linking.openURL(
                                `tel:${formatPhoneNumber(
                                  clientPhoneNumber,
                                  'backend'
                                )}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                          style={StyleSheet.applyWidth(
                            { width: '86%' },
                            dimensions.width
                          )}
                        >
                          {/* View Details and Right icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title and Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexWrap: 'nowrap' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <View>
                                {/* Call */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Call'}
                                </Text>
                              </View>
                              {/* Phone number */}
                              <View>
                                {/* Client phone number */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {formatPhoneNumber(
                                    clientPhoneNumber,
                                    'frontend'
                                  )}
                                </Text>
                              </View>
                            </View>
                            {/* View Right Arrow Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end', height: 16 },
                                dimensions.width
                              )}
                            >
                              <SVG
                                source={Constants['uiArrowRightBlack']}
                                style={StyleSheet.applyWidth(
                                  { height: 16, width: 16 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        </Touchable>
                      </View>
                    </View>
                  )}
                </>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      marginBottom: 32,
                      paddingTop: 18,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Close Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowModalClientContact(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Close'}
                  />
                </View>
              </View>
            </KeyboardAvoidingView>
          )}
        </>
      </Modal>
      {/* Contact Sent Success */}
      <>
        {!showModalContactSuccess ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
              'contact sss success'
            ].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'contact sss success'
              ].style,
              dimensions.width
            )}
          >
            {/* View Manage Lesson Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* View Modal Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/email-check'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      {/* Modal Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Message sent'}
                      </Text>
                      <Icon
                        color={palettes.App['App Black 191F32']}
                        name={'MaterialCommunityIcons/sort-variant'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 4, marginRight: 8 },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Message body container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          paddingBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Reply fast */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            paddingBottom: 6,
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'Glad to hear from you! We work fast. Generally, you should receive a reply within the same business day. '
                        }
                      </Text>
                      {/* Call us */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'If you need a immediate assistance, give us a call M-F 8:30AM - 5PM PST at 1-888-788-2140.'
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* View Close Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingTop: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close Enabled */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModalContactSuccess(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Sun Orange F4A100'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Sun Orange F4A100'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Review Request Sent Success */}
      <>
        {!showRequestReviewSuccess ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
              'contact sss success'
            ].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'contact sss success'
              ].style,
              dimensions.width
            )}
          >
            {/* View Manage Lesson Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowRequestReviewSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowRequestReviewSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* View Modal Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/email-check'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      {/* Modal Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Message sent'}
                      </Text>
                      <Icon
                        color={palettes.App['App Black 191F32']}
                        name={'MaterialCommunityIcons/sort-variant'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 4, marginRight: 8 },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Message body container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          paddingBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Reply fast */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            paddingBottom: 6,
                          },
                          dimensions.width
                        )}
                      >
                        {'Your review request has been emailed to '}
                        {clientName}
                        {' from '}
                        {Constants['gUserEmail']}
                        {
                          ". If you don't hear back from them soon, we suggest a follow up in person, text or phone call."
                        }
                      </Text>
                      {/* Call us */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Thanks for delivering a Sunsational experience!'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* View Close Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingTop: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close Enabled */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowRequestReviewSuccess(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Sun Orange F4A100'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Sun Orange F4A100'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Review Bonus Submitted Success */}
      <>
        {!showReviewBonusSubmittedSuccess ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
              'contact sss success'
            ].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'contact sss success'
              ].style,
              dimensions.width
            )}
          >
            {/* View Manage Lesson Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowReviewBonusSubmittedSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowReviewBonusSubmittedSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* View Modal Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/email-check'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      {/* Modal Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Review Bonus Request Received'}
                      </Text>
                      <Icon
                        color={palettes.App['App Black 191F32']}
                        name={'MaterialCommunityIcons/sort-variant'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 4, marginRight: 8 },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Done Checkmark Animation */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Checkmark Animation */}
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          `${Constants['uiAnimationCheckmarkDone']}`
                        )}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Message body container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          paddingBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Reply fast */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            paddingBottom: 6,
                          },
                          dimensions.width
                        )}
                      >
                        {
                          "We received your request to add a bonus to your swim lesson package for a good review. Usually, we'll process your request within 24 - 72 hours."
                        }
                      </Text>
                      {/* Call us */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Thanks for delivering a Sunsational experience!'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* View Close Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingTop: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close Enabled */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowReviewBonusSubmittedSuccess(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Sun Orange F4A100'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Sun Orange F4A100'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderColor: palettes.App['App Disabled Button EDEDEF'],
            borderTopWidth: 2,
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Row Button Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 0,
              marginTop: 20,
              paddingBottom: 0,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Contact Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', width: '100%' },
              dimensions.width
            )}
          >
            {/* Contact Client Enabled */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  setShowModalClientContact(true);
                  amplitudeEvents(
                    Variables,
                    'start contact client',
                    {
                      step: '01',
                      funnel: 'contact client',
                      origin: 'client details screen',
                      category: 'client management',
                    },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Red EB3E26'],
                  borderRadius: 50,
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  paddingBottom: 14,
                  paddingTop: 14,
                  textAlign: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
              title={'Contact Client'}
            />
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ClientDetailsScreen);
