import React from 'react';
import { MapCallout, MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Divider,
  KeyboardAvoidingView,
  NumberInput,
  Pressable,
  RadioButtonGroup,
  RadioButtonRow,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  Alert,
  ImageBackground,
  Modal,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as OpportunitiesMap2 from '../custom-files/OpportunitiesMap2';
import * as Track from '../custom-files/Track';
import * as viewOpportunities from '../custom-files/viewOpportunities';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import concatAddress from '../global-functions/concatAddress';
import generateAvatarBg from '../global-functions/generateAvatarBg';
import getFirstNameLastInitial from '../global-functions/getFirstNameLastInitial';
import getUserInitials from '../global-functions/getUserInitials';
import mathRound from '../global-functions/mathRound';
import resetSendDeclinePackageResponse from '../global-functions/resetSendDeclinePackageResponse';
import sendDeclinePackageNotice from '../global-functions/sendDeclinePackageNotice';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const JobsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [any, setAny] = React.useState(false);
  const [filterActive, setFilterActive] = React.useState(false);
  const [filterDays, setFilterDays] = React.useState('');
  const [filteredJobsCount, setFilteredJobsCount] = React.useState(0);
  const [filteredOpenJobs, setFilteredOpenJobs] = React.useState([]);
  const [filters, setFilters] = React.useState('');
  const [friday, setFriday] = React.useState(false);
  const [jobsList, setJobsList] = React.useState('open');
  const [maxLessons, setMaxLessons] = React.useState(0);
  const [modalDeclineVisible, setModalDeclineVisible] = React.useState(false);
  const [modalFilterVisible, setModalFilterVisible] = React.useState(false);
  const [modalSortVisible, setModalSortVisible] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(true);
  const [monday, setMonday] = React.useState(false);
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [numberInputValue2, setNumberInputValue2] = React.useState('');
  const [passNav, setPassNav] = React.useState({});
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [requestedAndDeclinedPackages, setRequestedAndDeclinedPackages] =
    React.useState([]);
  const [saturday, setSaturday] = React.useState(false);
  const [selectedLessonPackageId, setSelectedLessonPackageId] =
    React.useState(0);
  const [selectedPackageKnackId, setSelectedPackageKnackId] =
    React.useState('');
  const [showTakeAction, setShowTakeAction] = React.useState(false);
  const [showTakeActionExpanded, setShowTakeActionExpanded] =
    React.useState(false);
  const [showTakeActionMini, setShowTakeActionMini] = React.useState(true);
  const [sortBy, setSortBy] = React.useState('newest');
  const [sort_field, setSort_field] = React.useState('field_129');
  const [sort_order, setSort_order] = React.useState('desc');
  const [spAddressClient, setSpAddressClient] = React.useState({});
  const [spAvailabilityDays, setSpAvailabilityDays] = React.useState('');
  const [spAvailabilityNotes, setSpAvailabilityNotes] = React.useState('');
  const [spAvailabilityTimesWeekDays, setSpAvailabilityTimesWeekDays] =
    React.useState([]);
  const [spAvailabilityTimesWeekEnds, setSpAvailabilityTimesWeekEnds] =
    React.useState([]);
  const [spClientName, setSpClientName] = React.useState('');
  const [spClientOpenToAlternativeSched, setSpClientOpenToAlternativeSched] =
    React.useState('');
  const [spCurrentlyBookedSi, setSpCurrentlyBookedSi] = React.useState('');
  const [spDistance, setSpDistance] = React.useState(0);
  const [spEndDate, setSpEndDate] = React.useState('');
  const [spHasPoolAccess, setSpHasPoolAccess] = React.useState(false);
  const [spInstructorBid, setSpInstructorBid] = React.useState(0);
  const [spKnackId, setSpKnackId] = React.useState('');
  const [spLessonsCount, setSpLessonsCount] = React.useState(0);
  const [spLessonsLength, setSpLessonsLength] = React.useState(0);
  const [spLessonsPerWeek, setSpLessonsPerWeek] = React.useState(0);
  const [spPackageId, setSpPackageId] = React.useState([]);
  const [spPackageStatus, setSpPackageStatus] = React.useState('');
  const [spPayBase, setSpPayBase] = React.useState(0);
  const [spPayBonus, setSpPayBonus] = React.useState(0);
  const [spPayPerLesson, setSpPayPerLesson] = React.useState(0);
  const [spPoolType, setSpPoolType] = React.useState('');
  const [spSpecialSchedulingRequests, setSpSpecialSchedulingRequests] =
    React.useState('');
  const [spStartDate, setSpStartDate] = React.useState('');
  const [spStudentsDetails, setSpStudentsDetails] = React.useState('');
  const [spStudentsDetailsAdditional, setSpStudentsDetailsAdditional] =
    React.useState('');
  const [spStudentsNotes, setSpStudentsNotes] = React.useState('');
  const [sunday, setSunday] = React.useState(false);
  const [thursday, setThursday] = React.useState(false);
  const [tuesday, setTuesday] = React.useState(false);
  const [viewType, setViewType] = React.useState('list');
  const [wednesday, setWednesday] = React.useState(false);
  const sortOpportunities = sortBy => {
    if (sortBy === 'newest') {
      setSort_field('field_129');
      setSort_order('desc');
      setSortBy('newest');
    }

    if (sortBy === 'oldest') {
      setSort_field('field_129');
      setSort_order('asc');
      setSortBy('oldest');
    }

    if (sortBy === 'nearest') {
      setSort_field('field_126');
      setSort_order('asc');
      setSortBy('nearest');
    }

    if (sortBy === 'farthest') {
      setSort_field('field_126');
      setSort_order('desc');
      setSortBy('farthest');
    }
  };

  const clearFilterParams = () => {
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);
    setAny(false);
    setMaxLessons(0);
    setFilterActive(false);
  };

  const extractPackageId = packageIdentifiers => {
    const packageId = packageIdentifiers[0].identifier;

    return packageId;
  };

  const convertYesNoToBoolean = stringYesOrNo => {
    if (stringYesOrNo.length > 0) {
      return stringYesOrNo.toLowerCase() == 'yes' ? true : false;
    }

    return false;
  };

  const joinArray = arr => {
    return arr.join(' ');
  };

  const concatAddress = addressObject => {
    const { street, street2, city, state, zip, latitude, longitude } =
      addressObject;

    let address;

    street2
      ? (address = `${street} ${street2}, ${city}, ${state} ${zip}`)
      : (address = `${street}, ${city}, ${state} ${zip}`);

    return address;
  };

  const extractDateFromString = str => {
    // date from server comes in On a particular day 04/17/24. This function removes the preceeding string.
    let date = str.replace('On a particular day', '');
    return date;
  };

  const closeConfirmationModal = async (Variables, setGlobalVariableValue) => {
    try {
      await refetchGetOpenOpportunities();
      resetSendDeclinePackageResponse();
      setModalDeclineVisible(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Set screen variables for selected package
  const setSelectedPackageVariables = selectedPackage => {
    // sp = selected package

    const {
      field_1890_raw,
      field_314_raw,
      field_167_raw,
      field_2394_raw,
      field_2395_raw,
      field_455_raw,
      field_2377_raw,
      field_239,
      field_126_raw,
      field_733,
      field_726_raw,
      field_176_raw,
      field_62_raw,
      field_253_raw,
      field_123_raw,
      field_231_raw,
      field_170_raw,
      field_173_raw,
      field_171_raw,
      field_99_raw,
      field_460_raw,
      field_449_raw,
      field_521_raw,
    } = selectedPackage;

    setSpAddressClient(field_1890_raw);
    setSpAvailabilityDays(field_314_raw);
    setSpAvailabilityNotes(field_167_raw);
    setSpAvailabilityTimesWeekDays(field_2394_raw);
    setSpAvailabilityTimesWeekEnds(field_2395_raw);
    setSpClientName(field_455_raw);
    setSpClientOpenToAlternativeSched(field_2377_raw);
    setSpCurrentlyBookedSi(field_239);
    setSpDistance(field_126_raw);
    setSpEndDate(field_733);
    setSpHasPoolAccess(field_726_raw);
    setSpLessonsLength(field_176_raw);
    setSpLessonsCount(field_62_raw);
    setSpLessonsPerWeek(field_253_raw);
    setSpPackageId(field_123_raw);
    setSpPackageStatus(field_231_raw);
    setSpPayBase(field_170_raw);
    setSpPayBonus(field_173_raw);
    setSpPayPerLesson(field_171_raw);
    setSpPoolType(field_99_raw);
    setSpStartDate(field_460_raw);
    setSpStudentsDetails(field_449_raw);
    setSpStudentsDetailsAdditional(field_521_raw);
  };

  const subtractListCount = startingNumber => {
    return startingNumber - 10;
  };

  const removeRequestedAndDeclinedPackages = packageList => {
    if (requestedAndDeclinedPackages.length > 0) {
      console.log(
        'requestedAndDeclinedPackages === ',
        requestedAndDeclinedPackages
      );
      const filteredLessonPackages = packageList.filter(lessonPackage => {
        console.log(
          'Include ' +
            lessonPackage.field_123_raw[0].id +
            ': ' +
            !requestedAndDeclinedPackages.includes(
              lessonPackage.field_123_raw[0].id
            )
        );
        return !requestedAndDeclinedPackages.includes(
          lessonPackage.field_123_raw[0].id
        );
      });

      return filteredLessonPackages;
    }

    return packageList;
  };

  const addPackageToExcludeList = (
    Variables,
    setGlobalVariableValue,
    packageKnackId
  ) => {
    let excludeList = [...requestedAndDeclinedPackages];
    excludeList.push(packageKnackId);
    setRequestedAndDeclinedPackages(excludeList);
  };

  const createFilterParams = () => {
    setFilterActive(false);

    let filterUrlSafe;
    let match = 'or';
    const rules = [];

    function FilterDay(day) {
      this.field = 'field_314';
      this.operator = 'contains';
      this.value = day;
    }

    function FilterMaximumLessonsPerWeek(limit) {
      this.field = 'field_2461';
      this.operator = 'lower than';
      this.value = limit;
    }

    if (monday) {
      rules.push(new FilterDay('Monday'));
    }

    if (tuesday) {
      rules.push(new FilterDay('Tuesday'));
    }

    if (wednesday) {
      rules.push(new FilterDay('Wednesday'));
    }

    if (thursday) {
      rules.push(new FilterDay('Thursday'));
    }

    if (friday) {
      rules.push(new FilterDay('Friday'));
    }

    if (saturday) {
      rules.push(new FilterDay('Saturday'));
    }

    if (sunday) {
      rules.push(new FilterDay('Sunday'));
    }

    if (maxLessons) {
      rules.push(new FilterMaximumLessonsPerWeek(maxLessons + 1));
    }

    if (rules.length > 0 && maxLessons > 0) {
      match = 'and';
    }

    const filterRules = {
      match,
      rules,
    };

    if (filterRules.rules.length > 0) {
      console.log('filterRules === ', filterRules);
      filterUrlSafe = encodeURIComponent(JSON.stringify(filterRules));
      setFilterActive(true);
    }
    88;

    return filterUrlSafe;
  };

  const shouldPreventJobRequest = (navigation, Variables) => {
    const { gAccountReadyForClients } = Variables;
    console.log(
      'shouldPreventJobRequest: gAccountReadyForClients === ',
      gAccountReadyForClients
    );

    // if (!gAccountReadyForClients) {
    //     if (Platform.OS == 'web') {
    //         alert('Hold! 🏊 Before diving into requesting packages, you must complete your account setup.');
    //     } else {
    //         Alert.alert('Hold! 🏊', 'Before diving into requesting packages, you must complete your account setup.', [
    //             {
    //                 text: 'Finish Setup',
    //                 onPress: () => {
    //                     navigation.navigate('MyAccountScreen')
    //                 }
    //             }
    //         ]);
    //     }
    //     return true;
    // }

    return false;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'JobsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'screen',
        value: 'jobs',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View
        style={StyleSheet.applyWidth(
          { width: '100%', zIndex: 10 },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Left */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: 24, width: 24 },
                dimensions.width
              )}
            >
              {/* View Back Arrow Container */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 24, marginRight: 8, width: 24 },
                  dimensions.width
                )}
              >
                <SVG
                  source={
                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_arrow_left_black_square.svg'
                  }
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Pressable>
            {/* View Page Title Container */}
            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'Poppins_700Bold',
                    fontSize: 18,
                  },
                  dimensions.width
                )}
              >
                {'Job Opportunities'}
              </Text>
            </View>
          </View>
          {/* View Right Filters */}
          <>
            {!(jobsList === 'open') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                {/* View Filter */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginRight: 16 },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setModalFilterVisible(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icon_filter_black.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: 28, width: 28 },
                        dimensions.width
                      )}
                    />
                    {/* View Active Indicator Container */}
                    <>
                      {!filterActive ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-end',
                              height: 28,
                              marginTop: -28,
                              width: 28,
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            elevation={0}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderBottomWidth: 2,
                                borderColor: palettes.App['Custom Color'],
                                borderLeftWidth: 2,
                                borderRadius: 50,
                                borderRightWidth: 2,
                                borderTopWidth: 2,
                                height: 16,
                                width: 16,
                              },
                              dimensions.width
                            )}
                          />
                        </View>
                      )}
                    </>
                  </Touchable>
                </View>
                {/* View Sort */}
                <View>
                  <Touchable
                    onPress={() => {
                      try {
                        setModalSortVisible(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <SVG
                      source={Constants['uiSortBlack']}
                      style={StyleSheet.applyWidth(
                        { height: 20, width: 24 },
                        dimensions.width
                      )}
                    />
                  </Touchable>
                </View>
              </View>
            )}
          </>
        </View>
      </View>
      {/* View Main Buttons Section */}
      <View
        style={StyleSheet.applyWidth(
          { marginTop: -115, width: '100%' },
          dimensions.width
        )}
      >
        {/* View Sunset Background */}
        <View
          style={StyleSheet.applyWidth(
            { height: 200, marginBottom: -80, width: '100%' },
            dimensions.width
          )}
        >
          {/* SVG Sunset Background */}
          <SVG
            source={
              'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_sunset_orange_rectangle_dynamic.svg'
            }
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          />
        </View>
        {/* View Top Main Screens Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              paddingBottom: 16,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 16,
            },
            dimensions.width
          )}
        >
          {/* View Open Jobs */}
          <View
            style={StyleSheet.applyWidth({ marginRight: 12 }, dimensions.width)}
          >
            {/* Open Jobs Active */}
            <>
              {!(jobsList === 'open') ? null : (
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Open Jobs'}
                />
              )}
            </>
            {/* Open Jobs Inactive */}
            <>
              {!(jobsList === 'requested') ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setJobsList('open');
                      amplitudeEvents(
                        Variables,
                        'view open jobs list',
                        {
                          step: '01',
                          funnel: 'request job',
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderRadius: 50,
                      color: palettes.App['App Red EB3E26'],
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Open Jobs'}
                />
              )}
            </>
          </View>
          {/* View Requested Jobs */}
          <View
            style={StyleSheet.applyWidth({ marginRight: 12 }, dimensions.width)}
          >
            {/* Requested Jobs Active */}
            <>
              {!(jobsList === 'requested') ? null : (
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'Poppins_400Regular',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Requested Jobs'}
                />
              )}
            </>
            {/* Requested Jobs Inactive */}
            <>
              {!(jobsList === 'open') ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setJobsList('requested');
                      setViewType('list');
                      amplitudeEvents(
                        Variables,
                        'view requested jobs',
                        {
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderRadius: 50,
                      color: palettes.App['App Red EB3E26'],
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Requested Jobs'}
                />
              )}
            </>
          </View>
        </View>
      </View>
      {/* View History Filters */}
      <>
        {!(jobsList === 'requested') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* View Past 30 Days Active */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                  borderRadius: 50,
                  paddingBottom: 6,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 6,
                },
                dimensions.width
              )}
            >
              {/* Past 30 days */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                  },
                  dimensions.width
                )}
              >
                {'Past 30 days'}
              </Text>
            </View>
          </View>
        )}
      </>
      {/* View Map Container */}
      <>
        {!(viewType === 'map') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { height: '68%', justifyContent: 'center', width: '100%' },
              dimensions.width
            )}
          >
            {/* Fetch Open Opps */}
            <KnackAPIApi.FetchGetOpenOpportunitiesGET
              filters={filters}
              handlers={{
                onData: fetchOpenOppsData => {
                  try {
                    setFilteredJobsCount(fetchOpenOppsData?.total_records);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({ loading, error, data, refetchGetOpenOpportunities }) => {
                const fetchOpenOppsData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* View No Open Jobs */}
                    <>
                      {!(fetchOpenOppsData?.total_records === 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: -40,
                            },
                            dimensions.width
                          )}
                        >
                          {/* SVG Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              { height: 250, width: 200 },
                              dimensions.width
                            )}
                          >
                            <SVG
                              {...GlobalStyles.SVGStyles(theme)['SVG'].props}
                              source={Constants['uiRelaxingPup']}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SVGStyles(theme)['SVG'].style,
                                  { height: 250, width: 200 }
                                ),
                                dimensions.width
                              )}
                            />
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 8,
                                paddingLeft: '7.35%',
                                paddingRight: '7.35%',
                                paddingTop: 16,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 14,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                "There are currently no open jobs near you. We'll notify you once a new job is available!"
                              }
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Opportunities Map */}
                    <>
                      {!(fetchOpenOppsData?.total_records > 0) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* Mobile Map */}
                          <>
                            {Platform.OS === 'web' ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <Utils.CustomCodeErrorBoundary>
                                  <OpportunitiesMap2.Map
                                    markers={fetchOpenOppsData?.records}
                                    navigation={props.navigation.navigate}
                                  />
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                            )}
                          </>
                          {/* Web Map */}
                          <>
                            {!(Platform.OS === 'web') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <MapView
                                  apiKey={
                                    'AIzaSyBzktToWosjNgrrUawZnbslB9NSXSXCkwo'
                                  }
                                  autoClusterMarkers={false}
                                  autoClusterMarkersDistanceMeters={10000}
                                  customMapStyle={'Lorem ipsum dolor sit amet'}
                                  keyExtractor={(mapViewData, index) =>
                                    mapViewData?.id ??
                                    mapViewData?.uuid ??
                                    index?.toString() ??
                                    JSON.stringify(mapViewData)
                                  }
                                  listKey={'O7HtdU7F'}
                                  loadingEnabled={true}
                                  markersData={fetchOpenOppsData?.records}
                                  moveOnMarkerPress={true}
                                  renderItem={({ item, index }) => {
                                    const mapViewData = item;
                                    return (
                                      <MapMarker
                                        androidUseDefaultIconImplementation={
                                          false
                                        }
                                        flat={false}
                                        pinImageSize={50}
                                        tracksViewChanges={true}
                                        latitude={
                                          mapViewData?.field_1890_raw?.latitude
                                        }
                                        longitude={
                                          mapViewData?.field_1890_raw?.longitude
                                        }
                                      >
                                        <MapCallout showTooltip={true}>
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  palettes.App['Custom Color'],
                                                borderRadius: 50,
                                                paddingBottom: 4,
                                                paddingLeft: 6,
                                                paddingRight: 6,
                                                paddingTop: 4,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Pay */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['primary14'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'primary14'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'$'}
                                              {mapViewData?.field_170_raw}
                                            </Text>
                                            {/* Lesson package */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  fontSize: 12,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {mapViewData?.field_62_raw}
                                              {' x '}
                                              {mapViewData?.field_176_raw}
                                              {' min lessons'}
                                            </Text>

                                            <Pressable
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'OpportunityDetailsLiveScreen',
                                                    {
                                                      opportunityID:
                                                        mapViewData?.id,
                                                    }
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* View details */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                    fontSize: 12,
                                                    textDecorationLine:
                                                      'underline',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'view details'}
                                              </Text>
                                            </Pressable>
                                          </View>
                                        </MapCallout>
                                      </MapMarker>
                                    );
                                  }}
                                  rotateEnabled={true}
                                  scrollEnabled={true}
                                  showsCompass={false}
                                  showsPointsOfInterest={true}
                                  showsUserLocation={false}
                                  zoom={8}
                                  zoomEnabled={true}
                                  {...GlobalStyles.MapViewStyles(theme)[
                                    'Map View'
                                  ].props}
                                  latitude={Constants['instructorAddressLat']}
                                  longitude={Constants['instructorAddressLong']}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.MapViewStyles(theme)[
                                      'Map View'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </>
                );
              }}
            </KnackAPIApi.FetchGetOpenOpportunitiesGET>
          </View>
        )}
      </>
      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* View Page Content Open Jobs */}
        <>
          {!(jobsList === 'open') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.App['Custom Color'] },
                dimensions.width
              )}
            >
              {/* View List View */}
              <>
                {!(viewType === 'list') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 98, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Fetch Open Opportunities */}
                    <KnackAPIApi.FetchGetOpenOpportunitiesGET
                      filters={filters}
                      handlers={{
                        onData: fetchOpenOpportunitiesData => {
                          try {
                            console.log('OPEN JOBS FETCHED');
                            setFilteredJobsCount(
                              fetchOpenOpportunitiesData?.total_records
                            );
                            setFilteredOpenJobs(
                              fetchOpenOpportunitiesData?.records
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                      rows_per_page={1000}
                      sort_field={sort_field}
                      sort_order={sort_order}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetOpenOpportunities,
                      }) => {
                        const fetchOpenOpportunitiesData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Over 10 Jobs - Take Action Notice */}
                            <>
                              {!(
                                fetchOpenOpportunitiesData?.total_records > 10
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'stretch', marginBottom: 8 },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!showTakeActionMini ? null : (
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'Surface'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ].style,
                                            {
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                              borderRadius: 50,
                                              bottom: -15,
                                              zIndex: 10,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setShowTakeActionExpanded(true);
                                              setShowTakeActionMini(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          {/* Take Action */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                backgroundColor:
                                                  palettes.App['Custom Color'],
                                                borderColor:
                                                  palettes.App[
                                                    'App Disabled Button Text 7F868C'
                                                  ],
                                                borderRadius: 50,
                                                borderWidth: 1,
                                                flexDirection: 'row',
                                                paddingBottom: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 4,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Take Action to See More */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Black 191F32'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                    fontSize: 12,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'Take Action to See '}
                                            </Text>
                                            {/* Open Jobs Count */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Black 191F32'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_700Bold',
                                                    fontSize: 12,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {subtractListCount(
                                                fetchOpenOpportunitiesData?.total_records
                                              )}{' '}
                                            </Text>
                                            {/* More Jobs */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Black 191F32'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_700Bold',
                                                    fontSize: 12,
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'More Jobs'}
                                            </Text>
                                          </View>
                                        </Pressable>
                                      </Surface>
                                    )}
                                  </>
                                  {/* Take Action Expanded Container */}
                                  <>
                                    {!showTakeActionExpanded ? null : (
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'Surface'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ].style,
                                            { borderRadius: 8 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* Take Action Expanded */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'stretch',
                                              backgroundColor:
                                                palettes.App[
                                                  'App Orange #FDF9EC'
                                                ],
                                              borderColor:
                                                palettes.App[
                                                  'App Disabled Button EDEDEF'
                                                ],
                                              borderRadius: 8,
                                              borderWidth: 1,
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              padding: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Text Container */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { width: '90%' },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    alignSelf: 'center',
                                                    color:
                                                      palettes.App[
                                                        'App Black 191F32'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                'Request or decline jobs now to see '
                                              }
                                              {
                                                fetchOpenOpportunitiesData?.total_records
                                              }
                                              {' more open jobs near you'}
                                            </Text>
                                          </View>
                                          {/* Close container */}
                                          <View>
                                            <Pressable
                                              onPress={() => {
                                                try {
                                                  setShowTakeActionExpanded(
                                                    false
                                                  );
                                                  setShowTakeActionMini(true);
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignContent: 'center',
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                    backgroundColor:
                                                      palettes.App[
                                                        'App Disabled Button EDEDEF'
                                                      ],
                                                    borderRadius: 100,
                                                    height: 20,
                                                    width: 20,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* x */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Disabled Button Text 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'x'}
                                                </Text>
                                              </View>
                                            </Pressable>
                                          </View>
                                        </View>
                                      </Surface>
                                    )}
                                  </>
                                  <Divider
                                    {...GlobalStyles.DividerStyles(theme)[
                                      'Divider'
                                    ].props}
                                    color={
                                      palettes.App['App Disabled Button EDEDEF']
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.DividerStyles(theme)[
                                        'Divider'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              )}
                            </>
                            <>
                              {!(
                                fetchOpenOpportunitiesData?.total_records > 0
                              ) ? null : (
                                <FlashList
                                  data={removeRequestedAndDeclinedPackages(
                                    fetchOpenOpportunitiesData?.records
                                  )}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(flashListData, index) =>
                                    flashListData?.id
                                  }
                                  listKey={'Bb2jrLvk'}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const flashListData = item;
                                    return (
                                      <>
                                        {/* View Open Job Card */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 8,
                                              borderColor:
                                                palettes.App['App Grey E5E5E5'],
                                              paddingBottom: 20,
                                              paddingTop: 20,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* View New Tag Holder */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { alignItems: 'flex-start' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View New Tag */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'flex-start',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Brand Blue 2C9AD9'
                                                    ],
                                                  borderBottomLeftRadius: 0,
                                                  borderBottomRightRadius: 30,
                                                  borderTopRightRadius: 30,
                                                  paddingBottom: 2,
                                                  paddingLeft: 10,
                                                  paddingRight: 12,
                                                  paddingTop: 2,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* New */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                    fontFamily:
                                                      'BebasNeue_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'NEW'}
                                              </Text>
                                            </View>
                                          </View>
                                          {/* View Card Content */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                paddingLeft: '5.35%',
                                                paddingRight: '5.35%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Pressable
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'OpportunityDetailsLiveScreen',
                                                    {
                                                      opportunityID:
                                                        flashListData?.id,
                                                    }
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* View Top Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      palettes.App[
                                                        'App Grey Inputs E2E2E2'
                                                      ],
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    paddingBottom: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View Left Details */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View Client PP */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        height: 48,
                                                        marginRight: 8,
                                                        width: 48,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Surface
                                                      elevation={3}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderBottomWidth: 1.25,
                                                          borderColor:
                                                            palettes.App[
                                                              'Custom Color'
                                                            ],
                                                          borderLeftWidth: 1.25,
                                                          borderRadius: 100,
                                                          borderRightWidth: 1.25,
                                                          borderTopWidth: 1.25,
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* SVG Avatar */}
                                                      <SVG
                                                        source={generateAvatarBg(
                                                          Variables,
                                                          Constants['uiAvatars']
                                                        )}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            height: '100%',
                                                            width: '100%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      />
                                                    </Surface>
                                                    {/* View Initials Container */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          height: 48,
                                                          justifyContent:
                                                            'center',
                                                          marginTop: -48,
                                                          width: 48,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Initials */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'Custom Color'
                                                              ],
                                                            fontFamily:
                                                              'Poppins_500Medium',
                                                            fontSize: 24,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {getUserInitials(
                                                          flashListData?.field_455_raw
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                  {/* View Client Details */}
                                                  <View>
                                                    {/* View Client Name */}
                                                    <View>
                                                      {/* Client First Name */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Black 191F32'
                                                              ],
                                                            fontFamily:
                                                              'Poppins_600SemiBold',
                                                            textTransform:
                                                              'capitalize',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {getFirstNameLastInitial(
                                                          flashListData?.field_455_raw
                                                        )}
                                                        {'.'}
                                                      </Text>
                                                    </View>
                                                    {/* View Distance */}
                                                    <View>
                                                      {/* Distance */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'App Text 2ndary 7F868C'
                                                              ],
                                                            fontFamily:
                                                              'Poppins_400Regular',
                                                            fontSize: 12,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          flashListData?.field_126
                                                        }
                                                        {' away'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                                {/* View Right Details */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { alignItems: 'flex-end' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View Status Loading */}
                                                  <>
                                                    {flashListData?.field_170_raw ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            backgroundColor:
                                                              palettes.App[
                                                                'App Disabled Button EDEDEF'
                                                              ],
                                                            borderRadius: 8,
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'center',
                                                            paddingBottom: 4,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            paddingTop: 4,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <ActivityIndicator
                                                          animating={true}
                                                          hidesWhenStopped={
                                                            true
                                                          }
                                                          size={'small'}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: 36,
                                                              width: 36,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        />
                                                      </View>
                                                    )}
                                                  </>
                                                  {/* View Pay Details */}
                                                  <>
                                                    {!flashListData?.field_170_raw ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flexDirection:
                                                              'row',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Pay */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Black 191F32'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_600SemiBold',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Pay'}
                                                        </Text>
                                                        {/* Base pay */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Text Green #1DB058'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_600SemiBold',
                                                              marginLeft: 8,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'$'}
                                                          {
                                                            flashListData?.field_170_raw
                                                          }
                                                        </Text>
                                                        {/* Bonus pay */}
                                                        <>
                                                          {!flashListData?.field_173 ? null : (
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    palettes
                                                                      .App[
                                                                      'App Sun Orange F4A100'
                                                                    ],
                                                                  fontFamily:
                                                                    'Poppins_600SemiBold',
                                                                  marginLeft: 8,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {'+$'}
                                                              {
                                                                flashListData?.field_173_raw
                                                              }
                                                            </Text>
                                                          )}
                                                        </>
                                                      </View>
                                                    )}
                                                  </>
                                                  {/* View Lesson Details */}
                                                  <>
                                                    {!flashListData?.field_170_raw ? null : (
                                                      <View>
                                                        {/* Lesson Details */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Text 2ndary 7F868C'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_400Regular',
                                                              fontSize: 12,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            flashListData?.field_62_raw
                                                          }
                                                          {' x '}
                                                          {
                                                            flashListData?.field_176_raw
                                                          }
                                                          {' min lessons'}
                                                        </Text>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              </View>
                                              {/* View Schedule Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    paddingBottom: 16,
                                                    paddingTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View Row */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      justifyContent:
                                                        'space-between',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View Label */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { marginBottom: 8 },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Available days */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'App Text 2ndary 7F868C'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 12,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Looking for '}
                                                      {
                                                        flashListData?.field_253_raw
                                                      }
                                                      {
                                                        ' lessons per week on any'
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* View Label */}
                                                  <>
                                                    {!Constants[
                                                      'is_loading'
                                                    ] ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          { marginBottom: 8 },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Requested lessons */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Text 2ndary 7F868C'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_400Regular',
                                                              fontSize: 12,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Wants '}
                                                          {
                                                            flashListData?.field_253_raw
                                                          }
                                                          {' lessons per week'}
                                                        </Text>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                                {/* View Days Available List */}
                                                <View>
                                                  <Utils.CustomCodeErrorBoundary>
                                                    <viewOpportunities.availableDays
                                                      availableDaysClient={
                                                        flashListData?.field_314_raw
                                                      }
                                                    />
                                                  </Utils.CustomCodeErrorBoundary>
                                                </View>
                                              </View>
                                            </Pressable>
                                          </View>
                                          {/* View Action Buttons */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                paddingLeft: '5.35%',
                                                paddingRight: '5.35%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View Decline Button Container */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { width: '49%' },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Decline */}
                                              <Button
                                                iconPosition={'left'}
                                                onPress={() => {
                                                  try {
                                                    const preventJobRequest =
                                                      shouldPreventJobRequest(
                                                        navigation,
                                                        Variables
                                                      );
                                                    if (preventJobRequest) {
                                                      return;
                                                    }
                                                    setSelectedPackageVariables(
                                                      flashListData
                                                    );
                                                    setSelectedLessonPackageId(
                                                      extractPackageId(
                                                        flashListData?.field_123_raw
                                                      )
                                                    );
                                                    setSelectedPackageKnackId(
                                                      flashListData?.id
                                                    );
                                                    setGlobalVariableValue({
                                                      key: 'showModalDeclineSurvey',
                                                      value: true,
                                                    });
                                                    amplitudeEvents(
                                                      Variables,
                                                      'start decline job',
                                                      {
                                                        step: '01',
                                                        view: 'jobs list view',
                                                        funnel: 'decline job',
                                                        origin:
                                                          'open jobs screen',
                                                        category:
                                                          'opportunity management',
                                                      },
                                                      undefined
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      palettes.App[
                                                        'App Grey Inputs E2E2E2'
                                                      ],
                                                    borderLeftWidth: 1,
                                                    borderRadius: 50,
                                                    borderRightWidth: 1,
                                                    borderTopWidth: 1,
                                                    color:
                                                      palettes.App[
                                                        'App Text 2ndary 7F868C'
                                                      ],
                                                    fontFamily:
                                                      'BebasNeue_400Regular',
                                                    textAlign: 'center',
                                                  },
                                                  dimensions.width
                                                )}
                                                title={'DECLINE'}
                                              />
                                            </View>
                                            {/* View View Details Button Container */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { width: '49%' },
                                                dimensions.width
                                              )}
                                            >
                                              {/* View Details */}
                                              <Button
                                                iconPosition={'left'}
                                                onPress={() => {
                                                  try {
                                                    navigation.navigate(
                                                      'OpportunityDetailsLiveScreen',
                                                      {
                                                        opportunityID:
                                                          flashListData?.id,
                                                      }
                                                    );
                                                    amplitudeEvents(
                                                      Variables,
                                                      'view open job details',
                                                      {
                                                        step: '02',
                                                        view: 'jobs list view',
                                                        funnel: 'request job',
                                                        origin:
                                                          'open jobs screen',
                                                        category:
                                                          'opportunity management',
                                                      },
                                                      undefined
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                    borderBottomWidth: 1,
                                                    borderColor:
                                                      palettes.App[
                                                        'App Sun Orange F4A100'
                                                      ],
                                                    borderLeftWidth: 1,
                                                    borderRadius: 50,
                                                    borderRightWidth: 1,
                                                    borderTopWidth: 1,
                                                    color:
                                                      palettes.App[
                                                        'App Sun Orange F4A100'
                                                      ],
                                                    fontFamily:
                                                      'BebasNeue_400Regular',
                                                    textAlign: 'center',
                                                  },
                                                  dimensions.width
                                                )}
                                                title={'View Details'}
                                              />
                                            </View>
                                          </View>
                                        </View>
                                      </>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                  estimatedItemSize={25}
                                />
                              )}
                            </>
                            {/* View No Open Jobs */}
                            <>
                              {!(
                                fetchOpenOpportunitiesData?.total_records === 0
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginTop: 40,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* SVG Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { height: 250, width: 200 },
                                      dimensions.width
                                    )}
                                  >
                                    <SVG
                                      {...GlobalStyles.SVGStyles(theme)['SVG']
                                        .props}
                                      source={Constants['uiRelaxingPup']}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.SVGStyles(theme)['SVG']
                                            .style,
                                          { height: '100%', width: '100%' }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        paddingBottom: 8,
                                        paddingLeft: '7.35%',
                                        paddingRight: '7.35%',
                                        paddingTop: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            alignSelf: 'center',
                                            color:
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 14,
                                            textAlign: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        "There are currently no open jobs near you. We'll notify you once a new job is available!"
                                      }
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                    </KnackAPIApi.FetchGetOpenOpportunitiesGET>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        {/* View Page Content Requested Jobs */}
        <>
          {!(jobsList === 'requested') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 98 },
                dimensions.width
              )}
            >
              {/* Fetch Requested Opps */}
              <>
                {!(jobsList === 'requested') ? null : (
                  <KnackAPIApi.FetchGetRequestedOpportunitiesGET>
                    {({
                      loading,
                      error,
                      data,
                      refetchGetRequestedOpportunities,
                    }) => {
                      const fetchRequestedOppsData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {!(
                            fetchRequestedOppsData?.total_records > 0
                          ) ? null : (
                            <FlashList
                              data={fetchRequestedOppsData?.records}
                              estimatedItemSize={50}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(flashListData, index) =>
                                flashListData?.id ??
                                flashListData?.uuid ??
                                index?.toString() ??
                                JSON.stringify(flashListData)
                              }
                              listKey={'mnq45rzu'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const flashListData = item;
                                return (
                                  <>
                                    {/* View Requested Job Card */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderBottomWidth: 8,
                                          borderColor:
                                            palettes.App['App Grey E5E5E5'],
                                          paddingBottom: 20,
                                          paddingTop: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* View Card Content */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            paddingLeft: '5.35%',
                                            paddingRight: '5.35%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* View Top Details */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              paddingBottom: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* View Left Details */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View Client PP */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  height: 48,
                                                  marginRight: 8,
                                                  width: 48,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Surface
                                                elevation={3}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderBottomWidth: 1.25,
                                                    borderColor:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                    borderLeftWidth: 1.25,
                                                    borderRadius: 100,
                                                    borderRightWidth: 1.25,
                                                    borderTopWidth: 1.25,
                                                    height: '100%',
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* SVG Avatar */}
                                                <SVG
                                                  source={
                                                    Constants['uiAvatar3']
                                                  }
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: '100%',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </Surface>
                                              {/* View Initials Container */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    height: 48,
                                                    justifyContent: 'center',
                                                    width: 48,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Initials */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'Custom Color'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_500Medium',
                                                      fontSize: 24,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {getUserInitials(
                                                    Constants['userFullName']
                                                  )}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* View Client Details */}
                                            <View>
                                              {/* View Client Name */}
                                              <View>
                                                {/* Client Name */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Black 191F32'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_600SemiBold',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {flashListData?.field_455_raw}
                                                </Text>
                                              </View>
                                              {/* Requested Date */}
                                              <View>
                                                {/* Requested Date */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    flashListData?.field_574_raw
                                                      ?.date_formatted
                                                  }
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                          {/* View Right Details */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-end',
                                                justifyContent: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View Requested Status */}
                                            <>
                                              {!(
                                                flashListData?.field_783_raw ===
                                                'Unmatched'
                                              ) ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      backgroundColor:
                                                        palettes.App[
                                                          'App Orange #FDF9EC'
                                                        ],
                                                      borderRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Requested Status */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Gold FFA900'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Requested'}
                                                  </Text>
                                                </View>
                                              )}
                                            </>
                                            {/* View Matched Status */}
                                            <>
                                              {!(
                                                flashListData?.field_783_raw ===
                                                'Matched'
                                              ) ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      borderRadius: 8,
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View Matched With SI */}
                                                  <>
                                                    {!(
                                                      (flashListData?.field_239_raw &&
                                                        (flashListData
                                                          ?.field_239_raw)[0]
                                                          .id) ===
                                                      Constants[
                                                        'AUTH_KNACK_USER_ID'
                                                      ]
                                                    ) ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            backgroundColor:
                                                              palettes.App[
                                                                'App Mint #F0F8F2'
                                                              ],
                                                            borderRadius: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            paddingTop: 8,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Matched Status */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Text Green #1DB058'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_400Regular',
                                                              fontSize: 12,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Matched'}
                                                        </Text>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* View Unavailable Finished Loading */}
                                            <>
                                              {!flashListData?.field_783_raw ? null : (
                                                <View>
                                                  {/* View Unavailable Status */}
                                                  <>
                                                    {!(
                                                      flashListData?.field_783_raw !==
                                                      'Unmatched'
                                                    ) ? null : (
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            borderRadius: 8,
                                                            flexDirection:
                                                              'row',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Unavailable Status */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                palettes.App[
                                                                  'App Text 2ndary 7F868C'
                                                                ],
                                                              fontFamily:
                                                                'Poppins_400Regular',
                                                              fontSize: 12,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Unavailable'}
                                                        </Text>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* View Status Loading */}
                                            <>
                                              {flashListData?.field_783_raw ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'App Disabled Button EDEDEF'
                                                        ],
                                                      borderRadius: 8,
                                                      flexDirection: 'row',
                                                      justifyContent: 'center',
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <ActivityIndicator
                                                    animating={true}
                                                    hidesWhenStopped={true}
                                                    size={'small'}
                                                    style={StyleSheet.applyWidth(
                                                      { height: 36, width: 36 },
                                                      dimensions.width
                                                    )}
                                                  />
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        </View>
                                        {/* View Action Buttons */}
                                        <>
                                          {!Constants['empty'] ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* View View Details Button Container */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { width: '100%' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View Details */}
                                                <Button
                                                  iconPosition={'left'}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Custom Color'
                                                        ],
                                                      borderBottomWidth: 1,
                                                      borderColor:
                                                        palettes.App[
                                                          'App Sun Orange F4A100'
                                                        ],
                                                      borderLeftWidth: 1,
                                                      borderRadius: 50,
                                                      borderRightWidth: 1,
                                                      borderTopWidth: 1,
                                                      color:
                                                        palettes.App[
                                                          'App Sun Orange F4A100'
                                                        ],
                                                      fontFamily:
                                                        'BebasNeue_400Regular',
                                                      textAlign: 'center',
                                                    },
                                                    dimensions.width
                                                  )}
                                                  title={'View Details'}
                                                />
                                              </View>
                                            </View>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              contentContainerStyle={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      );
                    }}
                  </KnackAPIApi.FetchGetRequestedOpportunitiesGET>
                )}
              </>
            </View>
          )}
        </>
      </ScrollView>
      {/* View Footer Navigation 2023-09-11 */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: '"rgba(0, 0, 0, 0)"',
            bottom: 0,
            minHeight: 67,
            position: 'absolute',
            width: '100%',
            zIndex: 100,
          },
          dimensions.width
        )}
      >
        {/* View Map Button Container */}
        <>
          {!(viewType === 'list') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: '"rgba(0, 0, 0, 0)"',
                  justifyContent: 'center',
                  marginBottom: 20,
                },
                dimensions.width
              )}
            >
              <>
                {!(jobsList === 'open') ? null : (
                  <Surface
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderRadius: 50,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Map View Button */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setViewType('map');
                          amplitudeEvents(
                            Variables,
                            'view open jobs map',
                            {
                              step: '01',
                              funnel: 'request job',
                              origin: 'open jobs screen',
                              category: 'opportunity management',
                            },
                            undefined
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          color: palettes.App['Custom Color'],
                          fontFamily: 'BebasNeue_400Regular',
                          fontSize: 18,
                          paddingBottom: 8,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 8,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Map View'}
                    />
                  </Surface>
                )}
              </>
            </View>
          )}
        </>
        {/* View List Button Container */}
        <>
          {!(viewType === 'map') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  paddingBottom: 20,
                  paddingTop: 13,
                },
                dimensions.width
              )}
            >
              {/* View Drag Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 13 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setViewType('list');
                      amplitudeEvents(
                        Variables,
                        'view open jobs list',
                        {
                          step: '01',
                          funnel: 'request job',
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 40,
                        paddingLeft: 6,
                        paddingRight: 6,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom Color'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'List view'}
                    </Text>
                  </View>
                </Touchable>
              </View>
              {/* View Jobs Count Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                {/* Jobs Found */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'center',
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {filteredJobsCount}
                  {' Jobs Found'}
                </Text>
              </View>
            </View>
          )}
        </>
        <Surface
          elevation={3}
          style={StyleSheet.applyWidth({ minHeight: 40 }, dimensions.width)}
        >
          {/* Footer Navigation Icons Container */}
          <View
            {...GlobalStyles.ViewStyles(theme)['Footer Nav Icons'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['Footer Nav Icons'].style,
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                    amplitudeEvents(
                      Variables,
                      'view homescreen',
                      { origin: 'bottom navigation', category: 'general' },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(
                            'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N'
                          )}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconSearchBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                    amplitudeEvents(
                      Variables,
                      'view client list',
                      {
                        origin: 'bottom navigation',
                        category: 'client management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                    amplitudeEvents(
                      Variables,
                      'view my account',
                      {
                        origin: 'bottom navigation',
                        category: 'account management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                    amplitudeEvents(
                      Variables,
                      'view knowledge base',
                      {
                        origin: 'bottom navigation',
                        category: 'knowledge base',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
      {/* Modal Filter Options */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={modalFilterVisible}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Surface
            elevation={3}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                bottom: 0,
                height: '60%',
                minHeight: 40,
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 13,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Drag Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 13 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setModalFilterVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 25,
                    },
                    dimensions.width
                  )}
                />
              </Touchable>
            </View>
            {/* Modal Title Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* View Modal Title */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column' },
                  dimensions.width
                )}
              >
                {/* Filter by */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'flex-start',
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                      textAlign: 'left',
                    },
                    dimensions.width
                  )}
                >
                  {'Filter by'}
                </Text>
              </View>

              <Touchable
                onPress={() => {
                  try {
                    setModalFilterVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* View Modal Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 30,
                      flexDirection: 'column',
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {/* close */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'flex-start',
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                  >
                    {'close x'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* View Section Days Available */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 20, paddingTop: 0 },
                dimensions.width
              )}
            >
              {/* View Section Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 16 },
                  dimensions.width
                )}
              >
                {/* Days Available */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {'Days Available'}
                </Text>
              </View>
              {/* View Days Options Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Custom Select Days */}
                <Utils.CustomCodeErrorBoundary>
                  <viewOpportunities.selectDaysForFilter
                    filterDays={filterDays}
                    monday={monday}
                    setMonday={setMonday}
                    tuesday={tuesday}
                    setTuesday={setTuesday}
                    wednesday={wednesday}
                    setWednesday={setWednesday}
                    thursday={thursday}
                    setThursday={setThursday}
                    friday={friday}
                    setFriday={setFriday}
                    saturday={saturday}
                    setSaturday={setSaturday}
                    sunday={sunday}
                    setSunday={setSunday}
                    any={any}
                    setAny={setAny}
                  />
                </Utils.CustomCodeErrorBoundary>
              </View>
            </View>
            {/* View Lessons Per Week */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 20, paddingTop: 0 },
                dimensions.width
              )}
            >
              {/* View Section Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 16 },
                  dimensions.width
                )}
              >
                {/* Max lessons per week */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {'Max. lessons per week'}
                </Text>
              </View>
              {/* View Days Options Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Custom Max Lessons */}
                <Utils.CustomCodeErrorBoundary>
                  <viewOpportunities.selectNumberOfLessonsPerWeek
                    filters={filters}
                    maxLessons={maxLessons}
                    setMaxLessons={setMaxLessons}
                  />
                </Utils.CustomCodeErrorBoundary>
              </View>
            </View>
            {/* View Buttons Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  bottom: 0,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 32,
                  position: 'relative',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Clear Filter Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '48%' },
                  dimensions.width
                )}
              >
                {/* Clear filters */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      clearFilterParams();
                      setFilters(Constants['empty']);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Gold FFA900'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Gold FFA900'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      letterSpacing: 1.2,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Clear'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        color={palettes.App['Custom Color']}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </View>
              {/* View Apply Filter Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '48%' },
                  dimensions.width
                )}
              >
                <Surface
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 50, minHeight: 40, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Apply filters */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        const filters = createFilterParams();
                        setFilters(filters);
                        setModalFilterVisible(false);
                        amplitudeEvents(
                          Variables,
                          'filter open jobs',
                          {
                            origin: 'open jobs screen',
                            category: 'opportunity management',
                          },
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        letterSpacing: 1.2,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Apply filters'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </Surface>
              </View>
            </View>
          </Surface>
        </View>
      </Modal>
      {/* Modal Sort Options */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={modalSortVisible}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Surface
            elevation={3}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                bottom: 0,
                height: '60%',
                minHeight: 40,
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 13,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Drag Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 13 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setModalSortVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                />
              </Touchable>
            </View>
            {/* Modal Title Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* View Modal Title */}
              <View>
                {/* Sort by */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'flex-start',
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                      textAlign: 'left',
                    },
                    dimensions.width
                  )}
                >
                  {'Sort by'}
                </Text>
              </View>

              <Touchable
                onPress={() => {
                  try {
                    setModalSortVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* View Modal Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 30,
                      flexDirection: 'column',
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {/* close */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'flex-start',
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                  >
                    {'close x'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* View Section Sort Options */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 20, paddingTop: 0 },
                dimensions.width
              )}
            >
              {/* View Row Newest Job */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      sortOpportunities('newest');
                      amplitudeEvents(
                        Variables,
                        'sort open jobs',
                        {
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        'newest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Newest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Newest Job */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Newest'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'newest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Nearest Job */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      sortOpportunities('nearest');
                      amplitudeEvents(
                        Variables,
                        'sort open jobs',
                        {
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        'nearest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Nearest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Nearest */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Nearest'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'nearest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Furthest Job */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      sortOpportunities('oldest');
                      amplitudeEvents(
                        Variables,
                        'sort open jobs',
                        {
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        'oldest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Oldest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Oldest */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Oldest'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'oldest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Furthest Job */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      sortOpportunities('farthest');
                      amplitudeEvents(
                        Variables,
                        'sort open jobs',
                        {
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        'farthest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Farthest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Farthest */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Farthest'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'farthest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
            </View>
            {/* View Buttons Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  bottom: 0,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  paddingBottom: 32,
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    borderRadius: 50,
                    minHeight: 40,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Sort */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setModalSortVisible(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      letterSpacing: 1.2,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Sort'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        color={palettes.App['Custom Color']}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </Surface>
            </View>
          </Surface>
        </View>
      </Modal>
      {/* Modal Decline Survey */}
      <>
        {!Constants['showModalDeclineSurvey'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'modalBackground'
              ].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                    'modalBackground'
                  ].style,
                  { width: '100%' }
                ),
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                          setRadioButtonGroupValue(Constants['empty']);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                          setRadioButtonGroupValue(Constants['empty']);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Title */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingBottom: 24 },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {'What is the main reason you are declining this package?'}
                  </Text>
                </View>
                {/* View Questionnaire Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'flex-start',
                      marginBottom: 20,
                      marginTop: -15,
                    },
                    dimensions.width
                  )}
                >
                  <RadioButtonGroup
                    onValueChange={newRadioButtonGroupValue => {
                      try {
                        setRadioButtonGroupValue(newRadioButtonGroupValue);
                        amplitudeEvents(
                          Variables,
                          'select decline reason',
                          {
                            step: '02',
                            view: 'jobs list view',
                            funnel: 'decline job',
                            origin: 'open jobs screen',
                            category: 'opportunity management',
                          },
                          newRadioButtonGroupValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { marginLeft: -20 },
                      dimensions.width
                    )}
                  >
                    {/* Schedule row */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          style={StyleSheet.applyWidth(
                            { marginBottom: 0 },
                            dimensions.width
                          )}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'schedule'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Schedule */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {"Schedule doesn't work for me"}
                        </Text>
                      </View>
                    </View>
                    {/* Prompt Suggest Alternate Sched */}
                    <>
                      {!(radioButtonGroupValue === 'schedule') ? null : (
                        <View
                          {...GlobalStyles.ViewStyles(theme)[
                            'decline survey prompt'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ViewStyles(theme)[
                              'decline survey prompt'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 6 },
                              dimensions.width
                            )}
                          >
                            {/* Request with alt sched */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.text.strong,
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {
                                'Optional: Request this package with an alternate schedule. Clients are often flexible to accommodate the right instructor.'
                              }
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Not accepting clients */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: -15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'not accepting clients'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Not accepting clients */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {"I'm not currently accepting new clients"}
                        </Text>
                      </View>
                    </View>
                    {/* Distance */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: -15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'distance'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Distance */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {"It's too far"}
                        </Text>
                      </View>
                    </View>
                    {/* Pay */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: -15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'pay'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Pay */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {'Pay is not enough'}
                        </Text>
                      </View>
                    </View>
                    {/* Request Amount Block */}
                    <>
                      {!(radioButtonGroupValue === 'pay') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Gold #FFC400'],
                              borderRadius: 8,
                              marginBottom: 15,
                              marginLeft: '5.35%',
                              paddingBottom: 8,
                              paddingLeft: '9%',
                              paddingRight: '9%',
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 6 },
                              dimensions.width
                            )}
                          >
                            {/* What pay would you accept */}
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.text.strong,
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {
                                'Optional: what pay would you accept to teach this package?'
                              }
                            </Text>
                          </View>
                          {/* View Negotiated pay */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'column', paddingBottom: 6 },
                              dimensions.width
                            )}
                          >
                            {/* View Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['Custom Color'],
                                    borderBottomLeftRadius: 8,
                                    borderTopLeftRadius: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* $ */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.text.strong,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 14,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'$'}
                                </Text>
                              </View>

                              <View>
                                {/* NegotiatedPay */}
                                <NumberInput
                                  changeTextDelay={500}
                                  onChangeText={newNegotiatedPayValue => {
                                    try {
                                      setNumberInputValue2(
                                        newNegotiatedPayValue
                                      );
                                      setSpInstructorBid(newNegotiatedPayValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  onFocus={() => {
                                    try {
                                      amplitudeEvents(
                                        Variables,
                                        undefined,
                                        undefined,
                                        undefined
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  webShowOutline={true}
                                  clearTextOnFocus={true}
                                  editable={true}
                                  maxLength={4}
                                  placeholder={'Enter bid'}
                                  placeholderTextColor={
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ]
                                  }
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Custom Color'],
                                      borderBottomRightRadius: 8,
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.border.brand,
                                      borderLeftWidth: 1,
                                      borderRightWidth: 1,
                                      borderTopRightRadius: 8,
                                      borderTopWidth: 1,
                                      fontFamily: 'Poppins_400Regular',
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                      textAlign: 'right',
                                      width: 100,
                                    },
                                    dimensions.width
                                  )}
                                  value={numberInputValue2}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Student */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: -15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'student'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Student */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {'Student level/age not for me'}
                        </Text>
                      </View>
                    </View>
                    {/* Lesson length */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginTop: -15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Radio Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: '-85%', width: '100%', zIndex: 2 },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={palettes.App['App Blue#4 007fd4']}
                          direction={'row-reverse'}
                          label={Constants['empty']}
                          unselectedColor={palettes.App['App Black 191F32']}
                          value={'lesson-length'}
                        />
                      </View>
                      {/* Reason */}
                      <View>
                        {/* Student */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['primary14'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['primary14'].style,
                            dimensions.width
                          )}
                        >
                          {'Lessons are too long or too short'}
                        </Text>
                      </View>
                    </View>
                  </RadioButtonGroup>
                </View>
                {/* View Request and Decline Buttons */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View Reason is Schedule */}
                  <>
                    {!(radioButtonGroupValue === 'schedule') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Request With Alt Sched */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setRadioButtonGroupValue(Constants['empty']);
                              setGlobalVariableValue({
                                key: 'showModalDeclineSurvey',
                                value: false,
                              });
                              navigation.navigate(
                                'OpportunityDetailsPreloadedScreen',
                                {
                                  studentsDetailsAdditional: spStudentsNotes,
                                  specialSchedulingRequests:
                                    spAvailabilityNotes,
                                  endDate: spEndDate,
                                  poolType: spPoolType,
                                  hasPoolAccess: spHasPoolAccess,
                                  startDate: extractDateFromString(spStartDate),
                                  availabilityNotes: spAvailabilityNotes,
                                  clientOpenToAlernateSchedule:
                                    convertYesNoToBoolean(
                                      spClientOpenToAlternativeSched
                                    ),
                                  packageId: extractPackageId(spPackageId),
                                  addressClient: concatAddress(spAddressClient),
                                  availabilityTimesWeekDay: joinArray(
                                    spAvailabilityTimesWeekDays
                                  ),
                                  availabilityTimesWeekEnd: joinArray(
                                    spAvailabilityTimesWeekEnds
                                  ),
                                  clientName: spClientName,
                                  distance: spDistance,
                                  lessonsPerWeek: spLessonsPerWeek,
                                  lessonsLength: spLessonsLength,
                                  lessonsCount: spLessonsCount,
                                  payBonus: spPayBonus,
                                  payPerLesson: spPayPerLesson,
                                  packageStatus: spPackageStatus,
                                  availabilityDays: spAvailabilityDays,
                                  payBase: spPayBase,
                                  studentsNotes: spStudentsNotes,
                                  currentlyBookedSi: spCurrentlyBookedSi,
                                  studentsDetails: spStudentsDetails,
                                  requestAltSchedule: true,
                                }
                              );
                              amplitudeEvents(
                                Variables,
                                'view open job details',
                                {
                                  step: '02',
                                  view: 'jobs list view',
                                  funnel: 'request job',
                                  origin: 'open jobs screen',
                                  source: 'decline survey',
                                  category: 'opportunity management',
                                  exitPath: 'alternate schedule',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Gold #FFC400'],
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Sun Orange F4A100'],
                              borderLeftWidth: 1,
                              borderRadius: 50,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              marginBottom: 10,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Request With Alt Schedule'}
                        />
                        {/* View Activity Indicator */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  marginTop: -50,
                                  opacity: 0.8,
                                  paddingBottom: 7,
                                  paddingTop: 7,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants['is_loading'] ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    color={palettes.App['Custom Color']}
                                    style={StyleSheet.applyWidth(
                                      { height: 36, width: 36 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Reason is Pay & Pay Filled */}
                  <>
                    {!(radioButtonGroupValue === 'pay') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* View Details With Higher Pay */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setRadioButtonGroupValue(Constants['empty']);
                              setGlobalVariableValue({
                                key: 'showModalDeclineSurvey',
                                value: false,
                              });
                              navigation.navigate(
                                'OpportunityDetailsPreloadedScreen',
                                {
                                  instructorBid: spInstructorBid,
                                  studentsDetailsAdditional:
                                    spStudentsDetailsAdditional,
                                  specialSchedulingRequests:
                                    spSpecialSchedulingRequests,
                                  endDate: spEndDate,
                                  poolType: spPoolType,
                                  hasPoolAccess: spHasPoolAccess,
                                  startDate: extractDateFromString(spStartDate),
                                  availabilityNotes: spAvailabilityNotes,
                                  clientOpenToAlernateSchedule:
                                    convertYesNoToBoolean(
                                      spClientOpenToAlternativeSched
                                    ),
                                  packageId: extractPackageId(spPackageId),
                                  addressClient: concatAddress(spAddressClient),
                                  availabilityTimesWeekDay: joinArray(
                                    spAvailabilityTimesWeekDays
                                  ),
                                  availabilityTimesWeekEnd: joinArray(
                                    spAvailabilityTimesWeekEnds
                                  ),
                                  clientName: spClientName,
                                  distance: spDistance,
                                  lessonsPerWeek: spLessonsPerWeek,
                                  lessonsLength: spLessonsLength,
                                  lessonsCount: spLessonsCount,
                                  payBonus: spPayBonus,
                                  payPerLesson: spPayPerLesson,
                                  packageStatus: spPackageStatus,
                                  availabilityDays: spAvailabilityDays,
                                  payBase: spPayBase,
                                  studentsNotes: spStudentsNotes,
                                  currentlyBookedSi: spCurrentlyBookedSi,
                                  studentsDetails: spStudentsDetails,
                                }
                              );
                              amplitudeEvents(
                                Variables,
                                'view open job details',
                                {
                                  step: '02',
                                  view: 'jobs list view',
                                  funnel: 'request job',
                                  origin: 'open jobs screen',
                                  source: 'decline survey',
                                  category: 'opportunity management',
                                  exitPath: 'job bid',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Gold #FFC400'],
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Sun Orange F4A100'],
                              borderLeftWidth: 1,
                              borderRadius: 50,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              marginBottom: 10,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'View Details With Higher Pay'}
                        />
                        {/* View Activity Indicator */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  marginTop: -50,
                                  opacity: 0.8,
                                  paddingBottom: 7,
                                  paddingTop: 7,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants['is_loading'] ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    color={palettes.App['Custom Color']}
                                    style={StyleSheet.applyWidth(
                                      { height: 36, width: 36 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Decline Package Disabled */}
                  <>
                    {radioButtonGroupValue ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Decline Package'}
                      />
                    )}
                  </>
                  {/* View Reason is Pay & Pay Filled */}
                  <>
                    {!(radioButtonGroupValue === 'pay') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '60%' },
                          dimensions.width
                        )}
                      >
                        {/* Decline Package Enabled RED Small */}
                        <>
                          {!numberInputValue ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const result =
                                      await sendDeclinePackageNotice(
                                        Variables,
                                        setGlobalVariableValue,
                                        Constants['gInstructorEmailAddress'],
                                        spPackageId &&
                                          spPackageId[0].identifier,
                                        radioButtonGroupValue
                                      );
                                    if (!result) {
                                      return;
                                    }
                                    addPackageToExcludeList(
                                      Variables,
                                      setGlobalVariableValue,
                                      spPackageId && spPackageId[0].id
                                    );
                                    amplitudeEvents(
                                      Variables,
                                      'decline job submit',
                                      {
                                        step: '03',
                                        view: 'jobs list view',
                                        funnel: 'decline job',
                                        origin: 'open jobs screen',
                                        category: 'opportunity management',
                                      },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 18,
                                  paddingBottom: 14,
                                  paddingTop: 14,
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Decline Package'}
                            />
                          )}
                        </>
                        {/* View Activity Indicator */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  marginTop: -50,
                                  opacity: 0.8,
                                  paddingBottom: 7,
                                  paddingTop: 7,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants['is_loading'] ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    color={palettes.App['Custom Color']}
                                    style={StyleSheet.applyWidth(
                                      { height: 36, width: 36 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Reason Not Pay and Reason Filled */}
                  <>
                    {!radioButtonGroupValue ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Decline Package Enabled RED */}
                        <>
                          {!(radioButtonGroupValue !== 'pay') ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const result =
                                      await sendDeclinePackageNotice(
                                        Variables,
                                        setGlobalVariableValue,
                                        Constants['gInstructorEmailAddress'],
                                        spPackageId &&
                                          spPackageId[0].identifier,
                                        radioButtonGroupValue
                                      );
                                    if (!result) {
                                      return;
                                    }
                                    addPackageToExcludeList(
                                      Variables,
                                      setGlobalVariableValue,
                                      spPackageId && spPackageId[0].id
                                    );
                                    amplitudeEvents(
                                      Variables,
                                      'decline job submit',
                                      {
                                        step: '03',
                                        view: 'jobs list view',
                                        funnel: 'decline job',
                                        origin: 'open jobs screen',
                                        category: 'opportunity management',
                                      },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 18,
                                  paddingBottom: 14,
                                  paddingTop: 14,
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Decline Package'}
                            />
                          )}
                        </>
                        {/* View Activity Indicator */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  marginTop: -50,
                                  opacity: 0.8,
                                  paddingBottom: 7,
                                  paddingTop: 7,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!Constants['is_loading'] ? null : (
                                  <ActivityIndicator
                                    animating={true}
                                    hidesWhenStopped={true}
                                    size={'small'}
                                    color={palettes.App['Custom Color']}
                                    style={StyleSheet.applyWidth(
                                      { height: 36, width: 36 },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Reason is Pay & Pay Empty */}
                  <>
                    {!(radioButtonGroupValue === 'pay') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Decline Package Enabled RED Large */}
                        <>
                          {numberInputValue2 ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    const result =
                                      await sendDeclinePackageNotice(
                                        Variables,
                                        setGlobalVariableValue,
                                        Constants['gInstructorEmailAddress'],
                                        spPackageId &&
                                          spPackageId[0].identifier,
                                        radioButtonGroupValue
                                      );
                                    if (!result) {
                                      return;
                                    }
                                    addPackageToExcludeList(
                                      Variables,
                                      setGlobalVariableValue,
                                      spPackageId && spPackageId[0].id
                                    );
                                    amplitudeEvents(
                                      Variables,
                                      'decline job submit',
                                      {
                                        step: '03',
                                        view: 'jobs list view',
                                        funnel: 'decline job',
                                        origin: 'open jobs screen',
                                        category: 'opportunity management',
                                      },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 18,
                                  paddingBottom: 14,
                                  paddingTop: 14,
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Decline Package'}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Decline Success */}
      <>
        {!Constants['showModalDeclineSuccess'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendDeclinePackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendDeclinePackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['sendDeclinePackageResponse']}
                  {
                    '. Great job! Request or decline more packages near you now.'
                  }
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Continue */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      resetSendDeclinePackageResponse(
                        Variables,
                        setGlobalVariableValue
                      );
                      amplitudeEvents(
                        Variables,
                        'view open jobs list',
                        {
                          step: '01',
                          view: 'decline modal success',
                          funnel: 'request job',
                          origin: 'open jobs screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Continue'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal API Error Message */}
      <>
        {!(Constants['sendDeclinePackageResponse'] === 'Error') ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable>
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Uh oh, there was an error. Please close and try again.'}
                </Text>
              </View>
              {/* View Message body */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Error message */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Error message: '}
                  {Constants['sendDeclinePackageResponse']}
                  {'. The Integromat scenario is off.'}
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close and retry */}
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close and retry'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Pause Jobs Page */}
      <>
        {!Constants['pauseJobsPageTimer'] ? null : (
          <Modal
            animationType={'none'}
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            presentationStyle={'fullScreen'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Blue C7EAF3'],
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Header Container */}
              <View
                {...GlobalStyles.ViewStyles(theme)['welcome page'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['welcome page'].style,
                    { height: '58%' }
                  ),
                  dimensions.width
                )}
              >
                <ImageBackground
                  resizeMode={'cover'}
                  source={imageSource(Images['OnboardingWelcome'])}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'center',
                      height: '100%',
                      justifyContent: 'flex-end',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Greeting Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: '8%', width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Welcome */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.text.strong,
                          fontFamily: 'BebasNeue_400Regular',
                          fontSize: 24,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {"WE'RE UPDATING YOUR NEARBY JOB OPPORTUNITIES"}
                    </Text>
                  </View>
                </ImageBackground>
              </View>
              {/* View Body Container */}
              <View
                {...GlobalStyles.ViewStyles(theme)['welcome page body'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['welcome page body'].style,
                    {
                      backgroundColor: palettes.App['App Blue/Low Emphasis'],
                      borderRadius: 18,
                      height: '42%',
                      paddingBottom: '10.6%',
                      paddingLeft: '5.34%',
                      paddingRight: '5.34%',
                      paddingTop: '6.3%',
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* View h4 Container */}
                <>
                  {!Constants['empty'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: '2%', width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Text h4 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: theme.colors.text.strong,
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            textTransform: 'none',
                          },
                          dimensions.width
                        )}
                      >
                        {'This screen will be back in'}
                      </Text>
                    </View>
                  )}
                </>
                {/* View h1 Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: '2%' },
                    dimensions.width
                  )}
                >
                  {/* Text h1 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Red EB3E26'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 32,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {Constants['pauseJobsPageTimer']}
                  </Text>
                </View>
                {/* View Body Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: '5%' },
                    dimensions.width
                  )}
                >
                  {/* Text body */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "You'll be able to view, decline and request jobs when this process is complete. Thank you!"
                    }
                  </Text>
                </View>
                {/* View Button */}
                <>
                  {!(Constants['pauseJobsPageTimer'] === true) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 32,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Let's Go */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'pauseJobsPageTimer',
                              value: false,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '50%',
                          },
                          dimensions.width
                        )}
                        title={"Let's Go!"}
                      />
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(JobsScreen);
