// Get unique packages from a list of lessons
const getUniquePackagesFromLessonsList = allLessons => {
  if (!allLessons) {
    return;
  }

  const uniquePackages = [];
  const packages = {};
  const map = new Map();

  for (const lesson of allLessons) {
    let packageId = lesson.field_58_raw[0].identifier;
    let lessonNumber = lesson.field_320_raw;
    let lessonStatus = lesson.field_54;
    let lessonPaymentStatus = lesson.field_534;
    let lessonPay = lesson.field_69_raw;
    let lessonScheduleDate = lesson.field_202;
    let lessonScheduleDateUnixTimestamp = lesson.field_202_raw?.unix_timestamp;
    let lessonCompletionDate = lesson.field_205;
    let lessonMatchingDate = lesson.field_312;

    if (!map.has(packageId)) {
      map.set(packageId);
      packages.packageId = {
        scheduledLessons: [],
        completedLessons: [],
        cancelledLessons: [],
        lessonsCompleted: 0,
        lessonsCancelled: 0,
        totalLessons: 0,
        paidLessons: 0,
        amountPaid: 0,
        payBonus: 0,
        totalPay: 0,
        completeness: 0,
        packageId,
        client: lesson.field_455_raw,
        poolAddress: lesson.field_98_raw,
        packageKnackId: lesson.field_58_raw[0].id,
        packageStatus: lesson.field_231_raw,
        clientAvatar: lesson.field_2415_raw,
        matchingDate: new Date('1970-01-01'),
        nextScheduledLesson: new Date('1970-01-01'),
      };

      uniquePackages.push(packages.packageId);
    }

    if (lessonStatus === 'Completed' && lessonNumber > 0) {
      packages.packageId.lessonsCompleted++;
      packages.packageId.completedLessons.push({
        lessonNumber,
        lessonCompletionDate,
      });
    }

    if (lessonStatus === 'Cancelled ' && lessonNumber > 0) {
      packages.packageId.lessonsCancelled++;
      packages.packageId.cancelledLessons.push({
        lessonNumber,
        lessonCompletionDate,
      });
    }

    if (lessonPaymentStatus === 'Paid') {
      packages.packageId.paidLessons++;
      packages.packageId.amountPaid += lessonPay;
    }

    if (lessonNumber > 0) {
      packages.packageId.totalLessons++;
    } else {
      packages.packageId.payBonus += lessonPay;
    }

    packages.packageId.totalPay += lessonPay;
    packages.packageId.scheduledLessons.push({
      lessonNumber,
      // scheduledDate: lessonScheduleDate ?? new Date(lessonScheduleDate),
      scheduledDateTimeString: lessonScheduleDate ?? null,
      scheduledDateUnixTimestamp: lessonScheduleDateUnixTimestamp ?? null,
      lessonStatus,
    });

    if (lessonMatchingDate) {
      packages.packageId.matchingDate =
        new Date(lessonMatchingDate) > packages.packageId.matchingDate
          ? new Date(lessonMatchingDate)
          : packages.packageId.matchingDate;
    }

    if (lessonScheduleDate) {
      packages.packageId.nextScheduledLesson =
        new Date(lessonScheduleDate) > packages.packageId.nextScheduledLesson
          ? new Date(nextScheduledLesson)
          : packages.packageId.nextScheduledLesson;
    }
  }

  for (const lessonPackage of uniquePackages) {
    lessonPackage.scheduledLessons.sort(
      (a, b) => a.lessonNumber - b.lessonNumber
    );
    lessonPackage.completeness = Math.round(
      (lessonPackage.lessonsCompleted / lessonPackage.totalLessons) * 100
    );
  }

  return uniquePackages;
};

export default getUniquePackagesFromLessonsList;
