import React from 'react';
import {
  Button,
  Link,
  SVG,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as MakeApi from '../apis/MakeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import openNativeEmailClient from '../global-functions/openNativeEmailClient';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { skip: '' };

const Onboarding3CompleteTipaltiFormsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cprUploaded, setCprUploaded] = React.useState(false);
  const [shouldShowTipaltiIncomplete, setShouldShowTipaltiIncomplete] =
    React.useState(false);
  const [showCheckmarkIcon, setShowCheckmarkIcon] = React.useState(false);
  const [showOpenEmailClient, setShowOpenEmailClient] = React.useState(false);
  const [tipaltiErrorMessage, setTipaltiErrorMessage] = React.useState('');
  const [tipaltiSetupStep, setTipaltiSetupStep] = React.useState('request');
  const [tipaltiSetupSteps, setTipaltiSetupSteps] = React.useState([
    'request',
    'creating',
    'failed',
    'invited',
    'ready',
  ]);
  const shouldSkipIncompleteNotice = () => {
    const {
      gCprImage,
      gBioImage,
      gBioText,
      gTipaltiComplete,
      gSunsational101,
    } = Variables;

    if (
      gCprImage == '' ||
      gBioImage == '' ||
      gBioText == '' ||
      gSunsational101 == '' ||
      gTipaltiComplete == false
    ) {
      return false;
    }

    return true;
  };

  const navOnboardingNext = () => {
    const {
      gBioImage,
      gBioText,
      gCprImage,
      gTipaltiComplete,
      gSunsational101Complete,
      onboardingSteps,
    } = Variables;

    if (!gSunsational101Complete) {
      props.navigation.navigate('OnboardingCompleteSunsational101Screen');
    }

    if (
      gCprImage == '' ||
      gBioImage == '' ||
      gBioText == '' ||
      gTipaltiComplete == false
    ) {
      props.navigation.navigate('Onboard4IncompleteWarningScreen');
    }

    props.navigation.navigate('HomeScreen');
  };

  const shouldSkipSunsational101Screen = () => {
    const {
      gCprImage,
      gBioImage,
      gBioText,
      gTipaltiComplete,
      gSunsational101Complete,
    } = Variables;

    if (gSunsational101Complete) {
      return true;
    }

    return false;
  };

  const showDoneAnimation = () => {
    setShowCheckmarkIcon(true);

    // setTimeout(() => {
    //     setShowCheckmarkIcon(false)
    // }, 3000)
  };

  const handleCheckTipaltiStatus = (setGlobalVariableValue, response) => {
    const { isPayable } = response;

    if (!isPayable) {
      setTipaltiSetupStep('incomplete');
    }

    if (isPayable) {
      setTipaltiSetupStep('ready');
      showDoneAnimation();
    }

    setGlobalVariableValue({
      key: 'is_loading',
      value: false,
    });

    setGlobalVariableValue({
      key: 'gTipaltiComplete',
      value: isPayable,
    });
  };

  const handleCreateTipaltiAccountResponse = response => {
    console.log('response ==== ', response);

    if (response == 'undefined' || !response?.success) {
      let errorMessage = `It looks like our servers couldn't finish that lap. Please retry the request.

    If the problem continues, please contact us. You can also skip this step and come back to it later.`;

      if (response.message === 'PayeeAlreadyInvited') {
        errorMessage = `An invite has previously been sent to your account. Please search your email for "Tipalti". If an invite can't be found, please contact us office@sunsationalswimschool.com`;
      }

      if (response.message === 'PayeeAlreadyRegistered') {
        errorMessage = `Our system has detected you have previously registered a Tipalti account. Please visit https://suppliers.tipalti.com/SunsationalSwimSchool/Account/Login to login or reset your password.`;
      }

      setTipaltiErrorMessage(errorMessage);
      setTipaltiSetupStep('failed');
      return;
    }

    setTipaltiSetupStep('invited');
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Onboarding3CompleteTipaltiFormsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!(props.route?.params?.skip ?? defaultProps.skip)) {
        return;
      }
      navigation.navigate('OnboardingCompleteSunsational101Screen', {
        skip: shouldSkipSunsational101Screen(),
      });
      setGlobalVariableValue({
        key: 'empty',
        value: Constants['empty'],
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: '10%',
            justifyContent: 'center',
            maxHeight: '11%',
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Complete Profile'}
            </Text>
          </View>
          {/* View Skip Container */}
          <View>
            {/* Skip */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate(
                    'OnboardingCompleteSunsational101Screen',
                    { skip: shouldSkipSunsational101Screen() }
                  );
                  amplitudeEvents(
                    Variables,
                    'skip tipalti setup',
                    {
                      step: '03',
                      funnel: 'onboarding',
                      origin: 'complete tipalti screen',
                      category: 'onboarding',
                    },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={'SKIP'}
            />
          </View>
        </View>
      </View>
      {/* View Card Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            height: '90%',
            justifyContent: 'space-between',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Card Title Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingTop: 22, width: '100%' },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Black 191F32'],
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Setup Direct Deposit'}
          </Text>
        </View>
        {/* Content - Request Account */}
        <>
          {!(tipaltiSetupStep === 'request') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  padding: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={imageSource(Images['LogoTipalti'])}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "Setup your direct deposit through our secure vendor, Tipalti. We use Tipalti to issue direct deposits to your preferred bank. Tap 'Create Tipalti Account' to receive an invite to setup your account."
                    }
                  </Text>
                </View>
                {/* View Finish On Tipalti Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Create Tipalti Account */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setTipaltiSetupStep('creating');
                          const response = (
                            await MakeApi.requestTipaltiInvitePOST(Constants, {
                              AUTH_KNACK_USER_ID:
                                Constants['AUTH_KNACK_USER_ID'],
                            })
                          )?.json;
                          handleCreateTipaltiAccountResponse(response);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '70%',
                      },
                      dimensions.width
                    )}
                    title={'Create Tipalti Account'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Content - Request Failed */}
        <>
          {!(tipaltiSetupStep === 'failed') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  padding: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={imageSource(Images['LogoTipalti'])}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  {/* Uh oh */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Uh oh, something went wrong'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {tipaltiErrorMessage}
                  </Text>
                </View>
                {/* View Finish On Tipalti Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Retry */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setTipaltiSetupStep('creating');
                          const response = (
                            await MakeApi.requestTipaltiInvitePOST(Constants, {
                              AUTH_KNACK_USER_ID:
                                Constants['AUTH_KNACK_USER_ID'],
                            })
                          )?.json;
                          handleCreateTipaltiAccountResponse(response);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Black 191F32'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '70%',
                      },
                      dimensions.width
                    )}
                    title={'Retry'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Content - Loading Creating Account */}
        <>
          {!(tipaltiSetupStep === 'creating') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  padding: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={imageSource(Images['LogoTipalti'])}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  {/* Creating Your Account... */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Creating Your Account . .'}
                  </Text>

                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'One moment while we swim some data to Tipalti. Swimming: fast'
                    }
                  </Text>
                </View>
                {/* Loading Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    size={'large'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Finish On Tipalti Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Create Tipalti Account */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const response = (
                            await MakeApi.requestTipaltiInvitePOST(Constants, {
                              AUTH_KNACK_USER_ID:
                                Constants['AUTH_KNACK_USER_ID'],
                            })
                          )?.json;
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    loading={tipaltiSetupStep === 'creating'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '70%',
                      },
                      dimensions.width
                    )}
                    title={'Creating Account'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Content - Invite Sent Success */}
        <>
          {!(tipaltiSetupStep === 'invited') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  padding: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={imageSource(Images['LogoTipalti'])}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  {/* Invite Sent */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Invite Sent!'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'We sent an email to your inbox at '}
                    {Constants['gInstructorEmailAddress']}
                    {
                      ". Use the link in your email to complete your Tipalti account setup.\n\nAfter you complete your Tipalti account setup, return here and tap 'Done' to check your Tipalti account status."
                    }
                  </Text>
                </View>
                {/* View Finish On Tipalti Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Open Email Client */}
                  <>
                    {!showOpenEmailClient ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setShowOpenEmailClient(false);
                              await openNativeEmailClient();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Open Email Client'}
                      />
                    )}
                  </>
                  {/* Done */}
                  <>
                    {showOpenEmailClient ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              const response = (
                                await MakeApi.checkTipaltiReadyGET(Constants, {
                                  auth_knack_id:
                                    Constants['AUTH_KNACK_USER_ID'],
                                })
                              )?.json;
                              const showTipaltiIncomplete =
                                handleCheckTipaltiStatus(
                                  setGlobalVariableValue,
                                  response
                                );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        loading={Constants['is_loading']}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderColor: palettes.App['App Sun Orange F4A100'],
                            borderRadius: 50,
                            borderWidth: 2,
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Done'}
                      />
                    )}
                  </>
                </View>
                {/* Re-send invite */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                ></View>
              </View>
            </View>
          )}
        </>
        {/* Content - Incomplete */}
        <>
          {!(tipaltiSetupStep === 'incomplete') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  padding: 16,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={imageSource(Images['LogoTipalti'])}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  {/* Incomplete */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Account Setup Not Finished'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "It doesn't look like you've fully set up your Tipalti account. You won't be able to receive payment until set up is completed."
                    }
                  </Text>
                </View>
                {/* View Finish On Tipalti Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* OK */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate(
                          'OnboardingCompleteSunsational101Screen',
                          { skip: shouldSkipSunsational101Screen() }
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    loading={Constants['is_loading']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '50%',
                      },
                      dimensions.width
                    )}
                    title={'OK'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Re-send invite */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                ></View>
              </View>
            </View>
          )}
        </>
        {/* Content - Ready */}
        <>
          {!(tipaltiSetupStep === 'ready') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderStyle: 'dashed',
                  borderTopWidth: 1,
                  height: '70%',
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', padding: 16, width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Tipalti Icon Container */}
                <>
                  {showCheckmarkIcon ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderRadius: 0,
                          height: 40,
                          justifyContent: 'center',
                          marginBottom: 20,
                          width: 79,
                        },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'cover'}
                        source={imageSource(Images['LogoTipalti'])}
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                {/* Complete Checkmark Container */}
                <>
                  {!showCheckmarkIcon ? null : (
                    <View>
                      {/* Complete checkmark */}
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          `${Constants['uiAnimationCheckmarkDone']}`
                        )}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  {/* Complete */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        marginBottom: 10,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Account Setup Complete!'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'You have successfully setup your Tipalti account. Your account is now ready to receive payments.'
                    }
                  </Text>
                </View>
                {/* Re-send invite */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                ></View>
              </View>
            </View>
          )}
        </>
        {/* View Bottom CTA Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Left Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Step Text Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 8 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Step 3 of 4'}
              </Text>
            </View>
            {/* View Progress Bar Container */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, flexDirection: 'row', width: '100%' },
                dimensions.width
              )}
            >
              {/* View 1st fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '25%',
                  },
                  dimensions.width
                )}
              />
              {/* View 2nd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '25%',
                  },
                  dimensions.width
                )}
              />
              {/* View 3rd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '25%',
                  },
                  dimensions.width
                )}
              />
              {/* View 4th fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* View Right Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Sign In Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Next Disabled */}
              <>
                {Constants['gTipaltiComplete'] ? null : (
                  <Button
                    iconPosition={'left'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 50,
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Next'}
                  />
                )}
              </>
              {/* Next Enabled */}
              <>
                {!Constants['gTipaltiComplete'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate(
                          'OnboardingCompleteSunsational101Screen',
                          { skip: shouldSkipSunsational101Screen() }
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    loading={Constants['is_loading']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Next'}
                  />
                )}
              </>
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding3CompleteTipaltiFormsScreen);
