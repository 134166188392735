import * as Libs from '../Libs';

const amplitudeEvents = (Variables, eventName, eventProperties, option) => {
  // const mode = 'test'
  // if (mode === 'test') {
  //     return
  // }
  //Amplitude_Init options = initialized, initializedUser, uninitialized

  function determineIncompleteAccountElements(event) {
    let result = '';
    let missingBio = Variables.gBioText?.length < 26;
    let missingPhoto = Variables.gBioImageUrl?.length < 6;
    let missingTipalti = !Variables.gTipaltiComplete;

    if (missingBio) {
      result += 'writeup ';
    }

    if (missingPhoto) {
      result += 'photo ';
    }

    if (event === 'skip incomplete account warning') {
      if (missingTipalti) {
        result += 'tipalti ';
      }
    }

    return result.trim();
  }

  if (eventName === 'view open jobs list') {
    eventProperties['openJobsCount'] = option;
  }

  if (
    eventName === 'select decline reason' ||
    eventName === 'decline job submit'
  ) {
    eventProperties['declineReason'] = option;
  }

  if (eventName === 'sort open jobs') {
    eventProperties['sortOptions'] = option;
  }

  if (eventName === 'sort clients') {
    eventProperties['sortOptions'] = option;
  }

  if (eventName === 'skip profile setup') {
    eventProperties['incomplete'] = determineIncompleteAccountElements();
  }

  if (eventName === 'skip incomplete account warning') {
    eventProperties['incomplete'] = determineIncompleteAccountElements();
  }

  if (eventName === 'request job submit') {
    eventProperties['matchedClientSchedule'] = option;
  }

  // if (eventProperties.funnel === 'schedule lesson') {
  //     eventProperties['lessonNumber'] = option;
  // }

  // if (eventProperties.funnel === 'complete lesson') {
  //     eventProperties['lessonNumber'] = option;
  // }

  // if (eventName === 'filter open jobs') {
  //     eventProperties['filterOptions'] = option;
  // }

  try {
    Libs.Amplitude.init(Variables.gEnvironment);
    Libs.Amplitude.track(eventName, eventProperties);
  } catch (e) {
    console.log(e);
  }
};

export default amplitudeEvents;
