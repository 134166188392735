const sendDeclinePackageNotice = async (
  Variables,
  setGlobalVariableValue,
  siEmail,
  packageId,
  declineReason
) => {
  setGlobalVariableValue({
    key: 'is_loading',
    value: true,
  });

  const opportunityId2 = `${packageId}-${siEmail}`;

  const data = {
    siReponse: 'Declined',
    requestSource: 'app',
    opportunityId2,
    declineReason,
  };

  try {
    const sendDecline = await fetch(
      `https://hook.integromat.com/gejrlmg57qs16yk6xah44dim4fqx38pj`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    const sendDeclineResponse = await sendDecline.json();

    if (!sendDecline.ok) {
      console.log('REPONSE NOT OK BLOCK');
      // setGlobalVariableValue({
      //     key: 'authError',
      //     value: sendDecline.errorDescription
      // })

      setGlobalVariableValue({
        key: 'sendDeclinePackageResponse',
        value: sendDeclineResponse.message,
      });

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      throw new Error(
        `Error! status: ${sendDeclineResponse.errorsDescription}`
      );
    }

    setGlobalVariableValue({
      key: 'is_loading',
      value: false,
    });

    setGlobalVariableValue({
      key: 'showModalDeclineSurvey',
      value: false,
    });

    setGlobalVariableValue({
      key: 'sendDeclinePackageResponse',
      value: sendDeclineResponse.message,
    });

    setGlobalVariableValue({
      key: 'showModalDeclineSuccess',
      value: true,
    });

    return true;
  } catch (err) {
    console.error(`Error! ${err}`);
    return false;
  }
};

export default sendDeclinePackageNotice;
