import * as convertTime from '../custom-files/convertTime';

const knackFilterParamsAllLessonsInPackage = packageKnackId => {
  function FilterLessonsInPackage(packageKnackId) {
    this.field = 'field_58';
    this.operator = 'is';
    this.value = packageKnackId;
  }

  const getDateXDaysAgo = () => {
    const currentDate = convertTime.moment();
    const dateXDaysAgo = currentDate.subtract(365, 'days');
    return dateXDaysAgo.toDate();
  };

  function FilterLessonBookingDate() {
    this.field = 'field_312';
    this.operator = 'is after';
    this.value = getDateXDaysAgo();
  }

  function FilterPackageStatusNotCompleted() {
    this.field = 'field_231';
    this.operator = 'is not';
    this.value = 'Completed';
  }

  function FilterPackageStatusNotPaused() {
    this.field = 'field_231';
    this.operator = 'is not';
    this.value = 'Paused';
  }

  function FilterLessonStatusNotUnbooked() {
    this.field = 'field_54';
    this.operator = 'is not';
    this.value = 'Unbooked';
  }

  let match = 'and';
  const rules = [];

  rules.push(new FilterLessonsInPackage(packageKnackId));
  rules.push(new FilterLessonStatusNotUnbooked());
  // rules.push(new FilterPackageStatusNotCompleted());
  // rules.push(new FilterPackageStatusNotPaused());
  // rules.push(new FilterLessonBookingDate());

  const filterRules = {
    match,
    rules,
  };

  return filterRules;
};

export default knackFilterParamsAllLessonsInPackage;
