import * as convertTime from '../custom-files/convertTime';

const convertTimeToLocalMachine = (timestring, inputFormat, finalFormat) => {
  if (!timestring) {
    return;
  }

  const formattedTimeString = convertTime.toLocalMachine(
    timestring,
    inputFormat,
    finalFormat
  );

  return formattedTimeString;
};

export default convertTimeToLocalMachine;
