import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as GlobalVariables from './config/GlobalVariableContext';
import AddPoolScreen from './screens/AddPoolScreen';
import ChangePasswordScreen from './screens/ChangePasswordScreen';
import ClientDetailsScreen from './screens/ClientDetailsScreen';
import Clients2Screen from './screens/Clients2Screen';
import EditProfileScreen from './screens/EditProfileScreen';
import HomeScreen from './screens/HomeScreen';
import JobPreferencesScreen from './screens/JobPreferencesScreen';
import JobsScreen from './screens/JobsScreen';
import KnowledgeBaseArticleScreen from './screens/KnowledgeBaseArticleScreen';
import KnowledgeBaseScreen from './screens/KnowledgeBaseScreen';
import LessonDetailsAndManagementScreen from './screens/LessonDetailsAndManagementScreen';
import Login2Screen from './screens/Login2Screen';
import LoginHelpScreen from './screens/LoginHelpScreen';
import MyAccountScreen from './screens/MyAccountScreen';
import MyAddressScreen from './screens/MyAddressScreen';
import NotificationSettingsScreen from './screens/NotificationSettingsScreen';
import Onboard4IncompleteWarningScreen from './screens/Onboard4IncompleteWarningScreen';
import Onboarding0WelcomeScreen from './screens/Onboarding0WelcomeScreen';
import Onboarding1UploadCPRScreen from './screens/Onboarding1UploadCPRScreen';
import Onboarding2InstructorBioScreen from './screens/Onboarding2InstructorBioScreen';
import Onboarding3CompleteTipaltiFormsScreen from './screens/Onboarding3CompleteTipaltiFormsScreen';
import OnboardingCompleteSunsational101Screen from './screens/OnboardingCompleteSunsational101Screen';
import OnboardingGetPushTokenScreen from './screens/OnboardingGetPushTokenScreen';
import OpportunityDetailsLiveScreen from './screens/OpportunityDetailsLiveScreen';
import OpportunityDetailsPreloadedScreen from './screens/OpportunityDetailsPreloadedScreen';
import PoolAccessSettingsScreen from './screens/PoolAccessSettingsScreen';
import ResetpasswordScreen from './screens/ResetpasswordScreen';
import ResetpasswordsuccessScreen from './screens/ResetpasswordsuccessScreen';
import SessionExpiredScreen from './screens/SessionExpiredScreen';
import SettingsScreen from './screens/SettingsScreen';
import UpdateAppRequiredScreen from './screens/UpdateAppRequiredScreen';
import UpdateTermsAgreementRequiredScreen from './screens/UpdateTermsAgreementRequiredScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function BottomTabNavigator() {
  const theme = useTheme();

  const tabBarOrDrawerIcons = {};

  return (
    <Tab.Navigator
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarStyle: { borderTopColor: 'transparent' },
      })}
    ></Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="LoginScreen_EbEHbgWi"
        screenOptions={({ navigation }) => ({
          cardStyle: { flex: 1 },
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerShown: false,
          headerStyle: { backgroundColor: 'transparent' },
        })}
      >
        <Stack.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Home',
          })}
        />
        <Stack.Screen
          name="JobsScreen"
          component={JobsScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Jobs',
          })}
        />
        <Stack.Screen
          name="SettingsScreen"
          component={SettingsScreen}
          options={({ navigation }) => ({
            title: 'Settings',
          })}
        />
        <Stack.Screen
          name="Login2Screen"
          component={Login2Screen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="ResetpasswordScreen"
          component={ResetpasswordScreen}
          options={({ navigation }) => ({
            title: 'Reset password',
          })}
        />
        <Stack.Screen
          name="ResetpasswordsuccessScreen"
          component={ResetpasswordsuccessScreen}
          options={({ navigation }) => ({
            title: 'Reset password success',
          })}
        />
        <Stack.Screen
          name="LoginHelpScreen"
          component={LoginHelpScreen}
          options={({ navigation }) => ({
            title: 'Login Help',
          })}
        />
        <Stack.Screen
          name="Onboarding0WelcomeScreen"
          component={Onboarding0WelcomeScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Onboarding 0 - Welcome',
          })}
        />
        <Stack.Screen
          name="Onboarding1UploadCPRScreen"
          component={Onboarding1UploadCPRScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Onboarding 1 - Upload CPR',
          })}
        />
        <Stack.Screen
          name="Onboarding2InstructorBioScreen"
          component={Onboarding2InstructorBioScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Onboarding 2 - Instructor Bio',
          })}
        />
        <Stack.Screen
          name="Onboarding3CompleteTipaltiFormsScreen"
          component={Onboarding3CompleteTipaltiFormsScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Onboarding 3 - Complete Tipalti Forms',
          })}
        />
        <Stack.Screen
          name="Onboard4IncompleteWarningScreen"
          component={Onboard4IncompleteWarningScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Onboard 4  - Incomplete Warning',
          })}
        />
        <Stack.Screen
          name="MyAccountScreen"
          component={MyAccountScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'My Account',
          })}
        />
        <Stack.Screen
          name="JobPreferencesScreen"
          component={JobPreferencesScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Job Preferences',
          })}
        />
        <Stack.Screen
          name="PoolAccessSettingsScreen"
          component={PoolAccessSettingsScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Pool Access Settings',
          })}
        />
        <Stack.Screen
          name="AddPoolScreen"
          component={AddPoolScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Add Pool',
          })}
        />
        <Stack.Screen
          name="ChangePasswordScreen"
          component={ChangePasswordScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Change Password',
          })}
        />
        <Stack.Screen
          name="MyAddressScreen"
          component={MyAddressScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'My Address',
          })}
        />
        <Stack.Screen
          name="NotificationSettingsScreen"
          component={NotificationSettingsScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Notification Settings',
          })}
        />
        <Stack.Screen
          name="EditProfileScreen"
          component={EditProfileScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Edit Profile',
          })}
        />
        <Stack.Screen
          name="OpportunityDetailsPreloadedScreen"
          component={OpportunityDetailsPreloadedScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Opportunity Details Preloaded',
          })}
        />
        <Stack.Screen
          name="Clients2Screen"
          component={Clients2Screen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Clients 2',
          })}
        />
        <Stack.Screen
          name="ClientDetailsScreen"
          component={ClientDetailsScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Client Details',
          })}
        />
        <Stack.Screen
          name="LessonDetailsAndManagementScreen"
          component={LessonDetailsAndManagementScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Lesson Details And Management',
          })}
        />
        <Stack.Screen
          name="UpdateAppRequiredScreen"
          component={UpdateAppRequiredScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Update App Required',
          })}
        />
        <Stack.Screen
          name="SessionExpiredScreen"
          component={SessionExpiredScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Session Expired',
          })}
        />
        <Stack.Screen
          name="OpportunityDetailsLiveScreen"
          component={OpportunityDetailsLiveScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Opportunity Details Live',
          })}
        />
        <Stack.Screen
          name="UpdateTermsAgreementRequiredScreen"
          component={UpdateTermsAgreementRequiredScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Update Terms Agreement Required',
          })}
        />
        <Stack.Screen
          name="KnowledgeBaseScreen"
          component={KnowledgeBaseScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Knowledge Base',
          })}
        />
        <Stack.Screen
          name="KnowledgeBaseArticleScreen"
          component={KnowledgeBaseArticleScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Knowledge Base Article',
          })}
        />
        <Stack.Screen
          name="OnboardingCompleteSunsational101Screen"
          component={OnboardingCompleteSunsational101Screen}
          options={({ navigation }) => ({
            headerShown: false,
            headerStyle: { backgroundColor: 'transparent' },
            title: 'Onboarding - Complete Sunsational 101',
          })}
        />
        <Stack.Screen
          name="OnboardingGetPushTokenScreen"
          component={OnboardingGetPushTokenScreen}
          options={({ navigation }) => ({
            title: 'Onboarding - Get Push Token',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={({ navigation }) => ({
            title: 'Bottom Tab Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
