// Return a random avatar background.
const generateAvatarBg = Variables => {
  const { uiAvatars } = Variables;

  const randomIndex = Math.floor(Math.random() * uiAvatars.length);

  return uiAvatars[randomIndex];
};

export default generateAvatarBg;
