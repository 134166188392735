import React from 'react';
import {
  Button,
  KeyboardAvoidingView,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as GetDeviceInfo from '../custom-files/GetDeviceInfo';
import * as Sync from '../custom-files/Sync';
import * as Track from '../custom-files/Track';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const Login2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [inputEmailAddress, setInputEmailAddress] = React.useState('');
  const [inputPassword, setInputPassword] = React.useState('');
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const [loadingStatusMessage, setLoadingStatusMessage] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const userLogin = async (
    Variables,
    setGlobalVariableValue,
    email,
    password
  ) => {
    const ip = await GetDeviceInfo.getIpAddress();
    const deviceId = await GetDeviceInfo.getUniqueId();

    const {
      Constants,
      APP_ID,
      APP_VERSION,
      DEVICE_PUSH_TOKEN,
      onboardingSteps,
      gNotificationsAllOptions,
      travelRanges,
    } = Variables;

    const KNACK_API_BASE_URL = 'https://api.knack.com/v1';

    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });
    setGlobalVariableValue({
      key: 'authError',
      value: '',
    });

    setLoadingStatusMessage('Checking your swim credentials ... ');

    try {
      const data = {
        email: email,
        password: password,
        remember: true,
      };

      const login = await fetch(
        `${KNACK_API_BASE_URL}/applications/${APP_ID}/session`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const loginResponse = await login.json();

      if (!login.ok) {
        console.log('LOGIN RESPONSE NOT OK BLOCK');
        setGlobalVariableValue({
          key: 'authError',
          value: loginResponse.errors[0].message,
        });

        throw new Error(`Error! status: ${loginResponse.errors[0].message}`);
      }

      const getKnackUserID = profileRoles => {
        const swimInstructorRole = profileRoles.find(
          role => role.object === 'object_2'
        );
        return swimInstructorRole ? swimInstructorRole.entry_id : null;
      };

      const userFirstName = loginResponse.session.user.values.name.first;
      const knack_user_id = getKnackUserID(
        loginResponse.session.user.profile_objects
      );
      const auth_knack_token = loginResponse.session.user.token;

      setLoadingStatusMessage(`Hi ${userFirstName}! Lifeguard checking app..`);

      const authHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Knack-Application-Id': APP_ID,
        Authorization: auth_knack_token,
      };

      const userData = await fetch(
        `${KNACK_API_BASE_URL}/pages/scene_1144/views/view_2126/records/${knack_user_id}`,
        {
          method: 'GET',
          headers: authHeaders,
        }
      );

      const userDataResponse = await userData.json();

      if (!userData.ok) {
        console.log('REPONSE NOT OK BLOCK');
        setGlobalVariableValue({
          key: 'authError',
          value: userData.errors[0].message,
        });

        throw new Error(`Error! status: ${userData.errors[0].message}`);
      }

      const userAuthorizedToUseApp = userDataResponse.field_2412_raw;

      if (!userAuthorizedToUseApp) {
        setGlobalVariableValue({
          key: 'authError',
          value: 'This account is not authorized for app use.',
        });

        throw new Error(`This account is not authorized for app use.`);
      }

      setLoadingStatusMessage(`Gathering your swim gear...`);

      // const getSunsationalsCurrentAppVersion = await fetch(`${KNACK_API_BASE_URL}/pages/scene_1148/views/view_2131/records`, {
      //     method: 'GET',
      //     headers: authHeaders,
      // });

      // const sunsationalsCurrentAppVersionData = await getSunsationalsCurrentAppVersion.json();
      // const sunsationalsCurrentAppVersion = sunsationalsCurrentAppVersionData.records[0].id;

      // const userAppVersion = APP_VERSION.id;

      // setLoadingStatusMessage(`Finishing lap..`)

      // if (userAppVersion !== sunsationalsCurrentAppVersion) {
      //     setGlobalVariableValue({
      //         key: 'is_loading',
      //         value: false,
      //     });
      //     props.navigation.navigate('UpdateAppRequiredScreen');
      //     return;
      // }

      const {
        field_321_raw, // user profile image
        field_45_raw, // user biography text
        field_177_raw, // cpr image
        field_48, // cpr expiration date
        field_1366_raw, // tipalti status boolean
        field_1105_raw, // sunsational101 status boolean
        field_1845, // onboarding calls attended
        field_6, // user fullname
        field_46_raw, // user address object
        field_2386_raw, // notifications options array
        field_930_raw, // days
        field_931_raw, // weekdaytimes
        field_932_raw, // weekendtimes
        field_47, //travel range
        field_2387_raw, // instructor profile bio status
        field_1896, // number of pools access
        field_2432_raw, // instructor pause activity status
        field_2430_raw, // push notification tokens (string)
      } = userDataResponse;

      const fetchedUserNotificationsSettings = field_2386_raw;
      const gPushNotificationsSavedToServer = field_2430_raw;

      const shouldShowPushTokenRequestScreen = () => {
        if (Platform.OS === 'web') {
          return false;
        }
        if (!gPushNotificationsSavedToServer || !DEVICE_PUSH_TOKEN) {
          return true;
        }
        return false;
      };

      const onboardingData = {
        bioImage: field_321_raw ?? '',
        bioText: field_45_raw ?? '',
        instructorBioStatus: field_2387_raw,
        cprImage: field_177_raw?.url ?? '',
        tipaltiComplete: field_1366_raw,
        sunsational101Complete: field_1105_raw || field_1845 > 0,
        askForPushToken: false, //shouldShowPushTokenRequestScreen(),
        onboardingSteps: onboardingSteps,
      };

      const {
        bioImage,
        bioText,
        instructorBioStatus,
        cprImage,
        tipaltiComplete,
        sunsational101Complete,
        askForPushToken,
      } = onboardingData;

      const bioComplete = bioImage && bioText;
      const cprActive = new Date() <= new Date(field_48);

      if (!instructorBioStatus) {
        if (!bioImage || !bioText) {
          setGlobalVariableValue({
            key: instructorBioStatus,
            value: 'incomplete',
          });
        }

        if (bioImage && bioText) {
          setGlobalVariableValue({
            key: instructorBioStatus,
            value: 'under review',
          });
        }
      }

      const numberOfPoolsAccess = field_1896;

      if (numberOfPoolsAccess) {
        const getPoolDetails = await fetch(
          `${KNACK_API_BASE_URL}/pages/scene_31/views/view_2066/records`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Knack-Application-Id': APP_ID,
              Authorization: auth_knack_token,
            },
          }
        );

        if (!getPoolDetails.ok) {
          throw Error(`Error!`);
        }

        const getPoolDetailsResponse = await getPoolDetails.json();

        setGlobalVariableValue({
          key: 'gSiPools',
          value: getPoolDetailsResponse.records,
        });
      }

      const addressData = field_46_raw;

      const instructorAvailability = {
        days: field_930_raw ?? [],
        weekdayTimes: field_931_raw ?? [],
        weekendTimes: field_932_raw ?? [],
      };

      const fetchedTravelRange = field_47;

      const selectTravelRange = travelRange => {
        let selectedTravelRange;

        if (travelRange == 10) {
          selectedTravelRange = travelRanges[0];
        }

        if (travelRange == 25) {
          selectedTravelRange = travelRanges[1];
        }

        if (travelRange == 40) {
          selectedTravelRange = travelRanges[2];
        }

        return selectedTravelRange;
      };

      let notificationsSettingsVariables = [];

      const [
        notificationsNewOppsEmail,
        notificationsOppsNewSMS,
        notificationsOppsBanner,
        notificationsOppsSumEmail,
        notificationsOppsSumBanner,
      ] = notificationsSettingsVariables;

      if (fetchedUserNotificationsSettings) {
        fetchedUserNotificationsSettings.forEach(activeNotification => {
          let activated = gNotificationsAllOptions.indexOf(activeNotification);
          notificationsSettingsVariables[activated] = true;
        });
      }

      setLoadingStatusMessage(`Finishing lap...`);

      const updateAppVariables = [
        setGlobalVariableValue({
          key: 'AUTH_KNACK_USER_ID',
          value: knack_user_id,
        }),
        setGlobalVariableValue({
          key: 'AUTH_KNACK_TOKEN',
          value: auth_knack_token,
        }),
        setGlobalVariableValue({
          key: 'userFullName',
          value: field_6,
        }),
        setGlobalVariableValue({
          key: 'userFirstName',
          value: userFirstName,
        }),
        setGlobalVariableValue({
          key: 'gBioImage',
          value: bioImage,
        }),
        setGlobalVariableValue({
          key: 'gBioImageUrl',
          value: field_321_raw ?? '',
        }),
        setGlobalVariableValue({
          key: 'gBioText',
          value: bioText,
        }),
        setGlobalVariableValue({
          key: 'gInstructorBioStatus',
          value: instructorBioStatus,
        }),
        setGlobalVariableValue({
          key: 'gBioComplete',
          value: bioText && bioImage,
        }),
        setGlobalVariableValue({
          key: 'gCprImage',
          value: cprImage,
        }),
        setGlobalVariableValue({
          key: 'gCprExpirationDate',
          value: new Date(field_48),
        }),
        setGlobalVariableValue({
          key: 'gCprActive',
          value: new Date() <= new Date(field_48),
        }),
        setGlobalVariableValue({
          key: 'gTipaltiComplete',
          value: tipaltiComplete,
        }),
        setGlobalVariableValue({
          key: 'gSunsational101Complete',
          value: sunsational101Complete,
        }),
        setGlobalVariableValue({
          key: 'gAccountReadyForClients',
          value:
            bioComplete &&
            cprActive &&
            sunsational101Complete &&
            tipaltiComplete,
        }),
        setGlobalVariableValue({
          key: 'screen',
          value: 'home',
        }),
        setGlobalVariableValue({
          key: 'instructorBaseAddress',
          value: addressData,
        }),
        setGlobalVariableValue({
          key: 'gNotificationsOppsNewEmail',
          value: notificationsSettingsVariables[0] ?? false,
        }),
        setGlobalVariableValue({
          key: 'gNotificationsOppsNewSMS',
          value: notificationsSettingsVariables[1] ?? false,
        }),
        setGlobalVariableValue({
          key: 'gNotificationsOppsNewBanner',
          value: notificationsSettingsVariables[2] ?? false,
        }),
        setGlobalVariableValue({
          key: 'gNotificationsOppsSumEmail',
          value: notificationsSettingsVariables[3] ?? false,
        }),
        setGlobalVariableValue({
          key: 'notificationsOppsSumBanner',
          value: notificationsSettingsVariables[4] ?? false,
        }),
        setGlobalVariableValue({
          key: 'existingPushTokens',
          value: gPushNotificationsSavedToServer,
        }),
        setGlobalVariableValue({
          key: 'gPushNotificationsSavedToServer',
          value: gPushNotificationsSavedToServer,
        }),
        setGlobalVariableValue({
          key: 'DEVICE_PUSH_TOKEN',
          value: DEVICE_PUSH_TOKEN,
        }),
        setGlobalVariableValue({
          key: 'gInstructorAvailability',
          value: instructorAvailability,
        }),
        setGlobalVariableValue({
          key: 'gInstructorAvailabilityDays',
          value: instructorAvailability.days,
        }),
        setGlobalVariableValue({
          key: 'gInstructorTravelRange',
          value: selectTravelRange(fetchedTravelRange),
        }),
        setGlobalVariableValue({
          key: 'gInstructorEmailAddress',
          value: email,
        }),
        setGlobalVariableValue({
          key: 'gUserEmail',
          value: email,
        }),
        setGlobalVariableValue({
          key: 'gInstructorWantsNewOpps',
          value: field_2432_raw,
        }),
        setGlobalVariableValue({
          key: 'DEVICE_IP',
          value: ip,
        }),
        setGlobalVariableValue({
          key: 'DEVICE_ID',
          value: deviceId,
        }),
      ];

      const updateAppVariablesComplete = await Promise.all(updateAppVariables);
      const saveAppVersion = await Sync.shouldSyncAppVersionToServer(
        Variables,
        setGlobalVariableValue,
        knack_user_id,
        auth_knack_token
      );

      setLoadingStatusMessage(`Welcome, ${userFirstName}!`);

      if (Sync.shouldSavePlatformOSToServer(Variables.DEVICE_OS)) {
        const saveOSVersion = await Sync.saveDevicePlatformOSToServer(
          Variables,
          Platform.OS,
          knack_user_id,
          auth_knack_token
        );

        setGlobalVariableValue({
          key: 'DEVICE_OS',
          value: Platform.OS,
        });
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      setGlobalVariableValue({
        key: 'require_sign_in',
        value: false,
      });

      if (
        !bioImage ||
        !bioText ||
        !cprImage ||
        !tipaltiComplete ||
        !sunsational101Complete ||
        askForPushToken
      ) {
        props.navigation.navigate('Onboarding0WelcomeScreen', {
          onboardingData,
        });
        return;
      }

      if (
        bioImage &&
        bioText &&
        cprImage &&
        tipaltiComplete &&
        sunsational101Complete &&
        !askForPushToken
      ) {
        props.navigation.navigate('HomeScreen');
        return;
      }
    } catch (err) {
      console.log('CATCH ERROR BLOCK!');
      console.log('err === ', err);
      console.error(`What is the error?: ${err.message}`);

      let displayMessage =
        err.message === 'Unexpected end of JSON input'
          ? 'Your account is locked. Use FORGOT PASSWORD to unlock your account.'
          : err.message;

      setGlobalVariableValue({
        key: 'authError',
        value: displayMessage,
      });

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    }
  };
  React.useEffect(() => {
    console.log(
      'ON SCREEN MOUNT: Variables.AUTH_KNACK_USER_ID ============== ',
      Variables.AUTH_KNACK_USER_ID
    );
    if (Variables.require_sign_in || Variables.AUTH_KNACK_TOKEN == '') {
      return;
    }
    setGlobalVariableValue({
      key: 'is_loading_login_screen',
      value: true,
    });
    Track.onEnterScreen(
      'LoginScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    // Track.updateLastDateUsed(Variables.appActivityData, setGlobalVariableValue);
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['Custom Color'] },
        dimensions.width
      )}
    >
      {/* View Header Login */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            height: '23%',
            marginBottom: 20,
            minHeight: 176,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'stretch'}
          source={imageSource(Images['HeaderLogin'])}
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: '100%', width: '100%' },
            dimensions.width
          )}
        >
          {/* Spacer Top Nav */}
          <Spacer bottom={0} left={0} right={0} top={44} />
          {/* View Logo Container */}
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: 0, marginTop: 10 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              source={imageSource(Images['Logo'])}
              style={StyleSheet.applyWidth(
                { height: 100, width: 100 },
                dimensions.width
              )}
            />
          </View>
        </ImageBackground>
      </View>

      <KeyboardAvoidingView
        enabled={true}
        keyboardVerticalOffset={0}
        behavior={'position'}
        style={StyleSheet.applyWidth({ paddingBottom: 20 }, dimensions.width)}
      >
        {/* View Page Content Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%' },
            dimensions.width
          )}
        >
          {/* View Sign In Block */}
          <View>
            {/* View Title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Please'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Sign In'}
              </Text>
            </View>
            {/* View Error Message */}
            <>
              {!Constants['authError'] ? null : (
                <View
                  {...GlobalStyles.ViewStyles(theme)['error message'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['error message'].style,
                    dimensions.width
                  )}
                >
                  {/* Error Message */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      { color: palettes.App['Custom Color'] },
                      dimensions.width
                    )}
                  >
                    {'⚠️  '}
                    {Constants['authError']}
                  </Text>
                </View>
              )}
            </>
            {/* View Inputs Container */}
            <View>
              {/* Text Input Email */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputEmailValue => {
                  try {
                    setInputEmailAddress(newTextInputEmailValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                clearTextOnFocus={false}
                editable={true}
                keyboardType={'email-address'}
                placeholder={'Email'}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'addressState'}
              />
              {/* Text Input Password */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputPasswordValue => {
                  try {
                    setInputPassword(newTextInputPasswordValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                clearTextOnFocus={true}
                editable={true}
                placeholder={'Password'}
                secureTextEntry={true}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'password'}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: 8,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App.White,
                    borderRadius: 12,
                    paddingLeft: 8,
                    paddingRight: 8,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['primary14'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['primary14'].style,
                      { color: palettes.App['App Disabled Button Text 7F868C'] }
                    ),
                    dimensions.width
                  )}
                >
                  {loadingStatusMessage}
                </Text>
              </View>
            </View>
            {/* View Sign In Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 32, width: '100%' },
                dimensions.width
              )}
            >
              {/* Sign In Disabled */}
              <>
                {inputPassword ? null : (
                  <Button
                    iconPosition={'left'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 50,
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Sign In'}
                  />
                )}
              </>
              {/* Sign In Enabled */}
              <>
                {!inputPassword ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          amplitudeEvents(
                            Variables,
                            'sign in',
                            { category: 'general' },
                            undefined
                          );
                          await userLogin(
                            Variables,
                            setGlobalVariableValue,
                            inputEmailAddress,
                            inputPassword
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    loading={Constants['is_loading']}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Sign In'}
                  />
                )}
              </>
            </View>
            {/* View Help Options Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingLeft: 32,
                  paddingRight: 32,
                },
                dimensions.width
              )}
            >
              {/* Forgot password */}
              <Link
                accessible={true}
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://sunsationalswimschool.knack.com/app#mobile-app-si-navigation/forgot-password/'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Sun Orange F4A100'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    textDecorationLine: 'underline',
                  },
                  dimensions.width
                )}
                title={'FORGOT PASSWORD'}
              />
              {/* Get help */}
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('LoginHelpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Sun Orange F4A100'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    textDecorationLine: 'underline',
                  },
                  dimensions.width
                )}
                title={'GET HELP'}
              />
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
      {/* View Footer */}
      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            minHeight: 314,
            paddingTop: 0,
            position: 'absolute',
            width: '100%',
            zIndex: -1,
          },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={imageSource(Images['FooterWideMin1'])}
          style={StyleSheet.applyWidth(
            { height: '100%', width: '100%' },
            dimensions.width
          )}
        />
      </View>
      {/* Modal Loading Overlay */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={Constants['is_loading_login_screen']}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App['Custom Color_9'],
              flex: 1,
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          <ActivityIndicator
            animating={true}
            hidesWhenStopped={true}
            {...GlobalStyles.ActivityIndicatorStyles(theme)[
              'Activity Indicator'
            ].props}
            color={palettes.App['App Blue C7EAF3']}
            size={'large'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].style,
                {
                  borderColor: palettes.App['App Blue eef6fa'],
                  height: 144,
                  width: 144,
                }
              ),
              dimensions.width
            )}
          />
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(Login2Screen);
