import React from 'react';
import {
  Button,
  Icon,
  Link,
  SVG,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  Vibration,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ConfettiCannon from '../custom-files/ConfettiCannon';
import * as Haptics from '../custom-files/Haptics';
import * as Track from '../custom-files/Track';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import updateAccountStatus from '../global-functions/updateAccountStatus';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { skip: '' };

const OnboardingCompleteSunsational101Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cprUploaded, setCprUploaded] = React.useState(false);
  const [is_loading, setIs_loading] = React.useState(false);
  const [showSunsational101Btn, setShowSunsational101Btn] =
    React.useState(true);
  const [sunsational101Status, setSunsational101Status] =
    React.useState('not started');
  const [sunsational101Statuses, setSunsational101Statuses] = React.useState([
    'completed',
    'not started',
    'incomplete',
  ]);
  const navOnboardingNext = () => {
    const {
      gBioImage,
      gBioText,
      gCprImage,
      gTipaltiComplete,
      onboardingSteps,
    } = Variables;

    if (
      gCprImage == '' ||
      gBioImage == '' ||
      gBioText == '' ||
      gTipaltiComplete == false
    ) {
      props.navigation.navigate('Onboard4IncompleteWarningScreen');
    }

    props.navigation.navigate('HomeScreen');
  };

  const update101Status = userDataResponse => {
    const is101Complete = userDataResponse.field_1105_raw;

    if (!is101Complete) {
      setSunsational101Status('incomplete');
      setShowSunsational101Btn(true);
    } else {
      setSunsational101Status('completed');
      ConfettiCannon.Confetti();
      if (Platform.OS != 'web') {
        Haptics.Haptic('success');
      }
    }
  };

  const shouldSkipIncompleteNotice = () => {
    const {
      gCprImage,
      gBioImage,
      gBioText,
      gTipaltiComplete,
      gSunsational101Complete,
    } = Variables;

    if (
      gCprImage == '' ||
      gBioImage == '' ||
      gBioText == '' ||
      !gSunsational101Complete ||
      !gTipaltiComplete
    ) {
      console.log('shouldSkipIncompleteNotice === ', false);
      return false;
    }

    console.log('shouldSkipIncompleteNotice === ', true);
    return true;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Onboarding3CompleteTipaltiFormsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!(props.route?.params?.skip ?? defaultProps.skip)) {
        return;
      }
      navigation.navigate('Onboard4IncompleteWarningScreen', {
        skip: shouldSkipIncompleteNotice(),
      });
      setGlobalVariableValue({
        key: 'empty',
        value: Constants['empty'],
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: '10%',
            justifyContent: 'center',
            maxHeight: '11%',
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Complete Profile'}
            </Text>
          </View>
          {/* View Skip Container */}
          <View>
            {/* Skip */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('Onboard4IncompleteWarningScreen', {
                    skip: shouldSkipIncompleteNotice(),
                  });
                  amplitudeEvents(
                    Variables,
                    'skip tipalti setup',
                    {
                      step: '03',
                      funnel: 'onboarding',
                      origin: 'complete tipalti screen',
                      category: 'onboarding',
                    },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={'SKIP'}
            />
          </View>
        </View>
      </View>
      {/* View Card Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            height: '90%',
            justifyContent: 'space-between',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Card Title Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingTop: 22, width: '100%' },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            selectable={false}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Black 191F32'],
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Complete Sunsational 101 Introduction'}
          </Text>
        </View>
        {/* View Image Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              borderBottomWidth: 1,
              borderColor: palettes.App['App Grey Inputs E2E2E2'],
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderStyle: 'dashed',
              borderTopWidth: 1,
              height: '70%',
              justifyContent: 'center',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View 101 Not Started */}
          <>
            {!(sunsational101Status === 'not started') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', padding: 8, width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 48,
                      justifyContent: 'center',
                      marginBottom: 20,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['App Brand Blue 2C9AD9']}
                    name={'FontAwesome/graduation-cap'}
                    size={48}
                    style={StyleSheet.applyWidth(
                      { height: 48, width: 48 },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'Complete this overview to be ready for your first lesson as a Sunsational Swim Instructor'
                    }
                  </Text>
                </View>
                {/* Finish Test Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Complete Sunsational 101 */}
                  <>
                    {!showSunsational101Btn ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setShowSunsational101Btn(false);
                              await WebBrowser.openBrowserAsync(
                                `https://form.jotform.com/232757224264154?email=${Constants['gUserEmail']}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Complete Sunsational 101'}
                      />
                    )}
                  </>
                  {/* Done */}
                  <>
                    {showSunsational101Btn ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIs_loading(true);
                              const userDataResponse = (
                                await KnackAPIApi.getProfileGET(Constants, {
                                  AUTH_USER_KNACK_ID:
                                    Constants['AUTH_KNACK_USER_ID'],
                                })
                              )?.json;
                              await updateAccountStatus(
                                Variables,
                                setGlobalVariableValue,
                                userDataResponse
                              );
                              update101Status(userDataResponse);
                              setIs_loading(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        loading={is_loading}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Done'}
                      />
                    )}
                  </>
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            )}
          </>
          {/* View 101 Incomplete */}
          <>
            {!(sunsational101Status === 'incomplete') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', padding: 8, width: '100%' },
                  dimensions.width
                )}
              >
                {/* warning icon */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 24 },
                    dimensions.width
                  )}
                >
                  {/* View Warning Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Gold #FFC400'],
                        borderRadius: 50,
                        height: 64,
                        justifyContent: 'center',
                        width: 64,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={imageSource(Images['IconInfoCircle'])}
                      style={StyleSheet.applyWidth(
                        { height: 24, width: 24 },
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "Hm, it doesn't seem you've completed the Sunsational 101 course yet. You'll need to complete it before you can teach clients."
                    }
                  </Text>
                </View>
                {/* Finish Test Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Complete Sunsational 101 */}
                  <>
                    {!showSunsational101Btn ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setShowSunsational101Btn(false);
                              await WebBrowser.openBrowserAsync(
                                `https://form.jotform.com/232757224264154?email=${Constants['gUserEmail']}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Complete Sunsational 101'}
                      />
                    )}
                  </>
                  {/* Done */}
                  <>
                    {showSunsational101Btn ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIs_loading(true);
                              const userDataResponse = (
                                await KnackAPIApi.getProfileGET(Constants, {
                                  AUTH_USER_KNACK_ID:
                                    Constants['AUTH_KNACK_USER_ID'],
                                })
                              )?.json;
                              await updateAccountStatus(
                                Variables,
                                setGlobalVariableValue,
                                userDataResponse
                              );
                              update101Status(userDataResponse);
                              setIs_loading(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        loading={is_loading}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        title={'Done'}
                      />
                    )}
                  </>
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Skip */}
                <View>
                  {/* Skip */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('Onboard4IncompleteWarningScreen', {
                          skip: shouldSkipIncompleteNotice(),
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    {...GlobalStyles.LinkStyles(theme)['Link'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.LinkStyles(theme)['Link'].style,
                      dimensions.width
                    )}
                    title={'Skip'}
                  />
                </View>
              </View>
            )}
          </>
          {/* View 101 Complete */}
          <>
            {!(sunsational101Status === 'completed') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 0,
                      height: 40,
                      justifyContent: 'center',
                      marginBottom: 24,
                      width: 79,
                    },
                    dimensions.width
                  )}
                >
                  {/* Checkmark */}
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(
                      `${Constants['uiAnimationCheckmarkDone']}`
                    )}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20, padding: 16 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      'Congratulations on completing your Sunsational 101 Course!'
                    }
                  </Text>
                </View>
              </View>
            )}
          </>
        </View>
        {/* View Bottom CTA Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Left Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Step Text Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 8 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Step 4 of 4'}
              </Text>
            </View>
            {/* View Progress Bar Container */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, flexDirection: 'row', width: '100%' },
                dimensions.width
              )}
            >
              {/* View 1st fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 2nd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 3rd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 4th fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View Last Remainder */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '4%',
                  },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* View Right Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Sign In Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Complete Disabled */}
              <>
                {Constants['gSunsational101Complete'] ? null : (
                  <Button
                    iconPosition={'left'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 50,
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Complete' ?? 'Sign In'}
                  />
                )}
              </>
              {/* Complete Enabled */}
              <>
                {!Constants['gSunsational101Complete'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('Onboard4IncompleteWarningScreen', {
                          skip: shouldSkipIncompleteNotice(),
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Complete' ?? 'Sign In'}
                  />
                )}
              </>
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(OnboardingCompleteSunsational101Screen);
