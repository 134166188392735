import * as ExpoCalendarFunc from '../custom-files/ExpoCalendarFunc';
import * as convertTime from '../custom-files/convertTime';

const addLessonToCalendar = async (
  lessonNumber,
  clientName,
  scheduledDateTime,
  lessonDuration
) => {
  const scheduledDateTimeISO = await convertTime.timestringToISO(
    scheduledDateTime,
    'MM/DD/YYYY h:mma'
  );

  try {
    const lessonCalendarID = await ExpoCalendarFunc.addCalendarEvent(
      lessonNumber,
      clientName,
      scheduledDateTimeISO,
      lessonDuration
    );

    if (!lessonCalendarID) {
      return false;
    }

    return true;
  } catch (err) {
    console.error(err);
    alert('Error: ${err}');
    return false;
  }
};

export default addLessonToCalendar;
