import * as Haptics from '../custom-files/Haptics';

const Haptic = type => {
  switch (type) {
    case 'light':
      Haptics.light();
      break;
    case 'medium':
      Haptics.medium();
      break;
    case 'heavy':
      Haptics.heavy();
      break;
    case 'selection':
      Haptics.selection();
      break;
    case 'success':
      Haptics.success();
      break;
    case 'error':
      Haptics.error();
      break;
    case 'warning':
      Haptics.warning();
      break;
    default:
      Haptics.light();
  }
};

export default Haptic;
