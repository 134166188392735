// Transform address object into string
const concatAddress = addressObj => {
  const { street, street2, city, state, zip, latitude, longitude } = addressObj;

  let address;

  street2
    ? (address = `${street} ${street2}, ${city}, ${state} ${zip}`)
    : (address = `${street}, ${city}, ${state} ${zip}`);

  return address;
};

export default concatAddress;
