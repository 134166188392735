import * as convertTime from '../custom-files/convertTime';

const convertTimeFormatString = (timestring, inputFormat, finalFormat) => {
  const formattedTime = convertTime.format(
    timestring,
    inputFormat,
    finalFormat
  );
  return formattedTime;
};

export default convertTimeFormatString;
