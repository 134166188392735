const updateAccountStatus = async (
  Variables,
  setGlobalVariableValue,
  userDataResponse
) => {
  const { Constants, onboardingSteps, gNotificationsAllOptions, travelRanges } =
    Variables;

  const {
    field_321_raw, // user profile image
    field_45_raw, // user biography text
    field_177_raw, // cpr image
    field_48, // cpr expiration date
    field_1366_raw, // tipalti status boolean
    field_1105_raw, // sunsational101 status boolean
    field_1845, // onboarding calls attended
    field_6, // user fullname
    field_6_raw, // user first name
    field_46_raw, // user address object
    field_2386_raw, // notifications options array
    field_930_raw, // days
    field_931_raw, // weekdaytimes
    field_932_raw, // weekendtimes
    field_47, //travel range
    field_2387_raw, // instructor profile bio status
    field_1896, // number of pools access
    field_2432_raw, // instructor pause activity status
    field_2430_raw, // push notification tokens (string)
    field_7_raw, // user email address
  } = userDataResponse;

  console.log('field_6_raw.first === ', field_6_raw.first);

  const onboardingData = {
    bioImage: field_321_raw ?? '',
    bioText: field_45_raw ?? '',
    instructorBioStatus: field_2387_raw,
    cprImage: field_177_raw?.url ?? '',
    tipaltiComplete: field_1366_raw,
    sunsational101Complete: field_1105_raw || field_1845 > 0,
    onboardingSteps: onboardingSteps,
  };

  const {
    bioImage,
    bioText,
    instructorBioStatus,
    cprImage,
    tipaltiComplete,
    sunsational101Complete,
    onboardingCallComplete,
  } = onboardingData;
  console.log('onboardingData === ', onboardingData);

  if (!instructorBioStatus) {
    if (!bioImage || !bioText) {
      setGlobalVariableValue({
        key: instructorBioStatus,
        value: 'incomplete',
      });
    }

    if (bioImage && bioText) {
      setGlobalVariableValue({
        key: instructorBioStatus,
        value: 'under review',
      });
    }
  }

  const numberOfPoolsAccess = field_1896;

  const addressData = field_46_raw;

  const fetchedUserNotificationsSettings = field_2386_raw;
  const existingPushTokens = field_2430_raw;

  const instructorAvailability = {
    days: field_930_raw ?? [],
    weekdayTimes: field_931_raw ?? [],
    weekendTimes: field_932_raw ?? [],
  };

  const fetchedTravelRange = field_47;

  const selectTravelRange = travelRange => {
    let selectedTravelRange;

    if (travelRange == 10) {
      selectedTravelRange = travelRanges[0];
    }

    if (travelRange == 25) {
      selectedTravelRange = travelRanges[1];
    }

    if (travelRange == 40) {
      selectedTravelRange = travelRanges[2];
    }

    return selectedTravelRange;
  };

  let notificationsSettingsVariables = [];

  const [
    notificationsNewOppsEmail,
    notificationsOppsNewSMS,
    notificationsOppsBanner,
    notificationsOppsSumEmail,
    notificationsOppsSumBanner,
  ] = notificationsSettingsVariables;

  if (fetchedUserNotificationsSettings) {
    console.log(
      'notificationsSettingsVariables === ',
      notificationsSettingsVariables
    );
    fetchedUserNotificationsSettings.forEach(activeNotification => {
      console.log('activeNotification === ', activeNotification);
      let activated = gNotificationsAllOptions.indexOf(activeNotification);
      console.log('activated === ', activated);
      notificationsSettingsVariables[activated] = true;
      console.log(
        'notificationsSettingsVariables[activated] === ',
        notificationsSettingsVariables[activated]
      );
    });
  }

  const updateAppVariables = [
    // setGlobalVariableValue({
    // key: 'AUTH_KNACK_USER_ID',
    // value: knack_user_id,
    // }),
    // setGlobalVariableValue({
    //     key: 'AUTH_KNACK_TOKEN',
    //     value: auth_knack_token,
    // }),
    setGlobalVariableValue({
      key: 'userFullName',
      value: field_6,
    }),
    setGlobalVariableValue({
      key: 'userFirstName',
      value: field_6_raw.first,
    }),
    setGlobalVariableValue({
      key: 'gBioImage',
      value: bioImage,
    }),
    setGlobalVariableValue({
      key: 'gBioImageUrl',
      value: field_321_raw ?? '',
    }),
    setGlobalVariableValue({
      key: 'gBioText',
      value: bioText,
    }),
    setGlobalVariableValue({
      key: 'gInstructorBioStatus',
      value: instructorBioStatus,
    }),
    setGlobalVariableValue({
      key: 'gBioComplete',
      value: bioText && bioImage,
    }),
    setGlobalVariableValue({
      key: 'gCprImage',
      value: cprImage,
    }),
    setGlobalVariableValue({
      key: 'gCprExpirationDate',
      value: new Date(field_48),
    }),
    setGlobalVariableValue({
      key: 'gTipaltiComplete',
      value: tipaltiComplete,
    }),
    setGlobalVariableValue({
      key: 'gSunsational101Complete',
      value: sunsational101Complete,
    }),
    setGlobalVariableValue({
      key: 'screen',
      value: 'home',
    }),
    setGlobalVariableValue({
      key: 'instructorBaseAddress',
      value: addressData,
    }),
    setGlobalVariableValue({
      key: 'gNotificationsOppsNewEmail',
      value: notificationsSettingsVariables[0] ?? false,
    }),
    setGlobalVariableValue({
      key: 'gNotificationsOppsNewSMS',
      value: notificationsSettingsVariables[1] ?? false,
    }),
    setGlobalVariableValue({
      key: 'gNotificationsOppsNewBanner',
      value: notificationsSettingsVariables[2] ?? false,
    }),
    setGlobalVariableValue({
      key: 'gNotificationsOppsSumEmail',
      value: notificationsSettingsVariables[3] ?? false,
    }),
    setGlobalVariableValue({
      key: 'notificationsOppsSumBanner',
      value: notificationsSettingsVariables[4] ?? false,
    }),
    setGlobalVariableValue({
      key: 'existingPushTokens',
      value: existingPushTokens,
    }),
    setGlobalVariableValue({
      key: 'gInstructorAvailability',
      value: instructorAvailability,
    }),
    setGlobalVariableValue({
      key: 'gInstructorAvailabilityDays',
      value: instructorAvailability.days,
    }),
    setGlobalVariableValue({
      key: 'gInstructorTravelRange',
      value: selectTravelRange(fetchedTravelRange),
    }),
    setGlobalVariableValue({
      key: 'gInstructorEmailAddress',
      value: field_7_raw.email,
    }),
    setGlobalVariableValue({
      key: 'gUserEmail',
      value: field_7_raw.email,
    }),
    setGlobalVariableValue({
      key: 'gInstructorWantsNewOpps',
      value: field_2432_raw,
    }),
  ];

  const updateAppVariablesComplete = await Promise.all(updateAppVariables);
};

export default updateAccountStatus;
