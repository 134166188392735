import React from 'react';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Link,
  NumberInput,
  Picker,
  Pressable,
  RadioButtonGroup,
  RadioButtonRow,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  ImageBackground,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Haptics from '../custom-files/Haptics';
import * as Track from '../custom-files/Track';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import * as viewOpportunities from '../custom-files/viewOpportunities';
import Haptic from '../global-functions/Haptic';
import getUserInitials from '../global-functions/getUserInitials';
import openMap from '../global-functions/openMap';
import resetSendDeclinePackageResponse from '../global-functions/resetSendDeclinePackageResponse';
import sendDeclinePackageNotice from '../global-functions/sendDeclinePackageNotice';
import splitStringOnComma from '../global-functions/splitStringOnComma';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  addressClient: '525 Olive St #409, San Diego, CA',
  addressObjClient: '',
  availabilityDays: 'Mondays Tuesdays Wednesdays Thursdays Fridays Saturdays',
  availabilityNotes: '',
  availabilityTimesWeekDay: '8AM - 10AM 10AM - 12PM',
  availabilityTimesWeekEnd: '8AM - 10AM 10AM - 12PM',
  clientName: 'Luke Skywalker',
  clientOpenToAlernateSchedule: true,
  currentlyBookedSi: '',
  distance: 8,
  endDate: '',
  hasPoolAccess: '',
  instructorBid: '',
  lessonsCount: 25,
  lessonsLength: 60,
  lessonsPerWeek: 3,
  lsgEligible: '',
  packageId: 69,
  packageStatus: 'Unmatched',
  payBase: 1200,
  payBonus: 200,
  payPerLesson: 100,
  poolType: 'Housing Community Pool',
  preloaded: '',
  requestAltSchedule: '',
  specialSchedulingRequests: 'Any time is good. Jedis never sleep.',
  startDate: 'ASAP',
  studentsDetails: 'Annekin Skywalker 6 Years, Padawan 2 Years',
  studentsDetailsAdditional: 'The force is strong with these ones.',
  studentsNotes: '',
};

const OpportunityDetailsPreloadedScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [agreedToClientSchedule, setAgreedToClientSchedule] =
    React.useState(false);
  const [alternateScheduleExpanded, setAlternateScheduleExpanded] =
    React.useState(true);
  const [alternateScheduleFilled, setAlternateScheduleFilled] =
    React.useState(false);
  const [alternateScheduleRequested, setAlternateScheduleRequested] =
    React.useState(false);
  const [alternateScheduleShouldBeSent, setAlternateScheduleShouldBeSent] =
    React.useState(false);
  const [availableForRequest, setAvailableForRequest] = React.useState(true);
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxRowValue2, setCheckboxRowValue2] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  const [empty, setEmpty] = React.useState('');
  const [enableRequestButton, setEnableRequestButton] = React.useState(false);
  const [falseValue, setFalseValue] = React.useState(false);
  const [instructorBid, setInstructorBid] = React.useState(0);
  const [is_loading, setIs_loading] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue10, setPickerValue10] = React.useState('');
  const [pickerValue11, setPickerValue11] = React.useState('');
  const [pickerValue12, setPickerValue12] = React.useState('');
  const [pickerValue13, setPickerValue13] = React.useState('');
  const [pickerValue14, setPickerValue14] = React.useState('');
  const [pickerValue15, setPickerValue15] = React.useState('');
  const [pickerValue16, setPickerValue16] = React.useState('');
  const [pickerValue17, setPickerValue17] = React.useState('');
  const [pickerValue18, setPickerValue18] = React.useState('');
  const [pickerValue19, setPickerValue19] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [pickerValue20, setPickerValue20] = React.useState('');
  const [pickerValue21, setPickerValue21] = React.useState('');
  const [pickerValue22, setPickerValue22] = React.useState('');
  const [pickerValue23, setPickerValue23] = React.useState('');
  const [pickerValue24, setPickerValue24] = React.useState('');
  const [pickerValue25, setPickerValue25] = React.useState('');
  const [pickerValue26, setPickerValue26] = React.useState('');
  const [pickerValue27, setPickerValue27] = React.useState('');
  const [pickerValue28, setPickerValue28] = React.useState('');
  const [pickerValue29, setPickerValue29] = React.useState('');
  const [pickerValue3, setPickerValue3] = React.useState('');
  const [pickerValue30, setPickerValue30] = React.useState('');
  const [pickerValue31, setPickerValue31] = React.useState('');
  const [pickerValue32, setPickerValue32] = React.useState('');
  const [pickerValue33, setPickerValue33] = React.useState('');
  const [pickerValue34, setPickerValue34] = React.useState('');
  const [pickerValue35, setPickerValue35] = React.useState('');
  const [pickerValue36, setPickerValue36] = React.useState('');
  const [pickerValue37, setPickerValue37] = React.useState('');
  const [pickerValue38, setPickerValue38] = React.useState('');
  const [pickerValue39, setPickerValue39] = React.useState('');
  const [pickerValue4, setPickerValue4] = React.useState('');
  const [pickerValue40, setPickerValue40] = React.useState('');
  const [pickerValue41, setPickerValue41] = React.useState('');
  const [pickerValue42, setPickerValue42] = React.useState('');
  const [pickerValue5, setPickerValue5] = React.useState('');
  const [pickerValue6, setPickerValue6] = React.useState('');
  const [pickerValue7, setPickerValue7] = React.useState('');
  const [pickerValue8, setPickerValue8] = React.useState('');
  const [pickerValue9, setPickerValue9] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const [showDeclineQuestions, setShowDeclineQuestions] = React.useState(true);
  const [showHigherPayQuestionnaire, setShowHigherPayQuestionnaire] =
    React.useState(false);
  const [showModalRequestSuccess, setShowModalRequestSuccess] =
    React.useState(false);
  const [showModalSSSGuarantee, setShowModalSSSGuarantee] =
    React.useState(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchRowValue2, setSwitchRowValue2] = React.useState(false);
  const [switchRowValue3, setSwitchRowValue3] = React.useState(false);
  const [switchRowValue4, setSwitchRowValue4] = React.useState(false);
  const [switchRowValue5, setSwitchRowValue5] = React.useState(false);
  const [switchRowValue6, setSwitchRowValue6] = React.useState(false);
  const [switchRowValue7, setSwitchRowValue7] = React.useState(false);
  const [textReasonWhy, setTextReasonWhy] = React.useState('');
  const [timeslotMonday, setTimeslotMonday] = React.useState(1);
  const [timeslotsFriday, setTimeslotsFriday] = React.useState(1);
  const [timeslotsSaturday, setTimeslotsSaturday] = React.useState(1);
  const [timeslotsSunday, setTimeslotsSunday] = React.useState(1);
  const [timeslotsThursday, setTimeslotsThursday] = React.useState(1);
  const [timeslotsTuesday, setTimeslotsTuesday] = React.useState(1);
  const [timeslotsWednesday, setTimeslotsWednesday] = React.useState(1);
  const alertPoolType = poolType => {
    alert(`These lessons take place at the client's ${poolType}`);
  };

  const getStudentsFirstName = students => {
    if (!students) {
      return;
    }

    let studentNamesStr = '';
    let studentsArr = students.split(',');

    studentsArr.forEach((student, i) => {
      let studentNames = student.split(' ');

      if (i < 1) {
        studentNamesStr += studentNames[0];
      }

      if (i > 0) {
        studentNamesStr += ` and ${studentNames[1]}`;
      }
    });

    return studentNamesStr;
  };

  const alertDeclineReasonRequired = () => {
    alert('Please select a decline reason.');
  };

  const sendPackageRequest = async (Variables, setGlobalVariableValue) => {
    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    const { gInstructorEmailAddress } = Variables;

    let siResponse;
    let requestSource;
    let siSchedule = '';
    let supportingDetails = textReasonWhy;
    let requestedPayment = props.route.params.instructorBid ?? instructorBid;

    let mondaySchedule = '';
    let mondayTimeslot = '';
    let mondayTimeslot2 = '';
    let mondayTimeslot3 = '';

    let tuesdaySchedule = '';
    let tuesdayTimeslot = '';
    let tuesdayTimeslot2 = '';
    let tuesdayTimeslot3 = '';

    let wednesdaySchedule = '';
    let wednesdayTimeslot = '';
    let wednesdayTimeslot2 = '';
    let wednesdayTimeslot3 = '';

    let thursdaySchedule = '';
    let thursdayTimeslot = '';
    let thursdayTimeslot2 = '';
    let thursdayTimeslot3 = '';

    let fridaySchedule = '';
    let fridayTimeslot = '';
    let fridayTimeslot2 = '';
    let fridayTimeslot3 = '';

    let saturdaySchedule = '';
    let saturdayTimeslot = '';
    let saturdayTimeslot2 = '';
    let saturdayTimeslot3 = '';

    let sundaySchedule = '';
    let sundayTimeslot = '';
    let sundayTimeslot2 = '';
    let sundayTimeslot3 = '';

    if (agreedToClientSchedule) {
      siSchedule = 'Same as client';
    }

    if (alternateScheduleRequested) {
      // create siSchedule string

      // set monday schedule
      if (switchRowValue) {
        mondaySchedule += 'Monday: ';
        if (pickerValue && pickerValue2 && timeslotMonday >= 1) {
          mondayTimeslot += `${pickerValue} - ${pickerValue2}`;
        }
        if (pickerValue3 && pickerValue4 && timeslotMonday >= 2) {
          mondayTimeslot2 += `, ${pickerValue3} - ${pickerValue4}`;
        }
        if (pickerValue5 && pickerValue6 && timeslotMonday >= 3) {
          mondayTimeslot3 += `, ${pickerValue5} - ${pickerValue6}`;
        }

        mondaySchedule += `${mondayTimeslot}${mondayTimeslot2}${mondayTimeslot3} /`;
      }

      //set tuesday schedule
      if (switchRowValue2) {
        tuesdaySchedule += 'Tuesday: ';
        if (pickerValue7 && pickerValue8 && timeslotsTuesday >= 1) {
          mondayTimeslot += `${pickerValue7} - ${pickerValue8}`;
        }
        if (pickerValue9 && pickerValue10 && timeslotsTuesday >= 2) {
          tuesdayTimeslot2 += `, ${pickerValue9} - ${pickerValue10}`;
        }
        if (pickerValue11 && pickerValue12 && timeslotsTuesday >= 3) {
          tuesdayTimeslot3 += `, ${pickerValue11} - ${pickerValue12}`;
        }

        tuesdaySchedule += `${tuesdayTimeslot}${tuesdayTimeslot2}${tuesdayTimeslot3} /`;
      }

      //set wednesday schedule
      if (switchRowValue3) {
        wednesdaySchedule += 'Wednesday: ';
        if (pickerValue13 && pickerValue14 && timeslotsWednesday >= 1) {
          wednesdayTimeslot += `${pickerValue13} - ${pickerValue14}`;
        }
        if (pickerValue15 && pickerValue16 && timeslotsWednesday >= 2) {
          wednesdayTimeslot2 += `, ${pickerValue15} - ${pickerValue16}`;
        }
        if (pickerValue17 && pickerValue18 && timeslotsWednesday >= 3) {
          wednesdayTimeslot3 += `, ${pickerValue17} - ${pickerValue18}`;
        }

        wednesdaySchedule += `${wednesdayTimeslot}${wednesdayTimeslot2}${wednesdayTimeslot3} /`;
      }

      //set thursday schedule
      if (switchRowValue4) {
        thursdaySchedule += 'Thursday: ';
        if (pickerValue19 && pickerValue20 && timeslotsThursday >= 1) {
          thursdayTimeslot += `${pickerValue19} - ${pickerValue20}`;
        }
        if (pickerValue21 && pickerValue22 && timeslotsThursday >= 2) {
          thursdayTimeslot2 += `, ${pickerValue21} - ${pickerValue22}`;
        }
        if (pickerValue23 && pickerValue24 && timeslotsThursday >= 3) {
          thursdayTimeslot3 += `, ${pickerValue23} - ${pickerValue24}`;
        }

        thursdaySchedule += `${thursdayTimeslot}${thursdayTimeslot2}${thursdayTimeslot3} /`;
      }

      //set friday schedule
      if (switchRowValue5) {
        fridaySchedule += 'Friday: ';
        if (pickerValue25 && pickerValue26 && timeslotsFriday >= 1) {
          fridayTimeslot += `${pickerValue25} - ${pickerValue26}`;
        }
        if (pickerValue27 && pickerValue28 && timeslotsFriday >= 2) {
          fridayTimeslot2 += `, ${pickerValue27} - ${pickerValue28}`;
        }
        if (pickerValue29 && pickerValue30 && timeslotsFriday >= 3) {
          fridayTimeslot3 += `, ${pickerValue29} - ${pickerValue30}`;
        }

        fridaySchedule += `${fridayTimeslot}${fridayTimeslot2}${fridayTimeslot3} /`;
      }

      //set saturday schedule
      if (switchRowValue6) {
        saturdaySchedule += 'Saturday: ';
        if (pickerValue31 && pickerValue32 && timeslotsSaturday >= 1) {
          saturdayTimeslot += `${pickerValue31} - ${pickerValue32}`;
        }
        if (pickerValue33 && pickerValue34 && timeslotsSaturday >= 2) {
          saturdayTimeslot2 += `, ${pickerValue33} - ${pickerValue34}`;
        }
        if (pickerValue35 && pickerValue36 && timeslotsSaturday >= 3) {
          saturdayTimeslot3 += `, ${pickerValue35} - ${pickerValue36}`;
        }

        saturdaySchedule += `${saturdayTimeslot}${saturdayTimeslot2}${saturdayTimeslot3} /`;
      }

      //set sunday schedule
      if (switchRowValue6) {
        sundaySchedule += 'Sunday: ';
        if (pickerValue31 && pickerValue32 && timeslotsSunday >= 1) {
          sundayTimeslot += `${pickerValue31} - ${pickerValue32}`;
        }
        if (pickerValue33 && pickerValue34 && timeslotsSunday >= 2) {
          sundayTimeslot2 += `, ${pickerValue33} - ${pickerValue34}`;
        }
        if (pickerValue35 && pickerValue36 && timeslotsSunday >= 3) {
          sundayTimeslot3 += `, ${pickerValue35} - ${pickerValue36}`;
        }

        sundaySchedule += `${sundayTimeslot}${sundayTimeslot2}${sundayTimeslot3} /`;
      }

      siSchedule = `${mondaySchedule} ${tuesdaySchedule} ${wednesdaySchedule} ${thursdaySchedule} ${fridaySchedule} ${saturdaySchedule} ${sundaySchedule}`;
    }

    const opportunityId2 = `${props.route.params.packageId}-${gInstructorEmailAddress}`;

    const data = {
      siReponse: 'Requested',
      requestSource: requestSource ?? 'app',
      opportunityId2,
      siSchedule,
      supportingDetails,
      requestedPayment,
    };

    try {
      const sendRequest = await fetch(
        `https://hook.integromat.com/gejrlmg57qs16yk6xah44dim4fqx38pj`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const sendRequestResponse = await sendRequest.json();

      if (!sendRequest.ok) {
        console.log('REPONSE NOT OK BLOCK');
        // setGlobalVariableValue({
        //     key: 'authError',
        //     value: sendRequest.errorDescription
        // })

        setGlobalVariableValue({
          key: 'sendRequestPackageResponse',
          value: sendRequestResponse.message,
        });

        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });

        throw new Error(
          `Error! status: ${sendRequestResponse.errorsDescription}`
        );
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      setShowSubmitModal(false);

      setGlobalVariableValue({
        key: 'sendRequestPackageResponse',
        value: sendRequestResponse.message,
      });

      setShowModalRequestSuccess(true);
      Haptics.Haptic('light');
    } catch (err) {
      console.error(`Error! ${err}`);
    }
  };

  const changeTimeslotsCount = (day, slotCount, math) => {
    const allSlotCounts = {
      mon: setTimeslotMonday,
      tue: setTimeslotsTuesday,
      wed: setTimeslotsWednesday,
      thu: setTimeslotsThursday,
      fri: setTimeslotsFriday,
      sat: setTimeslotsSaturday,
      sun: setTimeslotsSunday,
    };

    if (math === 'increment') {
      if (slotCount >= 3) {
        return;
      }

      slotCount++;
    } else {
      slotCount--;
    }

    allSlotCounts[day](slotCount);
  };

  const checkShouldSendAlternateSchedule = () => {
    if (alternateScheduleRequested && alternateScheduleFilled) {
      setAlternateScheduleShouldBeSent(true);
    }
  };

  const closeModalRequestSuccess = async (
    Variables,
    setGlobalVariableValue
  ) => {
    setGlobalVariableValue({
      key: 'sendRequestPackageResponse',
      value: '',
    });

    setShowModalRequestSuccess(false);
  };

  const getDate = string => {
    let date = string.replace('On a particular day', '');

    return date;
  };

  const mathRound = num => {
    return Math.round(num);
  };

  const calcBidPayPerLesson = (instructorBid, numberOfLessons) => {
    const payPerLesson = instructorBid / numberOfLessons;
    return Math.round(payPerLesson, 2);
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'OpportunityDetailsPreloadedScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);

  React.useEffect(() => {
    // check if alternate schedule is filled
    if (
      (switchRowValue && pickerValue && pickerValue2) ||
      (switchRowValue2 && pickerValue7 && pickerValue8) ||
      (switchRowValue3 && pickerValue13 && pickerValue14) ||
      (switchRowValue4 && pickerValue19 && pickerValue20) ||
      (switchRowValue5 && pickerValue25 && pickerValue26) ||
      (switchRowValue6 && pickerValue31 && pickerValue32) ||
      (switchRowValue7 && pickerValue37 && pickerValue38)
    ) {
      setAlternateScheduleFilled(true);
    } else {
      setAlternateScheduleFilled(false);
    }

    // check if alternate schedule should be sent
    if (alternateScheduleRequested && alternateScheduleFilled) {
      setAlternateScheduleShouldBeSent(true);
    } else {
      setAlternateScheduleShouldBeSent(false);
    }

    // enableRequestButton
    if (agreedToClientSchedule || alternateScheduleShouldBeSent) {
      setEnableRequestButton(true);
    } else {
      setEnableRequestButton(false);
    }
  });
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setInstructorBid(
        props.route?.params?.instructorBid ?? defaultProps.instructorBid
      );
      setCheckboxValue2(
        props.route?.params?.requestAltSchedule ??
          defaultProps.requestAltSchedule
      );
      setAlternateScheduleRequested(
        props.route?.params?.requestAltSchedule ??
          defaultProps.requestAltSchedule
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          { justifyContent: 'center' },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Job Details'}
            </Text>
          </View>
          {/* Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            {/* Spacer */}
            <Link
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={''}
            />
          </View>
        </View>
      </View>
      {/* Curve Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingTop: 15,
          },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              paddingBottom: 100,
            },
            dimensions.width
          )}
        >
          {/* View Card Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderRadius: 0,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                height: '90%',
                justifyContent: 'space-between',
                paddingBottom: 180,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Primary Details */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: '5.35%', paddingRight: '5.35%' },
                dimensions.width
              )}
            >
              {/* View Card Title Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 24, width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Top Details */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Left Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* View Client PP */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 48, marginRight: 8, width: 48 },
                        dimensions.width
                      )}
                    >
                      <Surface
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1.25,
                            borderColor: palettes.App['Custom Color'],
                            borderLeftWidth: 1.25,
                            borderRadius: 100,
                            borderRightWidth: 1.25,
                            borderTopWidth: 1.25,
                            height: '100%',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* SVG Avatar */}
                        <SVG
                          source={Constants['uiAvatar3']}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      </Surface>
                      {/* View Initials Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 48,
                            justifyContent: 'center',
                            marginTop: -48,
                            width: 48,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Initials */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['Custom Color'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 24,
                              textTransform: 'uppercase',
                            },
                            dimensions.width
                          )}
                        >
                          {getUserInitials(
                            props.route?.params?.clientName ??
                              defaultProps.clientName
                          )}
                        </Text>
                      </View>
                    </View>
                    {/* View Client Details */}
                    <View>
                      {/* View Client Name */}
                      <View>
                        {/* Client Name */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_600SemiBold',
                            },
                            dimensions.width
                          )}
                        >
                          {props.route?.params?.clientName ??
                            defaultProps.clientName}
                        </Text>
                      </View>
                      {/* Package ID */}
                      <View>
                        {/* Package ID */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {'ID '}
                          {props.route?.params?.packageId ??
                            defaultProps.packageId}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* View Right Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-end', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* View Requested Status */}
                    <>
                      {availableForRequest ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Orange #FDF9EC'],
                              borderRadius: 8,
                              flexDirection: 'row',
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Requested Status */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Gold FFA900'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {props.route?.params?.packageStatus ??
                              defaultProps.packageStatus}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* View Matched Status */}
                    <>
                      {props.route?.params?.packageStatus ??
                      defaultProps.packageStatus ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { borderRadius: 8, flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* View Matched With SI */}
                          <>
                            {!(
                              (props.route?.params?.currentlyBookedSi ??
                                defaultProps.currentlyBookedSi) ===
                              Constants['gInstructorEmailAddress']
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['App Mint #F0F8F2'],
                                    borderRadius: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Matched Status */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text Green #1DB058'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Matched'}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* View Unavailable Finished Loading */}
                    <>
                      {availableForRequest ? null : (
                        <View>
                          {/* View Unavailable Status */}
                          <>
                            {!(
                              (props.route?.params?.packageStatus ??
                                defaultProps.packageStatus) !== 'Unmatched'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { borderRadius: 8, flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* View Not Matched with SI */}
                                <>
                                  {!(
                                    (props.route?.params?.currentlyBookedSi ??
                                      defaultProps.currentlyBookedSi) !==
                                    Constants['gInstructorEmailAddress']
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App[
                                              'App Disabled Button EDEDEF'
                                            ],
                                          borderRadius: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                          paddingTop: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Unavailable Status */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App[
                                                'App Text 2ndary 7F868C'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Unavailable'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* View Available Finished Loading */}
                    <View>
                      {/* View Available Status */}
                      <>
                        {!(
                          (props.route?.params?.packageStatus ??
                            defaultProps.packageStatus) === 'Unmatched'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { borderRadius: 8, flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Not Requested By SI */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Blue/Low Emphasis'],
                                  borderRadius: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Available */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Blue#4 007fd4'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Available'}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* View Status Loading */}
                    <>
                      {!is_loading ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              flexDirection: 'row',
                              justifyContent: 'center',
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        </View>
                      )}
                    </>
                  </View>
                </View>
                {/* View LSG Eligibility */}
                <>
                  {!(
                    props.route?.params?.lsgEligible ?? defaultProps.lsgEligible
                  ) ? null : (
                    <View>
                      <Pressable
                        onPress={() => {
                          try {
                            setShowModalSSSGuarantee(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: palettes.App['App Black 191F32'],
                              borderRadius: 8,
                              borderWidth: 1,
                              flexDirection: 'row',
                              marginTop: 10,
                              padding: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={palettes.App['App Black 191F32']}
                            name={'MaterialCommunityIcons/swim'}
                            style={StyleSheet.applyWidth(
                              { marginRight: 4 },
                              dimensions.width
                            )}
                          />
                          {/* Learn To Swim Guaranteed */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'This package is Learn To Swim Guaranteed™'}
                          </Text>
                          <IconButton
                            color={
                              palettes.App['App Disabled Button Text 7F868C']
                            }
                            icon={'MaterialCommunityIcons/information-outline'}
                            size={18}
                          />
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
              </View>
              {/* View Row Pay and Lesson Details */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Surface Lesson Pay Details */}
                <Surface
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 16,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: -16,
                      minHeight: 40,
                      paddingBottom: 16,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 16,
                      zIndex: 2,
                    },
                    dimensions.width
                  )}
                >
                  {/* View No Instructor Bid */}
                  <>
                    {instructorBid ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', width: '63%' },
                          dimensions.width
                        )}
                      >
                        {/* View Base Pay */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '57%' },
                            dimensions.width
                          )}
                        >
                          {/* Label Base Pay */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: 'center',
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 14,
                                marginBottom: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Base Pay'}
                          </Text>
                          {/* Base Pay */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: 'center',
                                color: palettes.App['App Text Green #1DB058'],
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {'$'}
                            {props.route?.params?.payBase ??
                              defaultProps.payBase}
                          </Text>
                        </View>
                        {/* View Bonus Pay */}
                        <>
                          {!(
                            (props.route?.params?.payBonus ??
                              defaultProps.payBonus) > 0
                          ) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor:
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ],
                                  borderLeftWidth: 1,
                                  borderRightWidth: 1,
                                  width: '43%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Label Bonus Pay */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 14,
                                    marginBottom: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Bonus'}
                              </Text>
                              {/* Bonus pay */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Sun Orange F4A100'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'+ $'}
                                {props.route?.params?.payBonus ??
                                  defaultProps.payBonus}
                              </Text>
                            </View>
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Instructor Bid */}
                  <>
                    {!instructorBid ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor:
                              palettes.App['App Disabled Button Text 7F868C'],
                            borderRightWidth: 1,
                            flexDirection: 'column',
                            width: '63%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Label Your Bid */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 14,
                              marginBottom: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Your Bid'}
                        </Text>
                        {/* Instructor Bid Amount */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              color: palettes.App['App Sun Orange F4A100'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {'$'}
                          {instructorBid}
                          {'.00'}
                        </Text>
                      </View>
                    )}
                  </>
                  {/* View Lesson Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '36%' },
                      dimensions.width
                    )}
                  >
                    {/* Label Total Lessons */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          marginBottom: 4,
                        },
                        dimensions.width
                      )}
                    >
                      {'Lesson Pack'}
                    </Text>
                    {/* Lesson Package */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {props.route?.params?.lessonsCount ??
                        defaultProps.lessonsCount}
                      {'x'}
                      {props.route?.params?.lessonsLength ??
                        defaultProps.lessonsLength}
                      {' min'}
                    </Text>
                  </View>
                </Surface>
                {/* View Paid Per Lesson Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['Custom Color_6'],
                      borderBottomLeftRadius: 16,
                      borderBottomRightRadius: 16,
                      flexDirection: 'row',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 32,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['App Sun Orange F4A100']}
                    name={'Ionicons/information-circle-outline'}
                    size={18}
                  />
                  {/* Get paid x per lesson */}
                  <>
                    {instructorBid ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'Poppins_400Regular',
                            marginLeft: 4,
                          },
                          dimensions.width
                        )}
                      >
                        {'Get paid $'}
                        {props.route?.params?.payPerLesson ??
                          defaultProps.payPerLesson}
                        {' per lesson'}
                      </Text>
                    )}
                  </>
                  {/* Get paid x per lesson bid */}
                  <>
                    {!instructorBid ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Sun Orange F4A100'],
                            fontFamily: 'Poppins_400Regular',
                            marginLeft: 4,
                          },
                          dimensions.width
                        )}
                      >
                        {'Get paid $'}
                        {calcBidPayPerLesson(
                          instructorBid,
                          props.route?.params?.lessonsCount ??
                            defaultProps.lessonsCount
                        )}
                        {' per lesson bid'}
                      </Text>
                    )}
                  </>
                </View>
              </View>
              {/* View Location Block */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 24 },
                  dimensions.width
                )}
              >
                {/* View Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'baseline',
                      flexDirection: 'row',
                      marginBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* H1 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 24,
                        marginRight: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {'Client'}
                  </Text>
                  {/* H1 Accent */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Red EB3E26'],
                        fontFamily: 'LeckerliOne_400Regular',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                  >
                    {'Location'}
                  </Text>
                </View>
                {/* View Address Block */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['Custom Color_6'],
                      borderRadius: 16,
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'flex-start', height: 40, width: 52 },
                      dimensions.width
                    )}
                  >
                    <SVG
                      source={Constants['uiLocationIcon']}
                      style={StyleSheet.applyWidth(
                        { height: 40, width: 40 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <Pressable
                    onPress={() => {
                      try {
                        openMap(
                          (
                            props.route?.params?.addressObjClient ??
                            defaultProps.addressObjClient
                          )?.street,
                          (
                            props.route?.params?.addressObjClient ??
                            defaultProps.addressObjClient
                          )?.city,
                          (
                            props.route?.params?.addressObjClient ??
                            defaultProps.addressObjClient
                          )?.zip
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* View Address and Icon Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          maxWidth: '100%',
                          paddingRight: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Address and Distance Conatiner */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexWrap: 'nowrap', width: '80%' },
                          dimensions.width
                        )}
                      >
                        {/* Address Container */}
                        <View>
                          {/* Address */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.text.strong,
                                fontFamily: 'Poppins_600SemiBold',
                              },
                              dimensions.width
                            )}
                          >
                            {props.route?.params?.addressClient ??
                              defaultProps.addressClient}
                          </Text>
                        </View>
                        {/* View Distance Container */}
                        <View>
                          {/* Distance */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {mathRound(
                              props.route?.params?.distance ??
                                defaultProps.distance
                            )}
                            {' Miles Away'}
                          </Text>
                        </View>
                      </View>
                      {/* View Right Arrow Icon Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', height: 16, width: '20%' },
                          dimensions.width
                        )}
                      >
                        <SVG
                          source={Constants['uiArrowRightBlack']}
                          style={StyleSheet.applyWidth(
                            { height: 16, width: 16 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  </Pressable>
                </View>
                {/* Pool type */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['pool type block'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['pool type block'].style,
                    dimensions.width
                  )}
                >
                  {/* Alert pool type */}
                  <Touchable
                    onPress={() => {
                      try {
                        alertPoolType(
                          props.route?.params?.poolType ?? defaultProps.poolType
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* View Row */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Pool Icon */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 20,
                            marginRight: 12,
                            width: 4,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Home Pool */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) === 'Home Pool'
                          ) ? null : (
                            <Icon
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/home'}
                              size={18}
                              style={StyleSheet.applyWidth(
                                { marginRight: 6 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Housing Community Pool */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) ===
                            'Housing Community Pool'
                          ) ? null : (
                            <Icon
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/home-city-outline'}
                              size={18}
                            />
                          )}
                        </>
                        {/* Public Pool */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) === 'Public Pool'
                          ) ? null : (
                            <Icon
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/city'}
                              size={20}
                            />
                          )}
                        </>
                        {/* Hotel Pool */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) === 'Hotel Pool'
                          ) ? null : (
                            <Icon
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/office-building'}
                              size={20}
                            />
                          )}
                        </>
                        {/* Members Only */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) ===
                            'Members Only Pool (country club, gym, etc)'
                          ) ? null : (
                            <Icon
                              name={
                                'MaterialCommunityIcons/card-account-details-star-outline'
                              }
                              size={18}
                            />
                          )}
                        </>
                        {/* Other Pool */}
                        <>
                          {!(
                            (props.route?.params?.poolType ??
                              defaultProps.poolType) === 'Other Pool'
                          ) ? null : (
                            <Icon
                              name={'MaterialCommunityIcons/home-modern'}
                              size={20}
                            />
                          )}
                        </>
                      </View>
                      {/* View Pool Type */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '90%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Pool Type */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {props.route?.params?.poolType ??
                            defaultProps.poolType}
                        </Text>
                        {/* Info */}
                        <IconButton
                          color={
                            palettes.App['App Disabled Button Text 7F868C']
                          }
                          icon={'MaterialCommunityIcons/information-outline'}
                          size={16}
                        />
                      </View>
                    </View>
                  </Touchable>
                </View>
              </View>
              {/* View Student Block */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 24 },
                  dimensions.width
                )}
              >
                {/* View Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'baseline',
                      flexDirection: 'row',
                      marginBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* H1 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 24,
                        marginRight: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {'Student'}
                  </Text>
                  {/* H1 Accent */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Red EB3E26'],
                        fontFamily: 'LeckerliOne_400Regular',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                  >
                    {'Details'}
                  </Text>
                </View>
                <FlashList
                  data={splitStringOnComma(
                    props.route?.params?.studentsDetails ??
                      defaultProps.studentsDetails
                  )}
                  estimatedItemSize={50}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(flashListData, index) =>
                    flashListData?.id ??
                    flashListData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(flashListData)
                  }
                  listKey={'8EWOHm93'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const flashListData = item;
                    return (
                      <>
                        {/* View Student Details Block */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['Custom Color_6'],
                              borderRadius: 16,
                              flexDirection: 'row',
                              marginBottom: 6,
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                height: 40,
                                justifyContent: 'center',
                                marginRight: 12,
                                width: 40,
                              },
                              dimensions.width
                            )}
                          >
                            <SVG
                              source={Constants['uiProfileOrangeBgCircle']}
                              style={StyleSheet.applyWidth(
                                { height: 40, width: 40 },
                                dimensions.width
                              )}
                            />
                          </View>
                          {/* View Name and Age */}
                          <View>
                            {/* Name */}
                            <View>
                              {/* Student name */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.text.strong,
                                    fontFamily: 'Poppins_600SemiBold',
                                    textTransform: 'capitalize',
                                  },
                                  dimensions.width
                                )}
                              >
                                {flashListData}
                              </Text>
                            </View>
                            {/* Age */}
                            <>
                              {!falseValue ? null : (
                                <View>
                                  {/* Age */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'30 - 40 Years Old'}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flex: 1 },
                    dimensions.width
                  )}
                />
                {/* View Additional Student Details */}
                <View
                  {...GlobalStyles.ViewStyles(theme)[
                    'additional student details block'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)[
                      'additional student details block'
                    ].style,
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 20,
                        marginRight: 12,
                        width: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['App Black 191F32']}
                      name={'MaterialCommunityIcons/book-account'}
                      size={18}
                      style={StyleSheet.applyWidth(
                        { marginRight: 6 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      { width: '90%' },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'More information about '}
                      {getStudentsFirstName(
                        props.route?.params?.studentsDetails ??
                          defaultProps.studentsDetails
                      )}
                    </Text>
                    {/* Additional student info */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                            marginTop: 6,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {props.route?.params?.studentsDetailsAdditional ??
                        defaultProps.studentsDetailsAdditional}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* View Divider */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Grey E5E5E5'],
                  height: 4,
                  width: '100%',
                },
                dimensions.width
              )}
            />
            {/* View Scheduling Details */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: '5.35%', paddingRight: '5.35%', paddingTop: 24 },
                dimensions.width
              )}
            >
              {/* View Schedule Block */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 24 },
                  dimensions.width
                )}
              >
                {/* View Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'baseline',
                      flexDirection: 'row',
                      marginBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* H1 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 24,
                        marginRight: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {'Requested'}
                  </Text>
                  {/* H1 Accent */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Red EB3E26'],
                        fontFamily: 'LeckerliOne_400Regular',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                  >
                    {'Schedule'}
                  </Text>
                </View>
                {/* Scheduling Start and Lessons Req */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      flexDirection: 'row',
                      paddingBottom: 16,
                    },
                    dimensions.width
                  )}
                >
                  {/* Start Date Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <View>
                      {/* Start */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'Start Date'}
                      </Text>
                    </View>
                    {/* Date */}
                    <View>
                      {/* Date */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.text.strong,
                            fontFamily: 'Poppins_600SemiBold',
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.startDate ??
                          defaultProps.startDate}
                      </Text>
                    </View>
                  </View>
                  {/* Lessons Per Week Block */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '50%' },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <View>
                      {/* Lessons / Week */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'Lessons / Week'}
                      </Text>
                    </View>
                    {/* Lessons */}
                    <View>
                      {/* No. of Lessons */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.text.strong,
                            fontFamily: 'Poppins_600SemiBold',
                          },
                          dimensions.width
                        )}
                      >
                        {props.route?.params?.lessonsPerWeek ??
                          defaultProps.lessonsPerWeek}
                        {' Lessons'}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* View Scheduling Notes */}
                <>
                  {!(
                    props.route?.params?.availabilityNotes ??
                    defaultProps.availabilityNotes
                  ) ? null : (
                    <View
                      {...GlobalStyles.ViewStyles(theme)[
                        'additional student details block'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)[
                            'additional student details block'
                          ].style,
                          {
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderLeftWidth: 0,
                            borderRadius: 0,
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* View Icon Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 12,
                            width: 4,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App['App Black 191F32']}
                          name={'MaterialCommunityIcons/calendar-text-outline'}
                          size={18}
                          style={StyleSheet.applyWidth(
                            { marginRight: 6 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          { width: '90%' },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Client notes about their schedule'}
                        </Text>
                        {/* Scheduling notes */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                                marginTop: 6,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {props.route?.params?.specialSchedulingRequests ??
                            defaultProps.specialSchedulingRequests}
                        </Text>
                      </View>
                    </View>
                  )}
                </>
              </View>
              {/* View Days Available Block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    marginBottom: 16,
                    paddingBottom: 16,
                  },
                  dimensions.width
                )}
              >
                {/* Label */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 8 },
                    dimensions.width
                  )}
                >
                  {/* Days Available */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {'Days available'}
                  </Text>
                </View>
                {/* View Days Available Container */}
                <View>
                  <Utils.CustomCodeErrorBoundary>
                    <viewOpportunities.availableDays
                      availableDaysClient={
                        props.route?.params?.availabilityDays
                      }
                    />
                  </Utils.CustomCodeErrorBoundary>
                </View>
                {/* Legend */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginRight: 6 },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['App Brand Blue 2C9AD9']}
                      name={'FontAwesome/circle'}
                      size={8}
                    />
                  </View>
                  {/* Key */}
                  <View>
                    {/* Client is available */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'Client is available'}
                    </Text>
                  </View>
                </View>
              </View>
              {/* View Weekdays Times Available Block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    marginBottom: 16,
                    paddingBottom: 16,
                  },
                  dimensions.width
                )}
              >
                {/* Label */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 8 },
                    dimensions.width
                  )}
                >
                  {/* Weekdays Times */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {'Weekdays Times Available'}
                  </Text>
                </View>
                {/* View Days Available Container */}
                <View>
                  <Utils.CustomCodeErrorBoundary>
                    <viewOpportunities.TimesAvailable
                      availableTimesClient={
                        props.route?.params?.availabilityTimesWeekDay
                      }
                    />
                  </Utils.CustomCodeErrorBoundary>
                </View>
              </View>
              {/* View Weekends Times Available Block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    marginBottom: 16,
                    paddingBottom: 16,
                  },
                  dimensions.width
                )}
              >
                {/* Label */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 8 },
                    dimensions.width
                  )}
                >
                  {/* Weekends Times */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Text 2ndary 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {'Weekends Times Available'}
                  </Text>
                </View>
                {/* View Days Available Container */}
                <View>
                  <Utils.CustomCodeErrorBoundary>
                    <viewOpportunities.TimesAvailable
                      availableTimesClient={
                        props.route?.params?.availabilityTimesWeekEnd
                      }
                    />
                  </Utils.CustomCodeErrorBoundary>
                </View>
              </View>
            </View>
          </View>
          {/* Spacer When Alternate Schedule Available */}
          <Spacer left={8} right={8} bottom={20} top={20} />
        </ScrollView>
      </View>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderColor: palettes.App['App Disabled Button Text 7F868C'],
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderTopWidth: 2,
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Show Higher Pay Bid */}
        <>
          {!instructorBid ? null : (
            <View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingBottom: 12,
                    paddingTop: 12,
                  },
                  dimensions.width
                )}
              >
                {/* Requesting this package for */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                      marginRight: 2,
                    },
                    dimensions.width
                  )}
                >
                  {'Requesting this package for '}
                </Text>
                {/* Edit bid */}
                <IconButton
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'showModalDeclineSurvey',
                        value: true,
                      });
                      setRadioButtonGroupValue('pay');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  color={palettes.App['App Gold #FFC400']}
                  icon={'MaterialCommunityIcons/pencil-outline'}
                  size={18}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 4 },
                    dimensions.width
                  )}
                />
                {/* Instructor Bid */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Gold FFA900'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'$'}
                  {instructorBid}
                  {'.00'}
                </Text>
              </View>
            </View>
          )}
        </>
        {/* Client Not Open To Alternate Schedules */}
        <>
          {props.route?.params?.clientOpenToAlernateSchedule ??
          defaultProps.clientOpenToAlernateSchedule ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgba(0, 0, 0, 0)"',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Black 191F32'],
                  borderLeftWidth: 1,
                  borderRadius: 40,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 20,
                  paddingBottom: '1.75%',
                  paddingLeft: '1.75%',
                  paddingRight: '1.75%',
                  paddingTop: '1.75%',
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 12,
                  },
                  dimensions.width
                )}
              >
                {'Client accepts their requested schedule only'}
              </Text>
            </View>
          )}
        </>
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.SurfaceStyles(theme)['Surface'].style,
              { borderRadius: 8, marginTop: 20 }
            ),
            dimensions.width
          )}
        >
          {/* Agree to Client Schedule Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Blue/Low Emphasis'],
                borderRadius: 8,
                flexDirection: 'row',
                paddingBottom: '1.75%',
                paddingLeft: '1.75%',
                paddingRight: '1.75%',
                paddingTop: '1.75%',
              },
              dimensions.width
            )}
          >
            {/* View Checkbox Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 8,
                  width: '8%',
                },
                dimensions.width
              )}
            >
              <Checkbox
                onPress={newCheckboxValue => {
                  try {
                    setCheckboxValue(newCheckboxValue);
                    setAgreedToClientSchedule(newCheckboxValue);
                    setAlternateScheduleRequested(false);
                    setCheckboxValue2(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                checkedIcon={'FontAwesome/check-circle'}
                color={palettes.App['App Blue#4 007fd4']}
                size={18}
                status={checkboxValue}
                style={StyleSheet.applyWidth(
                  { height: 8, width: 8 },
                  dimensions.width
                )}
                uncheckedColor={palettes.App['App Blue#4 007fd4']}
                uncheckedIcon={'Entypo/circle'}
              />
            </View>
            {/* View Agreement Text Container */}
            <View
              style={StyleSheet.applyWidth({ width: '90%' }, dimensions.width)}
            >
              <Touchable
                onPress={() => {
                  try {
                    Haptic('light');
                    setCheckboxValue(!checkboxValue);
                    setAgreedToClientSchedule(!agreedToClientSchedule);
                    setCheckboxValue2(false);
                    setAlternateScheduleRequested(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* View Preferred Tag */}
                <>
                  {!falseValue ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          alignSelf: 'flex-start',
                          backgroundColor: palettes.App['App Blue#4 007fd4'],
                          borderRadius: 40,
                          marginBottom: 4,
                          paddingBottom: 2,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 2,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['Custom Color'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {'preferred'}
                      </Text>
                    </View>
                  )}
                </>
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Blue#4 007fd4'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {
                    "I can teach 3 lessons per week within the client's listed availability."
                  }
                </Text>
              </Touchable>
            </View>
          </View>
        </Surface>
        <>
          {!(
            props.route?.params?.clientOpenToAlernateSchedule ??
            defaultProps.clientOpenToAlernateSchedule
          ) ? null : (
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              elevation={3}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  { borderRadius: 8, marginTop: 20 }
                ),
                dimensions.width
              )}
            >
              {/* Share Alternate Schedule */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color_6'],
                    borderRadius: 8,
                    flexDirection: 'row',
                    marginTop: 0,
                    paddingBottom: '1.75%',
                    paddingLeft: '1.75%',
                    paddingRight: '1.75%',
                    paddingTop: '1.75%',
                  },
                  dimensions.width
                )}
              >
                {/* View Checkbox Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 8,
                      width: '8%',
                    },
                    dimensions.width
                  )}
                >
                  {/* CheckboxAltSchedule */}
                  <Checkbox
                    onPress={newCheckboxAltScheduleValue => {
                      try {
                        setCheckboxValue2(newCheckboxAltScheduleValue);
                        setAlternateScheduleRequested(
                          newCheckboxAltScheduleValue
                        );
                        setCheckboxValue(false);
                        setAgreedToClientSchedule(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    checkedIcon={'FontAwesome/check-circle'}
                    color={palettes.App['App Sun Orange F4A100']}
                    size={18}
                    status={checkboxValue2}
                    uncheckedColor={palettes.App['App Sun Orange F4A100']}
                    uncheckedIcon={'Entypo/circle'}
                  />
                </View>
                {/* View Agreement Text Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: '90%' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setCheckboxValue2(!checkboxValue2);
                        setAlternateScheduleRequested(
                          !alternateScheduleRequested
                        );
                        setCheckboxValue(false);
                        setAgreedToClientSchedule(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Sun Orange F4A100'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'I can not teach within the clients availability. Share my alternate availability with the client.'
                      }
                    </Text>
                  </Touchable>
                </View>
              </View>
            </Surface>
          )}
        </>
        {/* Fill Alternate Schedule */}
        <>
          {!alternateScheduleRequested ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color_6'],
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  flexDirection: 'column',
                  marginTop: 15,
                  paddingBottom: '1.75%',
                  paddingLeft: '1.75%',
                  paddingRight: '1.75%',
                  paddingTop: 1.75,
                },
                dimensions.width
              )}
            >
              {/* Expand/Contract Alternate Sched */}
              <Touchable
                onPress={() => {
                  try {
                    setAlternateScheduleExpanded(!alternateScheduleExpanded);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* View Title */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', paddingBottom: 10, paddingTop: 10 },
                    dimensions.width
                  )}
                >
                  {/* Icon container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, width: '8%' },
                      dimensions.width
                    )}
                  >
                    {/* Open Icon */}
                    <>
                      {!alternateScheduleExpanded ? null : (
                        <Icon
                          size={24}
                          color={palettes.App['App Sun Orange F4A100']}
                          name={'Entypo/chevron-down'}
                        />
                      )}
                    </>
                    {/* Closed Icon */}
                    <>
                      {alternateScheduleExpanded ? null : (
                        <Icon
                          size={24}
                          color={palettes.App['App Sun Orange F4A100']}
                          name={'Entypo/chevron-right'}
                        />
                      )}
                    </>
                  </View>

                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 14,
                      },
                      dimensions.width
                    )}
                  >
                    {'My alternate availability'}
                  </Text>
                </View>
              </Touchable>
              {/* View Share Alternate Schedule */}
              <>
                {!alternateScheduleExpanded ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'flex-start',
                        alignSelf: 'center',
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 8,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        paddingBottom: 6,
                        paddingLeft: 9,
                        paddingRight: 9,
                        paddingTop: 6,
                        width: '98%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Monday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 6,
                          paddingBottom: 6,
                          paddingTop: 6,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Monday */}
                      <SwitchRow
                        onValueChange={newMondayValue => {
                          try {
                            setSwitchRowValue(newMondayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Monday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue}
                      />
                      {/* View Monday Time Options1 */}
                      <>
                        {!switchRowValue ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerMonday1StartValue => {
                                  try {
                                    setPickerValue(newPickerMonday1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].style,
                                    { height: 30 }
                                  ),
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday1End */}
                              <>
                                {!pickerValue ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerMonday1EndValue => {
                                      try {
                                        setPickerValue2(
                                          newPickerMonday1EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'buttonIncremental'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ViewStyles(theme)[
                                  'buttonIncremental'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue2 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        if (timeslotMonday >= 3) {
                                          return;
                                        }
                                        setTimeslotMonday(timeslotMonday + 1);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Monday Time Options2 */}
                      <>
                        {!(timeslotMonday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerMonday2StartValue => {
                                  try {
                                    setPickerValue3(newPickerMonday2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Start time'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday2End */}
                              <>
                                {!pickerValue3 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerMonday2EndValue => {
                                      try {
                                        setPickerValue4(
                                          newPickerMonday2EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Minus Monday Time Row */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'buttonIncremental'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ViewStyles(theme)[
                                  'buttonIncremental'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    setTimeslotMonday(timeslotMonday - 1);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Monday Time Options3 */}
                      <>
                        {!(timeslotMonday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Start time'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Monday3End */}
                              <>
                                {!pickerValue5 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'buttonIncremental'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ViewStyles(theme)[
                                  'buttonIncremental'
                                ].style,
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    setTimeslotMonday(timeslotMonday - 1);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Tuesday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Tuesday */}
                      <SwitchRow
                        onValueChange={newTuesdayValue => {
                          try {
                            setSwitchRowValue2(newTuesdayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Tuesday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue2}
                      />
                      {/* View Tuesday Time Options1 */}
                      <>
                        {!switchRowValue2 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerTuesday1StartValue => {
                                  try {
                                    setPickerValue7(
                                      newPickerTuesday1StartValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday1End */}
                              <>
                                {!pickerValue7 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerTuesday1EndValue => {
                                      try {
                                        setPickerValue8(
                                          newPickerTuesday1EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue8 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        if (timeslotsTuesday >= 3) {
                                          return;
                                        }
                                        setTimeslotsTuesday(
                                          timeslotsTuesday + 1
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Tuesday Time Options2 */}
                      <>
                        {!(timeslotsTuesday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerTuesday2StartValue => {
                                  try {
                                    setPickerValue9(
                                      newPickerTuesday2StartValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday2End */}
                              <>
                                {!pickerValue9 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerTuesday2EndValue => {
                                      try {
                                        setPickerValue10(
                                          newPickerTuesday2EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Minus Tuesday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    setTimeslotsTuesday(timeslotsTuesday - 1);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Tuesday Time Options3 */}
                      <>
                        {!(timeslotsTuesday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerTuesday3StartValue => {
                                  try {
                                    setPickerValue11(
                                      newPickerTuesday3StartValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Tuesday3End */}
                              <>
                                {!pickerValue11 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerTuesday3EndValue => {
                                      try {
                                        setPickerValue12(
                                          newPickerTuesday3EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    undefined;
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Wednesday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Wednesday */}
                      <SwitchRow
                        onValueChange={newWednesdayValue => {
                          try {
                            setSwitchRowValue3(newWednesdayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Wednesday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue3}
                      />
                      {/* View Wednesday Time Options1 */}
                      <>
                        {!switchRowValue3 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerWed1StartValue => {
                                  try {
                                    setPickerValue13(newPickerWed1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed1End */}
                              <>
                                {!pickerValue13 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerWed1EndValue => {
                                      try {
                                        setPickerValue14(newPickerWed1EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue14 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        if (timeslotsWednesday >= 3) {
                                          return;
                                        }
                                        setTimeslotsWednesday(
                                          timeslotsWednesday + 1
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Wednesday Time Options2 */}
                      <>
                        {!(timeslotsWednesday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerWed2StartValue => {
                                  try {
                                    setPickerValue15(newPickerWed2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed2End */}
                              <>
                                {!pickerValue15 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerWed2EndValue => {
                                      try {
                                        setPickerValue16(newPickerWed2EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'wed',
                                      timeslotsWednesday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Wednesday Time Options3 */}
                      <>
                        {!(timeslotsWednesday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerWed3StartValue => {
                                  try {
                                    setPickerValue17(newPickerWed3StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Wed3End */}
                              <>
                                {!pickerValue17 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerWed3EndValue => {
                                      try {
                                        setPickerValue18(newPickerWed3EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'wed',
                                      undefined,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Thursday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      <SwitchRow
                        onValueChange={newSwitchRowValue => {
                          try {
                            setSwitchRowValue4(newSwitchRowValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Thursday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue4}
                      />
                      {/* View Thursday Time Options1 */}
                      <>
                        {!switchRowValue4 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerThur1StartValue => {
                                  try {
                                    setPickerValue19(newPickerThur1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur1End */}
                              <>
                                {!pickerValue19 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerThur1EndValue => {
                                      try {
                                        setPickerValue20(
                                          newPickerThur1EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Thursday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue20 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        changeTimeslotsCount(
                                          'thu',
                                          timeslotsThursday,
                                          'increment'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Thursday Time Options2 */}
                      <>
                        {!(timeslotsThursday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerThur2StartValue => {
                                  try {
                                    setPickerValue21(newPickerThur2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur2End */}
                              <>
                                {!pickerValue21 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerThur2EndValue => {
                                      try {
                                        setPickerValue22(
                                          newPickerThur2EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'thu',
                                      timeslotsThursday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Thursday Time Options3 */}
                      <>
                        {!(timeslotsThursday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerThur3StartValue => {
                                  try {
                                    setPickerValue23(newPickerThur3StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Thur3End */}
                              <>
                                {!pickerValue23 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerThur3EndValue => {
                                      try {
                                        setPickerValue24(
                                          newPickerThur3EndValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'thu',
                                      timeslotsThursday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Friday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Friday */}
                      <SwitchRow
                        onValueChange={newFridayValue => {
                          try {
                            setSwitchRowValue5(newFridayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Friday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue5}
                      />
                      {/* View Friday Time Options1 */}
                      <>
                        {!switchRowValue5 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerFri1StartValue => {
                                  try {
                                    setPickerValue25(newPickerFri1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri1End */}
                              <>
                                {!pickerValue25 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerFri1EndValue => {
                                      try {
                                        setPickerValue26(newPickerFri1EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Friday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue26 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        changeTimeslotsCount(
                                          'fri',
                                          timeslotsFriday,
                                          'increment'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Friday Time Options2 */}
                      <>
                        {!(timeslotsFriday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerFri2StartValue => {
                                  try {
                                    setPickerValue27(newPickerFri2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri2End */}
                              <>
                                {!pickerValue27 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerFri2EndValue => {
                                      try {
                                        setPickerValue28(newPickerFri2EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'fri',
                                      timeslotsFriday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Friday Time Options3 */}
                      <>
                        {!(timeslotsFriday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerFri3StartValue => {
                                  try {
                                    setPickerValue29(newPickerFri3StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Fri3End */}
                              <>
                                {!pickerValue29 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerFri3EndValue => {
                                      try {
                                        setPickerValue30(newPickerFri3EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'fri',
                                      timeslotsFriday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Saturday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Saturday */}
                      <SwitchRow
                        onValueChange={newSaturdayValue => {
                          try {
                            setSwitchRowValue6(newSaturdayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        direction={'row'}
                        label={'Saturday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue6}
                      />
                      {/* View Saturday Time Options1 */}
                      <>
                        {!switchRowValue6 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSat1StartValue => {
                                  try {
                                    setPickerValue31(newPickerSat1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat1End */}
                              <>
                                {!pickerValue31 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSat1EndValue => {
                                      try {
                                        setPickerValue32(newPickerSat1EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Friday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue32 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        changeTimeslotsCount(
                                          'sat',
                                          timeslotsSaturday,
                                          'increment'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Saturday Time Options2 */}
                      <>
                        {!(timeslotsSaturday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSat2StartValue => {
                                  try {
                                    setPickerValue33(newPickerSat2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat2End */}
                              <>
                                {!pickerValue33 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSat2EndValue => {
                                      try {
                                        setPickerValue34(newPickerSat2EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'sat',
                                      timeslotsSaturday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Saturday Time Options3 */}
                      <>
                        {!(timeslotsSaturday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSat3StartValue => {
                                  try {
                                    setPickerValue35(newPickerSat3StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sat3End */}
                              <>
                                {!pickerValue35 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSat3EndValue => {
                                      try {
                                        setPickerValue36(newPickerSat3EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'sat',
                                      timeslotsSaturday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Sunday Availability */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 6, paddingTop: 6, width: '100%' },
                        dimensions.width
                      )}
                    >
                      {/* Sunday */}
                      <SwitchRow
                        onValueChange={newSundayValue => {
                          try {
                            setSwitchRowValue7(newSundayValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchRowStyles(theme)['availability']
                          .props}
                        activeTrackColor={palettes.App['App Blue#4 007fd4']}
                        label={'Sunday'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchRowStyles(theme)['availability']
                            .style,
                          dimensions.width
                        )}
                        value={switchRowValue7}
                      />
                      {/* View Sunday Time Options1 */}
                      <>
                        {!switchRowValue7 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun1Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSun1StartValue => {
                                  try {
                                    setPickerValue37(newPickerSun1StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun1End */}
                              <>
                                {!pickerValue37 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSun1EndValue => {
                                      try {
                                        setPickerValue38(newPickerSun1EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Friday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <>
                                {!pickerValue38 ? null : (
                                  <IconButton
                                    onPress={() => {
                                      try {
                                        changeTimeslotsCount(
                                          'sun',
                                          timeslotsSunday,
                                          'increment'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    color={palettes.App['App Blue#4 007fd4']}
                                    icon={'FontAwesome/plus-circle'}
                                    size={26}
                                  />
                                )}
                              </>
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Sunday Time Options2 */}
                      <>
                        {!(timeslotsSunday > 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun2Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSun2StartValue => {
                                  try {
                                    setPickerValue39(newPickerSun2StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                                type={'underline'}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun2End */}
                              <>
                                {!pickerValue39 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSun2EndValue => {
                                      try {
                                        setPickerValue40(newPickerSun2EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'sun',
                                      timeslotsSunday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* View Sunday Time Options3 */}
                      <>
                        {!(timeslotsSunday > 2) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Start Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun3Start */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerSun3StartValue => {
                                  try {
                                    setPickerValue41(newPickerSun3StartValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                type={'solid'}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'pickerTime'
                                ].props}
                                iconSize={16}
                                options={Constants['gAlternateScheduleTimes']}
                                placeholder={'Available at'}
                                placeholderTextColor={
                                  palettes.App['App Text 2ndary 7F868C']
                                }
                                rightIconName={
                                  'Entypo/chevron-with-circle-down'
                                }
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)['pickerTime']
                                    .style,
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View End Time */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingTop: 8, width: '40%' },
                                dimensions.width
                              )}
                            >
                              {/* Picker Sun3End */}
                              <>
                                {!pickerValue41 ? null : (
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickerSun3EndValue => {
                                      try {
                                        setPickerValue42(newPickerSun3EndValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    {...GlobalStyles.PickerStyles(theme)[
                                      'pickerTime'
                                    ].props}
                                    iconSize={16}
                                    options={
                                      Constants['gAlternateScheduleTimes']
                                    }
                                    placeholder={'Until time'}
                                    placeholderTextColor={
                                      palettes.App['App Text 2ndary 7F868C']
                                    }
                                    rightIconName={
                                      'Entypo/chevron-with-circle-down'
                                    }
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].style,
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Add Monday Time Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '10%',
                                },
                                dimensions.width
                              )}
                            >
                              <IconButton
                                onPress={() => {
                                  try {
                                    changeTimeslotsCount(
                                      'sun',
                                      timeslotsSunday,
                                      'decrement'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={
                                  palettes.App['App Disabled Button EDEDEF']
                                }
                                icon={'FontAwesome/minus-circle'}
                                size={26}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        {/* View Row Button Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 0,
              marginTop: 20,
              paddingBottom: 0,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Decline Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', width: '48%' },
              dimensions.width
            )}
          >
            {/* Decline Button */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'showModalDeclineSurvey',
                    value: true,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Red EB3E26'],
                  borderLeftWidth: 1,
                  borderRadius: 50,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: palettes.App['App Red EB3E26'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  paddingBottom: 14,
                  paddingTop: 14,
                  textAlign: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
              title={'DECLINE'}
            />
          </View>
          {/* Request Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', width: '48%' },
              dimensions.width
            )}
          >
            {/* Request Disabled */}
            <>
              {enableRequestButton ? null : (
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 50,
                      color: palettes.App['App Disabled Button Text 7F868C'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'REQUEST'}
                />
              )}
            </>
            {/* Request Enabled */}
            <>
              {!enableRequestButton ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowSubmitModal(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'REQUEST'}
                />
              )}
            </>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
      {/* Modal Submit Request */}
      <>
        {!showSubmitModal ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  bottom: 0,
                  paddingTop: '125%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowSubmitModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowSubmitModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {
                            "Tell Sunsational why you're a good fit for this client!"
                          }
                        </Text>
                      </View>
                      {/* View Text Area Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { height: 145, paddingBottom: 16 },
                          dimensions.width
                        )}
                      >
                        <TextInput
                          autoCorrect={true}
                          changeTextDelay={500}
                          multiline={true}
                          numberOfLines={4}
                          onChangeText={newTextAreaValue => {
                            try {
                              setTextReasonWhy(newTextAreaValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          textAlignVertical={'top'}
                          webShowOutline={true}
                          editable={true}
                          placeholder={'Enter your short message'}
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Grey Inputs E2E2E2'],
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                              height: '100%',
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Instructions */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['field instructions']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['field instructions']
                            .style,
                          dimensions.width
                        )}
                      >
                        {/* View Icon Holder */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '5%' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App['App Text 2ndary 7F868C']}
                            name={'Feather/info'}
                            size={16}
                            style={StyleSheet.applyWidth(
                              { height: 16, width: 16 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* View Instructions Text */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '93%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'This field is optional but could help improve your chance to get matched with this client.'
                            }
                          </Text>
                        </View>
                      </View>
                    </View>
                  )}
                </>
                {/* View Show While Request Uploading */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 8,
                          height: 200,
                          marginBottom: 16,
                          overflow: 'hidden',
                          width: '97%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Sending your request!'}
                        </Text>
                      </View>

                      <ImageBackground
                        resizeMode={'cover'}
                        source={imageSource(
                          'https://thumbs.gfycat.com/WickedAdventurousFairyfly-size_restricted.gif'
                        )}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Activity Holder */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              alignSelf: 'center',
                              height: '100%',
                              paddingTop: 50,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            color={palettes.App['Custom Color']}
                            size={'large'}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </ImageBackground>
                    </View>
                  )}
                </>
                {/* View Request Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Submit Request Disabled */}
                  <>
                    {!falseValue ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Submit Request'}
                      />
                    )}
                  </>
                  {/* Submit Request Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await sendPackageRequest(
                            Variables,
                            setGlobalVariableValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Submit Request'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Spacer Move Modal Up While Sending Request */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <Spacer left={8} right={8} bottom={135} top={135} />
                  )}
                </>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Request Success */}
      <>
        {!showModalRequestSuccess ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await closeModalRequestSuccess(
                            Variables,
                            setGlobalVariableValue
                          );
                          navigation.navigate('JobsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await closeModalRequestSuccess(
                            Variables,
                            setGlobalVariableValue
                          );
                          navigation.navigate('JobsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['sendRequestPackageResponse']}
                  {
                    '. Great job! Request or decline more packages near you now.'
                  }
                </Text>
              </View>
              {/* View Request More Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 34, width: '100%' },
                  dimensions.width
                )}
              >
                {/* See more open jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await closeModalRequestSuccess(
                          Variables,
                          setGlobalVariableValue
                        );
                        navigation.navigate('JobsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Request more packages'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -52,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Decline Survey */}
      <>
        {!Constants['showModalDeclineSurvey'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'modalBackground'
              ].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                    'modalBackground'
                  ].style,
                  { width: '100%' }
                ),
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                          setRadioButtonGroupValue(Constants['empty']);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setRadioButtonGroupValue(Constants['empty']);
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Decline Questions */}
                <View>
                  {/* View Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'What is the main reason you are declining this package?'
                      }
                    </Text>
                  </View>
                  {/* View Questionnaire Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'flex-start',
                        marginBottom: 20,
                        marginTop: -15,
                      },
                      dimensions.width
                    )}
                  >
                    <RadioButtonGroup
                      onValueChange={newRadioButtonGroupValue => {
                        try {
                          setRadioButtonGroupValue(newRadioButtonGroupValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginLeft: -20 },
                        dimensions.width
                      )}
                    >
                      {/* Schedule row */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            style={StyleSheet.applyWidth(
                              { marginBottom: 0 },
                              dimensions.width
                            )}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'schedule'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Schedule */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"Schedule doesn't work for me"}
                          </Text>
                        </View>
                      </View>
                      {/* Prompt Suggest Alternate Sched */}
                      <>
                        {!(radioButtonGroupValue === 'schedule') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App['App Gold #FFC400'],
                                borderRadius: 9,
                                marginBottom: 16,
                                marginLeft: '5.35%',
                                paddingBottom: 9,
                                paddingLeft: '9.1%',
                                paddingRight: '9.1%',
                                paddingTop: 9,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* Request with alt sched */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.text.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  'Optional: Request this package with an alternate schedule. Clients are often flexible to accommodate the right instructor.'
                                }
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Not accepting clients */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'not accepting clients'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Not accepting clients */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"I'm not currently accepting new clients"}
                          </Text>
                        </View>
                      </View>
                      {/* Distance */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'distance'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Distance */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"It's too far"}
                          </Text>
                        </View>
                      </View>
                      {/* Pay */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'pay'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Pay */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Pay is not enough'}
                          </Text>
                        </View>
                      </View>
                      {/* Request Amount Block */}
                      <>
                        {!(radioButtonGroupValue === 'pay') ? null : (
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'requested payment block'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'requested payment block'
                                ].style,
                                {
                                  borderRadius: 9,
                                  marginBottom: 16,
                                  paddingBottom: 9,
                                  paddingLeft: '9.1%',
                                  paddingRight: '9.1%',
                                  paddingTop: 9,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* What pay would you accept */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.text.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  'Optional: what pay would you accept to teach this package?'
                                }
                              </Text>
                            </View>
                            {/* View Negotiated pay */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'column', paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* View Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Custom Color'],
                                      borderBottomLeftRadius: 8,
                                      borderTopLeftRadius: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* $ */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.text.strong,
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'$'}
                                  </Text>
                                </View>

                                <View>
                                  {/* NegotiatedPay */}
                                  <NumberInput
                                    changeTextDelay={500}
                                    onChangeText={newNegotiatedPayValue => {
                                      try {
                                        setInstructorBid(newNegotiatedPayValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    clearTextOnFocus={true}
                                    editable={true}
                                    maxLength={4}
                                    placeholder={'Enter bid'}
                                    placeholderTextColor={
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ]
                                    }
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Custom Color'],
                                        borderBottomRightRadius: 8,
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.border.brand,
                                        borderLeftWidth: 1,
                                        borderRightWidth: 1,
                                        borderTopRightRadius: 8,
                                        borderTopWidth: 1,
                                        fontFamily: 'Poppins_400Regular',
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        paddingTop: 8,
                                        textAlign: 'right',
                                        width: 100,
                                      },
                                      dimensions.width
                                    )}
                                    value={instructorBid}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Student */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'student'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Student */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Student level/age not for me'}
                          </Text>
                        </View>
                      </View>
                      {/* Lesson length */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'lesson-length'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Student */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Lessons are too long or too short'}
                          </Text>
                        </View>
                      </View>
                    </RadioButtonGroup>
                  </View>
                  {/* View Button Request W/ Alt Sched */}
                  <>
                    {!(radioButtonGroupValue === 'schedule') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Request With Alt Sched */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'showModalDeclineSurvey',
                                value: false,
                              });
                              setCheckboxValue2(true);
                              setAlternateScheduleRequested(true);
                              setCheckboxValue(false);
                              setAgreedToClientSchedule(false);
                              setRadioButtonGroupValue(Constants['empty']);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor:
                                  palettes.App['App Gold #FFC400'],
                                borderColor:
                                  palettes.App['App Sun Orange F4A100'],
                                borderRadius: 50,
                                borderWidth: 1,
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Request With Alt Schedule'}
                        />
                      </View>
                    )}
                  </>
                  {/* View Button Request With Higher Pay */}
                  <>
                    {!(radioButtonGroupValue === 'pay') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Request With Higher Pay */}
                        <>
                          {!(instructorBid > 100) ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'showModalDeclineSurvey',
                                    value: false,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['Button']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button']
                                    .style,
                                  {
                                    backgroundColor:
                                      palettes.App['App Gold #FFC400'],
                                    borderColor:
                                      palettes.App['App Sun Orange F4A100'],
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 18,
                                    paddingBottom: 14,
                                    paddingTop: 14,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request With Higher Pay'}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Button Decline */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginBottom: 32, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Decline Package Disabled */}
                    <>
                      {radioButtonGroupValue ? null : (
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              alertDeclineReasonRequired();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 50,
                              color:
                                palettes.App['App Disabled Button Text 7F868C'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Decline Package'}
                        />
                      )}
                    </>
                    {/* Decline Package Enabled */}
                    <>
                      {!radioButtonGroupValue ? null : (
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await sendDeclinePackageNotice(
                                  Variables,
                                  setGlobalVariableValue,
                                  Constants['gInstructorEmailAddress'],
                                  props.route?.params?.packageId ??
                                    defaultProps.packageId,
                                  radioButtonGroupValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 50,
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Decline Package'}
                        />
                      )}
                    </>
                    {/* View Activity Indicator */}
                    <>
                      {!Constants['is_loading'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 50,
                              marginTop: -50,
                              opacity: 0.8,
                              paddingBottom: 7,
                              paddingTop: 7,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!Constants['is_loading'] ? null : (
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                color={palettes.App['Custom Color']}
                                style={StyleSheet.applyWidth(
                                  { height: 36, width: 36 },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Decline Success */}
      <>
        {!Constants['showModalDeclineSuccess'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'showModalDeclineSuccess',
                          value: false,
                        });
                        navigation.navigate('JobsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'showModalDeclineSuccess',
                          value: false,
                        });
                        navigation.navigate('JobsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['sendDeclinePackageResponse']}
                  {
                    '. Great job! Request or decline more packages near you now.'
                  }
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* See more open jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('JobsScreen');
                      setGlobalVariableValue({
                        key: 'showModalDeclineSuccess',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'See More Open Jobs'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal API Error Message */}
      <>
        {!(Constants['sendDeclinePackageResponse'] === 'Error') ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendDeclinePackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendDeclinePackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Uh oh, there was an error. Please close and try again.'}
                </Text>
              </View>
              {/* View Message body */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Error message */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Error message: '}
                  {Constants['sendDeclinePackageResponse']}
                  {'. The Integromat scenario is off.'}
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close and retry */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      resetSendDeclinePackageResponse(
                        Variables,
                        setGlobalVariableValue
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close and retry'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal LSG */}
      <>
        {!showModalSSSGuarantee ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'pageSheet'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Brand Blue 2C9AD9'],
                  height: '100%',
                  paddingBottom: '5.35%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: '5.35%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalSSSGuarantee(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalSSSGuarantee(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['Custom Color'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 18,
                      marginBottom: 14,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Sunsational Learn To Swim Guaranteed™'}
              </Text>

              <ScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'When you request a package that is Sunsational Learn to Swim Guaranteed, you agree to teach and have the students be able to complete the Sunsational Safety Sequence by the end of the last lesson in the package. The Sunsational Safety Sequence is a skill set for young beginners that states the swimmer should be able to:'
                  }
                </Text>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Turn around to face the edge of the pool from where they entered.'
                    }
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Safely resurface.'}
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Kick back to the edge of the pool.'}
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Grab onto the wall.'}
                  </Text>
                </View>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'If by the end of the last lesson in the package the student(s) is not able to complete the Sunsational Safety Sequence, you agree to teach up to 4 additional lessons at half the original pay rate.'
                  }
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Email office@sunsationalswimschool.com after the 8th or 9th lesson in the series if you believe the a child will require additional lessons to meet the guarantee.'
                  }
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'In the event a student needs additional lessons to meet the guarantee, do NOT schedule additional lessons with the client before consulting Sunsational.'
                  }
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'If you are not confident you can teach and have the students be able to complete the Sunsational Safety Sequence within the lesson package, you should not request a Sunsational Learn to Swim Guaranteed package.'
                  }
                </Text>
              </ScrollView>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(OpportunityDetailsPreloadedScreen);
