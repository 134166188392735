import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  Alert,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Sync from '../custom-files/Sync';
import * as Track from '../custom-files/Track';
import * as nativeProgress from '../custom-files/nativeProgress';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import checkAccountStatus from '../global-functions/checkAccountStatus';
import fetchUserData from '../global-functions/fetchUserData';
import getUserInitials from '../global-functions/getUserInitials';
import updateAccountStatus from '../global-functions/updateAccountStatus';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MyAccountScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [progress, setProgress] = React.useState(0);
  const [receiveNewOpps, setReceiveNewOpps] = React.useState(false);
  const [showActivitySwitchRow, setShowActivitySwitchRow] =
    React.useState(true);
  const [showSwitchOffConfirmation, setShowSwitchOffConfirmation] =
    React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const alertProfileStatusInformation = () => {
    alert(
      'Sets if you would like to receive new job opportunities. If off, you will not receive new job opportunities.'
    );
  };

  const confirmSwitchOffNewOpps = (setGlobalVariableValue, switchValue) => {
    if (!switchValue) {
      setShowActivitySwitchRow(false);
      setShowSwitchOffConfirmation(true);
      return false;
    }

    setGlobalVariableValue({
      key: 'gInstructorWantsNewOpps',
      value: true,
    });

    return true;
  };

  const profileProgress = userDataResponse => {
    const {
      field_321_raw, // user profile image
      field_45_raw, // user biography text
      field_1366_raw, // tipalti status boolean
      field_177_raw, // cpr image
      field_1105_raw, // sunsational101 status boolean
      field_1845, // onboarding calls attended
    } = userDataResponse;

    let sunsational101Complete = field_1105_raw || field_1845;

    let complete = 4;
    let progressCount = 0;

    field_321_raw && field_45_raw ? progressCount++ : null;
    field_1366_raw ? progressCount++ : null;
    field_177_raw ? progressCount++ : null;
    sunsational101Complete ? progressCount++ : null;

    let progressCompletion = progressCount / complete;
    progressCompletion = progressCompletion.toFixed(2);

    setProgress(progressCompletion);
  };

  const testAppVersionSync = async (Variables, setGlobalVariableValue) => {
    Sync.shouldSyncAppVersionToServer(Variables, setGlobalVariableValue);
  };

  const checkForInstructorKnackID = siKnackID => {
    if (siKnackID) {
      return true;
    }

    if (Platform.OS === 'web') {
      alert(
        'Woops, there was an error processing your request. Please logout and log back in to try again.'
      );
      return false;
    } else {
      Alert.alert(
        'Woops',
        'There was an error processing your request. Please logout and log back in to try again.',
        [
          {
            text: 'Cancel',
            onPress: () => {
              console.log('OK Pressed');
              return false;
            },
          },
          {
            text: 'Logout',
            onPress: () => {
              console.log('Logout Pressed');
              navigation.navigate('LoginScreen');
              return false;
            },
          },
        ]
      );
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'MyAccountScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const integromatActivelyReceiveNewOpps$SIControlled$POST =
    IntegromatApi.useActivelyReceiveNewOpps$SIControlled$POST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const userData = await fetchUserData(Variables);
        await updateAccountStatus(Variables, setGlobalVariableValue, userData);
        await checkAccountStatus(Variables, setGlobalVariableValue, userData);
        profileProgress(userData);
        setGlobalVariableValue({
          key: 'screen',
          value: 'profile',
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View
        style={StyleSheet.applyWidth(
          { backgroundColor: palettes.App['App Gold #FFC400'], width: '100%' },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.PressableStyles(theme)['full-width-height']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.PressableStyles(theme)['full-width-height'].style,
                dimensions.width
              )}
            >
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Pressable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'My Account'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
        {/* View Sunset Container */}
        <View
          style={StyleSheet.applyWidth(
            { height: 180, marginBottom: -168, width: '100%' },
            dimensions.width
          )}
        >
          <SVG
            source={
              'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_sunset_orange_web.svg'
            }
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
          />
        </View>
      </View>

      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingTop: 40 },
          dimensions.width
        )}
      >
        {/* View Top Card Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 8,
              borderColor: palettes.App['Custom Color_4'],
              paddingBottom: 18,
            },
            dimensions.width
          )}
        >
          {/* View Header Container */}
          <View>
            {/* View Profile Box */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* View Profile Image Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 4,
                    borderColor: palettes.App['Custom Color'],
                    borderLeftWidth: 4,
                    borderRadius: 100,
                    borderRightWidth: 4,
                    borderTopWidth: 4,
                    height: 120,
                    overflow: 'hidden',
                    width: 120,
                  },
                  dimensions.width
                )}
              >
                {/* Image Background Profile Uploaded */}
                <>
                  {!Constants['gBioImageUrl'] ? null : (
                    <ImageBackground
                      resizeMode={'cover'}
                      source={imageSource(`${Constants['gBioImageUrl']}`)}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  )}
                </>
                {/* Image Background Placeholder */}
                <>
                  {Constants['gBioImage'] ? null : (
                    <ImageBackground
                      resizeMode={'cover'}
                      style={StyleSheet.applyWidth(
                        {
                          height: '100%',
                          justifyContent: 'center',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Initials Container */}
                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              color: palettes.App['Custom Color'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 32,
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            },
                            dimensions.width
                          )}
                        >
                          {getUserInitials(Constants['userFullName'])}
                        </Text>
                      </View>
                    </ImageBackground>
                  )}
                </>
              </View>
              {/* View Edit Option Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderBottomWidth: 6,
                      borderColor: palettes.App['App Red EB3E26'],
                      borderLeftWidth: 6,
                      borderRadius: 100,
                      borderRightWidth: 6,
                      borderTopWidth: 6,
                      flexDirection: 'row',
                      height: 36,
                      marginLeft: -36,
                      width: 36,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('EditProfileScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Edit Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_edit.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </Touchable>
                </View>
              </View>
            </View>
          </View>
          {/* View Top Card Content Container */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: '5.35%', paddingRight: '5.35%' },
              dimensions.width
            )}
          >
            {/* View Username Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingTop: 16 },
                dimensions.width
              )}
            >
              {/* User Name */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 20,
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  },
                  dimensions.width
                )}
              >
                {Constants['userFullName']}
              </Text>
            </View>
            {/* View Bio Text Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  marginBottom: 8,
                  paddingBottom: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
            >
              {/* Bio */}
              <Text
                accessible={true}
                selectable={false}
                numberOfLines={3}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 12,
                  },
                  dimensions.width
                )}
              >
                {Constants['gBioText']}
              </Text>
            </View>
          </View>
          {/* View Profile Status Block */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
              },
              dimensions.width
            )}
          >
            {/* View Profile Setup Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: palettes.App['App Grey Inputs E2E2E2'],
                  borderLeftWidth: 1,
                  borderRadius: 16,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            >
              {/* View Progress Row */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', paddingBottom: 20 },
                  dimensions.width
                )}
              >
                {/* View Progress Circle Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: '20%' },
                    dimensions.width
                  )}
                >
                  <Utils.CustomCodeErrorBoundary>
                    <nativeProgress.Circle
                      progress={progress}
                    ></nativeProgress.Circle>
                  </Utils.CustomCodeErrorBoundary>
                </View>
                {/* Complete Account */}
                <>
                  {!Constants['gAccountComplete'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 10, width: '80%' },
                        dimensions.width
                      )}
                    >
                      {/* View Title Container */}
                      <View>
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_700Bold',
                            },
                            dimensions.width
                          )}
                        >
                          {'Your account is ready for clients!'}
                        </Text>
                      </View>
                      {/* View Body Container */}
                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {
                            'Great job! Your Bio, CPR and Direct Deposit are up-to-date. Request clients now to make money!'
                          }
                        </Text>
                      </View>
                    </View>
                  )}
                </>
                {/* Incomplete Account */}
                <>
                  {Constants['gAccountComplete'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 10, width: '80%' },
                        dimensions.width
                      )}
                    >
                      {/* View Title Container */}
                      <View>
                        {/* Title */}
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_700Bold',
                            },
                            dimensions.width
                          )}
                        >
                          {"You're almost there!"}
                        </Text>
                      </View>
                      {/* View Body Container */}
                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {"Let's complete your account setup now 🚀"}
                        </Text>
                      </View>
                    </View>
                  )}
                </>
              </View>
              {/* Incomplete Requirements */}
              <>
                {Constants['gAccountComplete'] ? null : (
                  <View>
                    {/* View Row Bio */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          borderTopWidth: 1,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingBottom: 18,
                          paddingTop: 18,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Bio Status Row */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Text Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* Action item */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {'Bio'}
                          </Text>
                        </View>
                        {/* View Status Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Status Container */}
                          <>
                            {!(
                              Constants['gInstructorBioStatus'] ===
                              'under review'
                            ) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { paddingRight: 10 },
                                  dimensions.width
                                )}
                              >
                                {/* Under review */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Under review'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* View Incomplete Container */}
                          <>
                            {Constants['gBioImage'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 20,
                                    justifyContent: 'center',
                                    width: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Incomplete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_incomplete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                          {/* View Complete Container */}
                          <>
                            {!Constants['gBioImage'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 20,
                                    justifyContent: 'center',
                                    width: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Complete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                      <>
                        {Constants['gBioComplete'] ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'Onboarding2InstructorBioScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* View Row Complete CTA */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Text Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Action item */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Sun Orange F4A100'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Complete your bio now >'}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                    {/* View Row CPR Certification */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          borderTopWidth: 1,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingBottom: 18,
                          paddingTop: 18,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Row CPR Status */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Text Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* Action item */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {'CPR Certification'}
                          </Text>
                        </View>
                        {/* View Status Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Incomplete Container */}
                          <>
                            {Constants['gCprImage'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Incomplete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_incomplete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                          {/* View Complete Container */}
                          <>
                            {!Constants['gCprImage'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Complete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                      <>
                        {Constants['gCprImage'] ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'Onboarding1UploadCPRScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* View Row Complete CTA */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Text Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Action item */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Sun Orange F4A100'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Upload your CPR certification >'}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                    {/* View Row Tipalti */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          borderTopWidth: 1,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingBottom: 18,
                          paddingTop: 18,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Row Tipalti Status */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Text Container */}
                        <View>
                          {/* Action item */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {'Link direct deposit through Tipalti'}
                          </Text>
                        </View>
                        {/* View Status Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'column',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Incomplete Container */}
                          <>
                            {Constants['gTipaltiComplete'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Incomplete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_incomplete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                          {/* View Complete Container */}
                          <>
                            {!Constants['gTipaltiComplete'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Complete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                      <>
                        {Constants['gTipaltiComplete'] ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'Onboarding3CompleteTipaltiFormsScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* View Row Complete CTA */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Text Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Action item */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Sun Orange F4A100'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Set up direct deposit >'}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                    {/* View Row Sunsational 101 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          borderTopWidth: 1,
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          paddingBottom: 18,
                          paddingTop: 18,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Row 101 Status */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Text Container */}
                        <View>
                          {/* Action item */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {'Complete Sunsational 101'}
                          </Text>
                        </View>
                        {/* View Status Icon Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'column',
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Incomplete Container */}
                          <>
                            {Constants['gSunsational101Complete'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Incomplete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_incomplete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                          {/* View Complete Container */}
                          <>
                            {!Constants['gSunsational101Complete'] ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 20, width: 20 },
                                  dimensions.width
                                )}
                              >
                                {/* Icon Complete */}
                                <SVG
                                  source={
                                    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                                  }
                                  style={StyleSheet.applyWidth(
                                    { height: '100%', width: '100%' },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                      <>
                        {Constants['gSunsational101Complete'] ? null : (
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'OnboardingCompleteSunsational101Screen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* View Row Complete CTA */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Text Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Action item */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Sun Orange F4A100'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Complete 101 Course >'}
                                </Text>
                              </View>
                            </View>
                          </Touchable>
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        {/* View Body Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['Custom Color'],
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Row Active Profile */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            {/* View Label and Switch */}
            <>
              {!showActivitySwitchRow ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Label Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* View Icon Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, width: 24 },
                        dimensions.width
                      )}
                    >
                      {/* Icon Profile */}
                      <SVG
                        source={
                          'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_profile_orange_hallow.svg'
                        }
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* View Label Title Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingLeft: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Label Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Active Profile'}
                      </Text>
                      <IconButton
                        onPress={() => {
                          try {
                            alertProfileStatusInformation();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        color={palettes.App['App Disabled Button Text 7F868C']}
                        icon={'MaterialCommunityIcons/information-outline'}
                        size={16}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 6 },
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                  {/* View Right Arrow Icon Container */}
                  <View>
                    {/* receive new opps */}
                    <Switch
                      onValueChange={newReceiveNewOppsValue => {
                        const handler = async () => {
                          try {
                            const result = confirmSwitchOffNewOpps(
                              setGlobalVariableValue,
                              newReceiveNewOppsValue
                            );
                            if (!newReceiveNewOppsValue) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: true,
                            });
                            (
                              await integromatActivelyReceiveNewOpps$SIControlled$POST.mutateAsync(
                                {
                                  siKnackID: Constants['AUTH_KNACK_USER_ID'],
                                  siWantsNewOpps: true,
                                }
                              )
                            )?.json;
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: false,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      activeTrackColor={palettes.App['App Blue#4 007fd4']}
                      defaultValue={Constants['gInstructorWantsNewOpps']}
                    />
                  </View>
                </View>
              )}
            </>
            {/* View Confirm Switch Off */}
            <>
              {!showSwitchOffConfirmation ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View Confirm Switch Off Actions */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Cancel */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '73%' },
                        dimensions.width
                      )}
                    >
                      {/* Cancel */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setShowActivitySwitchRow(true);
                            setShowSwitchOffConfirmation(false);
                            setSwitchValue(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        disabled={Constants['is_loading']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            {
                              backgroundColor: palettes.App['Custom Color'],
                              borderColor: palettes.App['App Gold #FFC400'],
                              borderWidth: 1,
                              color: palettes.App['App Gold #FFC400'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Cancel'}
                      />
                    </View>
                    {/* Confirm */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Confirm */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const validKnackId = checkForInstructorKnackID(
                                Constants['AUTH_KNACK_USER_ID']
                              );
                              if (!validKnackId) {
                                return;
                              }
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              (
                                await integromatActivelyReceiveNewOpps$SIControlled$POST.mutateAsync(
                                  {
                                    siKnackID: Constants['AUTH_KNACK_USER_ID'],
                                    siWantsNewOpps: false,
                                  }
                                )
                              )?.json;
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                              setShowSwitchOffConfirmation(false);
                              setShowActivitySwitchRow(true);
                              setGlobalVariableValue({
                                key: 'gInstructorWantsNewOpps',
                                value: false,
                              });
                              setSwitchValue(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        disabled={Constants['is_loading']}
                        loading={Constants['is_loading']}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            {
                              backgroundColor:
                                palettes.App['App Blue#4 007fd4'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 16,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Confirm'}
                      />
                    </View>
                  </View>
                  {/* Warning text */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginTop: 6,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['App Black 191F32']}
                      name={'MaterialCommunityIcons/alert-decagram-outline'}
                      size={20}
                      style={StyleSheet.applyWidth(
                        { marginRight: 6, marginTop: 3 },
                        dimensions.width
                      )}
                    />
                    {/* Switch off warning */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Confirming means you will stop receiving job opportunities. Cancel to continue receiving job opps.'
                      }
                    </Text>
                  </View>
                </View>
              )}
            </>
          </View>
          {/* View Row My Address */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('MyAddressScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_location_orange_hallow.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'My Address'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row Job Preferences */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('JobPreferencesScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={Constants['uiGearOrange']}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Job Preferences'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row Pool Access Settings */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('PoolAccessSettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_waves_orange.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Pool Access Settings'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row Notification Settings */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('NotificationSettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_bell_orange_hallow.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Notification Settings'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row Change Password */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://sunsationalswimschool.knack.com/app#mobile-app-si-navigation/forgot-password/'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_lock_orange.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Change Password'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row Sign Out */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 22,
                paddingTop: 22,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'AUTH_KNACK_TOKEN',
                    value: Constants['empty'],
                  });
                  setGlobalVariableValue({
                    key: 'AUTH_KNACK_USER_ID',
                    value: Constants['empty'],
                  });
                  navigation.navigate('Login2Screen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon */}
                    <SVG
                      source={
                        'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_logout_orange.svg'
                      }
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Log out'}
                    </Text>
                  </View>
                </View>
                {/* View Right Arrow Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                >
                  {/* Icon arrow right */}
                  <SVG
                    source={Constants['uiArrowRightBlack']}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Row App Version */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderTopWidth: 1,
                justifyContent: 'center',
                paddingBottom: 10,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                const handler = async () => {
                  try {
                    await testAppVersionSync(Variables, setGlobalVariableValue);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
            >
              {/* View Touchable Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  {/* View Icon Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, justifyContent: 'center', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Icon 2 */}
                    <Icon
                      color={palettes.App['App Text 2ndary 7F868C']}
                      name={'MaterialCommunityIcons/cellphone-information'}
                      size={16}
                    />
                  </View>
                  {/* View Label Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 16 },
                      dimensions.width
                    )}
                  >
                    {/* Label Title */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 11,
                        },
                        dimensions.width
                      )}
                    >
                      {'My App Version: '}
                      {Constants['LATEST_APP_VERSION']?.identifier}
                    </Text>
                  </View>
                </View>
              </View>
            </Touchable>
          </View>
          {/* View Footer Spacer */}
          <View>
            <Spacer left={8} right={8} bottom={50} top={50} />
          </View>
        </View>
      </ScrollView>
      {/* Footer Navigation 2023-09-11 */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-12']
          .props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-12'].style,
          dimensions.width
        )}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* View Navigation Icons Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'auto',
                flex: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 12,
                marginTop: 12,
              },
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                    amplitudeEvents(
                      Variables,
                      'view homescreen',
                      { origin: 'bottom navigation', category: 'general' },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    amplitudeEvents(
                      Variables,
                      'view open jobs list',
                      {
                        origin: 'bottom navigation',
                        category: 'opportunity management',
                      },
                      undefined
                    );
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(
                            'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N'
                          )}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconSearchBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                    amplitudeEvents(
                      Variables,
                      'view client list',
                      {
                        origin: 'bottom navigation',
                        category: 'client management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                    amplitudeEvents(
                      Variables,
                      'view knowledge base',
                      {
                        origin: 'bottom navigation',
                        category: 'knowledge base',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(MyAccountScreen);
