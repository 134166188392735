import React from 'react';
import {
  Button,
  SVG,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as Utility from '../custom-files/Utility';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import getUserInitials from '../global-functions/getUserInitials';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const EditProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [previousBioImage, setPreviousBioImage] = React.useState({});
  const [previousBioText, setPreviousBioText] = React.useState('');
  const loadingMessageSequence = () => {
    const messages = [
      'Looking great',
      'Swimming data to server...',
      'Saving image....',
      'Almost done..',
      'Finishing lap...',
    ];

    function displayMessage(msg) {
      setLoadingMessage(msg);
    }

    messages.forEach((message, index) => {
      setTimeout(() => displayMessage(message), index * 4500);
    });
  };

  const shouldEnableSaveButton = () => {
    if (
      Variables.gBioImage != previousBioImage ||
      Variables.gBioText != previousBioText
    ) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  };

  // Upload bio image to Knack
  const uploadBioImage = async (Variables, setGlobalVariableValue) => {
    console.log('UPLOADING IMAGE');
    await setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    let bioImage = Variables.gBioImage.split(',');
    let base64Data = bioImage[1];

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        base64Data: base64Data,
        knackUserRecordId: Variables.AUTH_KNACK_USER_ID,
      }),
    };

    try {
      if (!Variables.AUTH_KNACK_USER_ID) {
        let preError = 'Missing user ID. Please logout and log back in.';
        setGlobalVariableValue({
          key: 'gUploadError',
          value: preError,
        });
        throw new Error(`Error! status: ${preError}`);
      }

      const uploadImage = await fetch(
        `https://hook.us1.make.com/jhzwdxbmv03nqt6xt8sggb5jfrpvlmpf`,
        requestOptions
      );
      const uploadImageResponse = await uploadImage.json();

      if (!uploadImage.ok) {
        setGlobalVariableValue({
          key: 'gUploadError',
          value: uploadImageResponse.errorMessage,
        });

        throw new Error(`Error! status: ${response.status}`);
      }

      const updateAppVariables = [
        setGlobalVariableValue({
          key: 'gBioImageUrl',
          value: uploadImageResponse.profilePicUrl,
        }),
        setGlobalVariableValue({
          key: 'gInstructorBioStatus',
          value: 'under review',
        }),
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        }),
      ];

      const updateAppVariablesComplete = Promise.all(updateAppVariables);
    } catch (err) {
      console.log('CATCH ERROR BLOCK!');
      console.error(`What is the error?: ${err.message}`);

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    }
  };

  const postSwimInstructorData = async (Variables, setGlobalVariableValue) => {
    try {
      if (Variables.gBioText != previousBioText) {
        (
          await knackAPIUpdateInstructorBiographyPUT.mutateAsync({
            AUTH_USER_KNACK_ID: Constants['AUTH_KNACK_USER_ID'],
            inputBiography: Constants['gBioText'],
          })
        )?.json;
        setPreviousBioText(Variables.gBioText);
      }

      if (Variables.gBioImage != previousBioImage) {
        await uploadBioImage(Variables, setGlobalVariableValue);
        setPreviousBioImage(Variables.gBioImage);
      }
    } catch (err) {
      console.error('Error: ', err);
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'EditProfileScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    setPreviousBioImage(Variables.gBioImage);
    setPreviousBioText(Variables.gBioText);
  }, []);

  React.useEffect(() => {
    shouldEnableSaveButton();
  });
  const knackAPIUpdateInstructorBiographyPUT =
    KnackAPIApi.useUpdateInstructorBiographyPUT();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: '10%',
            justifyContent: 'center',
            maxHeight: '10%',
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignContent: 'center', justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Page Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'Edit Profile'}
            </Text>
          </View>
          {/* View Placeholder */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* View Card Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderRadius: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            height: '90%',
            justifyContent: 'space-between',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            paddingTop: 22,
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Main Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              borderRadius: 24,
              borderRightWidth: 0,
              borderStyle: 'dashed',
              borderTopWidth: 0,
              height: '70%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <KeyboardAwareScrollView
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            enableAutomaticScroll={true}
            enableOnAndroid={true}
            enableResetScrollToCoords={true}
          >
            {/* View Upload Profile Photo Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignContent: 'center', alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const instructorProfilePic = await openImagePickerUtil({
                        mediaTypes: 'Images',
                        allowsEditing: true,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });

                      if (!instructorProfilePic) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'gBioImage',
                        value: instructorProfilePic,
                      });
                      /* hidden 'Set Variable' action */
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      borderRadius: 100,
                      height: 144,
                      justifyContent: 'center',
                      marginBottom: 12,
                      overflow: 'hidden',
                      width: 144,
                    },
                    dimensions.width
                  )}
                >
                  {/* Image Background Placeholder */}
                  <>
                    {Constants['gBioImage'] ? null : (
                      <ImageBackground
                        resizeMode={'cover'}
                        source={imageSource(Images['ProfileFiller'])}
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* View Initial Container */}
                        <>
                          {Constants['gBioImage'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  justifyContent: 'center',
                                  paddingTop: 32,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color: palettes.App['Custom Color'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 32,
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {getUserInitials(Constants['userFullName'])}
                              </Text>
                            </View>
                          )}
                        </>
                      </ImageBackground>
                    )}
                  </>
                  {/* Image Background Uploaded */}
                  <>
                    {!Constants['gBioImage'] ? null : (
                      <ImageBackground
                        resizeMode={'cover'}
                        source={imageSource(`${Constants['gBioImage']}`)}
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </View>
                {/* View Text Container */}
                <>
                  {Constants['gBioImage'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 12,
                          marginTop: -34,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Upload Profile Picture */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Sun Orange F4A100'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              textDecorationLine: 'underline',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'UPLOAD PROFILE PICTURE'}
                      </Text>
                    </View>
                  )}
                </>
              </Touchable>
              {/* Uploading image messages */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View>
                    {/* Uploading messages */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {loadingMessage}
                    </Text>
                  </View>
                )}
              </>
            </View>
            {/* View Bio Input Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 10 },
                dimensions.width
              )}
            >
              {/* View Instructions Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 8, marginTop: 16 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Introduce yourself to your future clients and students'}
                </Text>
              </View>
              {/* View Text Area Container */}
              <View
                style={StyleSheet.applyWidth({ height: 145 }, dimensions.width)}
              >
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'gBioText',
                        value: newTextAreaValue,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  autoCorrect={true}
                  defaultValue={Constants['gBioText']}
                  editable={true}
                  placeholder={'Write a short bio'}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                      height: '100%',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* View Tips Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Blue eef6fa'],
                  borderRadius: 8,
                  flexDirection: 'row',
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* View Icon Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '11%' },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={imageSource(Images['IconInfo'])}
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                />
              </View>
              {/* View Tips Text Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-start', width: '89%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Brand Blue 2C9AD9'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Pro Tip: Share your swimming journey and what makes you a great swim teacher!'
                  }
                </Text>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
        {/* View Bottom CTA Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Save Button */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', width: '100%' },
              dimensions.width
            )}
          >
            {/* Save Disabled */}
            <>
              {isSaveButtonEnabled ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      console.log(Constants['gBioImage']);
                      console.log(previousBioImage);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 50,
                      color: palettes.App['App Disabled Button Text 7F868C'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'SAVE' ?? 'Sign In'}
                />
              )}
            </>
            {/* Save Enabled */}
            <>
              {!isSaveButtonEnabled ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setGlobalVariableValue({
                          key: 'is_loading',
                          value: true,
                        });
                        await postSwimInstructorData(
                          Variables,
                          setGlobalVariableValue
                        );
                        /* hidden 'API Request' action */
                        setGlobalVariableValue({
                          key: 'is_loading',
                          value: false,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'SAVE' ?? 'Sign In'}
                />
              )}
            </>
            {/* View Activity Indicator */}
            <>
              {!Constants['is_loading'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      marginTop: -50,
                      opacity: 0.8,
                      paddingBottom: 7,
                      paddingTop: 7,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    color={palettes.App['Custom Color']}
                    style={StyleSheet.applyWidth(
                      { height: 36, width: 36 },
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(EditProfileScreen);
