const openNativeEmailClient = async () => {
  const handleOpenEmailboxAsync = async () => {
    if (Platform.OS === 'ios') {
      try {
        await openInbox({
          message: messageActionSheetiOS,
          cancelLabel: 'Cancel',
        });
      } catch (error) {
        console.error(`OpenEmailbox > iOS Error > ${error}`);
      }
    }

    if (Platform.OS === 'android') {
      const activityAction = 'android.intent.action.MAIN';
      const intentParams = (IntentLauncher.IntentLauncherParams = {
        category: 'android.intent.category.APP_EMAIL',
      });
      IntentLauncher.startActivityAsync(activityAction, intentParams);
    }
  };
};

export default openNativeEmailClient;
