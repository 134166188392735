import React from 'react';
import {
  Pressable,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as validateTermsAgreement from '../custom-files/validateTermsAgreement';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { articleID: '64cc881c3b9456002868a478' };

const KnowledgeBaseArticleScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [articleContent, setArticleContent] = React.useState('');
  const [articleTitle, setArticleTitle] = React.useState('');
  const [is_loading, setIs_loading] = React.useState(true);
  const [relatedArticles, setRelatedArticles] = React.useState([]);
  const [styling, setStyling] = React.useState('');
  const setScreenVariables = data => {
    const { field_675_raw, field_683 } = data;

    setArticleTitle(field_675_raw);
    setArticleContent(createHTMLDoc(field_683));
    setIs_loading(false);
  };

  const createHTMLDoc = (htmlBody, articleTitle) => {
    const HTMLdoc = `
<!DOCTYPE html>
<html>
    <head>
        <title>${{ articleTitle }}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins&display=swap" rel="stylesheet">
        <style>
            body {
                color: #191F32;
                padding-left: 5.35%;
                padding-right: 5.35%;
            }
            h1, h2, h3, h4, h5 {
                font-family: 'BebasNeue';
            }
            p, ul, li {
                font-family: 'Poppins';
                font-size: 14px;
            }
        </style>
    </head>
    <body>
        ${htmlBody}
    </body>
</html>
`;

    return HTMLdoc;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'KnowledgeBaseScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    validateTermsAgreement.ValidateTermsAgreement(
      props.navigation.navigate,
      Variables
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setIs_loading(true);
        const response = (
          await KnackAPIApi.getArticleDetailsGET(Constants, {
            recordID: props.route?.params?.articleID ?? defaultProps.articleID,
          })
        )?.json;
        setScreenVariables(response);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Nav - Subtitle */}
      <View
        {...GlobalStyles.ViewStyles(theme)['View Top Nav - Subtitle 3'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['View Top Nav - Subtitle 3'].style,
            { backgroundColor: palettes.App['Custom Color_9'] }
          ),
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 8,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          <Pressable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* View Back Arrow Container */}
            <View
              style={StyleSheet.applyWidth(
                { height: 24, width: 24 },
                dimensions.width
              )}
            >
              <SVG
                source={
                  'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_arrow_left_black_square.svg'
                }
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </View>
          </Pressable>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', justifyContent: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['IconKnowledgeBlue'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 32, width: 32 }
                ),
                dimensions.width
              )}
            />
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Loading placeholder */}
      <>
        {!is_loading ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                height: '88%',
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Placeholder title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'auto',
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 8,
                  height: '10%',
                  justifyContent: 'center',
                  marginBottom: 16,
                },
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Placeholder body */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 8,
                  height: '50%',
                  justifyContent: 'center',
                  marginBottom: 16,
                },
                dimensions.width
              )}
            >
              {/* Activity Indicator 2 */}
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            </View>
          </View>
        )}
      </>
      <>
        {is_loading ? null : (
          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
            contentContainerStyle={StyleSheet.applyWidth(
              { flexDirection: 'column', minHeight: '100%' },
              dimensions.width
            )}
          >
            {/* Featured title container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingBottom: 18,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 36,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 30,
                    }
                  ),
                  dimensions.width
                )}
              >
                {articleTitle}
              </Text>
            </View>
            <WebView
              allowUniversalAccessFromFileURLs={false}
              cacheEnabled={true}
              incognito={false}
              javaScriptCanOpenWindowsAutomatically={false}
              javaScriptEnabled={true}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              startInLoadingState={false}
              {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
              allowFileAccessFromFileURLs={true}
              mediaPlaybackRequiresUserAction={true}
              source={{ html: `${articleContent}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.WebViewStyles(theme)['HTML View'].style,
                  { borderWidth: 0, height: '100%' }
                ),
                dimensions.width
              )}
            />
          </ScrollView>
        )}
      </>
      {/* Footer spacer */}
      <View>
        <Spacer left={8} right={8} bottom={50} top={50} />
      </View>
      {/* Footer Navigation 2023-09-08 */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-11']
          .props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-11'].style,
          dimensions.width
        )}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* View Navigation Icons Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'auto',
                flex: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 12,
                marginTop: 12,
              },
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconHomeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(
                            'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N'
                          )}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconSearchBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconBriefcaseBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconProfileBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeGrey'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={imageSource(Images['IconKnowledgeBlue'])}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(KnowledgeBaseArticleScreen);
