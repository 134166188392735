import React from 'react';
import {
  Button,
  KeyboardAvoidingView,
  SVG,
  ScreenContainer,
  Spacer,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { ActivityIndicator, Text, View } from 'react-native';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Track from '../custom-files/Track';
import * as confirmNotificationsSettingsUpdated from '../custom-files/confirmNotificationsSettingsUpdated';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NotificationSettingsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [bannersDailySumOpps, setBannersDailySumOpps] = React.useState(false);
  const [bannersNewOpps, setBannersNewOpps] = React.useState(false);
  const [emailDailySumOpps, setEmailDailySumOpps] = React.useState(false);
  const [emailNewOpps, setEmailNewOpps] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [smsNewOpps, setSmsNewOpps] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const updateNotificationsSettings = async (
    Variables,
    setGlobalVariableValue
  ) => {
    const {
      gNotificationsOppsNewEmail,
      gNotificationsOppsNewSMS,
      gNotificationsOppsNewBanner,
      gNotificationsOppsSumEmail,
      gNotificationsOppsSumBanner,
      gNotificationsAllOptions,
      APP_ID,
      AUTH_KNACK_TOKEN,
      AUTH_KNACK_USER_ID,
    } = Variables;

    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    console.log('AUTH_KNACK_TOKEN === ', AUTH_KNACK_TOKEN);

    const notificationsSettings = [];

    if (gNotificationsOppsNewEmail) {
      notificationsSettings.push(gNotificationsAllOptions[0]);
    }

    if (gNotificationsOppsNewSMS) {
      notificationsSettings.push(gNotificationsAllOptions[1]);
    }

    if (gNotificationsOppsNewBanner) {
      notificationsSettings.push(gNotificationsAllOptions[2]);
    }

    if (gNotificationsOppsSumEmail) {
      notificationsSettings.push(gNotificationsAllOptions[3]);
    }

    if (gNotificationsOppsSumBanner) {
      notificationsSettings.push(gNotificationsAllOptions[4]);
    }

    try {
      const data = {
        field_2386: notificationsSettings,
      };

      const sendNotificationsSettings = await fetch(
        `https://api.knack.com/v1/pages/scene_1130/views/view_2105/records/${AUTH_KNACK_USER_ID}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Knack-Application-Id': APP_ID,
            Authorization: AUTH_KNACK_TOKEN,
          },
          body: JSON.stringify(data),
        }
      );

      const sendNotificationsSettingsResponse =
        await sendNotificationsSettings.json();

      if (!sendNotificationsSettings.ok) {
        console.log('RESPONSE NOT OK BLOCK');

        throw new Error(`Error! status: ${sendNotificationsSettings.status}`);
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      setModalVisible(true);
    } catch (err) {
      console.log('CATCH ERROR BLOCK!');
      console.error(`What is the error?: ${err.message}`);

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'NotificationSettingsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}>
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Icon Back Arrow */}
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              selectable={false}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'Notification Settings'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>

      <KeyboardAvoidingView
        behavior={'padding'}
        enabled={true}
        keyboardVerticalOffset={0}
      >
        {/* View Top Card Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 8,
              borderColor: palettes.App['App Grey Inputs E2E2E2'],
            },
            dimensions.width
          )}
        >
          {/* View Padding */}
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: '5.35%', paddingRight: '5.35%' },
              dimensions.width
            )}
          >
            {/* View New And Updated Opps Settings */}
            <View>
              {/* View Section Title Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 8 },
                  dimensions.width
                )}
              >
                {/* Section Title */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'New opportunities and updated packages'}
                </Text>
              </View>
              {/* View Description Container */}
              <View>
                {/* Description */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Receive a notice when a new job opportunity becomes available'
                  }
                </Text>
              </View>
              {/* View Settings Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignContent: 'center' },
                  dimensions.width
                )}
              >
                {/* View Row Container New Opps Email */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingBottom: 22,
                      paddingTop: 22,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Label Container */}
                  <View>
                    {/* Email */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Email'}
                    </Text>
                  </View>
                  {/* View Switch Container */}
                  <View>
                    {/* NewOppsEmail */}
                    <Switch
                      onValueChange={newNewOppsEmailValue => {
                        try {
                          setGlobalVariableValue({
                            key: 'gNotificationsOppsNewEmail',
                            value: newNewOppsEmailValue,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeTrackColor={palettes.App['App Brand Blue 2C9AD9']}
                      defaultValue={Constants['gNotificationsOppsNewEmail']}
                    />
                  </View>
                </View>
                {/* View Row Container New Opps SMS */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      borderTopWidth: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingBottom: 22,
                      paddingTop: 22,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Label Container */}
                  <View>
                    {/* SMS */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Text Messages'}
                    </Text>
                  </View>
                  {/* View Switch Container */}
                  <View>
                    {/* NewOppsSMS */}
                    <Switch
                      onValueChange={newNewOppsSMSValue => {
                        try {
                          setGlobalVariableValue({
                            key: 'gNotificationsOppsNewSMS',
                            value: newNewOppsSMSValue,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeTrackColor={palettes.App['App Brand Blue 2C9AD9']}
                      defaultValue={Constants['gNotificationsOppsNewSMS']}
                    />
                  </View>
                </View>
                {/* View Row Container New Opps SMS */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      borderTopWidth: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingBottom: 22,
                      paddingTop: 22,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Label Container */}
                  <View>
                    {/* Banners */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {'Banner Notification'}
                    </Text>
                  </View>
                  {/* View Switch Container */}
                  <View>
                    {/* NewOppsBanner */}
                    <Switch
                      onValueChange={newNewOppsBannerValue => {
                        try {
                          setGlobalVariableValue({
                            key: 'gNotificationsOppsNewBanner',
                            value: newNewOppsBannerValue,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      activeTrackColor={palettes.App['App Brand Blue 2C9AD9']}
                      defaultValue={Constants['gNotificationsOppsNewBanner']}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* View Padding */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%', paddingTop: 32 },
            dimensions.width
          )}
        >
          {/* Daily Summary */}
          <View>
            {/* View Section Title Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 8 },
                dimensions.width
              )}
            >
              {/* Section Title */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'Poppins_700Bold',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'Daily opportunities summary'}
              </Text>
            </View>
            {/* View Description Container */}
            <View>
              {/* Description */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Receive a daily notice of new job opportunities'}
              </Text>
            </View>
            {/* View Settings Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignContent: 'center' },
                dimensions.width
              )}
            >
              {/* View Row Container New Opps Email */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 22,
                    paddingTop: 22,
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View>
                  {/* Email */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>
                </View>
                {/* View Switch Container */}
                <View>
                  {/* OppsSummaryEmail */}
                  <Switch
                    onValueChange={newOppsSummaryEmailValue => {
                      try {
                        setGlobalVariableValue({
                          key: 'gNotificationsOppsSumEmail',
                          value: newOppsSummaryEmailValue,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeTrackColor={palettes.App['App Brand Blue 2C9AD9']}
                    defaultValue={Constants['gNotificationsOppsSumEmail']}
                  />
                </View>
              </View>
              {/* View Row Container New Opps SMS */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderTopWidth: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 22,
                    paddingTop: 22,
                  },
                  dimensions.width
                )}
              >
                {/* View Label Container */}
                <View>
                  {/* Banners */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {'Banner Notification'}
                  </Text>
                </View>
                {/* View Switch Container */}
                <View>
                  {/* OppsSummaryBanner */}
                  <Switch
                    onValueChange={newOppsSummaryBannerValue => {
                      try {
                        setGlobalVariableValue({
                          key: 'gNotificationsOppsSumBanner',
                          value: newOppsSummaryBannerValue,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeTrackColor={palettes.App['App Brand Blue 2C9AD9']}
                    defaultValue={Constants['gNotificationsOppsSumBanner']}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 16, width: '100%' },
            dimensions.width
          )}
        >
          {/* Save settings */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const notificationSettings =
                    await updateNotificationsSettings(
                      Variables,
                      setGlobalVariableValue
                    );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Red EB3E26'],
                borderRadius: 50,
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'Save Settings' ?? 'RESET PASSWORD'}
          />
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
      {/* View Modal */}
      <View>
        <>
          {!modalVisible ? null : (
            <Utils.CustomCodeErrorBoundary>
              <confirmNotificationsSettingsUpdated.modal
                navigation={props.navigation}
              ></confirmNotificationsSettingsUpdated.modal>
            </Utils.CustomCodeErrorBoundary>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(NotificationSettingsScreen);
