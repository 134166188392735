const getUserInitials = fullname => {
  if (fullname.length < 1) {
    return '';
  }

  let initials;
  const fullnameCollection = fullname.split(' ');

  if (fullnameCollection.length > 1) {
    initials =
      fullnameCollection.shift().charAt(0) + fullnameCollection.pop().charAt(0);
    return initials.toUpperCase();
  }

  initials = fullnameCollection.shift().charAt(0);
  return initials.toUpperCase();
};

export default getUserInitials;
